import footer from './footer';
import navigation from './navigation';
import account from './account';
import assetOrganic from './asset-organic';
import auditLogin from './audit-login';
import background from './background';

import board from './board';
import card from './card';
import chat from './chat';
import clipboard from './clipboard';
import comment from './comment';
import company from './company';
import dashboard from './dashboard';
import dragAndDrop from './drag-and-drop';
// import evaluation from './evaluation';
// import evaluationAccount from './evaluation-account';
// import evaluationResult from './evaluation-result';
import folder from './folder';
import group from './group';
import media from './media';
import member from './member';
import notification from './notification';
import search from './search';
import shareAsset from './share-asset';
import shareAssetSearch from './share-asset-search';
import subscription from './subscription';

const stores = [
  footer,
  navigation,
  account,
  auditLogin,
  chat,
  search,
  company,
  group,
  member,
  background,
  board,
  dashboard,
  folder,
  media,
  card,
  assetOrganic,
  shareAsset,
  shareAssetSearch,
  clipboard,
  dragAndDrop,
  comment,
  notification,
  subscription
  // evaluation,
  // evaluationAccount,
  // evaluationResult
];

const map = new Map();

stores.forEach((s) => {
  map.set(s.key, { use: s.use });
});

export const mapStores = map;
