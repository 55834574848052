import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

import {
  get as apiGet,
  getAll as apiGetAll,
  create as apiCreate,
  update as apiUpdate,
  getAuthPool as apiGetAuthPool,
  getSettings as apiGetSettings,
  updateSettings as apiUpdateSettings
} from '@/api/endpoints/company';
import { COMPANY_TYPE } from '@/utils/types';

const baseFilter = {
  blocked: false
};

const initialState = {
  loading: 0,
  companies: [],
  hasNextPage: false,
  filter: {},
  searchText: null
};

export const key = COMPANY_TYPE;
export const useCompanyStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, { ...initialState });
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async updateItem(item) {
      this.companies = this.companies.map((company) =>
        company.id !== item.id ? company : { ...item }
      );
    },
    async refresh({ id }) {
      const item = await apiGet(id);
      if (!item) return item;

      await this.updateItem(item);

      return item;
    },
    async create(data) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const company = await apiCreate(data);
        return company;
      } finally {
        await appStore.stopLoading();
      }
    },
    async update({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiUpdate(id, data);

        await this.updateItem(newItem);

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async get(options = {}) {
      const { filter, companies, searchText } = this;
      let { start = companies.length, limit, text } = options;

      if (text === undefined) text = searchText;

      const apiSort = {
        description: 'asc'
      };

      const apiFilter = {
        ...baseFilter
      };

      await this.startLoading();

      try {
        const { items, hasNextPage } = await apiGetAll({
          displayInactiveCompanies: filter.key === 'displayInactiveCompanies',
          query: { start, sort: apiSort, limit, filter: apiFilter },
          text: text || ''
        });

        this.companies = start === 0 ? items : [...companies, ...items];
        this.hasNextPage = hasNextPage;
        this.searchText = searchText;

        return { items, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async setFilter(filter) {
      this.filter = filter;
      await this.get({ start: 0 });
    },
    async search({ text }) {
      await this.get({ start: 0, text });
    },
    async clearSearch() {
      await this.get({ start: 0, text: '' });
    },
    async clearFilters() {
      this.filter = {};
      await this.get({ start: 0, text: '' });
    },
    async getAuthPool() {
      return await apiGetAuthPool();
    },
    async getSettings() {
      return await apiGetSettings();
    },
    async updateSettings(data) {
      const authStore = useAuthStore();
      return await apiUpdateSettings(authStore.currentUser.company.id, data);
    }
  }
});

export default {
  key,
  use: useCompanyStore
};
