<template>
  <MenuItem
    class="asset-menu-item"
    :class="[{ 'asset-menu-item--hide': diplayOnlyOnSmallWidth }, $attrs.class]"
    :label="label"
    :disabled="disabled"
    :inline="inline"
    @selected="$emit('selected', $event)"
  >
    <template #icon>
      <div v-if="diplayIcon" class="asset-menu-item-icon" :class="classes">
        <slot>
          <component :is="icon" size="xsmall" />
        </slot>
      </div>
    </template>

    <template #inline><slot name="inline"></slot></template>
  </MenuItem>
</template>

<script>
import MenuItem from '../menus/MenuItem';
import SubBoardIcon from '../icons/SubBoard';
import FolderIcon from '../icons/Folder';
import SquareIcon from '../icons/Square';
import BriefIcon from '../icons/Brief';
import AudioMediaIcon from '../icons/AudioMedia';
import FileMediaIcon from '../icons/FileMedia';
import LinkMediaIcon from '../icons/LinkMedia';
import TextMediaIcon from '../icons/TextMedia';
import PhotoMediaIcon from '../icons/PhotoMedia';
import VideoMediaIcon from '../icons/VideoMedia';
import NoteMediaIcon from '../icons/NoteMedia';

const icons = {
  board: SubBoardIcon,
  folder: FolderIcon,
  card: SquareIcon,
  brief: BriefIcon,
  audio: AudioMediaIcon,
  file: FileMediaIcon,
  link: LinkMediaIcon,
  photo: PhotoMediaIcon,
  text: TextMediaIcon,
  video: VideoMediaIcon,
  note: NoteMediaIcon
};

export default {
  inheritAttrs: false,
  name: 'AssetMenuItemIcon',
  components: {
    MenuItem
  },
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    diplayIcon: {
      type: Boolean,
      default: true
    },
    diplayOnlyOnSmallWidth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon() {
      return icons[this.type];
    },
    classes() {
      if (this.type) {
        return `asset-menu-item-icon--${this.type}`;
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss">
.asset-menu-item {
  @include small-plus-mq {
    &--hide {
      display: none !important;
    }
  }
}

.asset-menu-item-icon {
  @include flexy($align: center);
  height: 100%;

  &--board {
    > * {
      fill: $board-base;
    }
  }

  &--folder {
    > * {
      fill: $folder-base;
    }
  }

  &--card {
    > * {
      fill: $card-base;
    }
  }

  &--brief {
    > * {
      fill: $brief-base;
    }
  }

  &--text {
    > * {
      fill: $media-text;
    }
  }

  &--photo {
    > * {
      fill: $media-photo;
    }
  }

  &--video {
    > * {
      fill: $media-video;
    }
  }

  &--audio {
    > * {
      fill: $media-audio;
    }
  }

  &--link {
    > * {
      fill: $media-link;
    }
  }

  &--file {
    > * {
      fill: $media-file;
    }
  }
}
</style>
