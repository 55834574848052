<template>
  <div class="form-field-memo" :class="$attrs.class" :for="name">
    <span v-if="label" class="form-field-memo__label">{{ label }}</span>
    <slot>
      <textarea
        ref="input"
        class="form-field-memo__text-area"
        :readonly="isReadOnly"
        :name="name"
        :id="name"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focusout="$emit('focusout', $event)"
        @keydown="$emit('keydown', $event)"
      >
      </textarea>
    </slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'FormFieldMemo',
  props: {
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    focus() {
      const { input } = this.$refs;
      input.focus();
    },
    select() {
      const { input } = this.$refs;
      input.focus();
      input.select();
    }
  }
};
</script>

<style lang="scss" scoped>
.form-field-memo {
  @include flexy($dir: column);

  &__label {
    margin-bottom: $spacing-third;
  }

  &__text-area {
    flex: 1;
    width: 100%;
    background-color: $background-light;
    border: $border-width solid $border-base;
    border-radius: $base-border-radius;
    color: $font-dark;
    font-size: $font-size-medium;
    padding: $spacing-half;
    resize: none;
  }
}

.form-field-memo.label-bold {
  > .form-field-memo__label {
    font-weight: bold;
  }
}
</style>
