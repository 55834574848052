import { capitalize } from '@/utils/text';
import { reactive, ref, toRefs, watchEffect } from 'vue';

export const useToggleState = (name: string, initialValue: string = '') => {
  const capName = capitalize(name);
  const menuClicked = ref(false);

  const rs = reactive({
    [`active${capName}`]: initialValue
  });

  if (capName === 'Menu') {
    const clicked = () => {
      if (!menuClicked.value) {
        rs.activeMenu = '';
      }
      menuClicked.value = false;
    };

    watchEffect(() => {
      if (rs.activeMenu) {
        document.addEventListener('click', clicked);
      } else {
        document.removeEventListener('click', clicked);
      }
    });
  }

  return {
    // DATA
    ...toRefs(rs),

    // METHODS
    [`toggle${capName}`](value: string = '') {
      const state = `active${capName}`;

      if (state === 'activeMenu') {
        menuClicked.value = true;
      }

      rs[state] = rs[state] === value ? '' : value;
      return true;
    }
  };
};
