<template>
  <div class="evaluation-statement-section" :class="$attrs.class">
    <div v-if="canEdit" class="evaluation-statement-section__header">
      <div class="evaluation-statement-section__header--add" @click="add">
        <Creation />
        <div>{{ $t('evaluationForm.statement.add') }}</div>
      </div>
      <div
        class="evaluation-statement-section__header--delete"
        :class="{ disabled: statements.length === 0 }"
        @click="confirmDeleteAll"
      >
        <div>{{ $t('evaluationForm.statement.deleteAll') }}</div>
      </div>
    </div>
    <ListPageable
      ref="statements"
      displayType="list"
      :items="statements"
      :isEmpty="statements.length === 0"
      :draggable="canEdit"
      handle=".handle"
      @reorderElements="reorderElements"
    >
      <template #content-item="{ item }">
        <div class="evaluation-statement-item">
          <div v-if="canEdit" class="evaluation-statement-item__move handle">
            <UpDown />
          </div>
          <span class="evaluation-statement-item__label">{{
            $t('evaluationForm.statement.itemLabel', [item.position])
          }}</span>
          <input
            class="evaluation-statement-item__description"
            :class="`description-${item.position}`"
            v-model.trim="item.description"
            :readonly="!canEdit"
          />
          <div
            v-if="canEdit"
            class="evaluation-statement-item__delete"
            @click="remove(item)"
          >
            <DeleteAction />
          </div>
        </div>
      </template>
      <template #content-empty>
        <span>{{ $t('evaluationForm.statement.emptyText') }}</span>
      </template>
    </ListPageable>

    <ToastAlert
      v-if="activeToast === 'confirmDeleteAll'"
      prompt
      level="warning"
      @ok="deleteAll"
      @cancel="toggleToast()"
    >
      {{ $t('evaluationForm.statement.confirmDeleteAll') }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useEvaluationStore } from '@/stores/evaluation';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import ListPageable from '../layout/ListPageable';
import Creation from '../icons/Creation';
import UpDown from '../icons/UpDown';
import DeleteAction from '../icons/Close';

export default {
  inheritAttrs: false,
  name: 'EvaluationStatementSection',
  mixins: [toggleState('toast')],
  components: {
    ToastAlert,
    Creation,
    UpDown,
    DeleteAction,
    ListPageable
  },
  props: {
    evaluation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statements: []
    };
  },
  computed: {
    canEdit() {
      return !this.evaluation.sent || this.evaluation.editing;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError', 'displayWarning']),
    ...mapActions(useEvaluationStore, ['getStatements', 'updateStatements']),
    async load() {
      try {
        this.statements = await this.getStatements(this.evaluation.id);
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    validate() {
      const descriptions = new Map();
      for (let i = 0; i < this.statements.length; i++) {
        const statement = this.statements[i];
        statement.description = (statement.description || '').trim();

        if (!statement.description) {
          this.displayWarning({
            message: this.$t('evaluationForm.statement.descriptionRequired', [
              statement.position
            ])
          });
          this.setDescriptionFocus(statement.position);
          return false;
        }

        const key = statement.description.toLowerCase();
        if (descriptions.has(key)) {
          this.displayWarning({
            message: this.$t('evaluationForm.statement.descriptionUnique', [
              statement.position
            ])
          });
          this.setDescriptionFocus(statement.position);
          return false;
        } else {
          descriptions.set(key, key);
        }
      }

      return true;
    },
    async update() {
      const evaluation = await this.updateStatements({
        id: this.evaluation.id,
        statements: this.statements
      });
      this.$emit('countChanged', evaluation.statementCount);
    },
    refreshInfoStatements(statements = []) {
      let i = 1;

      // Clean position
      statements.forEach((statement) => {
        statement.position = i;
        statement.description = (statement.description || '').trim();

        i++;
      });

      this.statements = statements;
    },
    add() {
      if (this.statements.length >= 100) {
        this.displayWarning({
          message: this.$t('evaluationForm.statement.maxStatement')
        });
        return;
      }

      const items = [
        ...this.statements,
        {
          position: this.statements.length + 1,
          description: ''
        }
      ];

      this.refreshInfoStatements(items);
      this.setDescriptionFocus(this.statements.length);
    },
    async remove(item) {
      const items = this.statements.filter((x) => x !== item);

      this.refreshInfoStatements(items);
    },
    confirmDeleteAll() {
      this.toggleToast('confirmDeleteAll');
    },
    async deleteAll() {
      try {
        const evaluation = await this.updateStatements({
          id: this.evaluation.id,
          deleteAll: true
        });
        this.$emit('countChanged', evaluation.statementCount);
        this.statements = [];
        this.toggleToast();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    reorderElements() {
      this.refreshInfoStatements([...this.statements]);
    },
    setDescriptionFocus(position) {
      this.$nextTick(() => {
        const { statements } = this.$refs;
        const { $el: element } = statements;
        if (!element) return;

        const txt = element.querySelector(`.description-${position}`);
        if (txt) {
          txt.focus();
          if (txt.select) txt.select();
        }
      });
    }
  },
  async beforeMount() {
    await this.load();
  }
};
</script>

<style lang="scss" scoped>
.evaluation-statement-section {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;
  flex: 1;

  &__header {
    @include flexy($align: center, $just: space-between);
    background-color: $background-light;
    color: $font-dark;
    font-weight: $font-weight-bold;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;

    &--add {
      @include flexy($align: center);

      > * {
        margin-right: $spacing-half;
      }
    }

    &--add,
    &--delete {
      height: 100%;
      cursor: pointer;

      > * {
        @include flexy($align: center);
        height: 100%;
      }
    }

    &--delete.disabled {
      opacity: $disable-opacity;
      cursor: default;
    }
  }
}

.evaluation-statement-item {
  @include grid(
    $cols: auto 1fr auto,
    $rows: auto auto,
    $areas: 'label label .' 'move description delete'
  );

  font-family: $roboto-condensed;
  width: 100%;
  padding: $spacing-base;

  &__move {
    @include grid-item($area: move);
    @include flexy($align: center);
    cursor: grab;
    margin-right: $spacing-half;
  }

  &__label {
    @include grid-item($area: label);
    margin-bottom: 3px;
    margin-right: $spacing-half;
  }

  &__description {
    @include grid-item($area: description);

    background-color: $background-light;
    border: $border-width solid $border-base;
    border-radius: $base-border-radius;
    color: $font-dark;
    font-size: $font-size-medium;
    padding: $spacing-half;
  }

  &__delete {
    @include grid-item($area: delete);
    @include flexy($align: center);
    cursor: pointer;
    margin-left: $spacing-half;
  }
}
</style>
