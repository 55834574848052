<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.631 26.172"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M353.957,287.3h-1.47c-1.658,0-1.89-2.56-2.353-3.8a1.643,1.643,0,0,0-1.5-1.2H337.91a1.643,1.643,0,0,0-1.5,1.2c-.463,1.242-.694,3.8-2.353,3.8h-1.427a3.673,3.673,0,0,0-3.651,3.673V304.8a3.662,3.662,0,0,0,3.651,3.672h21.33a3.662,3.662,0,0,0,3.651-3.672V290.977A3.662,3.662,0,0,0,353.957,287.3Zm-10.665,18.288a8.4,8.4,0,1,1,8.346-8.4A8.371,8.371,0,0,1,343.292,305.592Z"
      transform="translate(-328.976 -282.3)"
    />
    <ellipse
      cx="5.059"
      cy="5.059"
      rx="5.059"
      ry="5.059"
      transform="translate(9.257 9.812)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'PhotoMedia';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes: [...(classes.value || []), 'icon-media'], text };
  }
};
</script>
