import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'PadlockIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13.645 18.416"
      >
        <title>{{ text }}</title>
        <g transform="translate(-54.261 -57.629)">
          <path
            d="M74.032,76.045h-9.9a1.877,1.877,0,0,1-1.875-1.874V65.836a1.876,1.876,0,0,1,1.875-1.874h9.9a1.876,1.876,0,0,1,1.874,1.874v8.335A1.877,1.877,0,0,1,74.032,76.045Zm-9.9-11.083a.874.874,0,0,0-.875.874v8.335a.875.875,0,0,0,.875.874h9.9a.875.875,0,0,0,.874-.874V65.836a.874.874,0,0,0-.874-.874Z"
            transform="translate(-8)"
          />
          <path
            d="M74.381,64.544h-1V62.808a4.179,4.179,0,0,0-8.359,0v1.736h-1V62.808a5.179,5.179,0,0,1,10.359,0Z"
            transform="translate(-8)"
          />
          <circle
            cx="1.77"
            cy="1.77"
            r="1.77"
            transform="translate(59.313 68.233)"
          />
        </g>
      </svg>
    );
  }
};
