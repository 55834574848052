<template>
  <BaseDialogue
    class="set-password-dialogue"
    :class="[`set-password-dialogue--${type}`, $attrs.class]"
    :title="$t('passwordForm.newPassword')"
    size="small"
    :disableOk="!canApply"
    @ok="apply"
    @cancel="cancel"
  >
    <template #ok>{{ $t('dialogue.footer.apply') }}</template>

    <div class="form-element request-reset__form" autocomplete="off">
      <img v-if="displayLogo" :src="logoPath" alt="Logo" class="logo" />

      <p v-if="applyRequirements" class="reset-password__requirements">
        {{ $t('passwordForm.requirements') }}
      </p>

      <BasePasswordInput
        ref="password"
        :label="$t('passwordForm.newPassword')"
        name="pwd"
        centered
        v-model.trim="form.password"
        @done="apply()"
        autocomplete="off"
      />

      <BasePasswordInput
        ref="passwordConfirmation"
        :label="$t('passwordForm.confirm')"
        name="pwd-c"
        centered
        v-model.trim="form.passwordConfirmation"
        @done="apply()"
        autocomplete="off"
      />
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapState } from 'pinia';
import BaseDialogue from '../dialogues/BaseDialogue';
import ToastAlert from '../toast/ToastAlert';
import { passwordIsValid } from '../../utils/password';
import { useAuthStore } from '@/stores/auth';

export default {
  name: 'SetPasswordDialogue',
  components: {
    BaseDialogue,
    ToastAlert
  },
  props: {
    type: {
      type: String,
      required: true
    },
    displayLogo: {
      type: Boolean,
      default: false
    },
    applyRequirements: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        password: '',
        passwordConfirmation: ''
      },
      error: ''
    };
  },
  computed: {
    ...mapState(useAuthStore, ['logoPath']),
    canApply() {
      return !!this.form.password && !!this.form.passwordConfirmation;
    }
  },
  methods: {
    validateApply() {
      const { password, passwordConfirmation } = this.form;

      if (!(password || '').trim()) {
        this.$refs.password.focus();
        return false;
      } else if (!(passwordConfirmation || '').trim()) {
        this.$refs.passwordConfirmation.focus();
        return false;
      }

      return true;
    },
    apply() {
      if (!this.validateApply()) return;

      const { password, passwordConfirmation } = this.form;
      this.error = '';

      if (password !== passwordConfirmation) {
        this.error = this.$t('passwordForm.noMatch');
        this.$refs.password.focus();
      } else if (this.applyRequirements && !passwordIsValid(password)) {
        this.error = this.$t('passwordForm.noRequirements');
        this.$refs.password.focus();
      } else {
        this.$emit('success', password);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    focus() {
      this.$nextTick(() => {
        const { password } = this.$refs;
        password.focus();
      });
    }
  },
  mounted() {
    this.focus();
  }
};
</script>

<style lang="scss">
.set-password-dialogue {
  @include auth-form();
  @include color-form();

  &__requirements {
    margin: $spacing-base;
    text-align: center;
  }
}
</style>
