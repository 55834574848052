<template>
  <div ref="player" class="audio-player" :class="$attrs.class" @click="seek">
    <div ref="bar" class="audio-player__bar" />
    <audio ref="audio">
      <source
        :src="source.src"
        :type="source.type"
        v-for="source in sources"
        :key="source.src"
        @error="sourceError"
      />
    </audio>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'AudioPlayer',
  props: {
    sources: {
      type: Array,
      required: true
    }
  },
  methods: {
    seek({ clientX }) {
      const { audio, player } = this.$refs;
      audio.currentTime = (audio.duration * clientX) / player.clientWidth;
    },
    togglePlay() {
      const { audio } = this.$refs;
      audio.paused ? audio.play() : audio.pause();
    },
    isPlaying() {
      const { audio } = this.$refs;
      return !audio.paused;
    },
    sourceError(event) {
      console.error(event);
      this.$emit('error', 'sourceError');
    }
  },
  mounted() {
    const { audio, bar, player } = this.$refs;

    audio.addEventListener('timeupdate', () => {
      const newX = (player.clientWidth * audio.currentTime) / audio.duration;
      bar.style.left = `${newX}px`;
    });
    audio.addEventListener('playing', () => {
      const { audio } = this.$refs;

      this.$emit('playing', {
        playing: true,
        event: 'playing',
        currentTime: audio.currentTime,
        duration: audio.duration
      });
    });

    audio.addEventListener('ended', () => {
      const { audio } = this.$refs;

      this.$emit('playing', {
        playing: false,
        event: 'ended',
        currentTime: audio.currentTime,
        duration: audio.duration
      });
    });

    audio.addEventListener('pause', () => {
      const { audio } = this.$refs;
      if (audio.currentTime !== audio.duration)
        this.$emit('playing', {
          playing: false,
          event: 'paused',
          currentTime: audio.currentTime,
          duration: audio.duration
        });
    });
    audio.addEventListener('error', () => this.$emit('error', 'playerError'));
  }
};
</script>

<style lang="scss" scoped>
$color: $media-audio;

.audio-player {
  background-image: url('../../assets/img/waveform.svg');
  background-position: center;
  background-size: contain;
  border: $border-base solid $border-width;
  border-radius: $base-border-radius;
  height: $vertical-rhythm * 3;
  position: relative;
  width: 100%;
}

.audio-player__bar {
  background-color: $color;
  height: 100%;
  left: 0;
  position: relative;
  width: 4px;
}
</style>
