<template>
  <section class="audit-login" :class="$attrs.class">
    <div class="audit-login__header">
      <BreadcrumbsBar
        class="audit-login__header--navigation"
        :type="AUDIT_LOGIN_TYPE"
        :canNavigate="false"
        :displayIcon="false"
      />
      <button class="icon-button audit-login__header--close" @click="close">
        <CrossIcon size="small" />
      </button>
    </div>

    <div v-if="currentEmail" class="audit-login__current-email">
      <div class="audit-login__current-email--label">
        {{ `${$t('auditLoginPage.search')}:` }}
      </div>
      <div class="audit-login__current-email--email">{{ currentEmail }}</div>
      <button
        class="icon-button audit-login__current-email--delete"
        @click="deleteCurrentEmail"
      >
        <TrashIcon size="small" />
      </button>
    </div>
    <AutoComplete
      v-else
      ref="emailAutoComplete"
      :label="$t('auditLoginPage.search')"
      :options="emails"
      :getOptionKey="getOptionKey"
      :displayAction="false"
      @option:selected="emailHasChanged"
      @v-search="searchEmails"
    >
      <template #no-options>
        <div style="height: 0"></div>
      </template>
      <template #option="option">
        <div class="audit-login__option">{{ option.email }}</div>
      </template>
    </AutoComplete>

    <ListPageable
      :items="items"
      :load="load"
      :hasNextPage="hasNextPage"
      :isEmpty="empty"
      displayType="list"
    >
      <template #content-item="{ item }">
        <AuditLoginItem :item="item" />
      </template>
      <template #content-empty>
        <span>{{ $t('auditLoginPage.emptyText') }}</span>
      </template>
    </ListPageable>

    <AppFooter>
      <template #left>
        <button
          class="icon-button menu-button layout-menu-button"
          :class="{ 'menu-button--active': activeMenu === 'layout' }"
          :disabled="!currentEmail"
          @click="toggleMenu('layout')"
          data-testid="layout-button"
        >
          <Layout :type="activeMenu === 'layout' ? 'active' : 'dark'" />
        </button>

        <button
          v-if="displayClearFilters"
          class="icon-button icon-button--active filter-active-button"
          @click="_clearFilters"
        >
          <span class="icon-button-label">{{ $t('search.clearFilters') }}</span>
          <Close type="active" />
        </button>
      </template>
    </AppFooter>

    <!-- Layout menu -->
    <transition name="slide">
      <SlideMenu v-if="activeMenu === 'layout'" slide="up" anchor="left">
        <SearchMenuItem
          v-model="localSearchText"
          @clear="_clearSearch"
          @search="_search"
        />
      </SlideMenu>
    </transition>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuditLoginStore } from '@/stores/audit-login';
import BreadcrumbsBar from '../navigation/BreadcrumbsBar';
import Close from '../icons/Close';
import AutoComplete from '../forms/AutoComplete';
import TrashIcon from '../icons/Trash';
import ListPageable from '../layout/ListPageable';
import AuditLoginItem from './AuditLoginItem';
import AppFooter from '../AppFooter';
import Layout from '../icons/Layout';
import CrossIcon from '../icons/Cross';
import SlideMenu from '../menus/SlideMenu';
import SearchMenuItem from '../search/SearchMenuItem';
import toggleState from '../mixins/toggleState';
import authMixins from '../mixins/auth';
import { searchMixin } from '../mixins/search';
import { AUDIT_LOGIN_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'AuditLogin',
  mixins: [authMixins, searchMixin(AUDIT_LOGIN_TYPE), toggleState('menu')],
  components: {
    BreadcrumbsBar,
    Close,
    AutoComplete,
    TrashIcon,
    ListPageable,
    AuditLoginItem,
    AppFooter,
    Layout,
    CrossIcon,
    SlideMenu,
    SearchMenuItem
  },
  data() {
    return {
      emailSearchText: ''
    };
  },
  computed: {
    ...mapState(useAuditLoginStore, [
      'items',
      'hasNextPage',
      'emails',
      'currentEmail',
      'searchText'
    ]),
    AUDIT_LOGIN_TYPE() {
      return AUDIT_LOGIN_TYPE;
    },
    empty() {
      return this.items.length === 0;
    },
    displayClearFilters() {
      return !!this.currentEmail && !!this.searchText;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useAuditLoginStore, {
      load: 'get',
      getEmails: 'getEmails',
      setCurrentEmail: 'setCurrentEmail',
      reset: 'reset'
    }),
    close() {
      this.setActiveLeftSideBar();
    },
    async emailHasChanged({ email }) {
      await this.setCurrentEmail(email);
      this.getEmails({ text: null });
      this.emailSearchText = '';
    },
    async searchEmails(text) {
      this.emailSearchText = (text || '').trim();
      await this.getEmails({ text: this.emailSearchText });
    },
    getOptionKey(option) {
      return option.email;
    },
    deleteCurrentEmail() {
      this.setCurrentEmail(null);
      this.$nextTick(() => {
        this.focus();
      });
    },
    focus() {
      const { emailAutoComplete } = this.$refs;
      if (emailAutoComplete) emailAutoComplete.focus();
    }
  },
  mounted() {
    this.focus();
  },
  async beforeUnmount() {
    await this.reset();
  }
};
</script>

<style lang="scss">
.audit-login .form-field__input .vs__actions {
  display: none;
}

.audit-login .form-field__input .vs__selected-options {
  padding: 0;
}
</style>

<style lang="scss" scoped>
.audit-login {
  @include flexy($dir: column);
  width: 100%;

  &__header {
    @include grid($gap: 0, $cols: 1fr auto, $areas: 'navigation close');

    &--navigation {
      @include grid-item(navigation);
    }

    &--close {
      @include grid-item(close);
      @include flexy($align: center);
      margin-right: $spacing-base;
    }
  }

  &__current-email {
    @include grid(
      $cols: auto 1fr auto,
      $areas: 'label email delete',
      $align: center
    );
    min-height: $height-item;
    font-weight: bold;
    padding: $spacing-base;
    background-color: $background-light;

    &--label {
      @include grid-item($area: label);
      margin-right: $spacing-base;
      font-weight: bold;
    }

    &--email {
      @include grid-item($area: email);
      margin-right: $spacing-base;
    }

    &--delete {
      @include flexy($align: center, $just: center);
      @include grid-item($area: delete);
      height: 100%;
    }
  }

  &__option {
    @include flexy($align: center);
    min-height: $height-item;
  }

  .filter-active-button {
    color: $font-light;
  }
}
</style>
