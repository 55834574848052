<template>
  <MediaFormDialogue
    class="form-dialogue-link"
    :class="$attrs.class"
    :title="$t(`mediaForm.link.label.${mode}`)"
    @cancel="$emit('cancel')"
    @ok="ok()"
    v-model:error="error"
  >
    <div class="form-dialogue-link__content" @keydown.enter.prevent="ok">
      <BaseInput
        ref="link"
        :label="$t('mediaForm.link.pasteLink')"
        name="link"
        v-model.trim="form.link"
      />

      <BaseInput
        ref="name"
        :label="$t('mediaForm.description')"
        name="name"
        :maxlength="120"
        v-model.trim="form.name"
      />

      <div class="form-dialogue-link__content__config-button">
        <BaseInput
          class="form-dialogue-link__content__config-button--label"
          :label="$t('mediaForm.link.button.label')"
          name="buttonLabel"
          :maxlength="32"
          v-model.trim="form.additionalData.button.text"
        />

        <div class="form-dialogue-link__content__config-button--colors">
          <div
            class="form-dialogue-link__content__config-button--colors--label"
          >
            <span>{{ $t('mediaForm.link.button.colors.label') }}</span>
          </div>
          <div
            class="form-dialogue-link__content__config-button--colors--content"
          >
            <div
              class="form-dialogue-link__content__config-button--colors--color"
            >
              <ColorPicker
                :title="$t('mediaForm.link.button.colors.backgroundTooltip')"
                :defaultColor="defaultColors.button.background"
                v-model="form.additionalData.button.colors.background"
              >
                <div
                  class="form-dialogue-link__content__config-button--colors--color--box"
                  :style="{ backgroundColor: buttonBackgroundColor }"
                ></div>
              </ColorPicker>

              <span
                :title="$t('mediaForm.link.button.colors.backgroundTooltip')"
                >{{ $t('mediaForm.link.button.colors.background') }}</span
              >
            </div>

            <div
              class="form-dialogue-link__content__config-button--colors--color"
            >
              <ColorPicker
                :title="$t('mediaForm.link.button.colors.textTooltip')"
                :defaultColor="defaultColors.button.text"
                v-model="form.additionalData.button.colors.text"
              >
                <div
                  class="form-dialogue-link__content__config-button--colors--color--box"
                  :style="{ backgroundColor: buttonTextColor }"
                ></div>
              </ColorPicker>

              <span :title="$t('mediaForm.link.button.colors.textTooltip')">{{
                $t('mediaForm.link.button.colors.text')
              }}</span>
            </div>
          </div>
        </div>

        <div class="form-dialogue-link__content__config-button--bg">
          <div class="form-dialogue-link__content__config-button--bg--label">
            <span>{{ $t('mediaForm.link.addBackgroundImageOrColor') }}</span>
          </div>

          <div class="form-dialogue-link__content__config-button--bg--content">
            <FileInput
              v-if="displayTakePicture"
              class="form-dialogue-link__content__config-button--bg--item"
              :accept="['image/*']"
              :cta="false"
              capture="environment"
              name="capture"
              size="auto"
              @change="fileSelected"
            >
              <div
                class="form-dialogue-link__content__config-button--bg--icon"
                :title="$t('mediaForm.link.takePicture')"
              >
                <PhotoIcon />
              </div>
            </FileInput>

            <FileInput
              v-if="displaySelectImage"
              class="form-dialogue-link__content__config-button--bg--item"
              :accept="acceptedTypes"
              :cta="false"
              name="files"
              size="auto"
              @change="fileSelected($event, true)"
            >
              <div
                class="form-dialogue-link__content__config-button--bg--icon"
                :title="$t('mediaForm.link.selectImage')"
              >
                <ImageIcon />
              </div>
            </FileInput>

            <div
              class="form-dialogue-link__content__config-button--bg--item"
              v-if="displaySelectBackground"
            >
              <ColorPicker
                :title="$t('mediaForm.link.backgroundTooltip')"
                :defaultColor="defaultColors.tile.background"
                v-model="form.additionalData.tile.background"
              >
                <div
                  class="form-dialogue-link__content__config-button--bg--icon"
                  :title="$t('mediaForm.link.selectBackgroundColor')"
                  :style="tileBackgroundStyle"
                >
                  <BackgroundIcon v-if="!tileBackgroundStyle" />
                </div>
              </ColorPicker>
            </div>

            <div
              class="form-dialogue-link__content__config-button--bg--item"
              v-if="preview"
            >
              <div
                :class="[
                  'form-dialogue-link__content__config-button--bg--icon',
                  'form-dialogue-link__content__config-button--bg--preview'
                ]"
                :style="tileBackgroundStyle"
              ></div>
            </div>

            <div
              class="form-dialogue-link__content__config-button--bg--item"
              v-if="displayDeleteBackground"
            >
              <div
                class="form-dialogue-link__content__config-button--bg--icon"
                @click="deleteTileBackground"
              >
                <TrashIcon />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-dialogue-link__content__result">
        <div class="form-dialogue-link__content__result--label">
          <span>{{ $t('mediaForm.link.button.result') }}</span>
        </div>
        <div class="form-dialogue-link__content__result--tile">
          <TileCallToActionMedia :options="tileCallToActionMediaOptions" />
        </div>
      </div>
    </div>
  </MediaFormDialogue>
</template>

<script>
import features from '../../utils/features';
import { toMegabytes } from '../../utils/numbers';
import MediaFormDialogue from './MediaFormDialogue';
import FileInput from '../forms/FileInput';
import PhotoIcon from '../icons/PhotoMedia';
import ImageIcon from '../icons/Image';
import BackgroundIcon from '../icons/Background';
import TrashIcon from '../icons/Trash';
import mediaForm from '../mixins/mediaForm';
import ColorPicker from '../forms/ColorPicker';
import TileCallToActionMedia from '../media/TileCallToActionMedia';

const acceptedTypes = process.env.PHOTO_MEDIA_SUPPORTED_TYPES;
const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

export default {
  inheritAttrs: false,
  name: 'FormDialogueLink',
  mixins: [mediaForm],
  components: {
    MediaFormDialogue,
    ColorPicker,
    FileInput,
    PhotoIcon,
    ImageIcon,
    BackgroundIcon,
    TrashIcon,
    TileCallToActionMedia
  },
  data() {
    const { name = '', link = '', fileInfos, additionalData } = this.editItem;

    return {
      defaultColors: {
        tile: {
          background: '#107989'
        },
        button: {
          background: '#ffffff',
          text: '#595b5f'
        }
      },
      showPicker: false,
      acceptedTypes,
      form: {
        file: null,
        link,
        name,
        mediaType: 'link',
        additionalData: {
          button: {
            text: additionalData?.button?.text || '',
            colors: {
              background: additionalData?.button?.colors?.background || '',
              text: additionalData?.button?.colors?.text || ''
            }
          },
          tile: {
            background: additionalData?.tile?.background || ''
          }
        }
      },
      preview: fileInfos?.url || '',
      submitted: false,
      error: '',
      maximized: false
    };
  },
  computed: {
    buttonBackgroundColor() {
      return (
        this.form.additionalData.button.colors.background ||
        this.defaultColors.button.background
      );
    },
    buttonTextColor() {
      return (
        this.form.additionalData.button.colors.text ||
        this.defaultColors.button.text
      );
    },
    tileBackgroundColor() {
      return (
        this.form.additionalData.tile.background ||
        this.defaultColors.tile.background
      );
    },

    previewStyle() {
      return {
        backgroundImage: `url(${this.preview})`
      };
    },
    canCapture() {
      return features.captureAttribute();
    },
    fileName() {
      const { fileInfos } = this.editItem;
      const { file } = this.form;
      return (fileInfos && fileInfos.name) || (file && file.name) || '';
    },
    hasPreview() {
      const ext = this.getFileExt();
      return !(ext === 'heic' || ext === 'heif' || ext === '');
    },
    displayTakePicture() {
      return this.canCapture && !this.tileBackgroundStyle;
    },
    displaySelectImage() {
      return !this.tileBackgroundStyle;
    },
    displaySelectBackground() {
      return !this.preview;
    },
    displayDeleteBackground() {
      return !!this.tileBackgroundStyle;
    },
    tileBackgroundStyle() {
      if (!this.preview && !this.form.additionalData.tile.background)
        return null;
      if (this.form.additionalData.tile.background) {
        return {
          backgroundColor: this.form.additionalData.tile.background
        };
      }

      return {
        backgroundImage: `url(${this.preview})`
      };
    },
    tileCallToActionMediaOptions() {
      return {
        backgroundImageUrl: this.preview,
        backgroundColor: this.tileBackgroundColor,
        button: {
          text: this.form.additionalData.button.text,
          colors: {
            background: this.buttonBackgroundColor,
            text: this.buttonTextColor
          }
        }
      };
    }
  },
  methods: {
    validate() {
      if (!(this.form.link || '').trim()) {
        // if (!(this.form.link || '').trim()) {
        this.error = this.$t('mediaForm.link.linkError', {
          link: this.form.link
        });

        this.select('link');
        return false;
      } else if (!(this.form.name || '').trim()) {
        this.error = this.$t('mediaForm.nameRequired');
        this.select('name');
        return false;
      }

      return true;
    },
    async ok() {
      if (this.submitted) return;
      this.submitted = true;

      try {
        if (!this.validate()) return;

        if (this.editItem.fileInfos?.id && !this.preview)
          this.form.mediaFileId = null;
        else delete this.form.mediaFileId;

        if (this.form.additionalData.button.text) {
          this.form.additionalData.button.colors.background =
            this.buttonBackgroundColor;
          this.form.additionalData.button.colors.text = this.buttonTextColor;
          if (!this.preview)
            this.form.additionalData.tile.background = this.tileBackgroundColor;
        }

        const media = await this.modify();
        this.$emit('success', media);
      } catch (e) {
        console.error(e);
        this.error = this.$t('mediaForm.error');
      } finally {
        this.submitted = false;
      }
    },
    clearPreview() {
      if (!this.preview) return;

      URL.revokeObjectURL(this.preview);
      this.preview = '';
    },
    deleteTileBackground() {
      this.clearPreview();

      if (this.form.file) {
        let name;
        const pos = this.form.file.name.lastIndexOf('.');
        if (pos > 0) {
          name = this.form.file.name.substring(0, pos);
        } else {
          name = this.form.file.name;
        }

        if (this.form.name === name) this.form.name = '';
      }

      this.form.file = null;
      this.form.additionalData.tile.background = '';
      this.select('name');
    },
    fileSelected(files, setName) {
      // eslint-disable-next-line
        const [file, ..._rest] = files

      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
        return;
      }

      this.form.file = file;

      if (setName && !this.form.name) {
        const pos = file.name.lastIndexOf('.');
        if (pos > 0) {
          this.form.name = file.name.substring(0, pos);
        } else {
          this.form.name = file.name;
        }
      }

      this.preview = URL.createObjectURL(file);
      this.select('name');
    },
    toggleImage() {
      if (!this.preview) return;

      this.maximized = !this.maximized;
    },
    getFileExt() {
      const reg = /.+\.(.+)$/i;

      const match = this.fileName.trim().match(reg);
      if (match && match[1]) {
        return match[1].trim().toLowerCase();
      }

      return '';
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.select('name');
    });
  }
};
</script>

<style lang="scss">
$base-index: 1;
$color: $media-link;
$element-height: 40px;
$bg-icon-color: #048b9d;
$border-color: rgba(112, 112, 112, 1); // #707070
$larger-width: 700px;

.form-dialogue-link {
  > .dialogue__box {
    @media screen and (max-width: #{$larger-width - 1}) {
      width: 320px;
      max-width: 100%;
      max-height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: $larger-width) {
      width: 800px;
      max-width: 800px;
      max-height: 100%;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dialogue__main {
    overflow: hidden;
  }

  .dialogue__main--content {
    border: solid 1px $background-light;
  }

  .dialogue__header {
    font-style: $font-italic;
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $color;
    border: solid 1px $color;
  }

  .form-field {
    border: none;
    margin: 0;
    margin-bottom: $spacing-base;
  }

  &__content {
    @include flexy($dir: column, $just: center);
    margin: 30px;

    &__config-button {
      margin-top: $spacing-base;

      @media screen and (max-width: #{$larger-width - 1}) {
        @include flexy($dir: column, $align: center);

        &--label {
          text-align: center;
        }

        &--colors {
          &--content {
            @include flexy($just: center);
          }
        }

        &--bg {
          margin-top: $spacing-base;

          &--content {
            @include flexy($just: center);
          }
        }
      }

      @media screen and (min-width: $larger-width) {
        @include grid(
          $gap: 25px,
          $cols: 1fr auto auto,
          $areas: 'label colors background'
        );

        &--label {
          grid-area: label;
        }

        &--colors {
          grid-area: colors;
        }

        &--bg {
          grid-area: background;
        }
      }

      &--colors {
        @include flexy($dir: column);

        &--label {
          margin-bottom: $spacing-third;
        }

        &--content {
          @include flexy();
        }

        &--color {
          @include flexy($dir: column, $align: center);
          margin-right: 10px;
          font-size: $font-size-small;

          &--box {
            display: inline-block;
            width: $element-height;
            height: $element-height;
            border: solid 1px $border-color;
            border-radius: $base-border-radius;
            overflow: hidden;
            margin-bottom: 2px;
          }
        }

        &--color:last-child {
          margin-right: 0;
        }
      }

      &--bg {
        @include flexy($dir: column);

        &--label {
          margin-bottom: $spacing-third;
        }

        &--content {
          @include flexy();
        }

        &--item {
          padding: 0;
          margin-right: 10px;
        }

        &--item:last-child {
          margin-right: 0;
        }

        &--icon {
          @include flexy($align: center, $just: center);
          width: $element-height;
          height: $element-height;
          border: solid 1px $border-color;
          border-radius: $base-border-radius;
          background-color: $bg-icon-color;
          cursor: pointer;

          > * {
            height: 20px;
            min-height: 20px;
            width: 20px;
            min-width: 20px;
            fill: $font-light;
          }
        }

        &--preview {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: unset;
          cursor: default;
        }
      }
    }

    &__result {
      @include flexy($dir: column, $align: center);
      margin-top: $spacing-base;

      &--label {
        margin-bottom: $spacing-third;
      }

      &--tile {
        width: 400px;
        height: 400px;
      }
    }

    @media screen and (max-width: #{$larger-width - 1}) {
      margin: 15px;

      &__result {
        &--tile {
          width: 290px;
          height: 290px;
        }
      }
    }
  }
}
</style>
