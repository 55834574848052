import { defineStore } from 'pinia';

import {
  get as apiGet,
  getEmails as apiGetEmails
} from '@/api/endpoints/auditLogin';

const initialState = {
  searchText: null,
  items: [],
  hasNextPage: false,
  emails: [],
  currentEmail: null
};

export const key = 'auditLogin';
export const useAuditLoginStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async get(options = {}) {
      const { currentEmail, sort, items, searchText } = this;
      const { start = items.length } = options;

      if (currentEmail) {
        const { items: newItems, hasNextPage } = await apiGet({
          email: currentEmail,
          text: searchText || '',
          query: { start, sort }
        });

        this.items = start === 0 ? newItems : [...items, ...newItems];
        this.hasNextPage = !!hasNextPage;

        return { items: newItems, hasNextPage };
      } else {
        this.items = [];
        this.hasNextPage = false;

        return { items: [], hasNextPage: false };
      }
    },
    async getEmails(options = {}) {
      const { text } = options;

      if ((text || '').trim().length > 2) {
        this.emails = await apiGetEmails({ text });
      } else {
        this.emails = [];
      }

      return this.emails;
    },
    async setCurrentEmail(email) {
      this.currentEmail = email;
      await this.get({ start: 0 });
    },
    async setSearchText(text) {
      this.searchText = text;
    },
    async search({ text }) {
      await this.setSearchText(text);
      await this.get({ start: 0 });
    },
    async clearSearch() {
      await this.setSearchText('');
      await this.get({ start: 0 });
    },
    async clearFilters() {
      await this.setSearchText('');
      await this.get({ start: 0 });
    }
  }
});

export default {
  key,
  use: useAuditLoginStore
};
