<template>
  <button
    class="call-to-action"
    :class="[`call-to-action--${size}`, $attrs.class]"
    :disabled="disabled"
    @click="$emit('v-click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script>
import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from '.';

export default {
  inheritAttrs: false,
  name: 'CallToAction',
  props: {
    size: {
      type: String,
      default: DEFAULT_BUTTON_SIZE,
      validator: (value) => BUTTON_SIZES.includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.call-to-action {
  @include cta();
  border: none;
  outline: 0;
}
</style>
