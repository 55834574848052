<template>
  <section class="notification" :class="$attrs.class">
    <div class="notification__header">
      <BreadcrumbsBar
        class="notification__header--navigation"
        :type="NOTIFICATION_TYPE"
        :canNavigate="false"
      />
      <button class="icon-button notification__header--close" @click="close">
        <Cross size="small" />
      </button>
    </div>

    <ListPageable
      :items="notifications"
      :load="load"
      :hasNextPage="hasNextPage"
      :loading="loading"
      :isEmpty="empty"
      displayType="list"
    >
      <template #content-item="{ item }">
        <NotificationItem
          :item="item"
          @toggle="$emit('toggle')"
          :selectable="selectable"
          @selectionChanged="selectionChanged(item, $event)"
        />
      </template>
      <template #content-empty>
        <span>{{ $t('notificationPage.emptyText') }}</span>
      </template>
    </ListPageable>

    <div v-if="selectable" class="notification__footer--selectable">
      <button
        class="text-button dialogue__footer__cancel"
        @click="toggleSelectToDelete"
      >
        {{ $t('dialogue.footer.cancel') }}
      </button>

      <button
        class="text-button dialogue__footer__ok"
        :disabled="selectedCount === 0"
        @click="toggleToast('confirmDeleteSelectedItems')"
      >
        {{ $t('dialogue.footer.ok') }}
      </button>
    </div>
    <AppFooter v-else>
      <template #left>
        <button
          class="icon-button menu-button layout-menu-button"
          :class="{ 'menu-button--active': activeMenu === 'layout' }"
          @click="toggleMenu('layout')"
          data-testid="layout-button"
        >
          <Layout :type="activeMenu === 'layout' ? 'active' : 'dark'" />
        </button>

        <button
          v-if="filter.key !== defaultFilter.key"
          class="icon-button icon-button--active filter-active-button"
          @click="setFilter(defaultFilter)"
        >
          <span class="icon-button-label">{{
            $t(`layoutNotificationMenu.filterBy.${filter.key}`)
          }}</span>
          <Close type="active" />
        </button>
      </template>
      <template #right>
        <button
          class="icon-button menu-button action-menu-button"
          :class="{ 'menu-button--active': activeMenu === 'action' }"
          @click="toggleMenu('action')"
        >
          <Action :type="activeMenu === 'action' ? 'active' : 'dark'" />
        </button>
      </template>
    </AppFooter>

    <!-- Layout menu -->
    <transition name="slide">
      <SlideMenu v-if="activeMenu === 'layout'" slide="up" anchor="left">
        <MenuItem :label="$t('layoutNotificationMenu.filterBy.label')">
          <template #submenu>
            <MenuItem
              v-for="option in filterOptions"
              :key="option.key"
              :label="$t(`layoutNotificationMenu.filterBy.${option.key}`)"
              :active="filter.key === option.key"
              @selected="setFilter(option)"
            />
          </template>
        </MenuItem>
      </SlideMenu>
    </transition>

    <!-- Action menu -->
    <transition name="slide">
      <SlideMenu
        v-if="activeMenu === 'action'"
        slide="up"
        anchor="right"
        fullWidth
      >
        <MenuItem
          :label="$t('actionNotificationMenu.manageSubscriptions')"
          @selected="manageSubscriptions"
        />
        <MenuItem
          :label="$t('actionNotificationMenu.selectToDelete')"
          @selected="toggleSelectToDelete"
        />
        <MenuItem
          :label="$t('actionNotificationMenu.deleteAll')"
          @selected="activeToast = 'confirmDeleteAll'"
        />
        <MenuItem
          :label="$t('actionNotificationMenu.markAllAsRead')"
          @selected="activeToast = 'confirmMarkAllAsRead'"
        />
      </SlideMenu>
    </transition>

    <ToastAlert
      v-if="activeToast === 'confirmDeleteSelectedItems'"
      prompt
      level="warning"
      :relative="true"
      @ok="deleteSelectedItems"
      @cancel="cancelDeleteSelectedItems"
    >
      {{ $t('notification.confirmDeleteSelectedItems') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmDeleteAll'"
      prompt
      level="warning"
      :relative="true"
      @ok="deleteAllItems"
      @cancel="cancelDeleteAllItems"
    >
      {{ $t('notification.confirmDeleteAll') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmMarkAllAsRead'"
      prompt
      level="warning"
      :relative="true"
      @ok="markAllAsReadItems"
      @cancel="toggleToast()"
    >
      {{ $t('notification.confirmMarkAllAsRead') }}
    </ToastAlert>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useNotificationStore } from '@/stores/notification';
import { filterNotificationMixin } from '../../utils/filter';
import AppFooter from '../AppFooter';
import Close from '../icons/Close';
import Cross from '../icons/Cross';
import Action from '../icons/Action';
import Layout from '../icons/Layout';
import SlideMenu from '../menus/SlideMenu';
import MenuItem from '../menus/MenuItem';
import BreadcrumbsBar from '../navigation/BreadcrumbsBar';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import authMixins from '../mixins/auth';
import ListPageable from '../layout/ListPageable';
import NotificationItem from './NotificationItem';
import { NOTIFICATION_TYPE, SUBSCRIPTION_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'NotificationView',
  mixins: [
    authMixins,
    filterNotificationMixin(),
    toggleState('menu'),
    toggleState('toast')
  ],
  components: {
    AppFooter,
    BreadcrumbsBar,
    Close,
    Layout,
    Action,
    Cross,
    MenuItem,
    SlideMenu,
    ListPageable,
    NotificationItem,
    ToastAlert
  },
  data() {
    return {
      selectable: false,
      selectedCount: 0,
      submitted: false
    };
  },
  computed: {
    ...mapState(useNotificationStore, [
      'notifications',
      'loading',
      'hasNextPage'
    ]),
    NOTIFICATION_TYPE() {
      return NOTIFICATION_TYPE;
    },
    empty() {
      return this.notifications.length === 0;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useNotificationStore, {
      load: 'get',
      start: 'start',
      reset: 'reset',
      markAllAsRead: 'markAllAsRead',
      deleteSelected: 'deleteSelected',
      deleteAll: 'deleteAll'
    }),
    close() {
      this.setActiveLeftSideBar();
    },
    manageSubscriptions() {
      this.setActiveLeftSideBar({ type: SUBSCRIPTION_TYPE });
    },
    toggleSelectToDelete() {
      if (this.selectable) {
        this.notifications.forEach((item) => {
          item.selected = false;
        });
        this.selectedCount = 0;
      }

      this.selectable = !this.selectable;
    },
    selectionChanged(item, selected) {
      item.selected = selected;

      if (item.selected) this.selectedCount++;
      else this.selectedCount--;
      if (this.selectedCount < 0) this.selectedCount = 0;
    },
    async deleteSelectedItems() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const ids = this.notifications
            .filter((notification) => notification.selected === true)
            .map((notification) => notification.id);

          await this.deleteSelected(ids);

          this.toggleToast();
          this.toggleSelectToDelete();
        } catch (e) {
          this.toggleToast();
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        } finally {
          this.submitted = false;
        }
      }
    },
    cancelDeleteSelectedItems() {
      this.toggleToast();
      this.toggleSelectToDelete();
    },
    async deleteAllItems() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          await this.deleteAll();
          this.toggleToast();
        } catch (e) {
          this.toggleToast();
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        } finally {
          this.submitted = false;
        }
      }
    },
    cancelDeleteAllItems() {
      this.toggleToast();
    },
    markAllAsReadItems() {
      this.markAllAsRead();
      this.toggleToast();
    }
  },
  async beforeMount() {
    await this.start();
  },
  async beforeUnmount() {
    await this.reset();
  }
};
</script>

<style lang="scss" scoped>
.notification {
  @include flexy($dir: column);
  width: 100%;

  &__footer--selectable {
    @include flexy($just: space-between);
    background-color: $notification-base;
    color: $font-light;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;

    .text-button {
      font-size: $font-size-large;
    }
  }

  &__header {
    @include grid($gap: 0, $cols: 1fr auto, $areas: 'navigation close');

    &--navigation {
      @include grid-item(navigation);
    }

    &--close {
      @include grid-item(close);
      @include flexy($align: center);
      margin-right: $spacing-base;
    }
  }

  .filter-active-button {
    color: $font-light;
  }
}

.menu-button--active {
  @include svg-active-background($background-triangle-down-svg);
}
</style>
