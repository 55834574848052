<template>
  <div v-if="display" class="tile-asset-state" :class="$attrs.class">
    <div
      v-if="item.favorite"
      class="tile-asset-state__info"
      :class="`tile-asset-state__info--${item.type}`"
    >
      <StarIcon size="small" />
    </div>
    <div
      v-if="displaySendAllAddNotifications"
      class="tile-asset-state__info"
      :class="`tile-asset-state__info--${item.type}`"
    >
      <NotificationIcon size="small" />
    </div>
    <div
      v-if="isProtected || isReadOnly"
      class="tile-asset-state__info"
      :class="`tile-asset-state__info--${item.type}`"
    >
      <ProtectedIcon size="small" />
    </div>
    <div
      v-if="isPrivate"
      class="tile-asset-state__info"
      :class="`tile-asset-state__info--${item.type}`"
    >
      <PadlockIcon size="small" />
    </div>
  </div>
</template>

<script>
import StarIcon from '../icons/Star';
import NotificationIcon from '../icons/NotificationIcon';
import ProtectedIcon from '../icons/Protected';
import PadlockIcon from '../icons/Padlock';
import assetMixin from '../mixins/asset';

export default {
  inheritAttrs: false,
  name: 'TileAssetState',
  mixins: [assetMixin],
  components: {
    StarIcon,
    NotificationIcon,
    ProtectedIcon,
    PadlockIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    display() {
      return (
        !!this.item.favorite ||
        this.isProtected ||
        this.isReadOnly ||
        this.isPrivate ||
        this.displaySendAllAddNotifications
      );
    },
    isMedia() {
      return this.item.type === 'media';
    }
  }
};
</script>

<style lang="scss">
.tile-asset-state {
  @include flexy();
  height: 100%;

  &__info {
    @include flexy($align: center);
    margin-right: $spacing-quarter;

    &--board {
      > .icon {
        fill: $board-icon-color;
      }
    }

    &--folder {
      > .icon {
        fill: $folder-base;
      }
    }

    &--card {
      > .icon {
        fill: $card-base;
      }
    }

    &--media {
      > .icon {
        fill: $media-icon-color;
      }
    }
  }
}
</style>
