import { MEDIA_TYPE, ASSET_TYPES } from '../../utils/types';
import BoardOptionsDialogue from '../board/BoardOptionsDialogue';
import FolderOptionsDialogue from '../folder/FolderOptionsDialogue';
import CardOptionsDialogue from '../card/CardOptionsDialogue';

const optionsComponents = {
  board: BoardOptionsDialogue,
  folder: FolderOptionsDialogue,
  card: CardOptionsDialogue
};

const types = ASSET_TYPES.filter((x) => x !== MEDIA_TYPE);

export default {
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => types.includes(value)
    }
  },
  data() {
    return {
      optionsDialogueOpen: false
    };
  },
  computed: {
    optionsComponent() {
      return optionsComponents[this.type];
    }
  },
  methods: {
    toggleOptionsDialogue() {
      this.optionsDialogueOpen = !this.optionsDialogueOpen;
    }
  }
};
