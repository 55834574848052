<template>
  <svg
    :class="[...classes, 'icon-media']"
    :style="style"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.099 14.59"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-430.357 -282.299)">
      <path
        d="M438.951,292.875a2.983,2.983,0,0,0,2.979-2.979v-4.617a2.979,2.979,0,1,0-5.958,0V289.9A2.983,2.983,0,0,0,438.951,292.875Z"
        transform="translate(-4.055 0)"
      />
      <path
        d="M439.456,309.139a.457.457,0,0,0-.913,0,3.636,3.636,0,1,1-7.272,0,.457.457,0,0,0-.913,0,4.555,4.555,0,0,0,4.025,4.517v1.375h-1.446a.481.481,0,0,0-.487.39.457.457,0,0,0,.452.523h3.861a.474.474,0,0,0,.475-.38.457.457,0,0,0-.451-.534H435.3v-1.362A4.553,4.553,0,0,0,439.456,309.139Z"
        transform="translate(0 -19.055)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'AudioMedia';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, style, text } = useLogin(name, props);
    return { classes, style, text };
  }
};
</script>
