/**
 * Light-weight feature detection module
 */

class MobileInfo {
  constructor() {
    this.Android = () => {
      return navigator.userAgent.match(/Android/i);
    };
    this.BlackBerry = () => {
      return navigator.userAgent.match(/BlackBerry/i);
    };
    this.iOS = () => {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    };
    this.Opera = () => {
      return navigator.userAgent.match(/Opera Mini/i);
    };
    this.Windows = () => {
      return (
        navigator.userAgent.match(/IEMobile/i) ||
        navigator.userAgent.match(/WPDesktop/i)
      );
    };
    this.any = () => {
      return (
        this.Android() ||
        this.BlackBerry() ||
        this.iOS() ||
        this.Opera() ||
        this.Windows()
      );
    };
  }
}

export default {
  audioRecording: () => {
    return Boolean(
      navigator.mediaDevices &&
        navigator.mediaDevices.getUserMedia &&
        window.MediaRecorder
    );
  },
  captureAttribute: () => {
    return document.createElement('input').capture !== undefined;
  },
  hasTouchSupport() {
    return !!('ontouchstart' in window || navigator.msMaxTouchPoints);
  },
  isMobile: new MobileInfo()
};
