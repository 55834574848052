import { trackError } from '@/log';

export default {
  computed: {
    fullscreenEnabled() {
      return (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      );
    }
  },
  methods: {
    isFullscreen() {
      return this.fullscreenEnabled && !!document.fullscreenElement;
    },
    requestFullscreen(el, exitCallback) {
      if (!this.fullscreenEnabled) return;

      return new Promise((resolve) => {
        let clearHandlers = null;

        const errorHandler = (error) => {
          trackError(error);
          clearHandlers();
          resolve(false);
        };
        const fullscreenchange = () => {
          if (this.isFullscreen()) {
            resolve(true);
          } else {
            // This part of code is call by the winodws handler
            // No need to resolve it!!
            if (exitCallback) exitCallback();
            clearHandlers();
          }
        };

        clearHandlers = () => {
          this.clearFullscreenChange(fullscreenchange);
          this.clearFullscreenerror(errorHandler);
        };

        if (!this.fullscreenEnabled) {
          clearHandlers();
          resolve(false);
          return;
        }

        this.handleFullscreenChange(fullscreenchange);
        this.handleFullscreenError(errorHandler);

        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen();
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen();
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen();
        }
      });
    },
    exitFullscreen() {
      if (!this.fullscreenEnabled) return;

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    handleFullscreenChange(handler) {
      if (!this.fullscreenEnabled) return;

      if (document.fullscreenEnabled)
        document.addEventListener('fullscreenchange', handler);
      else if (document.webkitFullscreenEnabled)
        document.addEventListener('webkitfullscreenchange', handler);
      else if (document.mozFullScreenEnabled)
        document.addEventListener('mozfullscreenchange', handler);
      else if (document.msFullscreenEnabled)
        document.addEventListener('MSFullscreenChange', handler);
    },
    clearFullscreenChange(handler) {
      if (!this.fullscreenEnabled) return;

      if (document.fullscreenEnabled)
        document.removeEventListener('fullscreenchange', handler);
      else if (document.webkitFullscreenEnabled)
        document.removeEventListener('webkitfullscreenchange', handler);
      else if (document.mozFullScreenEnabled)
        document.removeEventListener('mozfullscreenchange', handler);
      else if (document.msFullscreenEnabled)
        document.removeEventListener('MSFullscreenChange', handler);
    },
    handleFullscreenError(handler) {
      if (!this.fullscreenEnabled) return;

      if (document.fullscreenEnabled)
        document.addEventListener('fullscreenerror', handler);
      else if (document.webkitFullscreenEnabled)
        document.addEventListener('webkitfullscreenerror', handler);
      else if (document.mozFullScreenEnabled)
        document.addEventListener('mozfullscreenerror', handler);
      else if (document.msFullscreenEnabled)
        document.addEventListener('MSFullscreenError', handler);
    },
    clearFullscreenerror(handler) {
      if (!this.fullscreenEnabled) return;

      if (document.fullscreenEnabled)
        document.removeEventListener('fullscreenerror', handler);
      else if (document.webkitFullscreenEnabled)
        document.removeEventListener('webkitfullscreenerror', handler);
      else if (document.mozFullScreenEnabled)
        document.removeEventListener('mozfullscreenerror', handler);
      else if (document.msFullscreenEnabled)
        document.removeEventListener('MSFullscreenError', handler);
    }
  }
};
