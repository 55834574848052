import { createRouter, createWebHashHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';

import auth from './auth';
import authenticationRoutes from './authentication';
import companyRoutes from './company';
import groupRoutes from './group';
import dashboardRoutes from './dashboard';
import boardRoutes from './board';
import folderRoutes from './folder';
import cardRoutes from './card';
import accountRoutes from './account';
import evaluationRoutes from './evaluation';
import shareAssetRoutes from './shareAsset';

const routes = [
  ...authenticationRoutes(),
  ...companyRoutes(),
  ...groupRoutes(),
  ...dashboardRoutes(),
  ...boardRoutes(),
  ...folderRoutes(),
  ...cardRoutes(),
  ...evaluationRoutes(),
  ...accountRoutes(),
  ...shareAssetRoutes(),

  {
    path: '/',
    name: 'root',
    beforeEnter: async (to) => {
      const authStore = useAuthStore();
      const { isLoggedIn } = authStore;

      if (isLoggedIn) return { name: 'dashboard' };

      return {
        name: 'login',
        params: to.params,
        query: to.query
      };
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'root' }
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async ({ name }) => {
  const store = useAuthStore();

  if (
    name === 'board-share' ||
    name === 'folder-share' ||
    name === 'card-share'
  ) {
    store.identifyUser();
  } else {
    store.identifyUser(true);
  }

  await auth.checkRefreshIsNeeded();
});

export default router;
