<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.012 6.635"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-259.691 -594.152)">
      <circle
        cx="3.317"
        cy="3.317"
        r="3.317"
        transform="translate(259.691 594.152)"
      />
      <circle
        cx="3.317"
        cy="3.317"
        r="3.317"
        transform="translate(268.379 594.152)"
      />
      <circle
        cx="3.317"
        cy="3.317"
        r="3.317"
        transform="translate(277.068 594.152)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'ActionIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
