<template>
  <BaseDialogue
    class="share-asset-form-dialogue share-asset-form-dialogue--share-asset"
    :class="[
      { 'share-asset-form-dialogue__member': step === STEP_MEMBERS },
      $attrs.class
    ]"
    :title="title"
    :showOk="step !== STEP_MEMBERS"
    :showCancel="showCancel"
    :disableOk="!isValid"
    @ok="ok()"
    @cancel="back(true)"
  >
    <template #ok>{{ okButtonText }}</template>
    <template #cancel>{{ cancelButtonText }}</template>

    <transition :name="transitionName" @afterEnter="focus">
      <div
        key="base"
        v-if="step === 'base'"
        class="form-element share-asset-form-dialogue__base"
        @keydown.enter="ok"
      >
        <BorderField>
          <BaseInput
            ref="name-input"
            class="label-bold"
            :label="$t('shareAssetForm.name')"
            name="name"
            v-model.trim="form.name"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <BaseInput
            ref="link-input"
            class="label-bold"
            :label="$t('shareAssetForm.link')"
            :modelValue="link"
            @v-click="selectLink"
            isReadOnly
          >
            <template #custom>
              <div
                class="form-field__custom share-asset-form-dialogue__link--copy"
                @click.prevent.stop="copyToClipboard"
              >
                <CopyIcon size="small" />
              </div>
            </template>
          </BaseInput>

          <div v-if="qrCode" class="share-asset-form-dialogue--qrcode">
            <img :src="qrCode" />
          </div>
        </BorderField>

        <BorderField>
          <div class="form-field label-bold share-asset-form-dialogue__type">
            <div class="form-field__label">
              {{ $t('shareAssetForm.type.label') }}
            </div>

            <!-- 
            <BaseRadio
              class="form-field__check"
              :label="$t('shareAssetForm.type.public')"
              name="type"
              value="public"
              v-model="form.type"
            />
            -->

            <BaseRadio
              class="form-field__check"
              :label="$t('shareAssetForm.type.web')"
              name="type"
              value="web"
              v-model="form.type"
            />

            <BaseRadio
              class="form-field__check"
              :label="$t('shareAssetForm.type.private')"
              name="type"
              value="private"
              v-model="form.type"
            />
          </div>
        </BorderField>

        <BorderField v-if="displayMemberManagement">
          <SliderFieldset
            class="label-bold"
            :label="$t('shareAssetForm.members')"
            :active="item.memberCount > 0"
            @v-click="displayMembers"
          />
        </BorderField>

        <BorderField>
          <div class="share-asset-form-dialogue__expiry-date">
            <BaseDatetimeInput
              class="label-bold"
              :label="$t('shareAssetForm.validUntil')"
              name="expiryDate"
              v-model="form.expiryDate"
              :minDate="minExpiryDate"
              :readonly="activeDialogue === 'set-password'"
              @open="refreshMinExpiryDate"
            />
          </div>
        </BorderField>

        <BorderField v-if="displayPassword">
          <div
            class="form-field label-bold share-asset-form-dialogue__password"
          >
            <div class="form-field__label">
              {{ $t('shareAssetForm.password.label') }}
            </div>

            <div class="share-asset-form-dialogue__password__actions">
              <CallToAction size="auto" @v-click="executeActionPassord">
                {{ titleActionPassword }}
              </CallToAction>
              <CallToAction
                v-if="displayDeletePassword"
                size="auto"
                @v-click="form.deletePassword = true"
              >
                {{ $t('shareAssetForm.password.delete') }}
              </CallToAction>
            </div>

            <div
              v-if="displayPasswordEditMessage"
              class="share-asset-form-dialogue__password--edit-message"
            >
              {{ $t('shareAssetForm.password.editMessage') }}
            </div>
          </div>
        </BorderField>

        <BorderField v-if="displayCustomizeShareButton">
          <div class="share-asset-form-dialogue__customize-share">
            <div class="share-asset-form-dialogue--action">
              <CallToAction size="auto" @v-click="customizeShare">
                {{ $t('shareAssetForm.customizeShare') }}
              </CallToAction>
            </div>
          </div>
        </BorderField>
      </div>

      <div
        key="named"
        v-else-if="step === 'named'"
        class="form-element share-asset-form-dialogue__base"
        @keydown.enter="ok"
      >
        <BorderField>
          <BaseInput
            ref="firstname-input"
            class="label-bold"
            :label="$t('shareAssetForm.firstName')"
            name="firstname"
            v-model.trim="form.firstName"
            :maxlength="255"
          />
        </BorderField>
        <BorderField>
          <BaseInput
            class="label-bold"
            :label="$t('shareAssetForm.lastName')"
            name="lastname"
            v-model.trim="form.lastName"
            :maxlength="255"
          />
        </BorderField>
        <BorderField>
          <BaseInput
            class="label-bold"
            :label="$t('shareAssetForm.email')"
            type="email"
            name="email"
            v-model.trim="form.email"
            :maxlength="255"
          />
        </BorderField>

        <BorderField>
          <BaseInput
            class="label-bold"
            :label="$t('shareAssetForm.cellular')"
            name="phoneNumber"
            v-model.trim="form.phoneNumber"
            :maxlength="20"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <BaseInput
            ref="link-input"
            class="label-bold"
            :label="$t('shareAssetForm.link')"
            :modelValue="link"
            @v-click="selectLink"
            isReadOnly
          >
            <template #custom>
              <div
                class="form-field__custom share-asset-form-dialogue__link--copy"
                @click.prevent.stop="copyToClipboard"
              >
                <CopyIcon size="small" />
              </div>
            </template>
          </BaseInput>

          <div v-if="qrCode" class="share-asset-form-dialogue--qrcode">
            <img :src="qrCode" />
          </div>
        </BorderField>

        <BorderField>
          <BaseInput
            class="label-bold"
            :label="$t('shareAssetForm.externalReference')"
            name="externalReference"
            v-model.trim="form.externalReference"
            :maxlength="255"
          />
        </BorderField>

        <BorderField v-if="displayCustomizeShareButton">
          <div class="share-asset-form-dialogue__customize-share">
            <div class="share-asset-form-dialogue--action">
              <CallToAction size="auto" @v-click="customizeShare">
                {{ $t('shareAssetForm.customizeShare') }}
              </CallToAction>
            </div>
          </div>
        </BorderField>
      </div>

      <MemberManagement
        :key="STEP_MEMBERS"
        v-else-if="step === STEP_MEMBERS"
        :type="SHARE_ASSET_TYPE"
        :parent="item"
        :showPrevious="!inviteParticipants"
        @keydown.enter="ok"
        @countChanged="memberCountChanged"
        @ok="ok()"
        @cancel="back(true)"
      />
    </transition>
  </BaseDialogue>

  <SetPasswordDialogue
    v-if="activeDialogue === 'set-password'"
    type="share-asset"
    @success="applyNewPassword"
    @cancel="toggleDialogue()"
  />
</template>

<script>
import '@/assets/stylesheets/components/_form.scss';
import { computed, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores/app';
import { useShareAssetStore } from '@/stores/share-asset';
import { useToggleState } from '@/composables/toggle-state';

import BaseDialogue from '@/components/dialogues/BaseDialogue';
import MemberManagement from '@/components/member/MemberManagement';
import SliderFieldset from '@/components/forms/SliderFieldset';
import BorderField from '@/components/forms/BorderField';
import SetPasswordDialogue from '@/components/forms/SetPasswordDialogue';
import CallToAction from '@/components/forms/CallToAction';
import CopyIcon from '@/components/icons/Copy';

import tools from '@/utils/tools';
import { now, toDisplay } from '@/utils/date';
import { SHARE_ASSET_TYPE } from '@/utils/types';

const STEP_BASE = 'base';
const STEP_NAMED = 'named';
const STEP_MEMBERS = 'memberManagement';

export default {
  inheritAttrs: false,
  name: 'ShareAssetFormDialogue',
  components: {
    BaseDialogue,
    MemberManagement,
    SliderFieldset,
    BorderField,
    SetPasswordDialogue,
    CallToAction,
    CopyIcon
  },
  props: {
    asset: {
      type: Object,
      default: null
    },
    editItem: {
      type: Object,
      default: () => ({})
    },
    inviteParticipants: {
      type: Boolean,
      default: false
    },
    isPaidNamedWebLink: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n();
    const { displayError } = useAppStore();
    const { modify, getQrCode } = useShareAssetStore();

    const { activeToast } = useToggleState('toast');
    const { activeDialogue, toggleDialogue } = useToggleState('dialogue');

    const $refs = {
      'link-input': ref(null),
      'name-input': ref(null),
      'firstname-input': ref(null)
    };

    const selectOnFocus = ref(!props.editItem.id);
    const isPaidNamedWebLink =
      props.editItem.type === 'named' || !!props.isPaidNamedWebLink;

    const ra = reactive({
      form: isPaidNamedWebLink
        ? {
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
            externalReference: ''
          }
        : { expiryDate: null },
      item: { ...props.editItem },
      qrCode: null,

      selectedParticipant: null,
      submitted: false,
      step: isPaidNamedWebLink
        ? STEP_NAMED
        : props.inviteParticipants
          ? STEP_MEMBERS
          : STEP_BASE,
      transitionName: 'prev',
      minExpiryDate: now(),

      paidNamedWebLink: isPaidNamedWebLink
    });

    const hasPassword = computed(() => {
      return ra.item.hasPassword && !ra.form.deletePassword;
    });
    const hasNewPassword = computed(() => {
      return !!(ra.form.newPassword || '').trim();
    });

    const cp = {
      STEP_MEMBERS: computed(() => STEP_MEMBERS),
      mode: computed(() => {
        return ra.item.id ? 'edit' : 'add';
      }),
      link: computed(() => {
        if (cp.mode.value !== 'edit') return '';

        const { origin } = document.location;
        const { href } = router.resolve({
          name: 'share',
          params: { hash: ra.item.hash }
        });
        return `${origin}/${href}`;
      }),
      isValid: computed(() => {
        if (ra.paidNamedWebLink) {
          if (!ra.form.firstName || !ra.form.lastName) return false;
          return !!ra.form.phoneNumber || tools.regExpEmail.test(ra.form.email);
        } else {
          return !!ra.form.name && !!ra.form.type;
        }
      }),
      title: computed(() => {
        switch (ra.step) {
          case STEP_BASE:
          case STEP_NAMED: {
            const mode = cp.mode.value;
            const assetType =
              mode === 'add' ? props.asset.type : ra.item.assetType;
            const assetName =
              mode === 'add' ? props.asset.name : ra.item.asset?.name;

            const key = `shareAssetForm.title.${mode}.${assetType}`;
            return t(key, [assetName]);
          }
          case STEP_MEMBERS: {
            const key = 'shareAssetForm.members';
            return t(key);
          }
        }

        return '';
      }),
      displayPassword: computed(() => {
        return !ra.paidNamedWebLink && ra.form.type !== 'private';
      }),
      displayDeletePassword: computed(() => {
        return (
          !ra.paidNamedWebLink && hasPassword.value && !hasNewPassword.value
        );
      }),
      displayPasswordEditMessage: computed(() => {
        return (
          !ra.paidNamedWebLink &&
          (hasNewPassword.value || !!ra.form.deletePassword)
        );
      }),
      titleActionPassword: computed(() => {
        if (hasNewPassword.value || ra.form.deletePassword) {
          return t('shareAssetForm.password.cancel');
        } else if (hasPassword.value) {
          return t('shareAssetForm.password.edit');
        } else {
          return t('shareAssetForm.password.add');
        }
      }),
      displayMemberManagement: computed(() => {
        return (
          !ra.paidNamedWebLink &&
          cp.mode.value === 'edit' &&
          ra.form.type === 'private'
        );
      }),
      okButtonText: computed(() => {
        if (
          (ra.step === STEP_BASE || ra.step === STEP_NAMED) &&
          cp.mode.value === 'add' &&
          ra.form.type === 'private'
        ) {
          return t('dialogue.footer.next');
        } else return t('dialogue.footer.ok');
      }),
      cancelButtonText: computed(() => {
        return props.editItem.id || cp.mode.value === 'add'
          ? t('dialogue.footer.cancel')
          : t('dialogue.footer.close');
      }),
      showCancel: computed(() => {
        return !props.inviteParticipants && ra.step !== STEP_MEMBERS;
      }),
      displayCustomizeShareButton: computed(() => {
        return cp.mode.value === 'edit' && ra.item.assetType === 'card';
      })
    };

    const init = () => {
      if (ra.paidNamedWebLink) {
        const {
          email = '',
          phoneNumber = '',
          firstName = '',
          lastName = '',
          externalReference = ''
        } = ra.item;

        ra.form = {
          type: 'named',
          email,
          phoneNumber,
          lastName,
          firstName,
          externalReference
        };
      } else {
        const {
          name = `${props.asset.name} - ${toDisplay(new Date())}`,
          type = 'web',
          expiryDate = null
        } = ra.item;

        ra.form = {
          name,
          type,
          expiryDate: expiryDate ? new Date(expiryDate) : null,
          deletePassword: false,
          newPassword: null
        };
      }
    };

    const fns = {
      back(cancel) {
        switch (ra.step) {
          case STEP_BASE:
          case STEP_NAMED:
            emit('cancel');
            break;
          case STEP_MEMBERS:
            ra.step = STEP_BASE;
            if (cancel && props.inviteParticipants) emit('cancel');
            break;
        }

        ra.transitionName = 'prev';
      },
      displayMembers: () => {
        if (ra.paidNamedWebLink) return;
        ra.step = STEP_MEMBERS;
      },
      copyToClipboard() {
        navigator.clipboard.writeText(cp.link.value);
      },
      customizeShare() {
        router.push({
          name: SHARE_ASSET_TYPE,
          params: { id: ra.item.id }
        });
        emit('cancel');
        emit('customizeShare');
      },
      focus: () => {
        if (ra.step === STEP_BASE) {
          $refs['name-input'].value.focus(selectOnFocus.value);
        } else if (ra.step === STEP_NAMED) {
          $refs['firstname-input'].value.focus(selectOnFocus.value);
        }

        selectOnFocus.value = false;
      }
    };

    const refreshQrCode = async () => {
      ra.qrCode = null;
      if (ra.item?.id) {
        const { qrCode } = await getQrCode(ra.item.id);
        ra.qrCode = qrCode;
      }
    };

    const refreshData = async () => {
      init();
      await refreshQrCode();
    };

    // HOOKS
    onMounted(() => {
      refreshData();

      nextTick(() => {
        if (!ra.paidNamedWebLink && props.inviteParticipants)
          fns.displayMembers();
        else fns.focus();
      });
    });

    return {
      // REFS
      ...$refs,
      activeToast,

      // COMPOSABLES
      activeDialogue,
      toggleDialogue,

      // DATA
      ...toRefs(ra),

      // COMPUTED
      SHARE_ASSET_TYPE: computed(() => SHARE_ASSET_TYPE),
      ...cp,

      // METHODS
      ...fns,

      async ok() {
        switch (ra.step) {
          case STEP_BASE:
          case STEP_NAMED:
            if (ra.submitted) return;
            ra.submitted = true;

            try {
              let data;

              if (ra.item.id) {
                data = {
                  ...ra.form,
                  id: ra.item.id
                };
              } else {
                data = {
                  ...ra.form,
                  assetId: props.asset.id,
                  assetType: props.asset.type
                };
              }

              const entity = await modify(data);

              if (cp.mode.value === 'edit') {
                emit('success', entity);
              } else {
                ra.item = { ...entity };
                refreshData();

                if (entity.type === 'private') {
                  fns.displayMembers();
                }
              }
            } catch (e) {
              console.error(e);
              displayError({ message: t('global.error') });
            } finally {
              ra.submitted = false;
            }

            break;
          case STEP_MEMBERS:
            emit('cancel');
            break;
        }
      },
      refreshMinExpiryDate() {
        ra.minExpiryDate = now();
      },
      executeActionPassord() {
        if (ra.form.deletePassword || hasNewPassword.value) {
          ra.form.deletePassword = false;
          ra.form.newPassword = '';
        } else {
          toggleDialogue('set-password');
        }
      },
      applyNewPassword(newPassword) {
        ra.form.newPassword = newPassword;
        toggleDialogue();
      },
      memberCountChanged(count) {
        ra.item = { ...ra.item, memberCount: count };
      },
      selectLink() {
        $refs['link-input'].value.select();
      }
    };
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';

.share-asset-form-dialogue {
  @include color-form();
  @include next-prev-transition();

  &__link--copy {
    @include flexy($align: center);
    height: 100%;
    padding: $spacing-quarter;
    margin-left: $spacing-half;
    cursor: pointer;
  }

  &__type {
    .form-field__check {
      margin: $spacing-half;
    }
  }

  &__expiry-date {
    @include flexy($align: flex-start);
  }

  &--action {
    @include flexy($just: center);
    margin: $spacing-base;

    > button {
      padding: $spacing-base;
      border: 1px solid $share-asset-base;
      background-color: $share-asset-base;
      margin-top: 0;
    }
  }

  &__password {
    &__actions {
      @include flexy();
      height: $vertical-rhythm;
      min-height: $vertical-rhythm;
      margin-top: $spacing-third;

      > * {
        margin-right: $spacing-half;
      }

      > button {
        background-color: $share-asset-base;
      }
    }

    &--edit-message {
      font-size: $font-size-small;
      font-weight: normal;
      font-style: italic;
      color: $font-important-color;
      margin: $spacing-half;
    }
  }

  &__member {
    > .dialogue__box {
      min-height: 410px;
      max-height: 100%;
    }
  }

  &--qrcode {
    @include flexy($just: center);
    margin: $spacing-base;

    > img {
      max-width: 200px;
      height: auto;
      vertical-align: middle;
      border: 0;
    }
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }

  .form-field__check {
    display: block;
    text-align: left;
  }
}
</style>
