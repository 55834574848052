<template>
  <BaseDialogue
    class="media-form-dialogue"
    :class="$attrs.class"
    :title="title"
    @cancel="$emit('cancel')"
    @ok="$emit('ok')"
    :disableOk="disableOk"
  >
    <template #head>
      <slot name="head">
        <button
          v-if="displayBack"
          class="button icon-button media-form-dialogue__back"
          @click="$emit('back')"
        >
          <Chevron direction="left" type="light" />
        </button>
        <h1>{{ title }}</h1>
      </slot>
    </template>

    <slot></slot>

    <ToastAlert v-if="error" level="error" @cancel="$emit('update:error', '')">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import BaseDialogue from '../dialogues/BaseDialogue';
import Chevron from '../icons/Chevron';
import ToastAlert from '../toast/ToastAlert';

export default {
  inheritAttrs: false,
  name: 'MediaFormDialogue',
  components: {
    BaseDialogue,
    Chevron,
    ToastAlert
  },
  props: {
    title: {
      type: String,
      required: true
    },
    displayBack: {
      type: Boolean,
      default: false
    },
    disableOk: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss">
.media-form-dialogue {
  .dialogue__header {
    font-style: $font-italic;
    position: relative;

    > .media-form-dialogue__back {
      position: absolute;
      left: 0;
      margin-left: $spacing-half;
    }
  }
}
</style>
