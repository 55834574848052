<template>
  <label
    class="checkbox"
    :for="id"
    :class="[
      { checkbox__disabled: disabled },
      `checkbox--${color}`,
      $attrs.class
    ]"
  >
    <input
      class="checkbox__input"
      type="checkbox"
      :disabled="disabled"
      :name="name"
      :id="id"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <span
      class="checkbox__text"
      :class="{ 'checkbox__text--disabled': disabled }"
    >
      <span class="checkbox__text__check"
        ><span
          class="checkbox__text__check--checked"
          :class="{ 'checkbox__text__check--checked--min': activeMinusState }"
        ></span
      ></span>
      <span v-if="label" class="checkbox__text--label">{{ label }}</span>
    </span>
  </label>
</template>

<script>
const COLORS = ['light', 'dark'];

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: Boolean,
      required: true
    },
    color: {
      type: String,
      default: 'dark',
      validator: (value) => COLORS.includes(value)
    },
    activeMinusState: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-block;

  $inner-size: 10px;

  &__text {
    @include flexy($disp: inline-flex, $align: center);
    cursor: pointer;

    &__check {
      @include flexy($align: center, $just: center);
      border: $border-width solid $border-checkbox;
      border-radius: calc($spacing-base / 6);
      background-color: $background-light;
      height: rem($spacing-base);
      width: rem($spacing-base);

      &--checked {
        height: rem($inner-size);
        width: rem($inner-size);

        &--min {
          height: rem(3px);
        }
      }
    }

    &--label {
      margin-left: rem($spacing-half);
    }

    &--disabled {
      cursor: default;
    }
  }

  &__input {
    flex: none;
    height: 0;
    opacity: 0;
    width: 0;
    display: none;

    &:checked
      + .checkbox__text
      > .checkbox__text__check
      > .checkbox__text__check--checked {
      background-color: $background-selected-checkbox;
      border: 1px solid $background-selected-checkbox;
    }
  }

  &--light {
    .checkbox__text__check {
      border: $border-width solid $background-dark;
    }

    .checkbox__input:checked
      + .checkbox__text
      > .checkbox__text__check
      > .checkbox__text__check--checked {
      background-color: $background-dark;
      border: 1px solid $background-dark;
    }
  }

  &__disabled {
    opacity: $disable-opacity;
  }
}
</style>
