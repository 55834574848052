<template>
  <div
    class="share-asset-item"
    :class="[{ 'share-asset-item--navigable': canNavigate }, $attrs.class]"
  >
    <div class="share-asset-item__avatar" @click.prevent="selected">
      <AvatarPill :info="item.author" />
    </div>

    <div class="share-asset-item__main" @click.prevent="selected">
      <div>{{ item.name }}</div>
      <div v-if="displayAsset" class="share-asset-item__main--asset">
        <ItemIcon
          :type="asset.type"
          :item="asset"
          .withLink="false"
          smallIcon
        />
      </div>
    </div>

    <div class="share-asset-item__open-count">
      {{ item.openCount }}
    </div>
    <div class="share-asset-item__action" @click="toggleDialogue('options')">
      <Action size="small" />
    </div>
    <div
      v-if="canNavigate"
      class="share-asset-item__next"
      @click.prevent="selected"
    >
      <Chevron size="xsmall" direction="right" />
    </div>

    <!-- Options dialogue -->
    <ShareAssetOptionsDialogue
      v-if="activeDialogue === 'options'"
      :item="item"
      @cancel="toggleDialogue()"
      @customizeShare="emitCustomizeShare"
    />
  </div>
</template>

<script>
import Action from '@/components/icons/Action';
import ShareAssetOptionsDialogue from '@/components/shareAsset/ShareAssetOptionsDialogue';
import AvatarPill from '../AvatarPill';
import ItemIcon from '@/components/layout/ItemIcon';
import Chevron from '@/components/icons/Chevron';

import { useRouter } from 'vue-router';
import { useToggleState } from '@/composables/toggle-state';
import { computed } from 'vue';
import { CARD_TYPE, SHARE_ASSET_TYPE } from '@/utils/types';

export default {
  inheritAttrs: false,
  name: 'ShareAssetItem',
  components: {
    Chevron,
    Action,
    ShareAssetOptionsDialogue,
    AvatarPill,
    ItemIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    displayAsset: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { activeDialogue, toggleDialogue } = useToggleState('dialogue');

    const canNavigate = computed(() => {
      return props.item?.assetType === CARD_TYPE;
    });

    return {
      canNavigate,

      asset: computed(() => {
        return {
          ...props.item.asset,
          type: props.item.assetType,
          id: props.item.assetId
        };
      }),

      activeDialogue,
      toggleDialogue,

      selected: () => {
        if (!canNavigate.value) return;

        router.push({
          name: SHARE_ASSET_TYPE,
          params: { id: props.item.id }
        });

        emit('selected');
      },
      emitCustomizeShare: () => {
        emit('customizeShare');
      }
    };
  }
};
</script>

<style lang="scss">
.share-asset-item {
  @include grid-item(item);
  @include grid(
    $gap: 0,
    $cols: auto 1fr auto auto auto,
    $areas: 'avatar main open-count action next'
  );

  min-height: $height-item;
  color: $font-dark;
  background-color: #ffffff;

  &--navigable {
    cursor: pointer;
  }

  &__avatar {
    @include grid-item(avatar);
    @include flexy($just: center, $align: center);
    margin: 0 $spacing-half;
    margin-right: 0;
    height: 100%;
  }

  &__main {
    @include grid-item(main);
    @include flexy($dir: column, $just: center);
    padding: $spacing-half;

    &--asset {
      font-style: italic;
      font-size: $font-size-small;
    }
  }

  &__open-count {
    @include grid-item(open-count);
    @include flexy($align: center);
    padding: $spacing-half;
    cursor: pointer;
  }

  &__action {
    @include grid-item(action);
    @include flexy($align: center);
    padding: $spacing-half;
    cursor: pointer;
  }

  &__next {
    @include grid-item(next);
    @include flexy($align: center);
    padding: $spacing-half;
  }
}
</style>
