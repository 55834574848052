<template>
  <BaseDialogue
    class="form-dialogue-photo"
    :class="$attrs.class"
    :title="$t(`mediaForm.photo.label.${mode}`)"
    :show-ok="showOk"
    :content-maximized="maximized"
    @cancel="$emit('cancel')"
    @ok="ok()"
  >
    <div class="form-dialogue-photo__content" @keydown.enter.prevent="ok">
      <BaseInput
        ref="name"
        :label="$t('mediaForm.name')"
        name="name"
        :maxlength="120"
        v-model.trim="form.name"
      />

      <div class="form-dialogue-photo__image">
        <transition :name="transitionName">
          <div
            v-if="step === 'select-image'"
            class="form-dialogue-photo__image--transition"
          >
            <div class="form-element form-dialogue-photo__image--select">
              <PhotoMedia
                class="form-dialogue-photo__image--select--icon"
                size="xlarge"
              />

              <FileInput
                v-if="!uploadInProgress && canCapture"
                :accept="['image/*']"
                @change="fileSelected"
                capture="environment"
                name="capture"
              >
                {{ $t('mediaForm.camera') }}
              </FileInput>

              <FileInput
                v-if="!uploadInProgress"
                :accept="acceptedTypes"
                @change="fileSelected($event, true)"
                name="files"
              >
                {{ $t('mediaForm.select') }}
              </FileInput>

              <div
                v-if="uploadInProgress"
                class="form-dialogue-photo__image--select--upload"
              >
                {{ $t('mediaForm.photo.uploadInProgress') }}
              </div>
            </div>
          </div>
        </transition>

        <transition :name="transitionName">
          <div
            v-if="step === 'preview'"
            class="form-dialogue-photo__image--transition"
          >
            <div class="form-element form-dialogue-photo__image--preview">
              <DblClickElement
                v-if="hasPreview"
                class="form-dialogue-photo__image--preview--content"
                :class="{
                  'form-dialogue-photo__image--preview--content--maximized':
                    maximized
                }"
                @dblclick="togglePhoto"
              >
                <div class="form-dialogue-photo__image--figure">
                  <img
                    class="form-dialogue-photo__image--figure--image"
                    :src="preview"
                    :alt="fileName"
                  />
                </div>
              </DblClickElement>
              <div
                v-else
                class="form-element form-dialogue-photo__image--preview--message"
              >
                {{ $t('mediaForm.photo.noPreviewAvailable') }}
              </div>

              <button
                class="button icon-button form-dialogue-photo__image--delete"
                @click="trash"
              >
                <Trash />
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import features from '../../utils/features';
import { toMegabytes } from '../../utils/numbers';
import BaseDialogue from '../dialogues/BaseDialogue';
import FileInput from '../forms/FileInput';
import PhotoMedia from '../icons/PhotoMedia';
import Trash from '../icons/Trash';
import mediaForm from '../mixins/mediaForm';
import ToastAlert from '../toast/ToastAlert';
import DblClickElement from '../extensions/DblClickElement';

const acceptedTypes = process.env.PHOTO_MEDIA_SUPPORTED_TYPES;
const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

const STEP_BASE = 'select-image';
const STEP_PREVIEW = 'preview';

export default {
  inheritAttrs: false,
  name: 'FormDialoguePhoto',
  mixins: [mediaForm],
  components: {
    BaseDialogue,
    FileInput,
    PhotoMedia,
    ToastAlert,
    Trash,
    DblClickElement
  },
  data() {
    const { id, name = '', fileInfos } = this.editItem;

    return {
      acceptedTypes,
      form: {
        file: null,
        name,
        mediaType: 'photo'
      },
      preview: (fileInfos && fileInfos.url) || '',
      submitted: false,
      step: id ? STEP_PREVIEW : STEP_BASE,
      transitionName: 'prev',
      error: '',
      maximized: false
    };
  },
  computed: {
    previewStyle() {
      return {
        backgroundImage: `url(${this.preview})`
      };
    },
    canCapture() {
      return features.captureAttribute();
    },
    showOk() {
      return this.step === STEP_PREVIEW;
    },
    fileName() {
      const { fileInfos } = this.editItem;
      const { file } = this.form;
      return (fileInfos && fileInfos.name) || (file && file.name) || '';
    },
    hasPreview() {
      const ext = this.getFileExt();
      return !(ext === 'heic' || ext === 'heif' || ext === '');
    }
  },
  methods: {
    async ok() {
      switch (this.step) {
        case STEP_BASE:
          this.transitionName = 'next';
          this.step = STEP_PREVIEW;
          break;
        case STEP_PREVIEW:
          if (!this.form.name) {
            this.error = this.$t('mediaForm.nameRequired');
            this.select('name');
            return;
          }

          if (!this.submitted) {
            this.submitted = true;
            try {
              const media = await this.modify();
              this.$emit('success', media);
            } catch (e) {
              console.error(e);
              this.submitted = false;
              this.error = this.$t('mediaForm.error');
            }
          }
          break;
      }
    },
    clearPreview() {
      if (this.preview) {
        URL.revokeObjectURL(this.preview);
        this.preview = '';
      }
    },
    trash() {
      this.clearPreview();

      if (this.form.file) {
        let name;
        const pos = this.form.file.name.lastIndexOf('.');
        if (pos > 0) {
          name = this.form.file.name.substring(0, pos);
        } else {
          name = this.form.file.name;
        }

        if (this.form.name === name) this.form.name = '';
      }

      this.form.file = null;
      this.transitionName = 'prev';
      this.step = STEP_BASE;
      this.select('name');
    },
    fileSelected(files, setName) {
      // eslint-disable-next-line
        const [file, ..._rest] = files

      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
        return;
      }

      this.form.file = file;

      if (setName && !(this.form.name || '')) {
        const pos = file.name.lastIndexOf('.');
        if (pos > 0) {
          this.form.name = file.name.substring(0, pos);
        } else {
          this.form.name = file.name;
        }
      }

      this.preview = URL.createObjectURL(file);
      this.ok();
      setTimeout(() => {
        this.select('name');
      }, 100);
    },
    togglePhoto() {
      if (!this.preview) return;

      this.maximized = !this.maximized;
    },
    getFileExt() {
      const reg = /.+\.(.+)$/i;

      const match = this.fileName.trim().match(reg);
      if (match && match[1]) {
        return match[1].trim().toLowerCase();
      }

      return '';
    }
  },
  mounted() {
    if (this.file) {
      this.fileSelected([this.file], true);
    }

    this.$nextTick(() => {
      this.select('name');
    });
  },
  beforeUnmount() {
    this.clearPreview();
  }
};
</script>

<style lang="scss">
$base-index: 1;
$color: $media-photo;

.form-dialogue-photo {
  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__main {
    overflow: hidden;
  }

  .dialogue__header {
    font-style: $font-italic;
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $color;
  }

  .form-field {
    border: none;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
  }

  &__image {
    @include next-prev-transition();
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;

    &--transition {
      @include flexy($dir: column, $just: center);
      width: 100%;
      height: 100%;
    }

    &--select {
      @include flexy($dir: column, $align: center, $just: center);

      > .icon {
        fill: $color;
      }

      > .file-input {
        background-color: $color;
      }

      &--upload {
        @include flexy($dir: column, $just: center, $align: center);
        flex: 1;
        font-style: $font-italic;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        padding: $spacing-double;
        text-align: center;
      }
    }

    &--preview {
      @include flexy($dir: column);
      height: 100%;

      &--content {
        @include flexy($dir: column, $align: center);
        flex: 1;
        height: 100%;
        padding: $spacing-base;
        overflow: hidden;

        &--maximized {
          @include flexy($dir: column, $just: center, $align: center);
          background-color: #000;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: $base-index + 10;
        }
      }

      &--message {
        @include flexy($align: center, $just: center);
        flex: 1;
        height: 100%;
        padding: $spacing-base;
        overflow: hidden;
      }
    }

    &--figure {
      @include flexy($dir: column, $just: center, $align: center);
      height: 100%;
      overflow: hidden;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;

      &--image {
        height: 100%;
        width: 100%;
        overflow: hidden;
        object-fit: contain;
      }
    }

    &--delete {
      align-self: flex-end;
      padding: $spacing-base;
    }
  }
}
</style>
