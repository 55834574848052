import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'ProtectedIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
      >
        <title>{{ text }}</title>
        <g transform="translate(-219.732 -448.511) rotate(16)">
          <path d="M353.7,371.2c-1.1-0.5-2.4,0.1-2.8,1.1l-0.2,0.6l4,1.7l0.2-0.6 C355.3,372.9,354.8,371.6,353.7,371.2z" />
          <path d="M348.7,385.7l-1.4,0.6l-0.6-1.4l4.4-10.6l-1-0.4l-4.6,11h0h0l1.1,2.8l0,0.1h0v0l0,0l2.8-1.2 v0h0l4.6-11l-1-0.4L348.7,385.7z" />
          <path d="M355.2,367.6c-6.4-2.7-13.7,0.3-16.4,6.7c-2.7,6.4,0.3,13.7,6.7,16.4c6.4,2.7,13.7-0.3,16.4-6.7c0,0,0,0,0,0C364.5,377.6,361.6,370.3,355.2,367.6z M359.7,385.3c-3.4,5.2-10.4,6.6-15.5,3.1c-4.5-3-6.2-8.7-4.1-13.7c0.1-0.3,0.3-0.7,0.5-1l6.7,4l0.5-1.2l-6.5-3.9c3.6-5.1,10.5-6.3,15.6-2.7c4.3,3,5.9,8.7,3.9,13.5c-0.1,0.2-0.2,0.5-0.3,0.7l-6.7-4l-0.5,1.2L359.7,385.3z" />
        </g>
      </svg>
    );
  }
};
