<template>
  <div class="burger-menu" :class="$attrs.class">
    <div class="burger-menu__action">
      <button
        class="icon-button burger-menu__action--button"
        :class="{ 'burger-menu__action--button--active': isOpen }"
        @click="toggleBurgerMenu()"
      >
        <Cross v-if="isOpen" title="burgerMenu.icon.close" />
        <Hamburger v-else title="burgerMenu.icon.menu" />
      </button>
      <div
        v-if="displayCounter"
        class="burger-menu__action--count"
        @click="toggleBurgerMenu"
      >
        {{ counter }}
      </div>
    </div>

    <transition name="slide">
      <SlideMenu v-if="menuOpen" slide="down" :zIndex="25">
        <MenuItem
          v-if="!isLoggedIn || isRootAdministrator || isWebShareLoggedIn"
          :label="$t(otherLanguage)"
          @selected="switchLanguage()"
          class="burger-menu__lang-switch"
        />
        <MenuItem
          v-if="displayActionLogin"
          :label="loginDescription"
          @selected="executeLoginAction()"
        />
      </SlideMenu>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import Cross from './icons/Cross';
import Hamburger from './icons/Hamburger';
import MenuItem from './menus/MenuItem';
import authMixins from './mixins/auth';
import SlideMenu from './menus/SlideMenu';
import routerMixins from './mixins/router';
import { NAVIGATION_TYPE } from '../utils/types';

export default {
  inheritAttrs: false,
  name: 'BurgerMenu',
  mixins: [authMixins, routerMixins],
  components: {
    Cross,
    MenuItem,
    SlideMenu,
    Hamburger
  },
  data() {
    return {
      menuOpen: false,
      menuClicked: false
    };
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar']),
    displayCounter() {
      if (
        this.isWebShareLoggedIn ||
        !this.isLoggedIn ||
        this.isRootAdministrator
      )
        return false;
      return !this.isOpen && !!this.counter;
    },
    counter() {
      if (!this.currentUser) return '';

      const count = this.currentUser.unreadChatMessageCount;
      if (!count || count < 0) return '';
      return count < 100 ? count : '99+';
    },
    isOpen() {
      if (this.isLoggedIn && !this.isWebShareLoggedIn)
        return this.activeLeftSideBar.type === NAVIGATION_TYPE;
      else return this.menuOpen;
    },
    otherLanguage() {
      return this.$i18n.locale === 'en'
        ? 'profileMenu.french'
        : 'profileMenu.english';
    },
    loginDescription() {
      if (this.isWebShareLoggedIn && this.isLoggedIn) {
        return this.$t('burgerMenu.home');
      } else if (this.isRootAdministrator && this.isLoggedIn) {
        return this.$t('profileMenu.logout');
      } else {
        return this.$t('burgerMenu.login');
      }
    },
    displayActionLogin() {
      return (
        this.$route.name !== 'login' &&
        (!this.isLoggedIn ||
          (this.isRootAdministrator && this.isLoggedIn) ||
          (this.isWebShareLoggedIn && this.isLoggedIn))
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    toggleBurgerMenu() {
      if (
        this.isLoggedIn &&
        !this.isRootAdministrator &&
        !this.isWebShareLoggedIn
      ) {
        if (this.activeLeftSideBar.type === NAVIGATION_TYPE) {
          this.setActiveLeftSideBar();
        } else {
          this.setActiveLeftSideBar({ type: NAVIGATION_TYPE });
        }
      } else {
        this.menuClicked = true;
        this.menuOpen = !this.menuOpen;
      }
    },
    async switchLanguage() {
      const newLanguage = this.$i18n.locale === 'en' ? 'fr' : 'en';
      this.$i18n.locale = newLanguage;
    },
    executeLoginAction() {
      if (this.isWebShareLoggedIn && this.isLoggedIn) {
        this.navigate({ name: 'root' });
      } else if (this.isRootAdministrator && this.isLoggedIn) {
        this.navigate({ name: 'logout' });
      } else {
        this.navigate({ name: 'login' });
      }
    },
    clicked() {
      if (!this.menuClicked) {
        this.menuOpen = false;
      }

      this.menuClicked = false;
    }
  },
  watch: {
    menuOpen(value) {
      if (value) {
        document.addEventListener('click', this.clicked);
      } else {
        document.removeEventListener('click', this.clicked);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.burger-menu {
  height: 100%;

  &__action {
    height: 100%;
    position: relative;

    &--button {
      height: 100%;

      &--active {
        @include svg-active-background($background-triangle-up-svg);
        background-position: bottom;
      }
    }

    &--count {
      @include flexy($just: center, $align: center);
      position: absolute;
      top: 5px;
      right: -5px;
      border-radius: 15px;
      height: 15px;
      min-width: 15px;
      background-color: $counter-background;
      color: $font-light;
      font-size: 11px;
      font-weight: bold;
      padding: 0 calc($spacing-quarter / 2);
      cursor: pointer;
    }
  }

  .burger-menu__lang-switch {
    text-transform: capitalize;
  }
}
</style>
