const passwordCheckExp =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/gi;

export const generatePassword = () => {
  const characters1 = '!@#$%&*';
  const characters2 = '0123456789';
  const characters3 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  const generate = (characters, length) => {
    let result = '';
    const l = characters.length;
    for (let i = 0; i < l; i++) {
      result += characters.charAt(Math.floor(Math.random() * l));
    }

    return result.substring(0, length);
  };

  return (
    generate(characters1, 1) +
    generate(characters2, 1) +
    generate(characters3, 5) +
    generate(characters1, 1) +
    generate(characters2, 1) +
    generate(characters3, 2)
  );
};

export const passwordIsValid = (password) => {
  if (!(password || '').trim()) return false;
  return passwordCheckExp.test(password);
};
