import auth from '../auth';
import { ROLE_PARTICIPANT_RESTRICTED } from '../../utils/role';
const FolderView = () => import('../../components/folder/FolderView');

export default () => {
  return [
    {
      path: '/folder/:id/share/:hash',
      name: 'folder-share',
      component: FolderView,
      props: (route) => ({
        id: parseInt(route.params.id)
      }),
      beforeEnter: auth.ensureHasShareToken()
    },
    {
      path: '/folder/:id',
      name: 'folder',
      component: FolderView,
      props: (route) => ({
        id: parseInt(route.params.id),
        displayComments: route.query.displayComments === 'true'
      }),
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    }
  ];
};
