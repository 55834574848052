export default {
  props: {
    media: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    slideShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    item() {
      return this.media;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.resized) this.resized();
    });
  },
  watch: {
    width() {
      this.$nextTick(() => {
        if (this.resized) this.resized();
      });
    },
    height() {
      this.$nextTick(() => {
        if (this.resized) this.resized();
      });
    }
  }
};
