import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'UpDown';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.705 22.705"
      >
        <title>{{ text }}</title>
        <path d="M12.637,10.067V4.936a1.285,1.285,0,0,0-2.57,0v5.131c.049.013,0,2.551,0,2.57v5.131a1.285,1.285,0,1,0,2.57,0v-7.7Z" />
        <path d="M18.937,1.375a2.4,2.4,0,0,1,2.394,2.393V18.937a2.4,2.4,0,0,1-2.394,2.394H3.768a2.4,2.4,0,0,1-2.393-2.394V3.768A2.4,2.4,0,0,1,3.768,1.375H18.937m0-1.375H3.768A3.78,3.78,0,0,0,0,3.768V18.937A3.78,3.78,0,0,0,3.768,22.7H18.937A3.78,3.78,0,0,0,22.7,18.937V3.768A3.78,3.78,0,0,0,18.937,0Z" />
        <g>
          <path d="M13.328,6.654a1,1,0,0,1-.707-.293L11.352,5.092,10.083,6.361A1,1,0,1,1,8.669,4.947L10.645,2.97a1,1,0,0,1,.707-.293h0a1,1,0,0,1,.707.293l1.976,1.977a1,1,0,0,1-.707,1.707Z" />
        </g>
        <g>
          <path d="M11.352,20.028a1,1,0,0,1-.707-.293L8.669,17.759a1,1,0,0,1,1.414-1.415l1.269,1.27,1.269-1.27a1,1,0,0,1,1.414,1.415L12.06,19.735A1,1,0,0,1,11.352,20.028Z" />
        </g>
      </svg>
    );
  }
};
