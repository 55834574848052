import { useAuthStore } from '@/stores/auth';
import auth from '../auth';
import { ROLE_ADMINISTRATOR } from '../../utils/role';
const AccountManagement = () =>
  import('../../components/account/AccountManagement');

export default () => {
  return [
    {
      path: '/accounts',
      name: 'account-management',
      component: AccountManagement,
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isRootAdministrator } = authStore;
        if (isRootAdministrator) return;
        return await auth.ensureUserHasRole(ROLE_ADMINISTRATOR)(to);
      }
    }
  ];
};
