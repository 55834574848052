<template>
  <section class="chat" :class="$attrs.class">
    <div class="chat__header">
      <BreadcrumbsBar
        class="chat__header--navigation"
        :type="CHAT_TYPE"
        :description="title"
        :canNavigate="canNavigate"
        :navigate="back"
        arrowSize="small"
      />
      <button class="icon-button chat__header--close" @click="close">
        <Cross size="small" />
      </button>
    </div>

    <div class="chat__content">
      <transition :name="transitionName">
        <div v-if="step === 'chat'" class="chat__transition">
          <ListPageable
            :items="items"
            :load="load"
            :hasNextPage="hasNextPage"
            :isEmpty="empty"
            displayType="list"
          >
            <template #content-item="{ item }">
              <ChatItem :item="item" @selected="displayMessages" />
            </template>
            <template #content-empty>
              <span>{{ $t('chatPage.emptyText') }}</span>
            </template>
          </ListPageable>

          <AppFooter>
            <template #right>
              <button
                class="icon-button menu-button creation-menu-button"
                @click="add"
              >
                <CreationIcon :title="$t('chatPage.add')" />
              </button>
            </template>
          </AppFooter>
        </div>

        <div v-else-if="step === 'chat-messages'" class="chat__transition">
          <ChatMessage ref="chatMessage" :id="selectedId" />
        </div>
      </transition>
    </div>

    <!-- Creation dialogue -->
    <ChatFormDialogue
      v-if="activeDialogue === 'create'"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useChatStore } from '@/stores/chat';
import AppFooter from '../AppFooter';
import Cross from '../icons/Cross';
import BreadcrumbsBar from '../navigation/BreadcrumbsBar';
import ListPageable from '../layout/ListPageable';
import ChatItem from './ChatItem';
import ChatFormDialogue from './ChatFormDialogue';
import ChatMessage from './ChatMessage';
import CreationIcon from '../icons/Creation';
import authMixins from '../mixins/auth';
import toggleState from '../mixins/toggleState';
import { CHAT_TYPE } from '../../utils/types';

const STEP_BASE = 'chat';
const STEP_MESSAGES = 'chat-messages';

export default {
  inheritAttrs: false,
  name: 'ChatView',
  mixins: [authMixins, toggleState('dialogue')],
  components: {
    AppFooter,
    BreadcrumbsBar,
    Cross,
    ListPageable,
    ChatItem,
    ChatFormDialogue,
    ChatMessage,
    CreationIcon
  },
  data() {
    return {
      selectedId: null,
      step: STEP_BASE,
      transitionName: 'prev'
    };
  },
  computed: {
    ...mapState(useChatStore, ['current', 'items', 'hasNextPage']),
    CHAT_TYPE() {
      return CHAT_TYPE;
    },
    title() {
      return this.step === STEP_MESSAGES && this.current
        ? this.current.name
        : null;
    },
    canNavigate() {
      return this.step === STEP_MESSAGES;
    },
    empty() {
      return this.items.length === 0;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useChatStore, {
      load: 'getAll',
      reset: 'reset'
    }),
    back() {
      switch (this.step) {
        case STEP_MESSAGES:
          this.transitionName = 'prev';
          this.step = STEP_BASE;
          this.selectedId = null;
          break;
      }
    },
    close() {
      this.setActiveLeftSideBar();
    },
    add() {
      this.toggleDialogue('create');
    },
    displayMessages(item) {
      this.selectedId = item.id;
      this.transitionName = 'next';
      this.step = STEP_MESSAGES;
    },
    focus() {
      const { chatMessage } = this.$refs;
      if (chatMessage) chatMessage.focus();
    }
  },
  async beforeMount() {
    await this.load({ start: 0 });
  },
  async beforeUnmount() {
    await this.reset();
  },
  watch: {
    current() {
      if (this.current) return;
      this.back();
    }
  }
};
</script>

<style lang="scss">
.chat {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;

  &__header {
    @include grid($gap: 0, $cols: 1fr auto, $areas: 'navigation close');

    &--navigation {
      @include grid-item(navigation);
    }

    &--close {
      @include grid-item(close);
      @include flexy($align: center);
      margin-right: $spacing-base;
    }
  }

  &__content {
    @include next-prev-transition($top: 0, $bottom: 0);
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  &__transition {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    background-color: $background-dark-2;
  }
}

.menu-button--active {
  @include svg-active-background($background-triangle-down-svg);
}
</style>
