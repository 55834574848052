<template>
  <section class="search" :class="$attrs.class">
    <div class="search__header">
      <div v-if="mode === 'bin'" class="search__header--bin">
        <TrashIcon />
      </div>

      <div class="search__header__field">
        <SearchField
          ref="input"
          v-model="text"
          @update:modelValue="setSearchText($event)"
          @clear="clearText"
          @search="startSearch"
        />
      </div>

      <button class="icon-button search__header--close" @click="closePanel">
        <Cross size="small" />
      </button>
    </div>

    <ListPageable
      :items="items"
      :load="load"
      :hasNextPage="hasNextPage"
      :loading="loading"
      :isEmpty="empty"
      displayType="list"
    >
      <template #content-item="{ item }">
        <SearchItem :item="item" @selected="$emit('toggle')" />
      </template>
      <template #content-empty>
        <span>{{ $t('searchPage.emptyText') }}</span>
      </template>
    </ListPageable>

    <AppFooter>
      <template #left>
        <button
          class="icon-button menu-button delete-filter-menu-button"
          :class="{ 'menu-button--active': displayFilter }"
          @click="displayFilter = !displayFilter"
        >
          <FilterIcon :type="displayFilter ? 'active' : 'dark'" />
        </button>

        <button
          v-if="filter && !displayFilter && !isSearchSelectionMode"
          class="icon-button icon-button--active filter-active-button"
          @click="clearFilters()"
        >
          <span class="icon-button-label">{{ $t('search.clearFilters') }}</span>
          <Close type="active" />
        </button>
      </template>
      <template #right>
        <ClipboardFooterAction :search="true" />

        <div v-if="displayFilter" class="apply-filter-menu-button">
          <CallToAction size="auto" @v-click="applyFilter">
            {{ $t('search.apply') }}
          </CallToAction>
        </div>
        <button
          v-else
          class="icon-button menu-button action-menu-button"
          :class="{ 'menu-button--active': activeMenu === 'action' }"
          @click="toggleMenu('action')"
          data-testid="action-button"
        >
          <Action :type="activeMenu === 'action' ? 'active' : 'dark'" />
        </button>
      </template>
    </AppFooter>

    <!-- Filter menu -->
    <transition name="slide">
      <SlideMenu
        v-if="displayFilter"
        slide="up"
        anchor="left"
        :fullWidth="true"
      >
        <SearchFilterMenu ref="filterMenu" @applied="fiterApplied" />
      </SlideMenu>
    </transition>

    <!-- Action menu -->
    <transition name="slide">
      <SlideMenu
        v-if="activeMenu === 'action'"
        slide="up"
        anchor="right"
        :fullWidth="true"
      >
        <MenuItem
          v-if="isSearchSelectionMode"
          :label="$t('selectionMode.exit')"
          @selected="quitSearchSelectionMode"
        />
        <MenuItem
          v-if="!isSearchSelectionMode"
          :label="$t('selectionMode.enterCopy')"
          @selected="enterSearchSelectionCopy"
        />
      </SlideMenu>
    </transition>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useSearchStore } from '@/stores/search';
import SearchField from '../forms/SearchField';
import ListPageable from '../layout/ListPageable';
import SearchItem from './SearchItem';
import AppFooter from '../AppFooter';
import ClipboardFooterAction from '../clipboard/ClipboardFooterAction';
import SlideMenu from '../menus/SlideMenu';
import SearchFilterMenu from './SearchFilterMenu';
import MenuItem from '../menus/MenuItem';
import CallToAction from '../forms/CallToAction';
import Cross from '../icons/Cross';
import FilterIcon from '../icons/FilterIcon';
import Close from '../icons/Close';
import Action from '../icons/Action';
import TrashIcon from '../icons/Trash';
import toggleState from '../mixins/toggleState';
import authMixins from '../mixins/auth';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import { SEARCH_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'SearchView',
  mixins: [authMixins, selectionClipboardMixin, toggleState('menu')],
  components: {
    SearchField,
    ListPageable,
    SearchItem,
    AppFooter,
    ClipboardFooterAction,
    SlideMenu,
    SearchFilterMenu,
    MenuItem,
    CallToAction,
    Cross,
    FilterIcon,
    Close,
    Action,
    TrashIcon
  },
  props: {
    mode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      text: '',
      displayFilter: false
    };
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar']),
    ...mapState(useSearchStore, [
      'isOpen',
      'items',
      'loading',
      'hasNextPage',
      'filter',
      'searchText'
    ]),
    SEARCH_TYPE() {
      return SEARCH_TYPE;
    },
    empty() {
      return this.items.length === 0;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useSearchStore, {
      setMode: 'setMode',
      load: 'get',
      start: 'start',
      setSearchText: 'setSearchText',
      setFilter: 'setFilter',
      close: 'close'
    }),
    closePanel() {
      this.setActiveLeftSideBar();
    },
    startSearch() {
      this.setFocus();
      this.start();
    },
    clearFilters() {
      this.setFocus();
      this.setFilter(null);
    },
    applyFilter() {
      const { filterMenu } = this.$refs;
      if (!filterMenu) return;
      filterMenu.apply();
    },
    fiterApplied() {
      this.setFocus();
      this.displayFilter = false;
    },
    clearText() {
      this.text = '';
      this.setSearchText('');
      this.setFocus();
      this.start();
    },
    setFocus() {
      const { input } = this.$refs;
      if (input) input.focus();
    }
  },
  async beforeMount() {
    await this.setMode(this.mode);
    this.text = this.searchText;
    await this.start();
  },
  mounted() {
    this.setFocus();
  },
  async beforeUnmount() {
    await this.close();
  },
  watch: {
    async activeLeftSideBar() {
      if (this.activeLeftSideBar.type === SEARCH_TYPE) {
        await this.setMode(this.mode);
        await this.start();
        this.setFocus();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  @include flexy($dir: column);
  width: 100%;
  background-color: $background-light;

  &__header {
    @include grid($cols: auto 1fr auto, $areas: 'bin field close');
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    background-color: $background-dark-2;

    &--bin {
      @include grid-item(bin);
      @include flexy($align: center);
      padding-left: $spacing-base;
    }

    &__field {
      @include grid-item(field);
      @include flexy($align: center);
      height: $vertical-rhythm;
      margin-left: $spacing-base;
    }

    &--close {
      @include grid-item(close);
      padding: 0 $spacing-base;
      background-color: $background-dark-2;
    }
  }

  .filter-active-button {
    color: $font-light;
  }
}

.menu-button--active {
  @include svg-active-background($background-triangle-down-svg);
}

.apply-filter-menu-button {
  @include flexy($align: center);

  > .call-to-action {
    background-color: $background-button;
    font-weight: bold;
  }
}
</style>
