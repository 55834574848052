import auth from '../auth';
import { useAuthStore } from '@/stores/auth';
import { ROLE_PARTICIPANT_RESTRICTED } from '../../utils/role';
const ProfileFormDialogue = () =>
  import('../../components/auth/ProfileFormDialogue');
const ResetPasswordFormDialogue = () =>
  import('../../components/auth/ResetPasswordFormDialogue');
const RequestResetFormDialogue = () =>
  import('../../components/auth/RequestResetFormDialogue');
const LoginView = () => import('../../components/auth/LoginView');

export default () => {
  return [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      props: (route) => ({ ...route.query }),
      beforeEnter: async () => {
        const authStore = useAuthStore();
        const { isLoggedIn } = authStore;

        if (isLoggedIn) {
          return { name: 'root' };
        }
      }
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: async () => {
        const authStore = useAuthStore();
        await authStore.logout();
        return { name: 'login' };
      }
    },
    {
      path: '/request-password-reset',
      name: 'requestPasswordReset',
      component: RequestResetFormDialogue,
      props: (route) => ({ ...route.query })
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPasswordFormDialogue,
      props: (route) => ({ ...route.query })
    },
    {
      path: '/my-profile',
      name: 'profile',
      component: ProfileFormDialogue,
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    }
  ];
};
