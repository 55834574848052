<template>
  <div class="fassilio-app">
    <AppHeader />
    <ClipboardBar />
    <AppUploadProgress v-if="upload" />

    <AppMain />

    <ToastAlert
      v-if="globalMessage"
      :timeout="timeoutMessage"
      :level="levelMessage"
      @cancel="clearGlobalMessage"
      :messageHtml="globalMessage"
    ></ToastAlert>
    <ToastAlert
      v-if="_displayMfaEnforcedMessage"
      :timeout="0"
      level="warning"
      @cancel="displayMfaEnforcedMessage = false"
    >
      <span>{{ mfaEnforcedMessage }}</span
      ><span v-if="isLoggedIn"
        ><a href="#" @click="setupMfa" class="app__configure-now">{{
          $t('mfa.configureNow')
        }}</a></span
      >
    </ToastAlert>
    <AccountSettings
      v-if="activeDialogue === 'setup-mfa'"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
    />

    <LoadingDialogue v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import './assets/stylesheets/main.scss';
import AppHeader from './components/AppHeader';
import AppMain from './components/AppMain';
import AppUploadProgress from './components/AppUploadProgress';
import ClipboardBar from './components/clipboard/ClipboardBar';
import ToastAlert from './components/toast/ToastAlert';
import AccountSettings from './components/account/AccountSettings';
import LoadingDialogue from './components/dialogues/LoadingDialogue';
import authMixin from './components/mixins/auth';
import toggleState from './components/mixins/toggleState';
import routerMixin from './components/mixins/router';

const sizeEvents = ['resize', 'orientationchange'];

export default {
  name: 'app',
  mixins: [authMixin, toggleState('dialogue'), routerMixin],
  components: {
    AppHeader,
    AppMain,
    AppUploadProgress,
    ClipboardBar,
    ToastAlert,
    AccountSettings,
    LoadingDialogue
  },
  data() {
    return {
      displayMfaEnforcedMessage: false,
      logoutSecondCounter: null,
      intervalHandle: null,
      // refresh variables
      refreshing: false
    };
  },
  computed: {
    ...mapState(useAppStore, [
      'timeoutMessage',
      'levelMessage',
      'globalMessage',
      'navigateTo',
      'loading',
      'upload'
    ]),
    _displayMfaEnforcedMessage() {
      if (!this.currentUser || this.activeDialogue) return false;
      return this.displayMfaEnforcedMessage;
    },
    logoutRemainingTime() {
      const minutes = parseInt(this.logoutSecondCounter / 60);

      if (minutes) return `${minutes} min`;
      else return `${this.logoutSecondCounter} sec`;
    },
    mfaEnforcedMessage() {
      const role = this.$t(`role.${this.currentUser.role.name}`);

      if (this.isLoggedIn) {
        return this.$t('mfa.enforcedLogout', [role, this.logoutRemainingTime]);
      } else {
        return this.$t('mfa.enforcedLogout2', [role]);
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, [
      'clearGlobalMessage',
      'clearNavigateTo',
      'cancelUpload'
    ]),
    // From https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    setViewportHeight() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    beforeUnloadApplication(event) {
      if (this.upload) {
        event.preventDefault();
        event.returnValue = true;
      }
    },
    async unloadApplication() {
      if (this.upload) {
        await this.cancelUpload();
      }
    },
    setupMfa() {
      this.toggleDialogue('setup-mfa');
    },
    refreshApp(e) {
      const registration = e?.detail;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!registration?.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.refreshApp, {
      once: true
    });

    // Prevent multiple refreshes
    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });

    if (window.zE) window.zE('webWidget', 'hide');

    window.addEventListener('beforeunload', this.beforeUnloadApplication);
    window.addEventListener('unload', this.unloadApplication);
  },
  beforeMount() {
    this.setViewportHeight();
  },
  mounted() {
    sizeEvents.forEach((event) => {
      window.addEventListener(event, this.setViewportHeight);
      window.addEventListener(event, () => window.scrollTo(0, 0));
    });
  },
  beforeUnmount() {
    sizeEvents.forEach((event) => {
      window.removeEventListener(event, this.setViewportHeight);
    });
  },
  watch: {
    currentUser() {
      const dispose = () => {
        if (this.intervalHandle) clearInterval(this.intervalHandle);
        this.intervalHandle = null;
        this.logoutSecondCounter = null;
        this.displayMfaEnforcedMessage = false;
      };

      if (this.currentUser) {
        if (this.currentUser.mfaEnforced && !this.currentUser.mfaActivated) {
          if (this.logoutSecondCounter === null) {
            if (this.intervalHandle) {
              clearInterval(this.intervalHandle);
              this.intervalHandle = null;
            }

            if (this.isLoggedIn) {
              this.logoutSecondCounter = 60 * 60;

              this.intervalHandle = setInterval(() => {
                this.logoutSecondCounter--;

                if ((this.logoutSecondCounter || 0) < 1) {
                  if (this.intervalHandle) clearInterval(this.intervalHandle);
                  this.intervalHandle = null;
                  this.navigate({ name: 'logout' });
                }
              }, 1000);
            }

            this.displayMfaEnforcedMessage = true;
          }
        } else {
          dispose();
        }
      } else {
        dispose();
      }
    },
    navigateTo() {
      if (!this.navigateTo) return;
      const to = this.navigateTo;
      this.clearNavigateTo();
      this.navigate(to);
    }
  }
};
</script>

<style lang="scss">
:root {
  // Fallback units for dynamic vh computation
  --vh: 1vh;
  --PI: 3.14159265359;
  overflow: hidden;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#app,
.fassilio-app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.app__configure-now {
  text-decoration: underline;
  margin-left: $spacing-half;
}
</style>
