<template>
  <label
    class="form-field"
    :class="[{ 'form-field--centered': centered }, $attrs.class]"
    :for="name"
    @click="$emit('v-click', $event)"
  >
    <div v-if="label" class="form-field__label">
      <slot name="label">
        <span>{{ label }}</span>
      </slot>
    </div>

    <slot>
      <div class="form-field__input--container">
        <input
          ref="input"
          class="form-field__input"
          :style="{ textAlign, width: inputWidth }"
          :type="type"
          :name="name"
          :id="name"
          :value="modelValue"
          :readonly="isReadOnly"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :tabindex="tabindex"
          :maxlength="maxlength"
          @input="$emit('update:modelValue', $event.target.value)"
          @focusin="$emit('focusin', $event)"
          @focusout="$emit('focusout', $event)"
          @keydown="$emit('keydown', $event)"
          @keydown.enter="$emit('done', $event)"
        />
      </div>
    </slot>
    <slot name="custom"></slot>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'BaseInput',
  props: {
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    centered: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    inputWidth: {
      type: String,
      default: null
    },
    textAlign: {
      type: String,
      default: null
    }
  },
  methods: {
    focus(select) {
      const { input } = this.$refs;
      input.focus();
      if (select) this.select();
    },
    select() {
      const { input } = this.$refs;
      input.focus();
      input.select();
    }
  }
};
</script>

<style lang="scss" scoped>
.form-field {
  @include grid(
    $cols: 1fr auto,
    $rows: auto auto,
    $areas: 'label label' 'input custom'
  );

  &__label {
    @include grid-item($area: label);
    margin-bottom: $spacing-third;
  }

  &__input--container {
    @include grid-item($area: input);
    @include flexy();
  }

  &__input {
    &[type='email'],
    &[type='text'],
    &[type='number'],
    &[type='password'] {
      background-color: $background-light;
      border: $border-width solid $border-base;
      border-radius: $base-border-radius;
      color: $font-dark;
      font-size: $font-size-medium;
      padding: $spacing-half;
      width: 100%;
      text-align: left;
    }
  }

  &__custom {
    @include grid-item($area: custom, $align: center);
    margin-left: $spacing-half;
  }

  &--centered {
    > .form-field__label {
      text-align: center;
    }

    > .form-field__input--container {
      @include flexy($just: center);
    }
  }
}

.form-field.label-bold {
  > .form-field__label {
    font-weight: bold;
  }
}
</style>
