<template>
  <div class="app-main" :class="$attrs.class">
    <AppLeftSideBar v-if="displayLeftSideBar" />
    <RouterView class="app-content" />
    <AppRightSideBar v-if="displayRightSideBar" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import features from '../utils/features';
import AppLeftSideBar from '../components/AppLeftSideBar';
import AppRightSideBar from '../components/AppRightSideBar';
import authMixins from '../components/mixins/auth';

export default {
  inheritAttrs: false,
  name: 'AppMain',
  mixins: [authMixins],
  components: {
    AppLeftSideBar,
    AppRightSideBar
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar', 'activeRightSideBar']),
    displayLeftSideBar() {
      return (
        !!this.activeLeftSideBar.type &&
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.isWebShareLoggedIn
      );
    },
    displayRightSideBar() {
      return (
        this.activeRightSideBar &&
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.$route.name.endsWith('-share')
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setCurrentRoute', 'setActiveRightSideBar']),
    async resized() {
      if (
        this.activeLeftSideBar.type &&
        this.activeRightSideBar &&
        document.body.clientWidth < 1170
      ) {
        await this.setActiveRightSideBar();
      }
    }
  },
  created() {
    if (features.isMobile.iOS()) {
      document
        .getElementById('meta-viewport')
        .setAttribute(
          'content',
          'width=device-width,user-scalable=no,initial-scale=1.0001,maximum-scale=1.0001,viewport-fit=cove'
        );
    } else {
      document
        .getElementById('meta-viewport')
        .setAttribute(
          'content',
          'width=device-width,initial-scale=1.0001,viewport-fit=cove'
        );
    }
  },
  mounted() {
    window.addEventListener('resize', this.resized);
    window.addEventListener('orientationchange', this.resized);

    this.setCurrentRoute(this.$route);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resized);
    window.removeEventListener('orientationchange', this.resized);
  },
  watch: {
    $route() {
      this.setCurrentRoute(this.$route);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-main {
  @include flexy($dir: row);
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;

  > :deep(.app-content) {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
}
</style>
