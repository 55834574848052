<template>
  <div
    class="sliding-icon icon"
    :class="[`sliding-icon--${zie}`, `icon--${size}`]"
  >
    <component
      v-for="n in 2"
      :key="n"
      :is="icon"
      :size="size"
      :type="type"
      :title="title"
    />
  </div>
</template>

<script>
import { mixin } from '.';
import './_icon.scss';

export default {
  mixins: [mixin],
  name: 'SlidingIcon',
  props: {
    icon: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.sliding-icon {
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);

  > .icon {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    top: 0;

    &:first-child {
      left: 0;
    }
  }

  @each $size, $dimension in $icon-sizes {
    $offset: $dimension - 3px;

    @keyframes sliding-icon-#{$size} {
      100% {
        transform: translateX($offset * -1);
      }
    }

    > .icon--#{$size} {
      animation-name: sliding-icon-#{$size};

      &:last-child {
        left: $offset;
      }
    }
  }
}
</style>
