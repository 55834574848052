import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import i18n from '../../i18n';
import { camelToKebab } from '../../utils/text';

export const ICON_SIZES = [
  'xxsmall',
  'xsmall',
  'xsmall2',
  'small',
  'medium',
  'medium2',
  'mediumlarge',
  'mediumlarge2',
  'large',
  'large2',
  'xlarge',
  'xxlarge',
  'xxxlarge'
];
export const ICON_TYPES = ['dark', 'light', 'active'];
export const DEFAULT_ICON_SIZE = 'medium';
export const DEFAULT_ICON_TYPE = 'dark';

export function getAttributes(
  name,
  { type, size, title, displayTitle, imageStyle, height, width }
) {
  const kebabName = camelToKebab(name);
  const classes = [
    'icon',
    `icon-${kebabName}`,
    `icon--${type}`,
    `icon--${size}`
  ];

  const t = i18n.global.t;
  const text = title ? t(title) : displayTitle ? t(`icon.${kebabName}`) : '';

  const style = imageStyle || {};
  if (!imageStyle) {
    if (height) {
      style.height = `${height}px`;
      style.minHeight = `${height}px`;
    }
    if (width) {
      style.width = `${width}px`;
      style.minWidth = `${width}px`;
    }
  }

  return [classes, text, style];
}

export const useLogin = (name, props) => {
  const { t } = useI18n();
  const kebabName = camelToKebab(name);

  return {
    classes: computed(() => {
      return [
        'icon',
        `icon-${kebabName}`,
        `icon--${props.type}`,
        `icon--${props.size}`
      ];
    }),
    text: computed(() => {
      return props.title
        ? t(props.title)
        : props.displayTitle
          ? t(`icon.${kebabName}`)
          : '';
    }),
    style: computed(() => {
      const style = props.imageStyle || {};
      if (!props.imageStyle) {
        if (props.height) {
          style.height = `${props.height}px`;
          style.minHeight = `${props.height}px`;
        }
        if (props.width) {
          style.width = `${props.width}px`;
          style.minWidth = `${props.width}px`;
        }
      }

      return style;
    })
  };
};

export const mixin = {
  props: {
    type: {
      type: String,
      default: DEFAULT_ICON_TYPE,
      validator: (value) => ICON_TYPES.includes(value)
    },
    size: {
      type: String,
      default: DEFAULT_ICON_SIZE,
      validator: (value) => ICON_SIZES.includes(value)
    },
    title: {
      type: String,
      default: null
    },
    imageStyle: {
      type: [Object, String],
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  }
};
