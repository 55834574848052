import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'RefreshIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11.9 12.7"
      >
        <title>{{ text }}</title>
        <path d="M11.1,5.8c-0.3,0-0.5,0.2-0.5,0.5c0,2.6-2.1,4.6-4.6,4.6S1.3,8.9,1.3,6.3s2.1-4.6,4.6-4.6c1.3,0,2.6,0.6,3.5,1.6h-2c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5v-3c0-0.3-0.2-0.5-0.5-0.5S9.9,0.5,9.9,0.7v1.6c-1-1-2.5-1.6-4-1.6c-3.1,0-5.6,2.5-5.6,5.6S2.8,12,5.9,12s5.6-2.5,5.6-5.6C11.6,6,11.3,5.8,11.1,5.8z" />
      </svg>
    );
  }
};
