<template>
  <svg :class="classes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
    <title v-if="text">{{ text }}</title>
    <g>
      <g>
        <g>
          <path
            d="M11.5,8c-2.4,0-4.6,1.5-5.7,3.7,1.3,3.1,5,4.6,8.1,3.2,1.4-.6,2.6-1.8,3.2-3.2-1-2.2-3.2-3.7-5.6-3.7M11.5,14.1c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4h0"
          />
          <circle cx="11.5" cy="11.7" r="1.4" />
          <path
            d="M19,23H4c-2.2,0-4-1.8-4-4V4C0,1.8,1.8,0,4,0h15c2.2,0,4,1.8,4,4v15c0,2.2-1.8,4-4,4M4,1.5c-1.4,0-2.5,1.1-2.5,2.5v15c0,1.4,1.1,2.5,2.5,2.5h15c1.4,0,2.5-1.1,2.5-2.5V4c0-1.4-1.1-2.5-2.5-2.5H4Z"
          />
        </g>
      </g>
      <path
        d="M22.8,6h-3.9c-.8,0-1.4-.6-1.4-1.4V1.4c0-.6.4,0,1,0h2.8c.8,0,1.4.6,1.4,1.4v2.2c0,.6.6,1,0,1"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'PreviewIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
