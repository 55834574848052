<template>
  <section v-if="displayUpload" class="app-upload" :class="$attrs.class">
    <div class="app-upload__description">{{ description }}</div>
    <div class="app-upload__progress">
      <div class="app-upload__progress--base">{{ fileName }}</div>
      <div
        v-if="percent"
        class="app-upload__progress--percent"
        :style="{ width: `${percent}%` }"
      >
        {{ fileName }}
      </div>
    </div>
    <div class="app-upload__percent">{{ percent }} %</div>
    <button
      :disabled="!canCancel"
      class="button icon-button app-upload__cancel"
      @click="toggleToast('confirmAbort')"
    >
      <Trash type="light" />
    </button>

    <ToastAlert
      v-if="activeToast === 'confirmAbort' && canCancel"
      prompt
      level="warning"
      @ok="cancelUpload"
      @cancel="toggleToast()"
    >
      {{ $t('upload.confirmAbort') }}
    </ToastAlert>
  </section>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import ToastAlert from './toast/ToastAlert';
import toggleState from './mixins/toggleState';
import Trash from './icons/Trash';

export default {
  inheritAttrs: false,
  name: 'AppUploadProgress',
  mixins: [toggleState('toast')],
  components: {
    ToastAlert,
    Trash
  },
  props: {
    timeout: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      display: false,
      intervalHandler: null
    };
  },
  computed: {
    ...mapState(useAppStore, ['upload']),
    description() {
      return this.upload.description;
    },
    fileName() {
      return this.upload.file.name;
    },
    percent() {
      return this.upload.progressPercent || 0;
    },
    canCancel() {
      return this.percent > 0 && this.percent < 100;
    },
    displayUpload() {
      return this.display;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['cancelUpload']),
    abort() {
      if (!this.canCancel) return;
      this.cancelUpload();
    }
  },
  mounted() {
    const self = this;
    this.intervalHandler = setTimeout(() => {
      self.intervalHandler = null;
      self.display = true;
    }, this.timeout);
  },
  beforeUnmount() {
    if (this.intervalHandler) clearTimeout(this.intervalHandler);
    this.display = false;
  }
};
</script>

<style lang="scss">
.app-upload {
  @include grid(
    $gap: 2px,
    $cols: 1fr auto,
    $rows: 1fr 1fr,
    $areas: 'description cancel' 'progress percent'
  );

  background-color: $clipboard-base;
  color: $font-light;

  height: $vertical-rhythm + 20;
  min-height: $vertical-rhythm + 20;
  font-family: $roboto-condensed;
  padding: $spacing-quarter $spacing-base;
  width: 100%;
  z-index: 0;

  &__description {
    @include grid-item(description);
    @include flexy($align: center);
    height: 100%;
  }

  &__progress {
    @include grid-item(progress);
    border: $border-base $border-width solid;
    border-radius: $base-border-radius;
    position: relative;

    > * {
      @include flexy($align: center);
      position: absolute;
      height: 100%;
      padding-left: $spacing-quarter;
      white-space: nowrap;
      overflow: hidden;
    }

    &--base {
      background-color: $background-light;
      color: $font-darker;
      width: 100%;
      z-index: 1;
    }

    &--percent {
      background-color: $clipboard-base;
      color: $font-light;
      z-index: 2;
    }
  }

  &__percent {
    @include grid-item(percent);
    @include flexy($align: center, $just: flex-end);
    font-size: $font-size-large;
    height: 100%;
    min-width: 60px;
    padding-left: $spacing-base;
  }

  &__cancel {
    @include grid-item(cancel);
    @include flexy($align: center, $just: flex-end);
    height: 100%;
  }
}
</style>
