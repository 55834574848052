<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <title v-if="text">{{ text }}</title>
    <circle
      cx="50"
      cy="50"
      r="45"
      stroke="white"
      stroke-width="5"
      fill="transparent"
    />

    <rect x="25" y="25" width="50" height="50" rx="2" fill="red" />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'StopRecording';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
