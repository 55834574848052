import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';

import {
  create as apiCreate,
  del as apiDel,
  get as apiGet,
  getAll as apiGetAll,
  uploadFile as apiUploadFile
} from '@/api/endpoints/background';
import { BACKGROUND_TYPE } from '@/utils/types';

const initialState = {
  items: [],
  hasNextPage: false
};

export const key = BACKGROUND_TYPE;
export const useBackgroundStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async fetch({ id }) {
      return apiGet(id);
    },
    async addOrUpdateItem(item) {
      if (this.items.some((x) => x.id === item.id)) {
        this.items = this.items.map((x) => {
          return x.id === item.id ? { ...item } : x;
        });
      } else {
        this.items = [{ ...item }, ...this.items];
      }
    },
    async create(file) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiCreate({
          name: file.name,
          size: file.size
        });

        appStore.uploadFile({
          type: BACKGROUND_TYPE,
          id: newItem.id,
          file,
          name: file.name
        });

        await this.addOrUpdateItem(newItem);

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async uploadFile(options) {
      await apiUploadFile(options);
    },
    async getAll(options = {}) {
      const { start = this.items.length } = options;

      const { items, hasNextPage } = await apiGetAll({
        query: { start }
      });

      this.items = start === 0 ? items : [...this.items, ...items];
      this.hasNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async delete(item) {
      await apiDel(item.id);
      await this.itemDeleted(item);
    },
    async itemAdded({ id }) {
      const item = await apiGet(id);
      if (!item) return;

      await this.addOrUpdateItem(item);
    },
    async itemUpdated({ id }) {
      const item = await apiGet(id);
      if (!item) return;

      await this.addOrUpdateItem(item);
    },
    async itemDeleted({ id }) {
      if (this.items.some((x) => x.id === id)) {
        this.items = this.items.filter((x) => x.id !== id);
      }
    }
  }
});

export default {
  key,
  use: useBackgroundStore
};
