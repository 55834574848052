import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';

import {
  create as apiCreate,
  update as apiUpdate,
  endEvaluation as apiEndEvaluation,
  del as apiDel,
  get as apiGet,
  getMyEvaluations as apiGetMyEvaluations,
  getCards as apiGetCards,
  updateCards as apiUpdateCards,
  getCriterias as apiGetCriterias,
  updateCriterias as apiUpdateCriterias,
  getStatements as apiGetStatements,
  updateStatements as apiUpdateStatements,
  send as apiSend,
  getInviteInfo as apiGetInviteInfo
} from '@/api/endpoints/evaluation';
import { exists as apiEmailExists } from '@/api/endpoints/email';
import i18n from '@/i18n/index';
import { getRemainingTime } from '@/utils/date';
import { pad } from '@/utils/numbers';
import { EVALUATION_TYPE } from '@/utils/types';

const initialState = {
  inProgressEvaluations: [],
  hasInProgressNextPage: false,

  draftEvaluations: [],
  hasDraftNextPage: false,

  completedEvaluations: [],
  hasCompletedNextPage: false,

  searchText: null
};

class Evaluation {
  constructor(item) {
    this.store = useEvaluationStore();

    this.id = item.id;
    this.name = item.name;
    this.type = item.type;
    this.endDate = item.endDate ? new Date(item.endDate) : null;
    this.sent = !!item.sent;
    this.editing = !!item.editing;
    this.author = item.author;
    this.creationDate = new Date(item.creationDate);
    this.completed = !!item.completed;
    this.hasCompleted = !!item.hasCompleted;
    this.cardCount = item.cardCount;
    this.criteriaCount = item.criteriaCount;
    this.statementCount = item.statementCount;
    this.memberCount = item.memberCount;

    this.participantCount = item.participantCount;
    this.completedParticipantCount = item.completedParticipantCount;
    this.shareMemberCount = item.shareMemberCount;
    this.isEnded = false;

    this.interval = null;
    this.intervalTime = null;

    this.checkIsEnded(false);

    this.intervalTimeRT = 60000;
    this.setRemainingTime();

    this.intervalRT = setInterval(() => {
      this.setRemainingTime();
    }, this.intervalTimeRT);

    this.dispose = () => {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;

      if (this.intervalRT) clearInterval(this.intervalRT);
      this.intervalRT = null;
    };
  }

  isDraft() {
    return !this.sent || this.editing;
  }

  isInProgress() {
    return !this.isDraft() && !this.isEnded;
  }

  checkIsEnded(refresh = true) {
    if (this.isDraft() || !this.endDate) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      this.isEnded = false;
    } else if (new Date() >= this.endDate) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      this.isEnded = true;

      if (refresh) this.store.refreshInternal(this);
    } else {
      const { days, hours, minutes } = getRemainingTime(this.endDate);
      if (days === 0 && hours === 0 && minutes <= 1) {
        if (this.intervalTime !== 1000) {
          if (this.interval) clearInterval(this.interval);
          this.intervalTime = 1000;
          this.interval = setInterval(() => {
            this.checkIsEnded();
          }, this.intervalTime);
        }
      } else if (this.intervalTime !== 60000) {
        if (this.interval) clearInterval(this.interval);
        this.intervalTime = 60000;
        this.interval = setInterval(() => {
          this.checkIsEnded();
        }, this.intervalTime);
      }

      this.isEnded = false;
    }
  }

  setRemainingTime() {
    if (new Date() >= this.endDate || this.isDraft()) {
      if (this.intervalRT) {
        clearInterval(this.intervalRT);
        this.intervalRT = null;
      }

      this.remainingTime = null;
    } else {
      const { days, hours, minutes, seconds } = getRemainingTime(this.endDate);
      if (
        days === 0 &&
        hours === 0 &&
        minutes <= 1 &&
        this.intervalTime > 1000
      ) {
        if (this.intervalRT) clearInterval(this.intervalRT);
        this.intervalTimeRT = 1000;
        this.intervalRT = setInterval(() => {
          this.setRemainingTime();
        }, this.intervalTimeRT);
      }

      if (this.intervalTimeRT > 1000) {
        this.remainingTime = i18n.global.t('evaluation.remainingTimeValue', [
          days,
          pad(hours, 2),
          pad(minutes, 2)
        ]);
      } else {
        this.remainingTime = i18n.global.t('evaluation.remainingTimeValue2', [
          days,
          pad(hours, 2),
          pad(minutes, 2),
          pad(seconds, 2)
        ]);
      }
    }
  }
}

export const key = EVALUATION_TYPE;
export const useEvaluationStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      this.inProgressEvaluations.forEach((item) => item.dispose());
      this.draftEvaluations.forEach((item) => item.dispose());
      this.completedEvaluations.forEach((item) => item.dispose());

      Object.assign(this, { ...initialState });
    },
    async exists({ id }) {
      return (
        this.inProgressEvaluations.some((x) => x.id === id) ||
        this.draftEvaluations.some((x) => x.id === id) ||
        this.completedEvaluations.some((x) => x.id === id)
      );
    },
    async updateItem(item) {
      const update = (list) => {
        this[list] = this[list].map((evaluation) => {
          if (evaluation.id !== item.id) return evaluation;
          evaluation.dispose();
          return { ...item };
        });
      };

      const remove = (list) => {
        const items = this[list].filter((x) => x.id === item.id);
        if (items.length === 0) return;
        items.forEach((x) => x.dispose());

        this[list] = this[list].filter((x) => x.id !== item.id);
      };

      const add = (list) => {
        if (this[list].some((x) => x.id === item.id)) {
          update(list);
        } else {
          this[list] = [{ ...item }, ...this[list]];
        }
      };

      if (this.inProgressEvaluations.some((x) => x.id === item.id)) {
        if (item.isInProgress()) {
          update('inProgressEvaluations');
        } else {
          remove('inProgressEvaluations');

          if (item.isDraft()) add('draftEvaluations');
          else add('completedEvaluations');
        }
      } else if (this.draftEvaluations.some((x) => x.id === item.id)) {
        if (item.isDraft()) {
          update('draftEvaluations');
        } else {
          remove('draftEvaluations');

          if (item.isInProgress()) add('inProgressEvaluations');
          else add('completedEvaluations');
        }
      } else if (this.completedEvaluations.some((x) => x.id === item.id)) {
        if (item.isEnded) {
          update('completedEvaluations');
        } else {
          remove('completedEvaluations');

          if (item.isDraft()) add('draftEvaluations');
          else add('inProgressEvaluations');
        }
      } else if (item.isDraft()) {
        add('draftEvaluations');
      } else if (item.isInProgress()) {
        add('inProgressEvaluations');
      } else {
        add('completedEvaluations');
      }
    },
    async save({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await (id ? apiUpdate(id, data) : apiCreate(data));

        if (id) {
          await this.updateItem(new Evaluation(newItem));
        }

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async end(id) {
      const newItem = await apiEndEvaluation(id);
      await this.updateItem(new Evaluation(newItem));
      return newItem;
    },
    async refreshLists() {
      await Promise.all([
        this.refreshInProgessEvaluations(),
        this.refreshDraftEvaluations(),
        this.refreshCompletedEvaluations()
      ]);
    },
    async getInProgessEvaluations(options = {}) {
      const { inProgressEvaluations, searchText } = this;
      const { start = inProgressEvaluations.length } = options;

      const apiSort = {
        creationDate: 'desc'
      };

      const result = await apiGetMyEvaluations({
        query: { start, sort: apiSort },
        text: searchText || '',
        state: 'progress'
      });

      let { items, hasNextPage } = result;

      items = items.map((x) => new Evaluation(x));

      if (start === 0) {
        this.inProgressEvaluations.forEach((item) => item.dispose());
      }

      this.inProgressEvaluations =
        start === 0 ? items : [...inProgressEvaluations, ...items];
      this.hasInProgressNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshInProgessEvaluations() {
      await this.getInProgessEvaluations({ start: 0 });
    },
    async getDraftEvaluations(options = {}) {
      const { draftEvaluations, searchText } = this;
      const { start = draftEvaluations.length } = options;

      const apiSort = {
        creationDate: 'desc'
      };

      const result = await apiGetMyEvaluations({
        query: { start, sort: apiSort },
        text: searchText || '',
        state: 'draft'
      });

      let { items, hasNextPage } = result;

      items = items.map((x) => new Evaluation(x));

      if (start === 0) {
        this.draftEvaluations.forEach((item) => item.dispose());
      }

      this.draftEvaluations =
        start === 0 ? items : [...draftEvaluations, ...items];
      this.hasDraftNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshDraftEvaluations() {
      await this.getDraftEvaluations({ start: 0 });
    },
    async getCompletedEvaluations(options = {}) {
      const { completedEvaluations, searchText } = this;
      const { start = completedEvaluations.length } = options;

      const apiSort = {
        creationDate: 'desc'
      };

      const result = await apiGetMyEvaluations({
        query: { start, sort: apiSort },
        text: searchText || '',
        state: 'completed'
      });

      let { items, hasNextPage } = result;

      items = items.map((x) => new Evaluation(x));

      if (start === 0) {
        this.completedEvaluations.forEach((item) => item.dispose());
      }

      this.completedEvaluations =
        start === 0 ? items : [...completedEvaluations, ...items];
      this.hasCompletedNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshCompletedEvaluations() {
      await this.getCompletedEvaluations({ start: 0 });
    },
    async search({ text }) {
      this.searchText = text;
      await this.refreshLists();
    },
    async clearSearch() {
      this.searchText = null;
      await this.refreshLists();
    },
    async clearFilters() {
      this.searchText = null;
      await this.refreshLists();
    },
    async getCards({ id, options = {} }) {
      return await apiGetCards(id, options);
    },
    async updateCards({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        return await apiUpdateCards(id, data);
      } finally {
        await appStore.stopLoading();
      }
    },
    async getCriterias(id) {
      return await apiGetCriterias(id);
    },
    async updateCriterias({ id, ...data }) {
      return await apiUpdateCriterias(id, data);
    },
    async getStatements(id) {
      return await apiGetStatements(id);
    },
    async updateStatements({ id, ...data }) {
      return await apiUpdateStatements(id, data);
    },
    async send(id) {
      const newItem = await apiSend(id);
      await this.updateItem(new Evaluation(newItem));
      return newItem;
    },
    async refreshInternal(evaluation) {
      const exists = await this.exists({ id: evaluation.id });
      if (!exists) return;

      await this.updateItem(evaluation);
    },
    async refresh(id) {
      const exists = await this.exists({ id });
      if (!exists) return;

      const item = await apiGet(id);
      await this.updateItem(new Evaluation(item));
    },
    async addItem(item) {
      const update = (list) => {
        this[list] = this[list].map((evaluation) => {
          if (evaluation.id !== item.id) return evaluation;
          evaluation.dispose();
          return { ...item };
        });
      };

      const add = (list) => {
        if (this[list].some((x) => x.id === item.id)) {
          update(list);
        } else {
          this[list] = [{ ...item }, ...this[list]];
        }
      };

      if (item.isDraft()) {
        add('draftEvaluations');
      } else if (item.isInProgress()) {
        add('inProgressEvaluations');
      } else {
        add('completedEvaluations');
      }
    },
    async newItem(id) {
      const item = await apiGet(id);
      if (!item) return;

      await this.addItem(new Evaluation(item));
    },
    async get(id) {
      return await apiGet(id);
    },
    async getInviteInfo(token) {
      return await apiGetInviteInfo(token);
    },
    async delete(item) {
      await apiDel(item.id);

      await this.itemDeleted(item);
    },
    async itemDeleted({ id }) {
      const remove = (list) => {
        const items = this[list].filter((x) => x.id === id);
        if (items.length === 0) return;
        items.forEach((x) => x.dispose());

        this[list] = this[list].filter((x) => x.id !== id);
      };

      remove('inProgressEvaluations');
      remove('draftEvaluations');
      remove('completedEvaluations');
    },
    async emailExists(email) {
      const s = (email || '').toString().trim();
      if (!s) return false;
      return await apiEmailExists(s);
    }
  }
});

export default {
  key,
  use: useEvaluationStore
};
