import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import eventDispatcher from '@/stores/event-dispatcher';

import {
  create as apiCreate,
  update as apiUpdate,
  del as apiDelete,
  get as apiGet,
  moveToBin as apiMoveToBin,
  restoreFromBin as apiRestoreFromBin,
  uploadFile as apiUploadFile,
  getSubscription as apiGetSubscription,
  addSubscription as apiAddSubscription,
  editSubscription as apiEditSubscription,
  getDownloadSignedUrl as apiGetDownloadSignedUrl
} from '@/api/endpoints/media';
import { track } from '@/utils/tracking';
import { MEDIA_TYPE } from '@/utils/types';

const initialState = {
  current: null
};

export const key = MEDIA_TYPE;
export const useMediaStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async assetExists({ id, current = false }) {
      return (
        current && (this.current?.id === id || this.current?.board.id === id)
      );
    },
    async fetch({ id, share }) {
      return await apiGet(id, false, share);
    },
    async modify({ file, id, data, current }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        if (file) {
          data.fileInfos = {
            processing: true,
            size: file.size,
            name: file.name
          };
        }

        if (
          id &&
          data.hidden === undefined &&
          current.mediaType === 'video' &&
          data.link
        ) {
          data.fileInfos = {};
        }

        const newItem = await (id ? apiUpdate(id, data) : apiCreate(data));

        if (file) {
          appStore.uploadFile({
            type: newItem.type,
            mediaType: data.mediaType,
            id: newItem.id,
            file,
            name: data.name || data.link || ''
          });
        }

        if (id) {
          await eventDispatcher.assetUpdated(newItem);
        } else {
          this.track({ event: 'created', item: newItem });
        }

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async update({ id, data }) {
      const item = await apiUpdate(id, data);
      await eventDispatcher.assetUpdated(item);
      return item;
    },
    async toggleHidden(item) {
      const { id, hidden } = item;

      const newItem = await this.modify({
        id,
        data: { hidden: !hidden }
      });

      if (newItem.hidden) {
        this.track({ event: 'hidden', item: newItem });
      }

      return newItem;
    },
    async toggleDeleted({ id, deleted }) {
      let newItem;
      if (deleted) {
        newItem = await apiRestoreFromBin(id);
      } else {
        newItem = await apiMoveToBin(id);
      }

      if (newItem.deleted) {
        this.track({ event: 'deleted', item: newItem });
      }

      return newItem;
    },
    async uploadFile(options) {
      await apiUploadFile(options);
    },
    async get({ id }) {
      this.current = await apiGet(id, true);
      return this.current;
    },
    async getSubscription({ parentId }) {
      return await apiGetSubscription(parentId);
    },
    async addSubscription({ parentId, data }) {
      return await apiAddSubscription(parentId, data);
    },
    async editSubscription({ parentId, id, data }) {
      return await apiEditSubscription(parentId, id, data);
    },
    async restoreFromBin(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        await this.get({ id: item.id });
      }
    },
    async deletePermanently({ id }) {
      await apiDelete(id);
    },
    async getDownloadSignedUrl({ id, share }) {
      return await apiGetDownloadSignedUrl(id, share);
    },
    async track({ event, item, isShared, ...rest }) {
      if (isShared && useAuthStore().isInPreviewWebShare) return;

      const logEvent = {
        object: 'media',
        id: item.id,
        name: item.name,
        type: item.mediaType,
        time: new Date().toISOString(),
        sandBox: !!item.board.sandBox,
        isShared,
        ...rest
      };

      if (item.link) logEvent.link = item.link;
      if (item.fileInfos?.name) logEvent.fileName = item.fileInfos.name;

      await track(event, logEvent);
    },
    async assetUpdated(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        this.current = { ...item };
      } else if (this.current.board?.id === item.id && item.board) {
        const current = { ...this.current };
        current.board = item.board;
        this.current = current;
      }
    },
    async assetDeleted(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        await this.reset();
      }
    }
  }
});

export default {
  key,
  use: useMediaStore
};
