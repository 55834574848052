<template>
  <div v-if="displayLoading" class="list-loading" :class="$attrs.class">
    <Loading />
  </div>
</template>

<script>
import Loading from '../icons/Loading';

export default {
  inheritAttrs: false,
  name: 'ListLoading',
  components: {
    Loading
  },
  props: {
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      display: false,
      intervalHandler: null
    };
  },
  computed: {
    displayLoading() {
      return this.display;
    }
  },
  mounted() {
    const self = this;
    this.intervalHandler = setTimeout(() => {
      self.intervalHandler = null;
      self.display = true;
    }, this.timeout);
  },
  beforeUnmount() {
    if (this.intervalHandler) clearTimeout(this.intervalHandler);
    this.display = false;
  }
};
</script>

<style lang="scss" scoped>
.list-loading {
  @include flexy($align: center);

  > * {
    height: $loading-size-small;
    width: $loading-size-small;
  }
}
</style>
