import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'RestoreIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16.452 18.899"
      >
        <title>{{ text }}</title>
        <g transform="translate(-155.864 -0.138)">
          <path d="M166.437,9.535l-2.274-1.6a.2.2,0,0,0-.315.164V8.6a3.094,3.094,0,0,0-2.308,1.2,3.982,3.982,0,0,0-.8,3.1.2.2,0,0,0,.194.155h.008a.2.2,0,0,0,.19-.169,2.607,2.607,0,0,1,1.271-1.94,1.815,1.815,0,0,1,1.464-.171v.579a.2.2,0,0,0,.318.161l2.26-1.66a.2.2,0,0,0,.082-.162A.2.2,0,0,0,166.437,9.535Z" />
          <path d="M161.753,14.411l2.274,1.6a.2.2,0,0,0,.315-.164v-.506a3.094,3.094,0,0,0,2.308-1.205,3.982,3.982,0,0,0,.805-3.1.2.2,0,0,0-.194-.155h-.009a.2.2,0,0,0-.189.169A2.6,2.6,0,0,1,165.792,13a1.811,1.811,0,0,1-1.464.171v-.579a.2.2,0,0,0-.318-.161l-2.26,1.66a.2.2,0,0,0,0,.324Z" />
          <path d="M169.634,2.384h-1.576V2.078a1.942,1.942,0,0,0-1.941-1.94h-4.006a1.942,1.942,0,0,0-1.941,1.94v.306h-1.625a2.684,2.684,0,0,0-2.681,2.68v1.18a.5.5,0,0,0,.5.5h.6l.963,10.439a1.832,1.832,0,0,0,1.91,1.854h8.55a1.838,1.838,0,0,0,1.912-1.868l.961-10.425h.554a.5.5,0,0,0,.5-.5V5.064A2.684,2.684,0,0,0,169.634,2.384Zm-8.464-.306a.942.942,0,0,1,.941-.94h4.006a.942.942,0,0,1,.941.94v.306H161.17Zm8.137,14.986c-.1.858-.523.973-.918.973h-8.55c-.4,0-.814-.115-.916-.96l-.952-10.333h12.286Zm2.009-11.32H156.864v-.68a1.683,1.683,0,0,1,1.681-1.68h11.089a1.683,1.683,0,0,1,1.682,1.68Z" />
        </g>
      </svg>
    );
  }
};
