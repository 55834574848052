import {
  get as genericGet,
  getAll as genericGetAll,
  create as genericCreate,
  update as genericUpdate,
  del as genericDel
} from '../generic';
import { post as httpPost, get as httpGet } from '../requests';

export const ENDPOINT_URL = '/groups';

export const get = genericGet(ENDPOINT_URL);

export const getAll = genericGetAll(ENDPOINT_URL);

export const create = genericCreate(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const del = genericDel(ENDPOINT_URL);

export const getMembers = async (id, options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/${id}/getMembers`, options);
  return response.json();
};

export const updateMembers = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/update-members`,
    options
  );
  return response.json();
};

export const inviteMultipleMembers = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/invite-multiple-members`,
    options
  );
  return response.json();
};

export const syncMembers = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/sync-members`,
    options
  );
  return response.json();
};

export const searchMembers = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/search-members`,
    options
  );
  return response.json();
};

export const getInviteInfo = async (token) => {
  const response = await httpGet(`${ENDPOINT_URL}/invite-info/${token}`);
  return response.json();
};
