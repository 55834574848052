<template>
  <div class="media-pill" :class="[`media-pill--${mediaType}`, $attrs.class]">
    <component :is="mediaIcon" type="light" :size="size" />
  </div>
</template>

<script>
import { MEDIA_TYPES } from '../../utils/types';
import { ICON_SIZES } from '../icons';
import AudioMedia from '../icons/AudioMedia';
import FileMedia from '../icons/FileMedia';
import LinkMedia from '../icons/LinkMedia';
import PhotoMedia from '../icons/PhotoMedia';
import TextMedia from '../icons/TextMedia';
import VideoMedia from '../icons/VideoMedia';
import NoteMedia from '../icons/NoteMedia';

const icons = {
  audio: AudioMedia,
  file: FileMedia,
  link: LinkMedia,
  photo: PhotoMedia,
  text: TextMedia,
  video: VideoMedia,
  note: NoteMedia
};

export default {
  inheritAttrs: false,
  name: 'MediaPill',
  props: {
    mediaType: {
      type: String,
      required: true,
      validator: (value) => MEDIA_TYPES.includes(value)
    },
    size: {
      type: String,
      default: ICON_SIZES[0],
      validator: (value) => ICON_SIZES.includes(value)
    }
  },
  computed: {
    mediaIcon() {
      return icons[this.mediaType];
    }
  }
};
</script>

<style lang="scss" scoped>
.media-pill {
  @include pill();
  @include media-colors(background-color);
}
</style>
