<template>
  <div ref="main" class="video-recording">
    <div class="video-recording__container">
      <video ref="video" class="video-recording__video" autoplay muted></video>
      <div class="video-recording__controls">
        <div class="video-recording__controls--left">
          <div
            class="video-recording__controls--action video-recording__controls--action--back"
            :title="$t('video-recording.back')"
            @click="back"
          >
            <ChevronIcon direction="left" type="light" size="small" />
          </div>
        </div>
        <div class="video-recording__controls--center">
          <div
            v-if="recording"
            class="video-recording__controls--action"
            :title="$t('video-recording.stop')"
            @click="stop"
          >
            <StopRecordingIcon size="large2" />
          </div>
          <div
            v-else
            class="video-recording__controls--action"
            :title="$t('video-recording.start')"
            @click="start"
          >
            <StartRecordingIcon size="large2" />
          </div>
        </div>
        <div class="video-recording__controls--right">
          <div class="video-recording__controls--time">
            <div
              v-if="recording"
              class="video-recording__controls--time--close"
              :title="$t('video-recording.cancel')"
              @click="cancel"
            >
              <CloseIcon size="small" />
            </div>
            <div class="video-recording__controls--time--value">
              {{ diffDisplay }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </div>
</template>

<script>
import { formatDateDiff } from '@/utils/date';
import ChevronIcon from '../icons/Chevron';
import StartRecordingIcon from '../icons/StartRecording';
import StopRecordingIcon from '../icons/StopRecording';
import CloseIcon from '../icons/Close';
import ToastAlert from '../toast/ToastAlert';
import * as video from '../../utils/video';

const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

export default {
  inheritAttrs: false,
  name: 'VideoRecording',
  components: {
    ChevronIcon,
    StartRecordingIcon,
    StopRecordingIcon,
    CloseIcon,
    ToastAlert
  },
  data() {
    const mimeType = video.getSupportedMimeType();
    return {
      recording: false,
      mediaRecorder: null,
      recordedBlobs: [],
      totalSize: 0,
      startDate: null,
      intervalId: null,
      diff: '00:00:00',
      mimeType,
      mimeTypeExt: video.getMimeTypeExt(mimeType),

      error: ''
    };
  },
  computed: {
    diffDisplay() {
      return this.diff;
    }
  },
  methods: {
    async init() {
      if (!this.mimeType) {
        this.error = 'No mime type supported.';
        return;
      }

      const { video } = this.$refs;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });
        video.srcObject = stream;

        this.recordedBlobs = [];
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: this.mimeType
        });

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data?.size > 0) {
            this.totalSize += event.data?.size;
            if (this.totalSize > maxSize) {
              this.stop();
              return;
            }

            this.recordedBlobs.push(event.data);
          }
        };
      } catch (e) {
        this.error = this.$t('video-recording.error');
        console.error(this.error, e);
      }
    },
    start() {
      this.recordedBlobs = [];
      this.totalSize = 0;
      this.mediaRecorder.start(10);
      this.recording = true;
      this.startDate = new Date();
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.diff = formatDateDiff(this.startDate, new Date());
      }, 1000);
    },
    stopRecording(stopTrack = true, clear = false) {
      if (!this.recording && !clear) return;
      if (this.intervalId) clearInterval(this.intervalId);
      this.diff = '00:00:00';
      this.mediaRecorder.stop();
      if (stopTrack)
        this.mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      this.startDate = null;
      this.totalSize = 0;
    },
    stop() {
      this.stopRecording();
      const blob = new Blob(this.recordedBlobs, { type: this.mimeType });
      blob.name = `recording${this.mimeTypeExt}`;
      this.recordedBlobs = [];
      this.$emit('stop', blob);
      this.recording = false;
    },
    cancel() {
      this.stopRecording(false);
      this.recordedBlobs = [];
      this.recording = false;
    },
    back() {
      this.$emit('cancel');
    }
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.stopRecording(true, true);
  }
};
</script>

<style lang="scss" scoped>
.video-recording {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #000;

  &__container {
    @include grid($rows: 1fr $vertical-rhythm, $areas: 'video' 'controls');
    width: 100%;
    height: 100%;
  }

  &__video {
    @include grid-item($area: video, $just: center, $align: center);
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    // max-width: fit-content;
    // max-height: fit-content;
    overflow: hidden;
  }

  &__controls {
    @include grid-item($area: controls);
    @include grid($cols: 1fr 1fr 1fr, $areas: 'left center right');
    height: $vertical-rhythm;

    &--left {
      @include grid-item($area: left, $just: flex-start, $align: center);
    }

    &--center {
      @include grid-item($area: center, $just: center, $align: center);
    }

    &--right {
      @include grid-item($area: right, $just: flex-end, $align: center);
      padding-right: $spacing-half;
    }

    &--action {
      @include flexy($just: center);
      margin-left: $spacing-half;
      cursor: pointer;

      &--back {
        padding: $spacing-half;
      }
    }

    &--action:first-child {
      margin-left: 0;
    }

    &--time {
      @include flexy($align: center);
      color: $font-light;
      border: 2px solid $font-light;
      border-radius: 20px;
      padding: $spacing-third 12px;

      &--close {
        cursor: pointer;
        margin-right: $spacing-half;
      }

      &--value {
        font-size: $font-size-small;
      }
    }
  }
}
</style>
