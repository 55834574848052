import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'MaximizeIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <title>{{ text }}</title>
        <g transform="translate(-1282 -120)">
          <path
            d="M-3509,8500v-5a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1,1,1,0,0,1-1,1h-4v4a1,1,0,0,1-1,1A1,1,0,0,1-3509,8500Z"
            transform="translate(4791 -8374)"
          />
          <path
            d="M1,7A1,1,0,0,1,0,6V1A1,1,0,0,1,2,1V5H6A1,1,0,0,1,6,7Z"
            transform="translate(1282 133)"
          />
          <path
            d="M1,7A1,1,0,0,1,0,6V1A1,1,0,0,1,2,1V5H6A1,1,0,1,1,6,7Z"
            transform="translate(1302 127) rotate(180)"
          />
          <path
            d="M0,6V1A1,1,0,0,1,1,0H6A1,1,0,1,1,6,2H2V6A1,1,0,1,1,0,6Z"
            transform="translate(1302 140) rotate(180)"
          />
        </g>
      </svg>
    );
  }
};
