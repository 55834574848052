<template>
  <section
    class="toast"
    :class="[`toast--${level}`, { 'toast--relative': relative }, $attrs.class]"
    @keydown.esc="cancel()"
    @mousedown.stop
  >
    <main class="toast__content">
      <span
        v-if="messageHtml"
        class="toast__message"
        v-html="messageHtml"
      ></span>
      <span v-else class="toast__message">
        <slot></slot>
      </span>
      <span v-if="prompt" class="toast__prompt">
        <button class="icon-button" @click="cancel()">
          <cross title="toast.icon.cross" type="light" />
        </button>
        <button class="icon-button" @click="ok()">
          <check title="toast.icon.check" type="light" />
        </button>
      </span>
    </main>
    <button v-if="!prompt" class="icon-button toast__close" @click="cancel()">
      <close title="toast.icon.close" size="small" />
    </button>
  </section>
</template>

<script>
import Check from '../icons/Check';
import Close from '../icons/Close';
import Cross from '../icons/Cross';

const LEVELS = ['info', 'warning', 'error'];
const DEFAULT_TIMEOUT = 4000;

export default {
  inheritAttrs: false,
  name: 'ToastRelative',
  components: {
    Close,
    Check,
    Cross
  },
  props: {
    level: {
      type: String,
      default: LEVELS[0],
      validator: (value) => LEVELS.includes(value)
    },
    prompt: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: DEFAULT_TIMEOUT
    },
    messageHtml: {
      type: String,
      default: ''
    },
    relative: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    ok() {
      this.$emit('ok');
    }
  },
  mounted() {
    if (!this.prompt && this.timeout) {
      setTimeout(this.cancel, this.timeout);
    }
  }
};
</script>

<style lang="scss" scoped>
.toast {
  background-color: $background-dark;
  bottom: 0;
  min-height: $toast-height;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: $toast-index;

  &--relative {
    position: absolute;
  }

  &--info {
    background-color: $toast-color-info;
  }

  &--warning {
    background-color: $toast-color-warning;
  }

  &--error {
    background-color: $toast-color-error;
  }
}

.toast__content {
  @include flexy($dir: column, $align: center);
  color: $font-light;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  font-style: italic;
  height: 100%;
  padding: $spacing-base;
}

.toast__prompt {
  @include flexy();
  margin-top: $spacing-base;

  > .icon-button {
    margin-right: $spacing-base;
  }
}

.toast__close {
  position: absolute;
  right: $spacing-base;
  top: -8px;
}
</style>
