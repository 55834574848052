<template>
  <div ref="root" class="content-audio" :class="$attrs.class">
    <Loading v-if="processing" class="content-audio__processing" />
    <div v-else class="content-audio__main">
      <div class="content-audio__top">
        <div class="content-audio__top__boxe" data-clickable="true">
          <AudioControl ref="audio" :item="media" @audio-action="audioEvent" />
        </div>
      </div>
      <div class="content-audio--name">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMediaStore } from '@/stores/media';
import AudioControl from '../controls/AudioControl';
import Loading from '../icons/Loading';
import mediaProperties from '../mixins/mediaProperties';
import assetMixin from '../mixins/asset';
import contentMediaMixin from './ContentMediaMixin';

export default {
  inheritAttrs: false,
  name: 'ContentAudio',
  mixins: [assetMixin, mediaProperties, contentMediaMixin],
  components: {
    AudioControl,
    Loading
  },
  methods: {
    ...mapActions(useMediaStore, ['track']),
    togglePlay() {
      const { audio } = this.$refs;
      if (audio) {
        audio.togglePlay();
      }
    },
    click($event) {
      if (!$event?.event) this.togglePlay();

      const clickableElement = $event?.event.target.closest(
        '[data-clickable="true"]'
      );
      if (clickableElement) this.togglePlay();
    },
    stop() {
      const { audio } = this.$refs;
      if (audio) {
        audio.stop();
      }
    },
    audioEvent(data) {
      this.track({
        event: `audio_${data.event}`,
        item: this.item,
        isShared: this.isShared,
        currentTime: data.currentTime,
        duration: data.duration
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-audio {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;

  &__processing {
    width: $loading-size-base;
    height: $loading-size-base;
  }

  &__main {
    @include grid($rows: 1fr auto, $areas: 'top' 'name');
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &__top {
    @include grid-item($area: top);
    @include flexy($dir: column, $align: center, $just: center);
    overflow: hidden;

    &__boxe {
      @include flexy($align: center, $just: center);
      height: $tile-media-slide-min;
      width: $tile-media-slide-min;
      max-width: 100%;
      max-height: 100%;
      background-color: $media-audio;
      border-radius: $tile-media-border-radius;
      border: 1px solid $media-file;
      cursor: pointer;

      @media screen and (max-width: $small-size-break),
        screen and (max-height: $small-size-break) {
        width: $tile-media-slide-min-small;
        height: $tile-media-slide-min-small;

        > * {
          transform: scale(0.9);
        }
      }

      @media screen and (max-height: $xsmall-size-break) {
        width: $tile-media-slide-min-xsmall;
        height: $tile-media-slide-min-xsmall;

        > * {
          transform: scale(0.8);
        }
      }
    }
  }

  &--name {
    @include grid-item($area: name);
    @include slider-tile-media-name();
  }
}
</style>
