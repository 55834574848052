import { defineStore } from 'pinia';
import { mapStores } from '@/stores';

import { uuid } from 'vue-uuid';
import { cleanHtml as apiCleanHtml } from '@/api/endpoints/util';

const initialState = {
  timeoutMessage: 4000,
  levelMessage: 'error',
  globalMessage: null,
  currentRoute: null,
  navigateTo: null,
  loading: 0,
  upload: null,
  activeLeftSideBar: {},
  activeRightSideBar: '',
  applicationId: uuid.v4()
};

export const key = 'app';
export const useAppStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async setCurrentRoute(route) {
      this.currentRoute = route;
    },
    async setActiveLeftSideBar(activeLeftSideBar = {}) {
      if (
        activeLeftSideBar.type &&
        this.activeRightSideBar &&
        document.body.clientWidth < 1170
      ) {
        this.activeRightSideBar = '';
      }

      this.activeLeftSideBar = activeLeftSideBar;
    },
    async setActiveRightSideBar(activeRightSideBar = '') {
      if (
        activeRightSideBar &&
        this.activeLeftSideBar.type &&
        document.body.clientWidth < 1170
      ) {
        this.activeLeftSideBar = {};
      }

      this.activeRightSideBar = activeRightSideBar;
    },
    async uploadFile(params) {
      this.upload = params;

      const { type, id, file } = params;
      const fileSize = file.size;

      const setPercent = (percent) => {
        if (this.upload) {
          let value = percent || 0;
          if (value < 0) value = 0;
          if (value > 100) value = 100;

          this.upload = { ...this.upload, progressPercent: value };
        }
      };

      const store = mapStores.get(type).use();
      await store.uploadFile({
        id,
        file,
        onProgress: (loaded) => {
          const percent = Math.round((loaded * 100) / fileSize);
          setPercent(percent);
        },
        onRetry: (_index, _tryNum, _maxUploadTries) => {
          // console.log(`Retry upload for block #${index}. ${tryNum} of ${maxUploadTries}`);
        },
        onComplete: () => {
          setPercent(100);
          // console.log('Upload completed');
        },
        onAbort: () => {
          // console.log('Upload aborted');
        },
        setCancelTokenSource: (source) => {
          // console.log('setCancelTokenSource called');
          if (this.upload) {
            this.upload.source = source;
          }
        }
      });

      this.upload = null;
    },
    async cancelUpload() {
      if (this.upload?.source) {
        this.upload.source.cancel('Canceled by user');
      }
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async displayError(info) {
      const { message, timeout } = info;

      if (timeout !== undefined) this.timeoutMessage = timeout;
      this.levelMessage = 'error';
      this.globalMessage = message;
    },
    async displayWarning(info) {
      const { message, timeout } = info;

      if (timeout !== undefined) this.timeoutMessage = timeout;
      this.levelMessage = 'warning';
      this.globalMessage = message;
    },
    async displayInfo(info) {
      const { message, timeout } = info;

      if (timeout !== undefined) this.timeoutMessage = timeout;
      this.levelMessage = 'info';
      this.globalMessage = message;
    },
    async clearGlobalMessage() {
      this.timeoutMessage = 4000;
      this.levelMessage = 'error';
      this.globalMessage = null;
    },
    async setNavigateTo(to) {
      this.navigateTo = to;
    },
    async clearNavigateTo() {
      this.navigateTo = null;
    },
    async cleanHtml(s) {
      const { html } = await apiCleanHtml({ html: s });
      return html || '';
    }
  }
});

export default {
  key,
  use: useAppStore
};
