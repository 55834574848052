<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.24 19.24"
  >
    <title v-if="text">{{ text }}</title>
    <defs>
      <clipPath id="clip-path">
        <rect width="19.24" height="19.24" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          d="M9.62,0a9.62,9.62,0,1,0,9.62,9.62A9.62,9.62,0,0,0,9.62,0m0,17.737A8.117,8.117,0,1,1,9.62,1.5Z"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'BackgroundIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
