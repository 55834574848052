import { get as genericGet, getAll as genericGetAll } from '../generic';
import { post as httpPost, del as httpDelete } from '../requests';

export const ENDPOINT_URL = '/subscriptions';

export const get = genericGet(ENDPOINT_URL);

export const getAll = genericGetAll(ENDPOINT_URL);

export const deleteSelected = async (data) => {
  const response = await httpPost(`${ENDPOINT_URL}/delete-selected`, data);
  return response.json();
};

export const deleteAll = async () => {
  const response = await httpDelete(`${ENDPOINT_URL}/delete-all`);
  return response.json();
};
