import { defineStore } from 'pinia';

import {
  get as apiGet,
  getAll as apiGetAll,
  create as apiCreate,
  update as apiUpdate,
  del as apiDelete,
  getMessages as apiGetMessages,
  addMessage as apiAddMessage,
  getMessage as apiGetMessage,
  deleteMessage as apiDeleteMessage
} from '@/api/endpoints/chat';
import i18n from '@/i18n';
import { getSince } from '@/utils/date';
import { htmlToText } from '@/utils/html';
import { CHAT_TYPE } from '@/utils/types';

const initialState = {
  current: null,
  items: [],
  hasNextPage: false,
  messages: [],
  messageHasNextPage: false
};

const mapChat = (item) => {
  item.creationDate = new Date(item.creationDate);
  let since = getSince(item.creationDate);

  let lastMessage = null;
  if (item.lastMessage) {
    lastMessage = htmlToText(item.lastMessage.content);
    since = getSince(new Date(item.lastMessage.creationDate));
  }

  return {
    ...item,
    lastMessage,
    since: i18n.global.t(`since.${since.type}`, since.value)
  };
};

const mapChatMessage = (item) => {
  item.creationDate = new Date(item.creationDate);
  const since = getSince(item.creationDate);
  return { ...item, since: i18n.global.t(`since.${since.type}`, since.value) };
};

export const key = CHAT_TYPE;
export const useChatStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async getAll(options = {}) {
      const { items } = this;
      const { start = items.length, limit } = options;

      let { items: newItems, hasNextPage } = await apiGetAll({
        query: { start, limit }
      });

      newItems = newItems.map(mapChat);

      this.items = start === 0 ? newItems : [...items, ...newItems];
      this.hasNextPage = hasNextPage;

      return { items: newItems, hasNextPage };
    },
    async setCurrent({ id }) {
      this.current = await apiGet(id);

      await this.refreshMessages();

      return this.current;
    },
    async clearCurrent() {
      this.messages = [];
      this.messageHasNextPage = false;
      this.current = null;
    },
    async addItem(item) {
      const { items } = this;
      if (items.some((x) => x.id === item.id)) return;

      this.items = [{ ...item }, ...items];
    },
    async create({ ...data }) {
      const item = await apiCreate(data);

      await this.addItem(mapChat(item));

      return item;
    },
    async edit({ id, ...data }) {
      const newItem = await apiUpdate(id, data);

      this.items = this.items.map((item) => {
        return item.id !== newItem.id ? item : mapChat(newItem);
      });

      return newItem;
    },
    async deleteItem(id) {
      if (this.items.some((x) => x.id === id)) {
        this.items = this.items.filter((x) => x.id !== id);
      }

      if (this.current?.id === id) {
        this.current = null;
      }
    },
    async remove({ id }) {
      await apiDelete(id);
      await this.deleteItem(id);
    },
    async getMessages(options = {}) {
      if (!this.current) return;

      const { messages } = this;
      const { start = messages.length, limit } = options;

      let { items, hasNextPage } = await apiGetMessages(this.current.id, {
        query: { start, limit }
      });

      items = items.map(mapChatMessage);

      this.messages = start === 0 ? items : [...messages, ...items];
      this.messageHasNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshMessages() {
      await this.getMessages({ start: 0 });
    },
    async addMessage({ ...data }) {
      if (!this.current) return;

      const newItem = await apiAddMessage(this.current.id, data);

      if (!this.messages.some((x) => x.id === newItem.id)) {
        this.messages = [mapChatMessage(newItem), ...this.messages];
      }

      return newItem;
    },
    async deleteMessage({ id }) {
      if (!this.current) return;

      await apiDeleteMessage(this.current.id, id);

      if (!this.messages.some((x) => x.id === id)) return;
      this.messages = this.messages.filter((x) => x.id !== id);
    },
    async chatSessionAdded({ id }) {
      if (this.items.some((x) => x.id === id)) return;

      const item = await apiGet(id);
      await this.addItem(mapChat(item));
    },
    async chatSessionUpdated({ id }) {
      let item = null;

      if (this.items.some((x) => x.id === id) || this.current?.id === id) {
        item = await apiGet(id);
      }
      if (!item) return;
      item = mapChat(item);

      if (this.items.some((x) => x.id === item.id)) {
        this.items = this.items.map((x) =>
          x.id !== item.id ? x : { ...item }
        );
      }

      if (this.current?.id === item.id) {
        this.current = { ...item };
      }
    },
    async chatSessionDeleted({ id }) {
      if (!this.items.some((x) => x.id === id) && this.current?.id !== id)
        return;
      await this.deleteItem(id);
    },
    async chatMessageAdded({ chatId, id }) {
      if (
        this.current?.id !== chatId ||
        this.messages.some((x) => x.id === id)
      ) {
        await this.chatSessionUpdated({ id: chatId });
        return;
      }

      await this.chatSessionUpdated({ id: chatId });

      const item = await apiGetMessage(chatId, id);
      if (!this.messages.some((x) => x.id === item.id)) {
        this.messages = [mapChatMessage(item), ...this.messages];
      }
    },
    async chatMessageDeleted({ chatId, id }) {
      if (
        this.current?.id !== chatId ||
        !this.messages.some((x) => x.id === id)
      ) {
        await this.chatSessionUpdated({ id: chatId });
        return;
      }

      await this.chatSessionUpdated({ id: chatId });

      if (!this.messages.some((x) => x.id === id)) return;
      this.messages = this.messages.filter((x) => x.id !== id);
    }
  }
});

export default {
  key,
  use: useChatStore
};
