import {
  get as genericGet,
  getAll as genericGetAll,
  create as genericCreate,
  update as genericUpdate
} from '../generic';
import { get as httpGet, post as httpPost, put as httpPut } from '../requests';

export const ENDPOINT_URL = '/companies';

export const get = genericGet(ENDPOINT_URL);

export const getAll = genericGetAll(ENDPOINT_URL);

export const create = genericCreate(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const getAllAccounts = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/accounts/getAll`,
    options
  );
  return response.json();
};

export const createAccount = async (id, data) => {
  const response = await httpPost(`${ENDPOINT_URL}/${id}/accounts`, data);
  return response.json();
};

export const updateAccount = async (id, userId, data) => {
  const response = await httpPut(
    `${ENDPOINT_URL}/${id}/accounts/${userId}`,
    data
  );
  return response.json();
};

export const getAuthPool = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/auth-pool`);
  return response.json();
};

export const updateSettings = async (id, data) => {
  const response = await httpPut(`${ENDPOINT_URL}/${id}/update-settings`, data);
  return response.json();
};

export const accountMfaDeactivate = async (id, userId) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/accounts/${userId}/mfa/deactivate`
  );
  return response.json();
};

export const getSettings = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/settings`);
  return response.json();
};
