<template>
  <div ref="root" class="content-text" :class="$attrs.class">
    <div class="content-text__main">
      <div class="content-text__main__content">
        <div class="content-text__main__content--html" v-html="media.content" />
      </div>
      <div class="content-text__main--name">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import mediaProperties from '../mixins/mediaProperties';
import contentMediaMixin from './ContentMediaMixin';

export default {
  inheritAttrs: false,
  name: 'ContentText',
  mixins: [mediaProperties, contentMediaMixin]
};
</script>

<style lang="scss">
.content-text {
  @include flexy($dir: column, $align: center, $just: center);
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: $spacing-base $tile-slide-move-icon-dim;

  @media screen and (max-width: $small-size-break),
    screen and (max-height: $small-size-break) {
    padding: $spacing-half $tile-slide-move-icon-dim;
  }

  &__main {
    @include flexy($dir: column, $align: center, $just: center);
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;

    &__content {
      height: $tile-media-slide-max;
      width: $tile-media-slide-max;
      max-width: 100%;
      max-height: 100%;
      padding: 30px;
      background-color: $background-light;
      border: 1px solid $background-light;
      overflow: hidden;
      flex: 1;

      @media screen and (max-width: $small-size-break),
        screen and (max-height: $small-size-break) {
        padding: 20px;
      }

      &--html {
        @include media-text();
        width: 100%;
        max-height: 100%;
        overflow-y: auto;

        font-size: $font-size-large;

        @media screen and (max-width: $small-size-break),
          screen and (max-height: $small-size-break) {
          font-size: $font-size-medium + 3;
        }

        a {
          text-decoration: underline;
        }
      }
    }

    &--name {
      @include slider-tile-media-name();
    }
  }
}
</style>
