import { mapStores } from '@/stores';
import pinia from '@/stores/pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useNavigationStore } from '@/stores/navigation';

import {
  INCOMING_USER_ADDED_TO_CHAT,
  INCOMING_USER_REMOVED_FROM_CHAT,
  INCOMING_USER_ADDED_TO_BOARD,
  INCOMING_USER_REMOVED_FROM_BOARD,
  INCOMING_ENTITY_ADDED,
  INCOMING_ENTITY_MODIFIED,
  INCOMING_ENTITY_DELETED,
  INCOMING_ASSIGN_ORGANIC_ITEM,
  INCOMING_MOVE_ORGANIC_ITEM,
  INCOMING_UNASSIGN_ORGANIC_ITEMS,
  INCOMING_MOVE_TO_BIN,
  INCOMING_RESTORE_FROM_BIN,
  INCOMING_ENTITY_MOVED,
  INCOMING_UPDATE_POSITIONS_COMPLETED,
  INCOMING_SET_ASSETTAG_COMPLETED,
  INCOMING_REFRESH_USER_CHAT_MESSAGE_COUNT,
  INCOMING_REFRESH_USER_NOTIFICATION_COUNT,
  INCOMING_REFRESH_USER_EVALUATION_COUNT,
  INCOMING_MARKASREAD_NOTIFICATION,
  INCOMING_LOGOUT,
  INCOMING_TEST_CONNECTION
} from '@/io/events';
import {
  CHAT_TYPE,
  CHAT_MESSAGE_TYPE,
  BOARD_TYPE,
  FOLDER_TYPE,
  CARD_TYPE,
  MEDIA_TYPE,
  ORGANIC_TITLE_TYPE,
  COMMENT_TYPE,
  ACCOUNT_TYPE,
  GROUP_TYPE,
  GROUP_MEMBER_TYPE,
  DASHBOARD_TYPE,
  NOTIFICATION_TYPE,
  SUBSCRIPTION_TYPE,
  COMPANY_TYPE,
  EVALUATION_TYPE,
  EVALUATION_ACCOUNT_TYPE,
  EVALUATION_RESULT_TYPE,
  BACKGROUND_TYPE,
  SEARCH_TYPE,
  ASSET_ORGANIC_TYPE,
  NAVIGATION_TYPE,
  SHARE_ASSET_TYPE,
  SHARE_ASSET_SEARCH_TYPE
} from '@/utils/types';

const getAllActions = (action) => {
  const actions = [];

  mapStores.forEach((v, k) => {
    if (pinia.state.value[k]) {
      const store = v.use();
      if (store[action]) actions.push(store[action]);
    }
  });

  return actions;
};

const getStore = (key, forceUse) => {
  if (forceUse) {
    return mapStores.get(key).use();
  } else if (pinia.state.value[key]) {
    return mapStores.get(key).use();
  }

  return null;
};

const getAction = (key, action, forceUse) => {
  const store = getStore(key, forceUse);
  if (store && store[action]) {
    return store[action];
  }
};

const executeAction = async (key, action, params, forceUse) => {
  const promise = getAction(key, action, forceUse);
  if (promise) {
    return await promise(params);
  }
};

export default {
  async reset() {
    const actions = getAllActions('reset');
    actions.push(useAuthStore().reset);
    actions.push(useAppStore().reset);

    await Promise.all(actions.map((action) => action()));
  },
  async assetExists(payload) {
    const actions = getAllActions('assetExists');
    const existValues = await Promise.all(
      actions.map((action) => action(payload))
    );
    return existValues.some((value) => value);
  },
  async assetUpdated(asset) {
    const appStore = useAppStore();
    if (
      appStore.activeLeftSideBar.type === SEARCH_TYPE &&
      appStore.activeLeftSideBar.mode !== 'bin'
    ) {
      await executeAction(SEARCH_TYPE, 'assetUpdated', asset);
    } else if (appStore.activeLeftSideBar.type === NOTIFICATION_TYPE) {
      await executeAction(NOTIFICATION_TYPE, 'assetUpdated', asset);
    } else if (appStore.activeLeftSideBar.type === SUBSCRIPTION_TYPE) {
      await executeAction(SUBSCRIPTION_TYPE, 'assetUpdated', asset);
    } else if (appStore.activeLeftSideBar.type === SHARE_ASSET_TYPE) {
      await executeAction(SHARE_ASSET_SEARCH_TYPE, 'assetUpdated', asset);
    }

    if (appStore.activeRightSideBar === COMMENT_TYPE) {
      await executeAction(COMMENT_TYPE, 'assetUpdated', asset);
    }

    await executeAction(NAVIGATION_TYPE, 'assetUpdated', asset);

    if (!appStore.currentRoute) return;
    const { name } = appStore.currentRoute;
    if (name === DASHBOARD_TYPE) {
      await executeAction(DASHBOARD_TYPE, 'assetUpdated', asset);
    } else if (name === BOARD_TYPE || name === `${BOARD_TYPE}-share`) {
      await executeAction(BOARD_TYPE, 'assetUpdated', asset);
      await executeAction(ASSET_ORGANIC_TYPE, 'assetUpdated', asset);
      await executeAction(CARD_TYPE, 'assetUpdated', asset);
      await executeAction(MEDIA_TYPE, 'assetUpdated', asset);
    } else if (name === FOLDER_TYPE || name === `${FOLDER_TYPE}-share`) {
      await executeAction(FOLDER_TYPE, 'assetUpdated', asset);
      await executeAction(ASSET_ORGANIC_TYPE, 'assetUpdated', asset);
      await executeAction(CARD_TYPE, 'assetUpdated', asset);
      await executeAction(MEDIA_TYPE, 'assetUpdated', asset);
    } else if (name === CARD_TYPE || name === `${CARD_TYPE}-share`) {
      await executeAction(CARD_TYPE, 'assetUpdated', asset);
      await executeAction(MEDIA_TYPE, 'assetUpdated', asset);
    } else if (name === SHARE_ASSET_TYPE) {
      await executeAction(SHARE_ASSET_TYPE, 'assetUpdated', asset);
    }
  },
  async [INCOMING_USER_ADDED_TO_CHAT](payload) {
    const { id, accountId } = payload;
    const authStore = useAuthStore();
    if (!authStore.currentUser || authStore.currentUser.id !== accountId)
      return;

    const appStore = useAppStore();
    const { activeLeftSideBar } = appStore;

    if (activeLeftSideBar.type === CHAT_TYPE) {
      await executeAction(CHAT_TYPE, 'chatSessionAdded', { id });
    }
  },
  async [INCOMING_USER_REMOVED_FROM_CHAT](payload) {
    const { id, accountId } = payload;

    const authStore = useAuthStore();
    if (!authStore.currentUser || authStore.currentUser.id !== accountId)
      return;

    const appStore = useAppStore();
    const { activeLeftSideBar } = appStore;

    if (activeLeftSideBar.type === CHAT_TYPE) {
      await executeAction(CHAT_TYPE, 'chatSessionRemoved', { id });
    }
  },
  async [INCOMING_USER_ADDED_TO_BOARD](payload) {
    const { id, parentId, accountId } = payload;

    const authStore = useAuthStore();
    if (!authStore.currentUser || authStore.currentUser.id !== accountId)
      return;

    if (parentId) {
      await this[INCOMING_ENTITY_ADDED]({
        type: BOARD_TYPE,
        id,
        parentType: BOARD_TYPE,
        parentId
      });
    }

    const appStore = useAppStore();
    if (appStore.currentRoute?.name === 'dashboard') {
      await executeAction(DASHBOARD_TYPE, 'getAssets', { start: 0 });
    }

    const navigationStore = useNavigationStore();
    if (navigationStore.navigation?.treeviewIsLoaded) {
      await navigationStore.reloadFirstLevel();
    }
  },
  async [INCOMING_USER_REMOVED_FROM_BOARD](payload) {
    const { id, accountId } = payload;

    const authStore = useAuthStore();
    if (!authStore.currentUser || authStore.currentUser.id !== accountId)
      return;

    await this[INCOMING_ENTITY_DELETED]({ type: BOARD_TYPE, id });

    const appStore = useAppStore();
    if (appStore.currentRoute?.name === DASHBOARD_TYPE) {
      await executeAction(DASHBOARD_TYPE, 'getAssets', { start: 0 });
    }

    const navigationStore = useNavigationStore();
    if (navigationStore.navigation?.treeviewIsLoaded) {
      await navigationStore.reloadFirstLevel();
    }
  },
  async [INCOMING_ENTITY_ADDED](payload) {
    const { parentType, parentId, shareAssetId, type, id, brief } = payload;
    const authStore = useAuthStore();

    if (type === ACCOUNT_TYPE) {
      await this.INCOMING_ACCOUNT_ADDED({ type, id });
      return;
    } else if (type === CHAT_TYPE) {
      await this[INCOMING_USER_ADDED_TO_CHAT](payload);
      return;
    } else if (type === CHAT_MESSAGE_TYPE) {
      await this.INCOMING_CHAT_MESSAGE_ADDED(payload);
      return;
    } else if (type === BACKGROUND_TYPE) {
      await this.INCOMING_BACKGROUND_ADDED(payload);
      return;
    } else if (type === GROUP_TYPE) {
      await this.INCOMING_GROUP_ADDED(payload);
      return;
    } else if (type === GROUP_MEMBER_TYPE) {
      await this.INCOMING_GROUP_MEMBER_ADDED(payload);
      return;
    } else if (type === EVALUATION_TYPE) {
      await this.INCOMING_EVALUATION_ADDED(payload);
      return;
    } else if (type === EVALUATION_ACCOUNT_TYPE) {
      await this.INCOMING_EVALUATION_ACCOUNT_ADDED(payload);
      return;
    } else if (type === EVALUATION_RESULT_TYPE) {
      await this.INCOMING_EVALUATION_RESULT_ADDED(payload);
      return;
    } else if (type === COMPANY_TYPE) {
      await this.INCOMING_COMPANY_ADDED({ type, id });
      return;
    } else if (type === COMMENT_TYPE) {
      await executeAction(COMMENT_TYPE, 'refresh', payload);
      await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });
      return;
    } else if (type === NOTIFICATION_TYPE) {
      await this.INCOMING_NOTIFICATION_ADDED(payload);
      return;
    } else if (type === SUBSCRIPTION_TYPE) {
      await this.INCOMING_SUBSCRIPTION_ADDED(payload);
      return;
    } else if (type === SHARE_ASSET_TYPE) {
      await executeAction(SHARE_ASSET_SEARCH_TYPE, 'itemAdded', payload);
      return;
    } else if (shareAssetId && type === MEDIA_TYPE) {
      const shareExists = await executeAction(
        SHARE_ASSET_TYPE,
        'currentExists',
        { id: shareAssetId }
      );

      if (shareExists) await executeAction(SHARE_ASSET_TYPE, 'getAssets');
      return;
    }

    if (parentId) {
      await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });

      const exists = await this.assetExists({
        type: parentType,
        id: parentId,
        current: parentType !== MEDIA_TYPE
      });
      if (!exists) return;

      if (type === MEDIA_TYPE) {
        await executeAction(parentType, 'getAssets');
      } else if (type === BOARD_TYPE) {
        await executeAction(parentType, 'getSubBoards', { start: 0 });

        const navigationStore = useNavigationStore();
        if (navigationStore.navigation?.treeviewIsLoaded) {
          await navigationStore.itemAdded({ id, type });
        }
      } else if (type === FOLDER_TYPE) {
        await executeAction(parentType, 'getFolders', { start: 0 });

        const navigationStore = useNavigationStore();
        const loaded = await navigationStore.parentIsLoaded({
          id: parentId,
          type: parentType
        });
        if (loaded) {
          await navigationStore.itemAdded({ id, type });
        }
      } else if (brief) {
        await executeAction(parentType, 'getBrief');
      } else if (type === CARD_TYPE) {
        await executeAction(parentType, 'getCards', { start: 0 });
        await executeAction(ASSET_ORGANIC_TYPE, 'getUnassignedItems');
      } else if (type === ORGANIC_TITLE_TYPE) {
        await executeAction(ASSET_ORGANIC_TYPE, 'itemAdded', payload);
      }
    } else if (
      type === BOARD_TYPE &&
      payload.accountId === authStore.currentUser?.id
    ) {
      await executeAction(DASHBOARD_TYPE, 'getAssets', { start: 0 });

      const navigationStore = useNavigationStore();
      if (navigationStore.navigation?.treeviewIsLoaded) {
        await navigationStore.itemAdded({ id, type });
      }
    }
  },
  async INCOMING_ACCOUNT_ADDED({ type }) {
    if (type !== ACCOUNT_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;
    if (!authStore.isAdministrator) return;

    await executeAction(ACCOUNT_TYPE, 'get', { start: 0 });
  },
  async INCOMING_CHAT_MESSAGE_ADDED(payload) {
    const { accountId } = payload;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await authStore.refreshUnreadChatMessageCount();

    const appStore = useAppStore();
    const { activeLeftSideBar } = appStore;

    if (activeLeftSideBar.type === CHAT_TYPE) {
      await executeAction(CHAT_TYPE, 'chatMessageAdded', payload);
    }
  },
  async INCOMING_BACKGROUND_ADDED({ type, id, accountId }) {
    if (type !== BACKGROUND_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(BACKGROUND_TYPE, 'itemAdded', { id });
  },
  async INCOMING_GROUP_ADDED({ type }) {
    if (type !== GROUP_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;
    if (!authStore.isAdministrator) return;

    await executeAction(GROUP_TYPE, 'get', { start: 0 });
  },
  async INCOMING_GROUP_MEMBER_ADDED({ parentId, parentType }) {
    if (parentType !== GROUP_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;
    if (!authStore.isAdministrator) return;

    const exists = await executeAction(GROUP_TYPE, 'exists', { id: parentId });
    if (!exists) return;

    await executeAction(GROUP_TYPE, 'getMembers', { start: 0 });
  },
  async INCOMING_EVALUATION_ADDED({ id, type, accountId }) {
    if (type !== EVALUATION_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(EVALUATION_TYPE, 'newItem', id);
  },
  async INCOMING_EVALUATION_ACCOUNT_ADDED({ type, accountId }) {
    if (type !== EVALUATION_ACCOUNT_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser.id !== accountId) return;

    await executeAction(EVALUATION_ACCOUNT_TYPE, 'getEvaluations', {
      start: 0
    });
  },
  async INCOMING_EVALUATION_RESULT_ADDED({ type, accountId }) {
    if (type !== EVALUATION_RESULT_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(EVALUATION_RESULT_TYPE, 'getEvaluations', { start: 0 });
  },
  async INCOMING_COMPANY_ADDED({ type }) {
    if (type !== COMPANY_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;
    if (!authStore.canManageCompanies) return;

    await executeAction(COMPANY_TYPE, 'get', { start: 0 });
  },
  async [INCOMING_ENTITY_MODIFIED](payload) {
    const {
      type,
      id,
      parentType,
      parentId,
      shareAssetId,
      subAction,
      accountId
    } = payload;

    const authStore = useAuthStore();
    if (accountId && authStore.currentUser?.id !== accountId) return;

    if (type === CHAT_TYPE) {
      await this.INCOMING_CHAT_MODIFIED(payload);
      return;
    } else if (type === EVALUATION_TYPE) {
      await this.INCOMING_EVALUATION_MODIFIED(payload);
      return;
    } else if (type === ACCOUNT_TYPE) {
      await this.INCOMING_ACCOUNT_MODIFIED(payload);
      return;
    } else if (type === BACKGROUND_TYPE) {
      await this.INCOMING_BACKGROUND_MODIFIED(payload);
      return;
    } else if (type === COMPANY_TYPE) {
      await this.INCOMING_COMPANY_MODIFIED(payload);
      return;
    } else if (type === GROUP_TYPE) {
      await this.INCOMING_GROUP_MODIFIED(payload);
      return;
    } else if (type === ORGANIC_TITLE_TYPE) {
      await executeAction(ASSET_ORGANIC_TYPE, 'itemUpdated', payload);
      return;
    } else if (type === SHARE_ASSET_TYPE) {
      await executeAction(SHARE_ASSET_TYPE, 'itemUpdated', payload);
      await executeAction(SHARE_ASSET_SEARCH_TYPE, 'itemUpdated', payload);
      return;
    }

    const appStore = useAppStore();
    const navigationStore = useNavigationStore();

    let item = null;

    if (subAction === 'hide' || subAction === 'display') {
      if (parentId) {
        let exists = await executeAction(parentType, 'assetExists', {
          id: parentId,
          current: true
        });
        if (!exists) {
          exists = await executeAction(ASSET_ORGANIC_TYPE, 'assetExists', {
            id: parentId,
            type: parentType,
            current: true
          });
        }

        if (exists) {
          await this[INCOMING_ENTITY_MODIFIED]({
            type: parentType,
            id: parentId
          });

          item = await executeAction(type, 'fetch', { id }, true);

          await executeAction(parentType, `${subAction}Item`, item);
          await executeAction(ASSET_ORGANIC_TYPE, `${subAction}Item`, item);
          if (type === BOARD_TYPE || type === FOLDER_TYPE) {
            await navigationStore[`${subAction}Item`](item);
          }
        }
      }

      if (accountId) {
        if (!item) {
          item = await executeAction(type, 'fetch', { id }, true);
        }

        if (appStore.currentRoute?.name === DASHBOARD_TYPE) {
          await executeAction(DASHBOARD_TYPE, `${subAction}Item`, item);
        }

        await navigationStore[`${subAction}Item`](item);
      }
    } else if (subAction === 'cardToBrief' || subAction === 'briefToCard') {
      await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });

      if (
        appStore.currentRoute?.name === parentType ||
        appStore.currentRoute?.name === `${parentType}-share`
      ) {
        const exists = await executeAction(parentType, 'assetExists', {
          id: parentId,
          current: true
        });
        if (exists) {
          await executeAction(parentType, 'getBrief');
          await executeAction(parentType, 'getCards', { start: 0 });
        }
      }
    }

    const options = { id, share: false };
    const exists = await this.assetExists(options);
    if (!exists) return;

    if (shareAssetId && type === MEDIA_TYPE) {
      await executeAction(SHARE_ASSET_TYPE, 'getAssets');
      return;
    }

    if (!item) {
      try {
        item = await executeAction(
          type,
          'fetch',
          { id, share: options.share },
          true
        );
      } catch {
        //
      }
    }

    if (item) {
      await this.assetUpdated(item);
    } else {
      await this.INCOMING_ASSET_DELETED(payload);
    }
  },
  async INCOMING_CHAT_MODIFIED(payload) {
    const { id, accountId } = payload;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    const appStore = useAppStore();
    const { activeLeftSideBar } = appStore;

    if (activeLeftSideBar.type === CHAT_TYPE) {
      await executeAction(CHAT_TYPE, 'chatSessionUpdated', { id });
    }
  },
  async INCOMING_EVALUATION_MODIFIED({ id, type, accountId }) {
    if (type !== EVALUATION_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id === accountId) {
      await executeAction(EVALUATION_TYPE, 'refresh', id);
    }

    await executeAction(EVALUATION_ACCOUNT_TYPE, 'refreshEvaluation', id);
    await executeAction(EVALUATION_RESULT_TYPE, 'refresh', id);
  },
  async INCOMING_ACCOUNT_MODIFIED({ type, id }) {
    if (type !== ACCOUNT_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;

    if (authStore.currentUser.id === id) {
      await authStore.refresh();
    }

    if (useAppStore().currentRoute?.name !== 'account-management') return;
    if (!authStore.isAdministrator && !authStore.canManageCompanies) return;

    await executeAction(ACCOUNT_TYPE, 'get', { start: 0 });
  },
  async INCOMING_BACKGROUND_MODIFIED({ type, id, accountId }) {
    if (type !== BACKGROUND_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(type, 'itemUpdated', { id });
  },
  async INCOMING_COMPANY_MODIFIED({ type, id }) {
    if (type !== COMPANY_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;

    if (authStore.currentUser.company.id === id) {
      await authStore.refresh();
    }

    if (!authStore.canManageCompanies) return;

    await executeAction(COMPANY_TYPE, 'get', { start: 0 });
  },
  async INCOMING_GROUP_MODIFIED({ type, id }) {
    if (type !== GROUP_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;
    if (!authStore.isAdministrator) return;

    const exists = await executeAction(GROUP_TYPE, 'exists', { id });
    if (!exists) return;
    await executeAction(type, 'refresh', { id });
  },
  async [INCOMING_ENTITY_DELETED](payload) {
    const { type, accountId } = payload;

    const authStore = useAuthStore();
    if (accountId && authStore.currentUser?.id !== accountId) return;

    if (type === NOTIFICATION_TYPE) {
      await this.INCOMING_NOTIFICATION_DELETED(payload);
    } else if (type === SUBSCRIPTION_TYPE) {
      await this.INCOMING_SUBSCRIPTION_DELETED(payload);
    } else if (type === EVALUATION_TYPE) {
      await this.INCOMING_EVALUATION_DELETED(payload);
    } else if (type === EVALUATION_ACCOUNT_TYPE) {
      await this.INCOMING_EVALUATION_ACCOUNT_DELETED(payload);
    } else if (type === EVALUATION_RESULT_TYPE) {
      await this.INCOMING_EVALUATION_RESULT_DELETED(payload);
    } else if (type === GROUP_TYPE) {
      await this.INCOMING_GROUP_DELETED(payload);
    } else if (type === GROUP_MEMBER_TYPE) {
      await this.INCOMING_GROUP_MEMBER_DELETED(payload);
    } else if (type === CHAT_TYPE) {
      await this.INCOMING_CHAT_DELETED(payload);
    } else if (type === CHAT_MESSAGE_TYPE) {
      await this.INCOMING_CHAT_MESSAGE_DELETED(payload);
    } else if (type === COMMENT_TYPE) {
      await this.INCOMING_COMMENT_DELETED(payload);
    } else if (type === SHARE_ASSET_TYPE) {
      await this.INCOMING_SHARE_ASSET_DELETED(payload);
    } else {
      await this.INCOMING_ASSET_DELETED(payload);
    }
  },
  async INCOMING_EVALUATION_DELETED(payload) {
    const { type, accountId } = payload;

    if (type !== EVALUATION_TYPE) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id === accountId) {
      await executeAction(EVALUATION_TYPE, 'itemDeleted', payload);
    }

    await executeAction(EVALUATION_ACCOUNT_TYPE, 'evaluationDeleted', payload);
    await executeAction(EVALUATION_RESULT_TYPE, 'itemDeleted', payload);
  },
  async INCOMING_EVALUATION_ACCOUNT_DELETED(payload) {
    if (payload.type !== EVALUATION_ACCOUNT_TYPE) return;
    await executeAction(EVALUATION_ACCOUNT_TYPE, 'itemDeleted', payload);
  },
  async INCOMING_EVALUATION_RESULT_DELETED(payload) {
    if (payload.type !== EVALUATION_RESULT_TYPE) return;
    await executeAction(EVALUATION_RESULT_TYPE, 'itemDeleted', payload);
  },
  async INCOMING_CHAT_DELETED(payload) {
    const { accountId } = payload;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await authStore.refreshUnreadChatMessageCount();

    const appStore = useAppStore();
    if (appStore.activeLeftSideBar.type === CHAT_TYPE) {
      await executeAction(CHAT_TYPE, 'chatSessionDeleted', payload);
    }
  },
  async INCOMING_CHAT_MESSAGE_DELETED(payload) {
    const { accountId } = payload;

    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await authStore.refreshUnreadChatMessageCount();

    const appStore = useAppStore();
    if (appStore.activeLeftSideBar.type === CHAT_TYPE) {
      await executeAction(CHAT_TYPE, 'chatMessageDeleted', payload);
    }
  },
  async INCOMING_GROUP_DELETED(payload) {
    if (payload.type !== GROUP_TYPE) return;

    await executeAction(GROUP_TYPE, 'itemDeleted', payload);
  },
  async INCOMING_GROUP_MEMBER_DELETED({ parentId, parentType, id }) {
    if (parentType !== GROUP_TYPE) return;

    const authStore = useAuthStore();
    if (!authStore.currentUser) return;
    if (!authStore.isAdministrator) return;

    const exists = await executeAction(GROUP_TYPE, 'exists', { id: parentId });
    if (!exists) return;

    await executeAction(GROUP_TYPE, 'deleteMember', { id });
  },
  async INCOMING_COMMENT_DELETED(payload) {
    const { parentType, parentId } = payload;

    await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });

    const appStore = useAppStore();
    if (appStore.activeRightSideBar === COMMENT_TYPE) {
      await executeAction(COMMENT_TYPE, 'commentDeleted', payload);
    }
  },
  async INCOMING_SHARE_ASSET_DELETED(payload) {
    await executeAction(SHARE_ASSET_SEARCH_TYPE, 'itemDeleted', payload);
    await executeAction(SHARE_ASSET_TYPE, 'itemDeleted', payload);
  },
  async INCOMING_ASSET_DELETED(payload) {
    const { id, parentId, parentType, shareAssetId } = payload;

    if (shareAssetId) {
      await executeAction(SHARE_ASSET_TYPE, 'assetDeleted', { id });
      return;
    }

    const appStore = useAppStore();
    if (appStore.activeLeftSideBar.type === SEARCH_TYPE) {
      await executeAction(SEARCH_TYPE, 'assetDeleted', payload);
    } else if (appStore.activeLeftSideBar.type === NOTIFICATION_TYPE) {
      await executeAction(NOTIFICATION_TYPE, 'assetDeleted', payload);
    } else if (appStore.activeLeftSideBar.type === SUBSCRIPTION_TYPE) {
      await executeAction(SUBSCRIPTION_TYPE, 'assetDeleted', payload);
    }

    if (appStore.activeRightSideBar === COMMENT_TYPE) {
      await executeAction(COMMENT_TYPE, 'assetDeleted', payload);
    }

    if (!appStore.currentRoute) return;
    const { name } = appStore.currentRoute;

    if (name === BOARD_TYPE || name === `${BOARD_TYPE}-share`) {
      await executeAction(BOARD_TYPE, 'assetDeleted', payload);
      await executeAction(ASSET_ORGANIC_TYPE, 'itemDeleted', payload);
    } else if (name === FOLDER_TYPE || name === `${FOLDER_TYPE}-share`) {
      await executeAction(FOLDER_TYPE, 'assetDeleted', payload);
      await executeAction(ASSET_ORGANIC_TYPE, 'itemDeleted', payload);
    } else if (name === CARD_TYPE || name === `${CARD_TYPE}-share`) {
      await executeAction(CARD_TYPE, 'assetDeleted', payload);
      await executeAction(MEDIA_TYPE, 'assetDeleted', payload);
    }

    if (parentId) {
      await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });
    }
  },
  async [INCOMING_ASSIGN_ORGANIC_ITEM](payload) {
    await executeAction(ASSET_ORGANIC_TYPE, 'itemHasBeenAssigned', payload);
  },
  async [INCOMING_MOVE_ORGANIC_ITEM](payload) {
    await executeAction(ASSET_ORGANIC_TYPE, 'itemHasBeenMoved', payload);
  },
  async [INCOMING_UNASSIGN_ORGANIC_ITEMS](payload) {
    await executeAction(ASSET_ORGANIC_TYPE, 'itemsHaveBeenUnassigned', payload);
  },
  async [INCOMING_MOVE_TO_BIN](payload) {
    const { type, parentId, parentType, parentDeleted } = payload;

    if (type === FOLDER_TYPE) {
      const navigationStore = useNavigationStore();
      await navigationStore.assetDeleted(payload);
    }

    const appStore = useAppStore();
    if (appStore.activeLeftSideBar.type === SEARCH_TYPE) {
      if (!appStore.activeLeftSideBar.mode) {
        await executeAction(SEARCH_TYPE, 'assetDeleted', payload);
      }
    } else if (appStore.activeLeftSideBar.type === NOTIFICATION_TYPE) {
      await executeAction(NOTIFICATION_TYPE, 'assetDeleted', payload);
    } else if (appStore.activeLeftSideBar.type === SUBSCRIPTION_TYPE) {
      await executeAction(SUBSCRIPTION_TYPE, 'assetDeleted', payload);
    } else if (appStore.activeLeftSideBar.type === SHARE_ASSET_TYPE) {
      await executeAction(SHARE_ASSET_SEARCH_TYPE, 'assetDeleted', payload);
    }

    if (appStore.activeRightSideBar === COMMENT_TYPE) {
      await executeAction(COMMENT_TYPE, 'assetDeleted', payload);
    }

    if (!appStore.currentRoute) return;
    const { name } = appStore.currentRoute;
    if (name === BOARD_TYPE || name === `${BOARD_TYPE}-share`) {
      await executeAction(BOARD_TYPE, 'assetDeleted', payload);
      await executeAction(ASSET_ORGANIC_TYPE, 'itemDeleted', payload);
    } else if (name === FOLDER_TYPE || name === `${FOLDER_TYPE}-share`) {
      await executeAction(FOLDER_TYPE, 'movedToBin', payload);
      await executeAction(ASSET_ORGANIC_TYPE, 'itemDeleted', payload);
    } else if (name === CARD_TYPE || name === `${CARD_TYPE}-share`) {
      await executeAction(CARD_TYPE, 'movedToBin', payload);
      await executeAction(MEDIA_TYPE, 'assetDeleted', payload);
    }

    if (parentId && !parentDeleted) {
      await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });
    }
  },
  async [INCOMING_RESTORE_FROM_BIN](payload) {
    const { id, type, parentId, parentType } = payload;

    if (type === FOLDER_TYPE) {
      const navigationStore = useNavigationStore();

      const loaded = await navigationStore.parentIsLoaded({
        id: parentId,
        type: parentType
      });
      if (loaded) {
        const item = await executeAction(type, 'fetch', { id }, true);
        await navigationStore.restoreFromBin(item);
      }
    }

    const appStore = useAppStore();
    if (
      appStore.activeLeftSideBar.type === SEARCH_TYPE &&
      appStore.activeLeftSideBar.mode === 'bin'
    ) {
      await executeAction(SEARCH_TYPE, 'restoreFromBin', payload);
    }

    if (appStore.activeRightSideBar === COMMENT_TYPE) {
      await this[INCOMING_ENTITY_MODIFIED]({ type, id });
    }

    if (!appStore.currentRoute) return;
    const { name } = appStore.currentRoute;
    if (name === BOARD_TYPE || name === `${BOARD_TYPE}-share`) {
      await executeAction(BOARD_TYPE, 'restoreFromBin', payload);
      await executeAction(ASSET_ORGANIC_TYPE, 'restoreFromBin', payload);
    } else if (name === FOLDER_TYPE || name === `${FOLDER_TYPE}-share`) {
      await executeAction(FOLDER_TYPE, 'restoreFromBin', payload);
      await executeAction(ASSET_ORGANIC_TYPE, 'restoreFromBin', payload);
    } else if (name === CARD_TYPE || name === `${CARD_TYPE}-share`) {
      await executeAction(CARD_TYPE, 'restoreFromBin', payload);
      await executeAction(MEDIA_TYPE, 'restoreFromBin', payload);
    }

    if (parentId) {
      await this[INCOMING_ENTITY_MODIFIED]({ type: parentType, id: parentId });
    }
  },
  async [INCOMING_ENTITY_MOVED](payload) {
    const { type, id, oldParentType, oldParentId, accountId } = payload;

    const authStore = useAuthStore();
    if (accountId && authStore.currentUser?.id !== accountId) return;

    await this[INCOMING_ENTITY_DELETED]({
      type,
      id,
      parentType: oldParentType,
      parentId: oldParentId,
      accountId
    });
    await this[INCOMING_ENTITY_ADDED](payload);

    if (type === BOARD_TYPE || type === FOLDER_TYPE || type === CARD_TYPE) {
      await executeAction(type, 'refreshBreadcrumbs', id);
    }
  },
  async [INCOMING_UPDATE_POSITIONS_COMPLETED]({ id, type, sortedType }) {
    if (type !== DASHBOARD_TYPE && type !== SHARE_ASSET_TYPE) {
      const exists = await this.assetExists({
        type,
        id,
        current: true
      });
      if (!exists) return;
    }

    if (type === SHARE_ASSET_TYPE) {
      await executeAction(SHARE_ASSET_TYPE, 'getAssets', { id });
    } else if (type === CARD_TYPE) {
      await executeAction(type, 'getAssets', { id });
    } else if (sortedType === BOARD_TYPE) {
      await executeAction(type, 'getSubBoards', { start: 0 });
    } else if (sortedType === FOLDER_TYPE) {
      await executeAction(type, 'getFolders', { start: 0 });
    } else if (sortedType === CARD_TYPE) {
      await executeAction(type, 'getCards', { start: 0 });
    }
  },
  async [INCOMING_SET_ASSETTAG_COMPLETED]({ id, type }) {
    const exists = await this.assetExists({
      type,
      id,
      current: true
    });
    if (!exists) return;

    const action = getAction(type, 'assetTagHasChanged');
    if (action) {
      const promisses = [action({ id, type })];
      if (type === BOARD_TYPE || type === FOLDER_TYPE) {
        promisses.push(
          executeAction(ASSET_ORGANIC_TYPE, 'assetTagHasChanged', { id, type })
        );
      }

      await Promise.all(promisses);
    }
  },
  async INCOMING_NOTIFICATION_ADDED({ id, accountId }) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(NOTIFICATION_TYPE, 'refresh', { id });
  },
  async INCOMING_NOTIFICATION_DELETED({ id, action, accountId }) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(NOTIFICATION_TYPE, 'delete', { id, action });
  },
  async [INCOMING_REFRESH_USER_CHAT_MESSAGE_COUNT](p) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== p.id) return;

    await authStore.refreshUnreadChatMessageCount();
  },
  async [INCOMING_REFRESH_USER_NOTIFICATION_COUNT](p) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== p.id) return;

    await authStore.refreshUnreadNotificationCount();
  },
  async [INCOMING_REFRESH_USER_EVALUATION_COUNT](p) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== p.id) return;

    await authStore.refreshEvaluationCount();
  },
  async [INCOMING_MARKASREAD_NOTIFICATION]({ ids, accountId }) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(NOTIFICATION_TYPE, 'refreshRead', { ids });
  },
  async [INCOMING_LOGOUT]({ applicationId, userId }) {
    const appStore = useAppStore();
    if (appStore.applicationId === applicationId) return;

    const authStore = useAuthStore();
    if (authStore.currentUser?.userId !== userId) return;

    await authStore.logoutWithoutCallingServer();
  },
  async [INCOMING_TEST_CONNECTION](payload) {
    console.log(INCOMING_TEST_CONNECTION, payload);
  },
  async INCOMING_SUBSCRIPTION_ADDED({ id, accountId }) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(SUBSCRIPTION_TYPE, 'refresh', { id });
  },
  async INCOMING_SUBSCRIPTION_DELETED({ id, action, accountId }) {
    const authStore = useAuthStore();
    if (authStore.currentUser?.id !== accountId) return;

    await executeAction(SUBSCRIPTION_TYPE, 'delete', { id, action });
  }
};
