import { get as httpGet } from '../requests';
import {
  get as genericGet,
  getAssets as genericGetAssets,
  create as genericCreate,
  update as genericUpdate,
  del as genericDelete,
  moveToBin as genericMoveToBin,
  restoreFromBin as genericRestoreFromBin,
  validateCopy as genericValidateCopy,
  copy as genericCopy,
  move as genericMove,
  getSubscription as genericGetSubscription,
  addSubscription as genericAddSubscription,
  editSubscription as genericEditSubscription,
  updatePositionSubElements as genericUpdatePositionSubElements,
  setAssetTagSubElements as genericSetAssetTagSubElements
} from '../generic';

export const ENDPOINT_URL = '/cards';

export const create = genericCreate(ENDPOINT_URL);

export const getAssets = genericGetAssets(ENDPOINT_URL);

export const get = genericGet(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const del = genericDelete(ENDPOINT_URL);

export const moveToBin = genericMoveToBin(ENDPOINT_URL);

export const restoreFromBin = genericRestoreFromBin(ENDPOINT_URL);

export const validateCopy = genericValidateCopy(ENDPOINT_URL);

export const copy = genericCopy(ENDPOINT_URL);

export const move = genericMove(ENDPOINT_URL);

export const getSubscription = genericGetSubscription(ENDPOINT_URL);

export const addSubscription = genericAddSubscription(ENDPOINT_URL);

export const editSubscription = genericEditSubscription(ENDPOINT_URL);

export const updatePositionSubElements =
  genericUpdatePositionSubElements(ENDPOINT_URL);

export const setAssetTagSubElements =
  genericSetAssetTagSubElements(ENDPOINT_URL);

export const getBreadcrumbTrail = async (id, share = false) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/${id}/breadcrumb-trail${share ? '/share' : ''}`
  );
  return response.json();
};

export const getFirstParentNoneDeleted = async (id) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/${id}/first-parent-none-deleted`
  );

  try {
    const result = await response.json();
    return result;
  } catch {
    return null;
  }
};
