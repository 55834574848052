import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'FileMedia';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text, style] = getAttributes(name, this.$props);
    return (
      <svg
        class={[...classes, 'icon-media']}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.65 13.102"
      >
        <title>{{ text }}</title>
        <g transform="translate(-42.894 -282.375)">
          <path
            d="M53.395,286.339l-3.814-3.814a.512.512,0,0,0-.362-.15h-3.97a2.357,2.357,0,0,0-2.355,2.354v8.393a2.357,2.357,0,0,0,2.355,2.354H51.19a2.357,2.357,0,0,0,2.354-2.354V286.7A.512.512,0,0,0,53.395,286.339Zm-.874,6.784a1.332,1.332,0,0,1-1.331,1.331H45.249a1.332,1.332,0,0,1-1.331-1.331v-8.393a1.332,1.332,0,0,1,1.331-1.331h3.758l.015.015v2.816a.674.674,0,0,0,.672.672h2.816l.011.012Z"
            transform="translate(0 0)"
          />
          <path
            d="M56.9,303.351H51.409a.512.512,0,0,0,0,1.024H56.9a.512.512,0,0,0,0-1.024Z"
            transform="translate(-6.005 -15.739)"
          />
          <path
            d="M56.9,310.99H51.409a.512.512,0,0,0,0,1.024H56.9a.512.512,0,0,0,0-1.024Z"
            transform="translate(-6.005 -21.471)"
          />
          <path
            d="M56.9,318.628H51.409a.512.512,0,0,0,0,1.024H56.9a.512.512,0,0,0,0-1.024Z"
            transform="translate(-6.005 -27.202)"
          />
        </g>
      </svg>
    );
  }
};
