import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'VideoMedia';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text, style] = getAttributes(name, this.$props);
    return (
      <svg
        class={[...classes, 'icon-media']}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 13.487 13.487"
      >
        <title>{{ text }}</title>
        <path
          d="M112.878,282.635a6.743,6.743,0,1,0,6.743,6.743A6.743,6.743,0,0,0,112.878,282.635Zm3.308,6.835a.527.527,0,0,1-.007.061c0,.005,0,.011,0,.016a.5.5,0,0,1-.2.3L111.461,293l-.007,0-.014.008a.5.5,0,0,1-.165.068l-.021,0a.534.534,0,0,1-.077.008h0c-.012,0-.023,0-.034,0a.469.469,0,0,1-.06-.006l-.039-.011a.554.554,0,0,1-.057-.018l-.025-.012a.513.513,0,0,1-.064-.035l-.009-.007a.484.484,0,0,1-.2-.309s0-.006,0-.01a.5.5,0,0,1-.009-.087V286.27a.5.5,0,0,1,.009-.087s0-.007,0-.01a.5.5,0,0,1,.08-.187.488.488,0,0,1,.124-.121l.009-.007a.511.511,0,0,1,.064-.034l.025-.012a.5.5,0,0,1,.057-.018l.039-.011a.531.531,0,0,1,.06-.006c.011,0,.022,0,.034,0h0a.5.5,0,0,1,.078.008l.02,0a.5.5,0,0,1,.166.069l.013.007.007,0,4.516,3.156a.5.5,0,0,1,.2.3c0,.006,0,.011,0,.017a.522.522,0,0,1,.007.061c0,.013,0,.026,0,.039S116.187,289.457,116.186,289.47Z"
          transform="translate(-106.135 -282.635)"
        />
      </svg>
    );
  }
};
