<template>
  <BaseDialogue
    class="background-dialogue"
    :class="$attrs.class"
    :title="$t('background.customize')"
    :disableOk="!canApply"
    @cancel="$emit('cancel')"
    @ok="ok()"
  >
    <ListPage
      type="background"
      :loading="loading"
      :empty="items.length === 0"
      :emptyText="$t('background.emptyText')"
    >
      <ListPageBlock
        type="background"
        displayType="tile"
        :items="items"
        :load="getAll"
        :hasNextPage="hasNextPage"
        displayAddItem
        displayDeleteItem
        autoLoadOnNextPage
      >
        <template #content-add-item>
          <div class="background-item" :title="$t('background.add')">
            <label
              class="background-item__add"
              :class="{
                'background-item--disabled': submitted || uploadInProgress
              }"
            >
              <CreationIcon />
              <input
                ref="input"
                type="file"
                :accept="acceptedTypes.join(',')"
                :disabled="submitted || uploadInProgress"
                @change="fileSelected($event.target.files)"
                @click="$event.target.value = ''"
              />
            </label>
          </div>
        </template>
        <template #content-delete-item>
          <div
            class="background-item"
            :title="$t('background.delete')"
            @click="toggleDeleteSelected"
            :class="[
              { 'background-item--disabled': !item.background || submitted },
              { 'background-item--selected': _deleteIsSelected }
            ]"
          >
            <div class="background-item__delete">
              <CreationIcon />
            </div>
          </div>
        </template>

        <template #content-item="{ item }">
          <div
            class="background-item"
            :style="itemStyle(item)"
            @click="toggleSelectedItem(item)"
            :class="[
              { 'background-item--background': !item.processing },
              { 'background-item--selected': _selectedId === item.id }
            ]"
          >
            <div v-if="item.processing" class="background-item__processing">
              <div class="background-item__processing--icon">
                <LoadingIcon />
              </div>
              <div class="background-item__processing--text">
                {{ $t(`upload.${item.step}`) }}
              </div>
            </div>
            <div
              v-else
              class="background-item__remove"
              @click="toggleToast('confirmDelete')"
            >
              <CreationIcon size="small" />
            </div>
          </div>
        </template>
      </ListPageBlock>
    </ListPage>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="remove"
      @cancel="toggleToast()"
    >
      {{ $t('background.confirmDelete') }}
    </ToastAlert>
    <ToastAlert v-else-if="_error" level="error" @cancel="error = ''">
      {{ _error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useBackgroundStore } from '@/stores/background';
import BaseDialogue from '../dialogues/BaseDialogue';
import ListPage from '../layout/ListPage';
import ListPageBlock from '../layout/ListPageBlock';
import CreationIcon from '../icons/Creation';
import LoadingIcon from '../icons/Loading';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import backgroundMixin from '../mixins/background';
import { toMegabytes } from '../../utils/numbers';
import { BOARD_TYPE, FOLDER_TYPE, CARD_TYPE } from '../../utils/types';

const acceptedTypes = process.env.IMAGE_BACKGROUND_SUPPORTED_TYPES;
const maxSize = process.env.IMAGE_BACKGROUND_FILE_MAX_SIZE;

export default {
  inheritAttrs: false,
  name: 'BackgroundDialogue',
  mixins: [
    toggleState('toast'),
    backgroundMixin([BOARD_TYPE, FOLDER_TYPE, CARD_TYPE])
  ],
  components: {
    BaseDialogue,
    ListPage,
    ListPageBlock,
    CreationIcon,
    LoadingIcon,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      acceptedTypes,
      loading: false,
      selectedId: null,
      deleteIsSelected: false,
      submitted: false,
      filePath: '',
      error: ''
    };
  },
  computed: {
    ...mapState(useAppStore, ['upload']),
    ...mapState(useBackgroundStore, ['items', 'hasNextPage']),
    _selectedId() {
      return this.selectedId;
    },
    _deleteIsSelected() {
      return this.deleteIsSelected;
    },
    _error() {
      return this.error;
    },
    uploadInProgress() {
      return !!this.upload;
    },
    canApply() {
      if (this.submitted) return false;
      if (this.deleteIsSelected) return true;
      if (!this.selectedId) return false;

      const background = this.items.filter((x) => x.id === this.selectedId)[0];
      return background && !background.processing;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useBackgroundStore, ['reset', 'getAll', 'create', 'delete']),
    async init() {
      return this.getAll({ start: 0 });
    },
    async ok() {
      if (this.submitted) return;

      const background = this.items.filter((x) => x.id === this.selectedId)[0];
      if (background && background.processing) return;

      this.submitted = true;

      try {
        const asset = await this[`${this.item.type}Modify`]({
          id: this.item.id,
          backgroundId: background ? background.id : null
        });

        if (asset.error) {
          this.displayError({ message: asset.error });
          return;
        }

        this.$emit('success', asset);
      } catch (e) {
        if (e.status === 400) {
          this.displayError({ message: e.message });
        } else {
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        }
      } finally {
        this.submitted = false;
      }
    },
    async fileSelected(files) {
      if (this.submitted) return;

      // eslint-disable-next-line
        const [file, ..._] = files;
      const { input } = this.$refs;

      if (file.size > maxSize) {
        this.error = this.$t('background.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });

        input.setAttribute('value', '');
        return;
      }

      this.submitted = true;

      try {
        const newItem = await this.create(file);
        const item = this.items.filter((x) => x.id === newItem.id)[0];
        if (item) this.toggleSelectedItem(item, true);
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
        return;
      } finally {
        this.submitted = false;
        // input.setAttribute('value', '');
      }
    },
    itemStyle(item) {
      if (item.processing) return {};

      return {
        backgroundImage: `url(${item.url})`
      };
    },
    toggleDeleteSelected() {
      this.selectedId = null;
      this.deleteIsSelected = !this.deleteIsSelected;
    },
    toggleSelectedItem(item) {
      this.deleteIsSelected = false;
      this.selectedId = item.id;
    },
    async remove() {
      if (this.submitted || !this.selectedId) return;

      try {
        this.submitted = true;

        await this.delete({ id: this.selectedId });

        this.selectedId = null;
        this.toggleToast();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    }
  },
  mounted() {
    this.loading = true;

    this.init()
      .catch((e) => {
        console.error('BackgroundDialogue => mounted', e);
        this.displayError({ message: this.$t('global.error') });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  async beforeUnmount() {
    this.reset();
  }
};
</script>

<style lang="scss">
$background-color: rgba(224, 224, 224, 1);
$background-remove-color: rgba(255, 255, 255, 0.6);
$remove-size: 29px;
$remove-radius: 3px;

.background-dialogue {
  .dialogue__header,
  .dialogue__footer {
    background-color: $manage-background-color;
  }
}

.background-item {
  @include flexy($align: center, $just: center);
  min-width: $tile-background-width;
  height: $tile-background-height;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $background-color;
  border-radius: $tile-border-radius;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &--background {
    background-color: $background-light;
  }

  &__processing {
    @include flexy($dir: column, $align: center);

    &--icon {
      text-align: center;
      width: 30px;
      height: 30px;
      position: relative;

      > img {
        position: absolute;
        left: -12px;
        top: -10px;

        width: $loading-size-xsmall;
        height: $loading-size-xsmall;
      }
    }

    &--text {
      font-size: $font-size-xsmall;
      text-align: center;
    }
  }

  &__remove {
    @include flexy($align: center, $just: center);
    width: $remove-size;
    height: $remove-size;
    border-radius: $remove-radius;
    position: absolute;
    top: $spacing-quarter;
    right: $spacing-quarter;
    background-color: $background-remove-color;

    > * {
      transform: rotate(45deg);
    }
  }

  &__add {
    @include flexy($align: center, $just: center);
    width: 100%;
    height: 100%;
    cursor: pointer;

    input {
      display: none;
    }
  }

  &__delete {
    transform: rotate(45deg);
  }

  &--selected {
    box-shadow: 4px 4px 0 $selected-shadow-color;
  }

  &--disabled {
    opacity: $disable-opacity;
    cursor: default;
  }
}
</style>
