import { mapActions, mapState } from 'pinia';
import { mapStores } from '@/stores';
import {
  FOLDER_TYPE,
  CARD_TYPE,
  MEDIA_TYPE,
  MEDIA_TYPES,
  ASSET_TYPES,
  ACCOUNT_TYPE,
  NOTIFICATION_TYPE,
  SUBSCRIPTION_TYPE,
  COMPANY_TYPE,
  GROUP_TYPE
} from './types';

const types = ASSET_TYPES.filter((x) => x !== MEDIA_TYPE);
const folderTypes = [FOLDER_TYPE, CARD_TYPE];

export const defaultFilter = { hidden: false };

export const filterNotificationOptions = [
  ...MEDIA_TYPES.map((type) => {
    return { key: type, condition: { subType: type } };
  }),
  ...types.map((type) => {
    return { key: type, condition: { type } };
  })
];

export const filterSubscriptionOptions = [
  ...MEDIA_TYPES.map((type) => {
    return { key: type, condition: { subType: type } };
  }),
  ...types.map((type) => {
    return { key: type, condition: { type } };
  })
];

export const filterAccountOptions = [{ key: 'displayInactiveAccounts' }];

export const filterCompanyOptions = [
  { key: 'displayInactiveCompanies', condition: { blocked: null } }
];

export const filterGroupOptions = [];

export const filterAssetOptions = [{ key: 'displayHidden' }];

export const filterBoardOptions = [
  ...types.map((type) => {
    return { key: type, type: true };
  }),
  ...filterAssetOptions
];

export const filterFolderOptions = [
  ...folderTypes.map((type) => {
    return { key: type, type: true };
  }),
  ...filterAssetOptions
];

export const filterCardOptions = [...filterAssetOptions];

export const filterAssetOrganicOptions = [...filterAssetOptions];

const filterMixin = function (module, filterOptions) {
  const useStore = mapStores.get(module).use;

  return {
    data() {
      return {
        defaultFilter,
        filterOptions
      };
    },
    computed: {
      ...mapState(useStore, ['filter'])
    },
    methods: mapActions(useStore, ['setFilter'])
  };
};

export function filterNotificationMixin() {
  return filterMixin(NOTIFICATION_TYPE, filterNotificationOptions);
}

export function filterSubscriptionMixin() {
  return filterMixin(SUBSCRIPTION_TYPE, filterSubscriptionOptions);
}

export function filterAccountMixin() {
  return filterMixin(ACCOUNT_TYPE, filterAccountOptions);
}

export function filterCompanyMixin() {
  return filterMixin(COMPANY_TYPE, filterCompanyOptions);
}

export function filterGroupMixin() {
  return filterMixin(GROUP_TYPE, filterGroupOptions);
}

export function filterDashboardMixin(module) {
  return filterMixin(module, filterAssetOptions);
}

export function filterBoardMixin(module) {
  return filterMixin(module, filterBoardOptions);
}

export function filterFolderMixin(module) {
  return filterMixin(module, filterFolderOptions);
}

export function filterCardMixin(module) {
  return filterMixin(module, filterCardOptions);
}

export function filterAssetOrganicMixin(module) {
  return filterMixin(module, filterAssetOrganicOptions);
}
