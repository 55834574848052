<template>
  <BaseDialogue
    class="form-dialogue-file"
    :class="$attrs.class"
    :title="$t(`mediaForm.file.label.${mode}`)"
    :show-ok="showOk"
    @cancel="$emit('cancel')"
    @ok="ok()"
  >
    <div class="form-dialogue-file__content" @keydown.enter.prevent="ok">
      <BaseInput
        ref="name"
        :label="$t('mediaForm.name')"
        name="name"
        :maxlength="120"
        v-model.trim="form.name"
      />

      <div class="form-dialogue-file__file">
        <transition :name="transitionName">
          <div v-if="step === 'select'" class="form-dialogue-file__transition">
            <div class="form-dialogue-file__select">
              <FileMedia size="xlarge" />

              <FileInput
                v-if="!uploadInProgress"
                @change="fileSelected($event, true)"
              >
                {{ $t('mediaForm.select') }}
              </FileInput>

              <div
                v-if="uploadInProgress"
                class="form-dialogue-file__select--upload"
              >
                {{ $t('mediaForm.file.uploadInProgress') }}
              </div>
            </div>
          </div>
        </transition>

        <transition :name="transitionName" @afterEnter="select('name')">
          <div v-if="step === 'preview'" class="form-dialogue-file__transition">
            <div class="form-dialogue-file__preview">
              <div class="form-dialogue-file__preview--content">
                <FileMedia size="xlarge" />

                <div class="form-dialogue-file__preview--confirm">
                  {{ fileName }}
                </div>
              </div>

              <button
                class="button icon-button form-dialogue-file__preview--delete"
                @click="trash"
              >
                <Trash />
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { toMegabytes } from '../../utils/numbers';
import BaseDialogue from '../dialogues/BaseDialogue';
import FileInput from '../forms/FileInput';
import FileMedia from '../icons/FileMedia';
import Trash from '../icons/Trash';
import mediaForm from '../mixins/mediaForm';
import ToastAlert from '../toast/ToastAlert';

const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

const STEP_BASE = 'select';
const STEP_PREVIEW = 'preview';

export default {
  inheritAttrs: false,
  name: 'FormDialogueFile',
  mixins: [mediaForm],
  components: {
    BaseDialogue,
    FileInput,
    FileMedia,
    Trash,
    ToastAlert
  },
  data() {
    const { id, name = '' } = this.editItem;

    return {
      form: {
        file: null,
        name,
        mediaType: 'file'
      },
      submitted: false,
      step: id ? STEP_PREVIEW : STEP_BASE,
      transitionName: 'prev',
      error: ''
    };
  },
  computed: {
    showOk() {
      return this.step === STEP_PREVIEW;
    },
    fileName() {
      const { fileInfos } = this.editItem;
      const { file } = this.form;
      return (fileInfos && fileInfos.name) || (file && file.name) || '';
    }
  },
  methods: {
    async ok() {
      switch (this.step) {
        case STEP_BASE:
          this.transitionName = 'next';
          this.step = STEP_PREVIEW;
          break;
        case STEP_PREVIEW:
          if (!this.form.name) {
            this.error = this.$t('mediaForm.nameRequired');
            this.select('name');
            return;
          }

          if (!this.submitted) {
            this.submitted = true;
            try {
              const media = await this.modify();
              this.$emit('success', media);
            } catch (e) {
              console.error(e);
              this.submitted = false;
              this.error = this.$t('mediaForm.error');
            }
          }
          break;
      }
    },
    trash() {
      if (this.form.file) {
        let name;
        const pos = this.form.file.name.lastIndexOf('.');
        if (pos > 0) {
          name = this.form.file.name.substring(0, pos);
        } else {
          name = this.form.file.name;
        }

        if (this.form.name === name) this.form.name = '';
      }

      this.form.file = null;
      this.transitionName = 'prev';
      this.step = STEP_BASE;
      this.select('name');
    },
    fileSelected(files, setName) {
      // eslint-disable-next-line
        const [file] = files

      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
        return;
      }

      this.form.file = file;

      if (setName && !(this.form.name || '')) {
        const pos = file.name.lastIndexOf('.');
        if (pos > 0) {
          this.form.name = file.name.substring(0, pos);
        } else {
          this.form.name = file.name;
        }
      }

      this.ok();
      setTimeout(() => {
        this.select('name');
      }, 100);
    }
  },
  mounted() {
    if (this.file) {
      this.fileSelected([this.file], true);
    }

    this.$nextTick(() => {
      this.select('name');
    });
  }
};
</script>

<style lang="scss">
$color: $media-file;

.form-dialogue-file {
  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__header {
    font-style: $font-italic;
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $color;
  }

  .form-field {
    border: none;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
  }

  &__file {
    @include next-prev-transition();
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__transition {
    @include flexy($dir: column, $just: center);
    width: 100%;
    height: 100%;
  }

  &__select {
    @include flexy($dir: column, $align: center, $just: center);

    > .icon {
      fill: $color;
    }

    > .file-input {
      background-color: $color;
    }

    &--upload {
      @include flexy($dir: column, $just: center, $align: center);
      flex: 1;
      font-style: $font-italic;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      padding: $spacing-double;
      text-align: center;
    }
  }

  &__preview {
    @include flexy($dir: column, $just: center, $align: center);
    height: 100%;

    &--content {
      @include flexy($dir: column, $align: center, $just: center);
      flex: 1;
      width: 100%;
      height: 100%;

      > .icon {
        fill: $color;
      }
    }

    &--confirm {
      font-weight: $font-weight-bold;
      padding: $spacing-base;
      word-break: break-word;
    }

    &--delete {
      align-self: flex-end;
      padding: $spacing-base;
    }
  }
}
</style>
