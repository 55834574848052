<template>
  <div ref="main" class="video-control" :class="$attrs.class">
    <div ref="container" class="video-control__container">
      <VideoPlayer
        ref="player"
        class="video-control__player"
        :poster="posterUrl"
        :sources="sources"
        :link="item.link"
        :autoPlay="autoPlay"
        @playing="updatePlaying"
        @error="videoError"
        :style="styleDimensions"
      />
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </div>
</template>

<script>
import VideoPlayer from '../mediaPlayer/VideoPlayer';
import ToastAlert from '../toast/ToastAlert';
import mediaProperties from '../mixins/mediaProperties';

export default {
  inheritAttrs: false,
  name: 'VideoControl',
  mixins: [mediaProperties],
  components: {
    VideoPlayer,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    autoPlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      playing: false,
      error: '',
      width: null,
      height: null,
      loading: false
    };
  },
  computed: {
    sources() {
      const { fileInfos } = this.item;
      const { id, url, contentType } = fileInfos || {};
      if (!url) return [];

      return [
        {
          id,
          src: url,
          type: contentType
        }
      ];
    },
    styleDimensions() {
      if (this.width && this.height) {
        return `max-width: ${this.width}px; max-height: ${this.height}px;`;
      } else {
        return 'max-width: 100%; max-height: 100%;';
      }
    }
  },
  methods: {
    updatePlaying(data) {
      this.playing = data.playing;
      this.loading = false;
      this.$emit('video-action', data);
    },
    videoError() {
      this.error = this.$t('mediaForm.video.cannotPlay');
    },
    resize() {
      const { main } = this.$refs;
      const { width, height } = main.getBoundingClientRect();
      this.width = width;
      this.height = height;
    },
    start() {
      if (!this.playing) {
        const { player } = this.$refs;
        player?.start();
      }
    },
    stop() {
      if (this.playing) {
        const { player } = this.$refs;
        player?.stop();
      }
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.resize);
  },
  mounted() {
    this.$nextTick(() => {
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  }
};
</script>

<style lang="scss" scoped>
.video-control {
  @include flexy($align: center, $just: center);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &__container,
  &__player {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.video-player.video-control__player .plyr__video-wrapper {
  background: unset;
}
</style>
