import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import eventDispatcher from '@/stores/event-dispatcher';

import {
  create as apiCreate,
  update as apiUpdate,
  del as apiDelete,
  get as apiGet,
  getBrief as apiGetBrief,
  getFolders as apiGetFolders,
  getCards as apiGetCards,
  moveToBin as apiMoveToBin,
  restoreFromBin as apiRestoreFromBin,
  validateCopy as apiValidateCopy,
  copy as apiCopy,
  move as apiMove,
  getSubscription as apiGetSubscription,
  addSubscription as apiAddSubscription,
  editSubscription as apiEditSubscription,
  updatePositionSubElements as apiUpdatePositionSubElements,
  setAssetTagSubElements as apiSetAssetTagSubElements,
  getBreadcrumbTrail as apiGetBreadcrumbTrail,
  getFirstParentNoneDeleted as apiGetFirstParentNoneDeleted
} from '@/api/endpoints/folder';
import { defaultSort, sortOptions } from '@/utils/sort';
import { track } from '@/utils/tracking';
import { CARD_DISPLAY_GRID, CARD_DISPLAY_ORGANIC } from '@/utils/asset';
import { FOLDER_TYPE, CARD_TYPE } from '@/utils/types';

const defaultLimit = 30;

const getDefaultFilter = () => {
  return {
    keys: {},
    types: [],
    displayHidden: false,
    hasFilters: false
  };
};

const initialState = {
  share: false,
  current: null,
  breadcrumbs: [],
  brief: null,

  folders: [],
  hasFoldersNextPage: false,

  cards: [],
  hasCardsNextPage: false,

  cardDisplay: CARD_DISPLAY_GRID,

  filter: getDefaultFilter(),
  sort: defaultSort,
  searchText: null,
  moveSubElementsType: null,

  selectingForGrouping: false,
  selectedAssetTag: null,
  selectedGroupingAssetIds: new Map()
};

export const key = FOLDER_TYPE;
export const useFolderStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, initialState);
      this.filter = getDefaultFilter();
    },
    async assetExists(options = {}) {
      const { id, current = false } = options;
      const currentExists =
        this.current?.id === id || this.current?.board.id === id;

      if (current) return currentExists;
      const result =
        currentExists ||
        this.brief?.id === id ||
        this.brief?.board.id === id ||
        this.breadcrumbs.some((x) => x.id === id || x.board.id === id) ||
        this.folders.some((x) => x.id === id || x.board.id === id) ||
        this.cards.some((x) => x.id === id || x.board.id === id);

      if (result) options.share = this.share;
      return result;
    },
    async fetch({ id, share }) {
      return await apiGet(id, false, share);
    },
    async modify({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await (id ? apiUpdate(id, data) : apiCreate(data));

        if (id) {
          await eventDispatcher.assetUpdated(newItem);
        } else {
          this.track({ event: 'created', item: newItem });
        }

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async toggleHidden(item) {
      const { id, hidden } = item;

      const newItem = await this.modify({
        id,
        hidden: !hidden
      });

      if (newItem.hidden) {
        this.track({ event: 'hidden', item: newItem });
      }

      return newItem;
    },
    async toggleDeleted({ id, deleted }) {
      let newItem;
      if (deleted) {
        newItem = await apiRestoreFromBin(id);
      } else {
        newItem = await apiMoveToBin(id);
      }

      if (newItem.deleted) {
        this.track({ event: 'deleted', item: newItem });
      }

      return newItem;
    },
    async get({ id, share }) {
      const gettingNewItem = !this.current || this.current.id !== id;
      if (gettingNewItem) {
        await this.reset();
      }

      this.current = await apiGet(id, true, share);

      if (!share) {
        if (this.current.board.private && !this.current.partOfParticipants) {
          const appStore = useAppStore();
          await appStore.setNavigateTo({ name: 'root' });
          return;
        }
      }

      this.share = share;

      const [breadcrumbs, brief] = await Promise.all([
        apiGetBreadcrumbTrail(id, this.share),
        apiGetBrief(id, this.share)
      ]);

      this.breadcrumbs = breadcrumbs;
      this.brief = brief;

      if (gettingNewItem) {
        await this.refreshLists();
      }

      return this.current;
    },
    async getBrief() {
      if (!this.current) return;

      this.brief = await apiGetBrief(this.current.id, this.share);
      return this.brief;
    },
    async refreshLists() {
      await Promise.all([this.refreshFolders(), this.refreshCards()]);
    },
    async getFolders(options = {}) {
      if (!this.current) return;

      const { sort, filter, folders, searchText, moveSubElementsType } = this;

      if (
        filter.types.length > 0 &&
        !filter.types.some((type) => type === FOLDER_TYPE)
      ) {
        this.folders = [];
        this.hasFoldersNextPage = false;

        return { items: [], hasNextPage: false };
      }

      const { start = folders.length } = options;
      let limit =
        start === 0 && folders.length > defaultLimit
          ? folders.length
          : defaultLimit;
      if (moveSubElementsType === FOLDER_TYPE) limit = null;

      const { items, hasNextPage } = await apiGetFolders(
        this.current.id,
        {
          query: { start, sort: sortOptions[sort], limit },
          text: searchText || '',
          displayHidden: filter.displayHidden
        },
        this.share
      );

      this.folders = start === 0 ? items : [...this.folders, ...items];
      this.hasFoldersNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshFolders() {
      await this.getFolders({ start: 0 });
    },
    async getCards(options = {}) {
      if (!this.current) return;

      const { sort, filter, cards, searchText, moveSubElementsType } = this;

      if (
        filter.types.length > 0 &&
        !filter.types.some((type) => type === CARD_TYPE)
      ) {
        this.cards = [];
        this.hasCardsNextPage = false;

        return { items: [], hasNextPage: false };
      }

      const { start = cards.length } = options;
      let limit =
        start === 0 && cards.length > defaultLimit
          ? cards.length
          : defaultLimit;
      if (moveSubElementsType === CARD_TYPE) limit = null;

      const { items, hasNextPage } = await apiGetCards(
        this.current.id,
        {
          query: { start, sort: sortOptions[sort], limit },
          text: searchText || '',
          displayHidden: filter.displayHidden
        },
        this.share
      );

      this.cards = start === 0 ? items : [...this.cards, ...items];
      this.hasCardsNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshCards() {
      await this.getCards({ start: 0 });
    },
    async setSort(sort) {
      this.sort = sort;

      await this.refreshLists();
    },
    async setFilter(newFilter) {
      const { filter } = this;

      if (filter.keys[newFilter.key]) {
        if (newFilter.type) {
          filter.types = filter.types.filter((x) => x !== newFilter.key);
        } else if (newFilter.key === 'displayHidden') {
          filter.displayHidden = false;
        }

        delete filter.keys[newFilter.key];
      } else {
        filter.keys[newFilter.key] = newFilter.key;

        if (newFilter.type) {
          filter.types.push(newFilter.key);
        } else if (newFilter.key === 'displayHidden') {
          filter.displayHidden = true;
        }
      }

      this.filter = {
        ...filter,
        hasFilters: filter.displayHidden || filter.types.length > 0
      };

      await this.refreshLists();
    },
    async search({ text }) {
      this.searchText = text;

      await this.refreshLists();
    },
    async clearSearch() {
      this.searchText = null;

      await this.refreshLists();
    },
    async clearFilters() {
      this.filter = getDefaultFilter();
      this.sort = defaultSort;
      this.searchText = null;

      await this.refreshLists();
    },
    async getSubscription({ parentId }) {
      return await apiGetSubscription(parentId);
    },
    async addSubscription({ parentId, data }) {
      return await apiAddSubscription(parentId, data);
    },
    async editSubscription({ parentId, id, data }) {
      return await apiEditSubscription(parentId, id, data);
    },
    async validateCopy({ id, data }) {
      return await apiValidateCopy(id, data);
    },
    async copy({ id, items }) {
      if (this.current?.id !== id) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiCopy(id, items);
        this.track({ event: 'copied', item: newItem });
        await eventDispatcher.assetUpdated(newItem);
        await this.refreshLists();

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async move({ id, items }) {
      if (this.current?.id !== id) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiMove(id, items);
        this.track({ event: 'moved', item: newItem });
        await eventDispatcher.assetUpdated(newItem);
        await this.refreshLists();
        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async moveTo({ id, item }) {
      const newItem = await apiMove(id, [item]);
      this.track({ event: 'moved', item: newItem });
      return newItem;
    },
    async updatePositionSubElements({ item: movedItem, newIndex }) {
      if (!this.current) return;

      const { folders, hasFoldersNextPage, cards, hasCardsNextPage } = this;

      const data = {
        type: movedItem.type,
        positions: []
      };

      let items = [];
      let hasNextPage = false;
      if (movedItem.type === FOLDER_TYPE) {
        items = [...folders];
        hasNextPage = hasFoldersNextPage;
      } else if (movedItem.type === CARD_TYPE) {
        items = [...cards];
        hasNextPage = hasCardsNextPage;
      }

      const index = items.findIndex((x) => !x.hidden && x.id === movedItem.id);
      if (index === -1) return;
      const item = items[index];

      items.splice(index, 1);
      items.splice(newIndex, 0, item);

      let i = 1;
      items
        .filter((x) => !x.hidden)
        .forEach((item) => {
          if (i !== item.position) {
            data.positions.push({ id: item.id, position: i });
          }

          item.position = i;
          i++;
        });

      await apiUpdatePositionSubElements(this.current.id, data);

      if (movedItem.type === FOLDER_TYPE) {
        this.folders = items;
        this.hasFoldersNextPage = hasNextPage;
      } else if (movedItem.type === CARD_TYPE) {
        this.cards = items;
        this.hasCardsNextPage = hasNextPage;
      }
    },
    async setAssetTagSubElements(data) {
      if (!this.current) return;

      await apiSetAssetTagSubElements(this.current.id, data);
    },
    async assetTagSelected(assetTag) {
      this.selectedAssetTag = assetTag;
      this.selectingForGrouping = true;
    },
    async setGroupingAsset(item) {
      const { asset, selected } = item;
      const ids = new Map(this.selectedGroupingAssetIds);

      if (selected) {
        ids.set(asset.id, true);
      } else {
        ids.delete(asset.id);
      }

      this.selectedGroupingAssetIds = ids;
    },
    async quitGroupingSelection() {
      this.selectingForGrouping = false;
      this.selectedAssetTag = null;
      this.selectedGroupingAssetIds = new Map();
    },
    async refreshBreadcrumbs(id) {
      if (!this.current) return;

      if (this.current.id === id || this.breadcrumbs.some((x) => x.id === id)) {
        this.breadcrumbs = await apiGetBreadcrumbTrail(this.current.id);
      }
    },
    async hideItem(item) {
      if (this.current.id !== item.parent.id) return;

      if (this.filter.displayHidden) return;

      if (item.type === FOLDER_TYPE) {
        if (!this.folders.some((x) => x.id === item.id)) return;
        if (this.hasFoldersNextPage) {
          await this.refreshFolders();
          return;
        }
      } else if (item.type === CARD_TYPE) {
        if (!this.cards.some((x) => x.id === item.id)) return;
        if (this.hasCardsNextPage) {
          await this.refreshCards();
          return;
        }
      }

      if (this.folders.some((x) => x.id === item.id)) {
        this.folders = this.folders.filter((asset) => asset.id !== item.id);
      } else if (this.cards.some((x) => x.id === item.id)) {
        this.cards = this.cards.filter((asset) => asset.id !== item.id);
      }

      if (this.brief?.id === item.id) {
        this.brief = null;
      }
    },
    async displayItem(item) {
      if (this.current.id !== item.parent.id) return;
      if (this.filter.displayHidden) return;

      if (item.type === FOLDER_TYPE) {
        if (this.folders.some((x) => x.id === item.id)) return;
        await this.refreshFolders();
      } else if (item.type === CARD_TYPE) {
        if (this.cards.some((x) => x.id === item.id)) return;
        await this.refreshCards();
      }
    },
    async movedToBin(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        const parent = await apiGetFirstParentNoneDeleted(item.id);
        const appStore = useAppStore();

        if (!parent || !this.breadcrumbs.some((x) => x.id === parent.id)) {
          await appStore.setNavigateTo({ name: 'root' });
        } else {
          await appStore.setNavigateTo({
            name: parent.type,
            params: { id: parent.id }
          });
        }

        return;
      }

      if (this.current.id !== item.parentId) return;
      if (item.parentDeleted) return;

      if (this.brief?.id === item.id) {
        await this.getBrief();
      } else if (item.type === FOLDER_TYPE) {
        if (this.folders.some((x) => x.id === item.id)) {
          await this.refreshFolders();
        }
      } else if (item.type === CARD_TYPE) {
        if (this.cards.some((x) => x.id === item.id)) {
          await this.refreshCards();
        }
      }
    },
    async restoreFromBin(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        await this.get({ id: item.id });
        await this.refreshLists();
        return;
      }

      if (this.current.id !== item.parentId) return;

      if (item.brief) {
        await this.getBrief();
      } else if (item.type === FOLDER_TYPE) {
        await this.refreshFolders();
      } else if (item.type === CARD_TYPE) {
        await this.refreshCards();
      }
    },
    async deletePermanently(item) {
      await apiDelete(item.id);
    },
    async setMoveSubElementsType(type) {
      this.moveSubElementsType = type;
    },
    async setCardDisplay(cardDisplay) {
      this.cardDisplay =
        cardDisplay === CARD_DISPLAY_GRID
          ? CARD_DISPLAY_GRID
          : CARD_DISPLAY_ORGANIC;
    },
    async assetTagHasChanged({ id }) {
      if (this.current?.id !== id) return;

      await this.refreshCards();
    },
    async track({ event, item, isShared }) {
      if (isShared && useAuthStore().isInPreviewWebShare) return;

      await track(event, {
        object: 'folder',
        id: item.id,
        name: item.name,
        time: new Date().toISOString(),
        sandBox: !!item.board?.sandBox,
        protected: item.protected,
        isShared: !!isShared
      });
    },
    async assetUpdated(item) {
      if (!this.current) return;

      if (this.breadcrumbs.some((x) => x.id === item.id)) {
        this.breadcrumbs = this.breadcrumbs.map((asset) => {
          return asset.id === item.id ? { ...asset, name: item.name } : asset;
        });
      }

      if (
        this.folders.some((x) => x.id === item.id || x.board?.id === item.id)
      ) {
        this.folders = this.folders.map((x) => {
          if (x.id === item.id) {
            return { ...item };
          } else if (x.board?.id === item.id && item.board) {
            const newItem = { ...x };
            newItem.board = { ...item.board };
            return newItem;
          } else return x;
        });
      } else if (
        this.cards.some((x) => x.id === item.id || x.board?.id === item.id)
      ) {
        this.cards = this.cards.map((x) => {
          if (x.id === item.id) {
            return { ...item };
          } else if (x.board?.id === item.id && item.board) {
            const newItem = { ...x };
            newItem.board = { ...item.board };
            return newItem;
          } else return x;
        });
      }

      if (this.current.id === item.id) {
        this.current = { ...item };
      } else if (this.current.board.id === item.id) {
        const current = { ...this.current };
        current.board = item.board;
        this.current = current;
      }

      if (this.brief) {
        if (this.brief.id === item.id) {
          this.brief = { ...item };
        } else if (this.brief.board.id === item.id) {
          const brief = { ...this.brief };
          brief.board = item.board;
          this.brief = brief;
        }
      }
    },
    async assetDeleted(item) {
      if (!this.current) return;

      if (this.current.id === item.id || this.current.board?.id === item.id) {
        const appStore = useAppStore();
        await appStore.setNavigateTo({ name: 'root' });
        return;
      }

      if (this.current.id !== item.parentId) return;
      if (item.parentDeleted) return;

      if (this.brief?.id === item.id) {
        await this.getBrief();
      } else if (item.type === FOLDER_TYPE) {
        if (this.folders.some((x) => x.id === item.id)) {
          await this.refreshFolders();
        }
      } else if (item.type === CARD_TYPE) {
        if (this.cards.some((x) => x.id === item.id)) {
          await this.refreshCards();
        }
      }
    }
  }
});

export default {
  key,
  use: useFolderStore
};
