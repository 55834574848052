<template>
  <BaseDialogue
    class="form-dialogue-audio"
    :class="$attrs.class"
    :title="$t(`mediaForm.audio.label.${mode}`)"
    :show-ok="showOk"
    @cancel="$emit('cancel')"
    @ok="ok()"
  >
    <div class="form-dialogue-audio__content" @keydown.enter.prevent="ok">
      <BaseInput
        ref="name"
        :label="$t('mediaForm.name')"
        name="name"
        :maxlength="120"
        v-model.trim="form.name"
      />

      <div class="form-dialogue-audio__audio">
        <transition :name="transitionName">
          <div v-if="step === 'select'" class="form-dialogue-audio__transition">
            <div class="form-dialogue-audio__select">
              <AudioMedia size="xlarge" />

              <CallToAction
                v-if="!uploadInProgress && canRecord"
                size="large"
                @v-click="startRecording"
              >
                {{ $t('mediaForm.audio.startRecording') }}
              </CallToAction>

              <FileInput
                v-if="!uploadInProgress"
                :accept="_acceptedTypes"
                size="large"
                @change="fileSelected($event, true)"
                name="files"
              >
                {{ $t('mediaForm.audio.select') }}
              </FileInput>

              <div v-if="uploadInProgress">
                {{ $t('mediaForm.audio.uploadInProgress') }}
              </div>
            </div>
          </div>
        </transition>

        <transition :name="transitionName">
          <div v-if="step === 'record'" class="form-dialogue-audio__transition">
            <div class="form-dialogue-audio__record">
              <AudioMedia
                class="form-dialogue-audio__record--icon"
                size="xlarge"
              />

              <CallToAction size="large" @v-click="stopRecording">
                {{ $t('mediaForm.audio.stopRecording') }}
              </CallToAction>

              <SlidingIcon
                size="xlarge"
                :icon="AudioWave"
                title="mediaForm.audio.recording"
              />
            </div>
          </div>
        </transition>

        <transition :name="transitionName">
          <div
            v-if="step === 'preview'"
            class="form-dialogue-audio__transition"
          >
            <div class="form-dialogue-audio__preview">
              <div class="form-dialogue-audio__preview--content">
                <AudioPlayer
                  ref="player"
                  :sources="sources"
                  @playing="updatePlaying"
                />

                <button
                  v-if="canPlay"
                  class="button icon-button form-dialogue-audio__preview--play"
                  @click="togglePlay"
                >
                  <Pause v-if="playing" size="xlarge" />
                  <VideoMedia v-else size="xlarge" />
                </button>
              </div>

              <button
                class="button icon-button form-dialogue-audio__preview--delete"
                @click="trash"
              >
                <Trash />
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import features from '../../utils/features';
import { toMegabytes } from '../../utils/numbers';
import BaseDialogue from '../dialogues/BaseDialogue';
import CallToAction from '../forms/CallToAction';
import FileInput from '../forms/FileInput';
import AudioMedia from '../icons/AudioMedia';
import AudioWave from '../icons/AudioWave';
import Pause from '../icons/Pause';
import SlidingIcon from '../icons/SlidingIcon';
import Trash from '../icons/Trash';
import VideoMedia from '../icons/VideoMedia';
import AudioPlayer from '../mediaPlayer/AudioPlayer';
import mediaForm from '../mixins/mediaForm';
import ToastAlert from '../toast/ToastAlert';

const acceptedTypes = process.env.AUDIO_MEDIA_SUPPORTED_TYPES;
const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

const STEP_BASE = 'select';
const STEP_RECORD = 'record';
const STEP_PREVIEW = 'preview';

export default {
  inheritAttrs: false,
  name: 'FormDialogueAudio',
  mixins: [mediaForm],
  components: {
    AudioMedia,
    AudioPlayer,
    CallToAction,
    BaseDialogue,
    FileInput,
    Pause,
    SlidingIcon,
    ToastAlert,
    Trash,
    VideoMedia
  },
  data() {
    const { id, name = '', fileInfos } = this.editItem;

    return {
      AudioWave,
      form: {
        name,
        file: null,
        mediaType: 'audio'
      },
      submitted: false,
      step: id ? STEP_PREVIEW : STEP_BASE,
      transitionName: 'prev',
      sources: fileInfos
        ? [
            {
              src: fileInfos.url,
              type: fileInfos.contentType
            }
          ]
        : [],
      playing: false,
      recorder: null,
      error: ''
    };
  },
  computed: {
    canRecord() {
      return features.audioRecording() && !features.isMobile.iOS();
    },
    canPlay() {
      return !features.isMobile.iOS();
    },
    showOk() {
      return this.step === STEP_PREVIEW;
    },
    _acceptedTypes() {
      return acceptedTypes;
    }
  },
  methods: {
    async ok() {
      switch (this.step) {
        case STEP_BASE:
        case STEP_RECORD:
          this.transitionName = 'next';
          this.step = STEP_PREVIEW;
          break;
        case STEP_PREVIEW:
          if (!this.form.name) {
            this.error = this.$t('mediaForm.nameRequired');
            this.select('name');
            return;
          }

          if (!this.submitted) {
            this.submitted = true;
            try {
              const media = await this.modify();
              this.$emit('success', media);
            } catch (e) {
              console.error(e);
              this.submitted = false;
              this.error = this.$t('mediaForm.error');
            }
          }
          break;
      }
    },
    trash() {
      if (this.form.file) {
        let name;
        const pos = this.form.file.name.lastIndexOf('.');
        if (pos > 0) {
          name = this.form.file.name.substring(0, pos);
        } else {
          name = this.form.file.name;
        }

        if (this.form.name === name) this.form.name = '';
      }

      this.form.file = null;

      this.step = STEP_BASE;
      this.transitionName = 'prev';
      this.select('name');
    },
    fileSelected(files, setName) {
      const [file] = files;

      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
      } else {
        this.form.file = file;

        if (setName && !(this.form.name || '')) {
          const pos = file.name.lastIndexOf('.');
          if (pos > 0) {
            this.form.name = file.name.substring(0, pos);
          } else {
            this.form.name = file.name;
          }
        }

        this.ok();
      }

      setTimeout(() => {
        this.select('name');
      }, 100);
    },
    clearSources() {
      this.sources.forEach((source) => URL.revokeObjectURL(source.src));
      this.sources = [];
    },
    togglePlay() {
      const { player } = this.$refs;
      player.togglePlay();
    },
    updatePlaying({ playing }) {
      this.playing = playing;
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });
        const recorder = new MediaRecorder(stream);
        this.recorder = recorder;

        recorder.addEventListener('dataavailable', (e) => {
          // Get the Blob of audio data and set a default name
          this.form.file = e.data;
          let ext = 'webm';
          if (e.data.type) {
            const reg = /audio\/([^;]+)(;.+)?$/i;
            const match = e.data.type.trim().match(reg);
            if (match && match[1]) {
              ext = match[1].trim().toLowerCase();
            }
          }

          this.form.file.name = `${new Date()
            .toISOString()
            .replace(/-|:|\./g, '')}.${ext}`;
        });

        this.transitionName = 'next';
        this.step = STEP_RECORD;

        // Start recording
        recorder.start();
      } catch (err) {
        console.error(err);
        this.error(this.$t('mediaForm.audio.cannotRecord'));
      }
    },
    stopRecording() {
      if (this.recorder) {
        if (this.recorder.state === 'recording') this.recorder.stop();
        // Removes the microphone icon from the tab
        this.recorder.stream.getTracks().forEach((i) => i.stop());
      }

      this.ok();
      this.select('name');
    }
  },
  mounted() {
    if (this.file) {
      this.fileSelected([this.file], true);
    }

    this.$nextTick(() => {
      this.select('name');
    });
  },
  watch: {
    'form.file': function (newFile) {
      this.clearSources();

      if (newFile) {
        this.sources = [
          {
            src: URL.createObjectURL(newFile),
            type: newFile.type
          }
        ];
      }
    }
  },
  beforeUnmount() {
    this.stopRecording();
    this.clearSources();
  }
};
</script>

<style lang="scss">
$color: $media-audio;

.form-dialogue-audio {
  padding: $spacing-base;

  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__main {
    overflow: hidden;
  }

  .dialogue__header {
    font-style: $font-italic;
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $color;
  }

  .form-field {
    border: none;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
  }

  &__audio {
    @include next-prev-transition();

    flex: 1;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__transition {
    @include flexy($dir: column, $just: center);
    width: 100%;
    height: 100%;
  }

  &__select,
  &__record {
    @include flexy($dir: column, $align: center, $just: center);

    > .icon-audio-media {
      fill: $color;
    }

    .icon-audio-wave {
      fill: rgba(0, 0, 0, 1);
    }

    > .call-to-action,
    > .file-input {
      background-color: $color;
    }
  }

  &__select {
    &--upload {
      @include flexy($dir: column, $just: center, $align: center);
      flex: 1;
      font-style: $font-italic;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      padding: $spacing-double;
      text-align: center;
    }
  }

  &__preview {
    @include flexy($dir: column, $just: center, $align: center);
    height: 100%;
    padding: $spacing-base;

    &--content {
      @include flexy($dir: column, $align: center, $just: center);
      flex: 1;
      width: 100%;
      height: 100%;
    }

    &--play {
      margin-top: $spacing-half;

      > .icon {
        fill: $color;
      }
    }

    &--delete {
      align-self: flex-end;
    }
  }
}
</style>
