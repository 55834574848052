import auth from '../auth';
import { ROLE_PARTICIPANT_RESTRICTED } from '../../utils/role';
const CardView = () => import('../../components/card/CardView');

export default () => {
  return [
    {
      path: '/card/:id/share/:hash',
      name: 'card-share',
      component: CardView,
      props: (route) => ({
        id: parseInt(route.params.id)
      }),
      beforeEnter: auth.ensureHasShareToken()
    },
    {
      path: '/card/:id',
      name: 'card',
      component: CardView,
      props: (route) => ({
        id: parseInt(route.params.id),
        displayComments: route.query.displayComments === 'true',
        mediaId: route.query.mediaId ? parseInt(route.query.mediaId) : null
      }),
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    }
  ];
};
