import { mapState } from 'pinia';
import { useBoardStore } from '@/stores/board';
import assetMixin from '../mixins/asset';
import { toggleHiddenMixin } from '../mixins/assetOptionsToggleHidden';
import commentMixin from '../mixins/comment';
import { BOARD_TYPE } from '../../utils/types';
import { CARD_DISPLAY_GRID } from '../../utils/asset';

export default {
  mixins: [assetMixin, toggleHiddenMixin(BOARD_TYPE), commentMixin],
  data() {
    return {
      inviteParticipants: false
    };
  },
  computed: {
    ...mapState(useBoardStore, ['cardDisplay']),
    canEdit() {
      return !this.isSandBox && this.isAdministrator && this.isAuthorBoard;
    },
    canAddComment() {
      return !this.isSandBox && !this.isReadOnly && !this.fromSearch;
    },
    canShare() {
      return this.isAuthorBoard;
    },
    canCreateSubBoard() {
      if (this.isSandBox || !this.isAdministrator) return false;
      return (
        this.isAuthorBoard &&
        !this.fromSearch &&
        this.cardDisplay === CARD_DISPLAY_GRID
      );
    },
    canManageSubscription() {
      if (this.isSandBox) return false;
      return !this.isReadOnly && !this.fromSearch;
    }
  },
  methods: {
    addItemToClipboard(action) {
      this.addToClipboard({ item: this.item, action });
      this.$emit('cancel');
    },
    edit() {
      this.inviteParticipants = false;
      this.toggleDialogue('editBoard');
    },
    editSuccess() {
      this.inviteParticipants = false;
      this.toggleDialogue();
      this.$emit('cancel');
    },
    editCancel() {
      this.inviteParticipants = false;
      this.toggleDialogue();
      this.$emit('cancel');
    },
    invite() {
      this.inviteParticipants = true;
      this.toggleDialogue('editBoard');
    },
    _copyLink() {
      this.copyLink();
      this.$emit('cancel');
    },
    _displayComments() {
      this.showComments();
      this.$emit('cancel');
    }
  }
};
