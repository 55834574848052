import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'LayoutIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.824 15.043"
      >
        <title>{{ text }}</title>
        <g transform="translate(-94.547 -590.61)">
          <path d="M105.96,590.61a12.728,12.728,0,0,0-11.413,7.52h0a12.418,12.418,0,0,0,22.824,0A12.725,12.725,0,0,0,105.96,590.61Zm0,12.322a4.8,4.8,0,1,1,4.8-4.8A4.8,4.8,0,0,1,105.96,602.932Z" />
          <path
            d="M104.346,594.424a2.824,2.824,0,1,0,2.824,2.825A2.825,2.825,0,0,0,104.346,594.424Z"
            transform="translate(1.614 0.883)"
          />
        </g>
      </svg>
    );
  }
};
