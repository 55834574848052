import { mapActions } from 'pinia';
import { mapStores } from '@/stores';
import { useFooter } from '@/stores/footer';

export function searchMixin(module) {
  const useStore = mapStores.get(module).use;

  return {
    data() {
      return {
        localSearchText: this.searchText || ''
      };
    },
    computed: {
      displayClearFilters() {
        return (
          !this.isSelectionMode &&
          (this.sortLabel !== this.defaultSort ||
            (this.filter && this.filter.hasFilters) ||
            !!this.searchText)
        );
      }
    },
    methods: {
      ...mapActions(useFooter, {
        setIsDisplayedClearFilters: 'setIsDisplayedClearFilters',
        resetFooter: 'reset'
      }),
      ...mapActions(useStore, ['search', 'clearSearch', 'clearFilters']),
      _search() {
        const { list } = this.$refs;
        if (list) list.scrollTop();
        this.search({ text: this.localSearchText || '' });
      },
      _clearSearch() {
        this.localSearchText = '';
        this.clearSearch();
      },
      _clearFilters() {
        this.localSearchText = '';
        this.clearFilters();
      }
    },
    watch: {
      displayClearFilters(value) {
        this.setIsDisplayedClearFilters(value);
        this.$forceUpdate();
      }
    },
    beforeUnmount() {
      this.resetFooter();
    }
  };
}
