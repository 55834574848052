import { cleanHtml as apiCleanHtml } from '@/api/endpoints/util';

export const getPasteHtmlFragment = (input) => {
  if (!input) return '';

  const sTag = '<!--StartFragment-->';
  const eTag = '<!--EndFragment-->';

  const sPos = input.indexOf(sTag);
  const ePos = input.indexOf(eTag);

  const start = sPos > -1 ? sPos + sTag.length : 0;
  return ePos > -1 ? input.substring(start, ePos) : input.substring(start);
};

export const convertTextToCleanHtml = (text) => {
  const content = document.createElement('div');

  const lines = text.split(/\r?\n/);

  lines.forEach((line) => {
    const div = document.createElement('div');
    const s = line.trim();

    if (s) {
      div.innerText = s;
    } else {
      div.innerHTML = '<br>';
    }

    content.appendChild(div);
  });

  return content.innerHTML;
};

export const htmlToText = (html) => {
  const div = document.createElement('div');
  div.innerHTML = (html || '')
    .replace(/<br>/gi, '<br>\r\n')
    .replace(/<\/div>/gi, '</div>\r\n')
    .replace(/<\/p>/gi, '</div>\r\n');
  return div.innerText.replace(/\r?\n/g, ' ').replace(/\s+/g, ' ').trim();
};

export const cleanHtml = async (_ctx, s) => {
  const { html } = await apiCleanHtml({ html: s });
  return html || '';
};
