<template>
  <section class="chat-message" :class="$attrs.class">
    <div class="chat-message__inner">
      <ul
        ref="list"
        class="chat-message__messages"
        :class="{ 'chat-message__messages--empty': empty }"
      >
        <li
          class="chat-message__messages--item"
          v-for="message in messages.values()"
          :key="message.id"
        >
          <ChatMessageItem :item="message" @deleteMessage="confirmDelete" />
        </li>

        <li v-if="!loading && empty" class="chat-message__messages-empty-text">
          <span>{{ $t('chatForm.messages.empty') }}</span>
        </li>
      </ul>

      <div class="chat-message__message">
        <MessageField
          ref="message"
          v-model="message"
          :placeholder="$t('chatForm.messages.message')"
          @done="add"
        />
      </div>
    </div>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      :relative="true"
      @ok="_delete"
      @cancel="closeConfirmDelete"
    >
      {{ $t('chatForm.messages.confirmDelete') }}
    </ToastAlert>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useChatStore } from '@/stores/chat';
import MessageField from '../forms/MessageField';
import ChatMessageItem from './ChatMessageItem';
import ToastAlert from '../toast/ToastAlert';
import pagination from '../mixins/pagination';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'ChatMessage',
  mixins: [pagination, toggleState('toast')],
  components: {
    MessageField,
    ChatMessageItem,
    ToastAlert
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      message: '',
      deleteMessageId: null,
      submitted: false,
      loading: false
    };
  },
  computed: {
    ...mapState(useChatStore, {
      current: 'current',
      messages: 'messages',
      hasNextPage: 'messageHasNextPage'
    }),
    empty() {
      return this.messages.length === 0;
    },
    title() {
      return this.current ? this.current.name : '';
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar', 'displayError']),
    ...mapActions(useChatStore, {
      clearCurrent: 'clearCurrent',
      addMessage: 'addMessage',
      deleteMessage: 'deleteMessage',
      setCurrent: 'setCurrent',
      load: 'getMessages'
    }),
    async add() {
      if (!this.message || this.submitted) return;

      this.submitted = true;

      try {
        await this.addMessage({ content: this.message });

        const { message, list } = this.$refs;
        message.clear();
        this.message = '';
        this.focus();
        list.scrollTop = list.scrollHeight;
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    },
    confirmDelete({ id }) {
      this.deleteMessageId = id;
      this.toggleToast('confirmDelete');
    },
    closeConfirmDelete() {
      this.deleteMessageId = null;
      this.toggleToast();
    },
    async _delete() {
      if (!this.deleteMessageId || this.submitted) return;

      this.submitted = true;

      try {
        await this.deleteMessage({ id: this.deleteMessageId });
        this.closeConfirmDelete();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    },
    close() {
      this.setActiveLeftSideBar();
    },
    focus() {
      const { message } = this.$refs;
      if (message) message.focus();
    }
  },
  async beforeMount() {
    await this.setCurrent({ id: this.id });
    this.loading = false;
  },
  mounted() {
    this.$nextTick(() => {
      this.focus();
    });
  },
  async beforeUnmount() {
    await this.clearCurrent();
  }
};
</script>

<style lang="scss">
.chat-message {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;

  &__inner {
    @include flexy($dir: column, $just: flex-start);
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    position: relative;
    padding-bottom: $spacing-base;
  }

  &__messages {
    @include flexy($dir: column-reverse);
    height: 100%;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    @include item-list-empty();

    &--item {
      margin: $spacing-third $spacing-base 0 $spacing-base;
    }

    &-empty-text {
      color: $font-light;
    }
  }

  &__message {
    margin: $spacing-base $spacing-base 0 $spacing-base;
    height: 40px;
    position: relative;

    > * {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
