<template>
  <div class="profile-menu" :class="$attrs.class">
    <div class="profile-menu__button">
      <button
        class="icon-button menu-button"
        @click="toggle"
        :class="{ 'profile-menu__button--active': menuOpen }"
      >
        <AvatarPill :info="currentUser" />
      </button>
    </div>

    <transition name="slide">
      <SlideMenu v-if="menuOpen" slide="down" anchor="right">
        <MenuItem
          :label="$t('profileMenu.myProfile')"
          @selected="goProfile()"
        />
        <MenuItem
          :label="$t('profileMenu.mySettings')"
          @selected="displayMySettings()"
        />
        <MenuItem
          :label="$t(otherLanguage)"
          @selected="switchLanguage()"
          class="profile-menu__lang-switch"
        />
        <MenuItem :label="loginDescription" @selected="logout()" />
      </SlideMenu>
    </transition>

    <AccountSettings
      v-if="activeDialogue === 'my-settings'"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
    />
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import AvatarPill from '../AvatarPill';
import MenuItem from '../menus/MenuItem';
import authMixins from '../mixins/auth';
import SlideMenu from '../menus/SlideMenu';
import AccountSettings from '../account/AccountSettings';
import routerMixins from '../mixins/router';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'ProfileMenu',
  mixins: [authMixins, toggleState('dialogue'), routerMixins],
  components: {
    AvatarPill,
    MenuItem,
    SlideMenu,
    AccountSettings
  },
  data() {
    return {
      menuOpen: false,
      menuClicked: false
    };
  },
  computed: {
    otherLanguage() {
      return this.$i18n.locale === 'en'
        ? 'profileMenu.french'
        : 'profileMenu.english';
    },
    loginDescription() {
      return this.$t('profileMenu.logout');
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['updateProfile']),
    toggle() {
      this.menuClicked = true;
      this.menuOpen = !this.menuOpen;
    },
    clicked() {
      if (!this.menuClicked) {
        this.menuOpen = false;
      }

      this.menuClicked = false;
    },
    async switchLanguage() {
      const newLanguage = this.$i18n.locale === 'en' ? 'fr' : 'en';
      await this.updateProfile({ lang: newLanguage });
    },
    logout() {
      this.navigate({ name: 'logout' });
    },
    goProfile() {
      this.goto('my-profile');
    },
    displayMySettings() {
      this.toggleDialogue('my-settings');
    }
  },
  watch: {
    menuOpen(value) {
      if (value) {
        document.addEventListener('click', this.clicked);
      } else {
        document.removeEventListener('click', this.clicked);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
$avatar-size: 33px;

.profile-menu {
  height: 100%;

  &__button {
    position: relative;
    height: 100%;

    > button {
      height: $avatar-size;

      > .avatar {
        width: $avatar-size;
        height: $avatar-size;

        > .avatar-initial {
          font-size: $font-size-base * 1.4;
        }
      }
    }

    &--active {
      @include svg-active-background($background-triangle-up-svg);
      background-position: bottom;
    }

    > .menu-button {
      height: 100%;
    }
  }

  .profile-menu__lang-switch {
    text-transform: capitalize;
  }
}
</style>
