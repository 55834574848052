<template>
  <div
    v-if="canDisplay"
    class="tile-item-container__item-checkbox"
    :class="$attrs.class"
  >
    <ItemCheckBox
      v-if="mode === 'clipboard'"
      class="tile-item__item-checkbox"
      :item="item"
      :type="type"
      :mode="mode"
      :color="color"
    />
    <ItemCheckBox
      v-else-if="mode === 'normal'"
      class="tile-item__item-checkbox"
      :item="item"
      :type="type"
      :mode="mode"
      :modelValue="modelValue"
      :displayCheckbox="displayCheckbox"
      :color="color"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script>
import selectionClipboardMixin from '../mixins/selectionClipboard';
import ItemCheckBox from './ItemCheckBox';

export default {
  inheritAttrs: false,
  name: 'TileItemCheckBox',
  mixins: [selectionClipboardMixin],
  components: { ItemCheckBox },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'clipboard'
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    displayCheckbox: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'dark'
    }
  },
  computed: {
    canDisplay() {
      if (this.clipboardAction === 'copy') {
        return this.canCopy(this.item);
      } else if (this.clipboardAction === 'move') {
        return this.canMove(this.item);
      } else if (this.clipboardAction === 'evaluation') {
        return this.canCopy(this.item);
      }

      return false;
    }
  }
};
</script>

<style lang="scss">
.tile-item-container__item-checkbox {
  position: absolute;
  left: 0;
  top: 0;

  > .tile-item__item-checkbox {
    @include flexy($just: center, $align: center);
    background-color: $background-dark;
  }
}
</style>
