<template>
  <BaseDialogue
    class="options-dialogue"
    :class="[`options-dialogue--${type + suffix}`, $attrs.class]"
    size="small"
    :title="title"
    showClose
    :showOk="false"
    :showCancel="false"
    @cancel="$emit('cancel')"
  >
    <slot></slot>
  </BaseDialogue>
</template>

<script>
import BaseDialogue from './BaseDialogue';
import { CARD_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'OptionsDialogue',
  components: {
    BaseDialogue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    suffix() {
      if (this.item.type === CARD_TYPE) {
        return this.item.brief ? '-brief' : '';
      }

      return '';
    }
  }
};
</script>

<style lang="scss">
.options-dialogue {
  @include media-dialogue(background-color);
  color: $font-dark;
  font-weight: $font-weight-regular;
  text-transform: none;

  .dialogue__main {
    background-color: $background-light;
    font-family: $roboto-condensed;
  }

  &--chat {
    .dialogue__header,
    .dialogue__footer {
      background-color: $chat-base;
    }
  }

  &--board {
    .dialogue__header,
    .dialogue__footer {
      background-color: $board-base;
    }
  }

  &--folder {
    .dialogue__header,
    .dialogue__footer {
      background-color: $folder-base;
    }
  }

  &--card {
    .dialogue__header,
    .dialogue__footer {
      background-color: $card-base;
    }
  }

  &--card-brief {
    .dialogue__header,
    .dialogue__footer {
      background-color: $brief-base;
    }
  }

  &--group {
    .dialogue__header,
    .dialogue__footer {
      background-color: $group-base;
    }
  }

  &--company {
    .dialogue__header,
    .dialogue__footer {
      background-color: $company-base;
    }
  }

  &--evaluation {
    .dialogue__header,
    .dialogue__footer {
      background-color: $evaluation-base;
    }
  }

  &--share-asset {
    .dialogue__header,
    .dialogue__footer {
      background-color: $share-asset-base;
    }
  }
}
</style>
