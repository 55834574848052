import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'EyeClose';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.8 15"
      >
        <title>{{ text }}</title>
        <g>
          <path d="M3.8,12.4l1.1-1.1c-1.3-1-2.4-2.3-3.2-3.8c1.9-3.6,5.6-6,9.8-6c1,0,2,0.2,3,0.5l1.2-1.2C14.3,0.3,12.9,0,11.4,0C6.5,0.1,2,3,0,7.5C0.9,9.5,2.2,11.2,3.8,12.4z" />
          <path d="M11.4,11.1c2,0,3.5-1.6,3.5-3.5c0-0.3,0-0.5-0.1-0.8L10.6,11C10.9,11,11.1,11.1,11.4,11.1z" />
          <path d="M7.9,7.5c0,0.3,0,0.5,0.1,0.8l4.2-4.2C12,4,11.7,4,11.4,4C9.5,4,7.9,5.6,7.9,7.5z" />
          <path d="M19,2.7l-1.1,1.1c1.3,1,2.5,2.3,3.3,3.8c-1.2,2.3-3.1,4.1-5.4,5.1c-1.4,0.6-2.8,0.9-4.3,0.9c-1,0-2-0.1-3-0.4l-1.2,1.2C8.6,14.8,10,15,11.4,15c1.6,0,3.3-0.3,4.9-1c2.9-1.3,5.3-3.6,6.5-6.5C22,5.6,20.6,3.9,19,2.7z" />
        </g>
        <polygon points="2.6,15 5.1,15 20.3,0 17.8,0 " />
      </svg>
    );
  }
};
