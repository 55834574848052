<template>
  <div class="audit-login-item" :class="$attrs.class">
    <div class="audit-login-item__level">
      <label>{{ `${$t('auditLogin.level')}:` }}</label
      ><span>{{ $t(`auditLogin.levels.${item.level}`) }}</span>
    </div>
    <div class="audit-login-item__date">
      <label>{{ `${$t('auditLogin.date')}:` }}</label
      ><span>{{ formattedDate }}</span>
    </div>
    <div class="audit-login-item__message">
      <div>
        <label>{{ `${$t('auditLogin.message')}:` }}</label>
      </div>
      <div>{{ item.message }}</div>
    </div>
    <div class="audit-login-item__info">
      <div class="audit-login-item__info--label-browser">
        <label>{{ `${$t('auditLogin.browserInfo')}:` }}</label>
      </div>
      <div class="audit-login-item__info--value-browser">
        {{ item.requestHeaders ? item.requestHeaders['user-agent'] : '' }}
      </div>
      <div class="audit-login-item__info--label-ip">
        <label>{{ `${$t('auditLogin.ipAddresses')}:` }}</label>
      </div>
      <div class="audit-login-item__info--value-ip">
        {{ item.requestHeaders ? item.requestHeaders['x-forwarded-for'] : '' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'AuditLoginItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.item.date);
    }
  },
  methods: {
    formatDate(value) {
      if (!value) return '';
      const d = new Date(value);
      return (
        [
          d.getFullYear(),
          ('0' + (d.getMonth() + 1)).slice(-2),
          ('0' + d.getDate()).slice(-2)
        ].join('-') +
        ' ' +
        [
          ('0' + d.getHours()).slice(-2),
          ('0' + d.getMinutes()).slice(-2),
          ('0' + d.getSeconds()).slice(-2)
        ].join(':')
      );
    }
  }
};
</script>

<style lang="scss">
.audit-login-item {
  @include grid(
    $gap: $spacing-quarter,
    $cols: 1fr auto,
    $rows: auto auto auto,
    $areas: 'level date' 'message message' 'info info'
  );
  color: $font-dark;
  background-color: #ffffff;
  padding: $spacing-half;

  label {
    font-weight: bold;
    margin-right: $spacing-half;
  }

  &__level {
    @include grid-item($area: level);
  }

  &__date {
    @include grid-item($area: date);
  }

  &__message {
    @include grid-item($area: message);
  }

  &__info {
    @include grid-item($area: info);
    @include grid(
      $gap: 0,
      $cols: auto 1fr,
      $rows: auto auto,
      $areas: 'label-browser value-browser' 'label-ip value-ip'
    );

    &--label-browser {
      @include grid-item($area: label-browser);
    }

    &--value-browser {
      @include grid-item($area: value-browser);
    }

    &--label-ip {
      @include grid-item($area: label-ip);
    }

    &--value-ip {
      @include grid-item($area: value-ip);
    }
  }
}
</style>
