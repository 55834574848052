<template>
  <div :class="$attrs.class">
    <div class="subscription-item-container">
      <div v-if="selectable" class="subscription-item__check">
        <BaseCheckbox
          :modelValue="item.selected"
          @update:modelValue="$emit('selectionChanged', $event)"
        />
      </div>
      <div class="subscription-item" @click="edit">
        <div
          class="subscription-item__image"
          :class="`subscription-item__image--${imageStyle}`"
        >
          <component v-if="item.subType" :is="mediaIcon" />
        </div>
        <div class="subscription-item__name">
          {{ item.name }}
        </div>
        <div class="subscription-item__next">
          <Chevron size="xsmall" direction="right" />
        </div>
      </div>
    </div>

    <component
      v-if="activeDialogue === 'edit'"
      :is="editComponent"
      :asset="item.asset"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
    />
  </div>
</template>

<script>
import BoardSubscriptionDialogue from '../board/BoardSubscriptionDialogue';
import FolderSubscriptionDialogue from '../folder/FolderSubscriptionDialogue';
import CardSubscriptionDialogue from '../card/CardSubscriptionDialogue';
import MediaSubscriptionDialogue from '../media/MediaSubscriptionDialogue';
import AudioMedia from '../icons/AudioMedia';
import FileMedia from '../icons/FileMedia';
import LinkMedia from '../icons/LinkMedia';
import PhotoMedia from '../icons/PhotoMedia';
import TextMedia from '../icons/TextMedia';
import VideoMedia from '../icons/VideoMedia';
import Chevron from '../icons/Chevron';
import toggleState from '../mixins/toggleState';

const icons = {
  audio: AudioMedia,
  file: FileMedia,
  link: LinkMedia,
  photo: PhotoMedia,
  text: TextMedia,
  video: VideoMedia
};

const subscriptionComponents = {
  board: BoardSubscriptionDialogue,
  folder: FolderSubscriptionDialogue,
  card: CardSubscriptionDialogue,
  media: MediaSubscriptionDialogue
};

export default {
  inheritAttrs: false,
  name: 'SubscriptionItem',
  mixins: [toggleState('dialogue')],
  components: {
    Chevron
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editComponent() {
      return subscriptionComponents[this.item.type];
    },
    imageStyle() {
      let style = this.item.type;
      if (this.item.asset.brief) style += '-brief';
      return style;
    },
    mediaIcon() {
      return icons[this.item.subType];
    }
  },
  methods: {
    edit() {
      this.toggleDialogue('edit');
    }
  }
};
</script>

<style lang="scss">
.subscription-item-container {
  @include grid($gap: 0, $cols: auto 1fr, $areas: 'check item');
  color: $font-dark;
  background-color: #ffffff;
  font-family: $roboto-condensed;

  .subscription-item__check {
    @include grid-item(check);
    @include flexy($align: center);
    margin: $spacing-base 0 $spacing-base $spacing-base;
    border-radius: $base-border-radius;
  }

  .subscription-item {
    @include grid-item(item);
    @include grid($gap: 0, $cols: auto 1fr auto, $areas: 'image name next');
    margin-left: $spacing-base;
    cursor: pointer;

    &__image {
      @include grid-item(image);
      @include flexy($just: center, $align: center);
      margin: $spacing-base $spacing-base $spacing-base 0;
      height: $spacing-base * 1.5;
      width: $spacing-base * 1.5;
      min-width: $spacing-base * 1.5;

      &--board {
        @include svg-background($background-board-medium-svg, $board-base);
      }

      &--folder {
        @include svg-background($background-folder-large-svg, $folder-base);
      }

      &--card {
        @include svg-background($background-card-large-svg, $card-base);
      }

      &--card-brief {
        @include svg-background($background-card-brief-medium-svg, $brief-base);
      }

      &--subscription {
        @include svg-background(
          $background-notification-medium-svg,
          $notification-base
        );
      }
    }

    &__name {
      @include grid-item(name);
      @include flexy($align: center);
    }

    &__next {
      @include grid-item(next);
      @include flexy($align: center);
      padding: $spacing-half;
    }
  }
}
</style>
