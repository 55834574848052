<template>
  <MenuItem
    class="search-menu-item"
    :class="$attrs.class"
    :displayIndicator="false"
  >
    <SearchField
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      @clear="$emit('clear', $event)"
      @search="$emit('search', $event)"
    />
  </MenuItem>
</template>

<script>
import MenuItem from '../menus/MenuItem';
import SearchField from '../forms/SearchField';

export default {
  inheritAttrs: false,
  name: 'SearchMenuItem',
  components: {
    MenuItem,
    SearchField
  },
  props: {
    modelValue: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.search-menu-item {
  background-color: $background-dark;
  padding-left: $spacing-half;
}
</style>
