import { createI18n } from 'vue-i18n';
import pinia from '@/stores/pinia';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import en from './en.json';
import fr from './fr.json';

const messages = { fr, en };

const authStore = useAuthStore(pinia);
const language = authStore.language;

// Attempt to pick a default language from the browser, if any
const browserLanguage = (navigator.languages || [])
  .map((locale) => locale.substr(0, 2).toLowerCase())
  .filter((locale) => messages[locale])[0];

// Attempt to determine browser timezone
let timeZone;
if (typeof Intl !== 'undefined') {
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
      timeZone
    }
  },
  fr: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
      timeZone
    }
  }
};

const numberFormats = {
  en: {
    decimal: {
      maximumFractionDigits: 2
    }
  },
  fr: {
    decimal: {
      maximumFractionDigits: 2
    }
  }
};

const i18n = createI18n({
  locale: language || browserLanguage || process.env.DEFAULT_LOCALE,
  messages,
  fallbackLocale: 'en',
  dateTimeFormats,
  numberFormats,
  legacy: false
});

const updateHelpDeskLanguage = () => {
  if (process.env.ACTIVATE_CHAT_APP) {
    window.zE('webWidget', 'setLocale', i18n.global.locale);
  }
};

const { language: languageRef } = storeToRefs(authStore);
watch(languageRef, (value) => {
  if (value) {
    i18n.global.locale.value = value;
    updateHelpDeskLanguage();
  }
});

updateHelpDeskLanguage();

export default i18n;
