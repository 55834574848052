import { identifyUser } from './tracking';

export const userInitials = ({ firstName = '', lastName = '' }) => {
  return (
    firstName.substr(0, 1).toUpperCase() + lastName.substr(0, 1).toUpperCase()
  );
};

export const userFullname = ({ firstName = '', lastName = '' }) => {
  return `${firstName} ${lastName}`;
};

export const refreshExternalAppUserInfo = async (info) => {
  let identify = null;
  let properties = null;

  if (info) {
    const {
      id,
      email,
      firstName,
      lastName,
      role: { name: role },
      company
    } = info;

    identify = `user-${id}`;
    properties = {
      firstName,
      lastName,
      email,
      role,
      company_id: company.id,
      company_name: company.name,
      company_description: company.description
    };
  }

  try {
    await identifyUser(identify, properties);
  } catch (e) {
    console.error('refreshExternalAppUserInfo', e);
  }
};
