<template>
  <div class="notification-menu" :class="$attrs.class">
    <div class="notification-menu__button">
      <button
        class="icon-button menu-button"
        @click="toggle"
        :class="{ 'notification-menu__button--active': isActive }"
      >
        <NotificationIcon :type="isActive ? 'active' : 'dark'" />
      </button>
      <div
        v-if="counter"
        class="notification-menu__button--count"
        @click="toggle"
      >
        {{ counter }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import authMixins from '../mixins/auth';
import NotificationIcon from '../icons/NotificationIcon';
import { NOTIFICATION_TYPE, SUBSCRIPTION_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'NotificationMenu',
  mixins: [authMixins],
  components: {
    NotificationIcon
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar']),
    isActive() {
      return (
        this.activeLeftSideBar.type === NOTIFICATION_TYPE ||
        this.activeLeftSideBar.type === SUBSCRIPTION_TYPE
      );
    },
    counter() {
      const count = this.currentUser.unreadNotificationCount;
      if (!count || count < 0) return '';
      return count < 100 ? count : '99+';
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar']),
    toggle() {
      if (this.activeLeftSideBar.type === NOTIFICATION_TYPE) {
        this.setActiveLeftSideBar();
      } else {
        this.setActiveLeftSideBar({ type: NOTIFICATION_TYPE });
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.notification-menu {
  height: 100%;

  &__button {
    position: relative;
    height: 100%;

    &--active {
      @include svg-active-background($background-triangle-up-svg);
      background-position: bottom;
    }

    > .menu-button {
      height: 100%;
    }

    &--count {
      @include flexy($just: center, $align: center);
      position: absolute;
      top: 5px;
      right: -5px;
      border-radius: 15px;
      height: 15px;
      min-width: 15px;
      background-color: $counter-background;
      color: $font-light;
      font-size: 11px;
      font-weight: bold;
      padding: 0 calc($spacing-quarter / 2);
      cursor: pointer;
    }
  }
}
</style>
