import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

import {
  get as apiGet,
  getAll as apiGetAll,
  create as apiCreate,
  update as apiUpdate,
  createUploadPicture as apiCreateUploadPicture,
  mfaDeactivate as apiMfaDeactivate
} from '@/api/endpoints/account';
import { getRoles as apiGetRoles } from '@/api/endpoints/auth';
import {
  get as apiGetCompany,
  getAllAccounts as apiGetCompanyAllAccounts,
  createAccount as apiCreateCompanyAccount,
  updateAccount as apiUpdateCompanyAccount,
  accountMfaDeactivate as apiCompanyAccountMfaDeactivate
} from '@/api/endpoints/company';
import { exists as apiEmailExists } from '@/api/endpoints/email';
import { isUserAdministrator } from '@/utils/role';
import { ACCOUNT_TYPE } from '@/utils/types';

const getDefaultFilter = () => {
  return {
    keys: {},
    role: null,
    displayInactiveAccounts: false,
    hasFilters: false
  };
};

const initialState = {
  loading: 0,
  accounts: [],
  hasNextPage: false,
  filter: getDefaultFilter(),
  roles: [],
  searchText: null,
  company: null
};

export const key = ACCOUNT_TYPE;
export const useAccountStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, { ...initialState });
      this.loading = loading;
      this.filter = getDefaultFilter();
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async updateItem(item) {
      this.accounts = this.accounts.map((x) => (x.id !== item.id ? x : item));
    },
    async refresh({ id }) {
      const account = await apiGet(id);
      if (!account) return;

      const authStore = useAuthStore();
      if (!authStore.isAccountAdministrator && isUserAdministrator(account)) {
        await this.itemDeleted(account);
      } else {
        await this.addItem(account);
      }

      return account;
    },
    async create({ file, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        if (file) {
          data.picture = {
            processing: true,
            size: file.size,
            name: file.name
          };
        }

        let account;
        if (this.company) {
          account = await apiCreateCompanyAccount(this.company.id, data);
        } else {
          account = await apiCreate(data);
        }

        if (file) {
          await apiCreateUploadPicture(data.token, file);
        }

        return account;
      } finally {
        await appStore.stopLoading();
      }
    },
    async update({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        let newItem;
        if (this.company) {
          newItem = await apiUpdateCompanyAccount(this.company.id, id, data);
        } else {
          newItem = await apiUpdate(id, data);
        }

        await this.updateItem(newItem);
        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async get(options = {}) {
      const { filter, accounts, searchText, company } = this;
      let { start = accounts.length, limit, text } = options;

      if (text === undefined) text = searchText;

      const apiSort = {
        searchFullName: 'asc'
      };

      await this.startLoading();

      try {
        let result;

        if (company) {
          result = await apiGetCompanyAllAccounts(company.id, {
            role: filter.role,
            displayInactiveAccounts: filter.displayInactiveAccounts,
            query: { start, sort: apiSort, limit },
            text: text || ''
          });
        } else {
          result = await apiGetAll({
            role: filter.role,
            displayInactiveAccounts: filter.displayInactiveAccounts,
            query: { start, sort: apiSort, limit },
            text: text || ''
          });
        }

        const { items, hasNextPage, hasPrevPage } = result;

        this.accounts = start === 0 ? items : [...accounts, ...items];
        this.hasNextPage = hasNextPage;
        this.searchText = searchText;
        return { items, hasNextPage, hasPrevPage };
      } finally {
        await this.stopLoading();
      }
    },
    async setFilter(newFilter) {
      const { filter } = this;

      if (filter.keys[newFilter.key]) {
        if (newFilter.role) {
          filter.role = null;
        } else if (newFilter.key === 'displayInactiveAccounts') {
          filter.displayInactiveAccounts = false;
        }

        delete filter.keys[newFilter.key];
      } else {
        filter.keys[newFilter.key] = newFilter.key;

        if (newFilter.role) {
          if (filter.role) {
            delete filter.keys[filter.role];
          }

          filter.role = newFilter.key;
        } else if (newFilter.key === 'displayInactiveAccounts') {
          filter.displayInactiveAccounts = true;
        }
      }

      this.filter = {
        ...filter,
        hasFilters: filter.displayInactiveAccounts || !!filter.role
      };

      await this.get({ start: 0 });
    },
    async search({ text }) {
      await this.get({ start: 0, text });
    },
    async clearSearch() {
      await this.get({ start: 0, text: '' });
    },
    async clearFilters() {
      this.filter = getDefaultFilter();
      await this.get({ start: 0, text: '' });
    },
    async getRoles() {
      if (this.roles.length > 0) {
        return this.roles;
      }

      this.roles = await apiGetRoles();
      return this.roles;
    },
    async getCompany(id) {
      await this.startLoading();

      try {
        if (id) {
          this.company = await apiGetCompany(id);
        } else {
          this.company = null;
        }

        return this.company;
      } finally {
        await this.stopLoading();
      }
    },
    async emailExists(email) {
      const s = (email || '').toString().trim();
      if (!s) return false;
      return await apiEmailExists(s);
    },
    async mfaDeactivate(id) {
      let newItem;
      if (this.company) {
        newItem = await apiCompanyAccountMfaDeactivate(this.company.id, id);
      } else {
        newItem = await apiMfaDeactivate(id);
      }

      await this.updateItem(newItem);
      return newItem;
    },
    async addItem(account) {
      const { accounts } = this;
      if (!accounts.some((a) => a.id === account.id)) return;
      this.accounts = accounts.map((a) =>
        a.id !== account.id ? a : { ...account }
      );
    },
    async itemDeleted({ id }) {
      const { accounts } = this;
      if (!accounts.some((a) => a.id === id)) return;
      this.accounts = accounts.filter((a) => a.id !== id);
    }
  }
});

export default {
  key,
  use: useAccountStore
};
