import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'UnlockedIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 21.022"
      >
        <title>{{ text }}</title>
        <g transform="translate(0 0)">
          <rect width="15" height="14" rx="2" transform="translate(0 7.022)" />
          <path
            d="M30.752,2.693H28.643a.445.445,0,0,1-.439-.44V-4.582a.446.446,0,0,1,.439-.44h4.349a.447.447,0,0,1,.44.44V-2.34h2V-4.582a2.443,2.443,0,0,0-2.44-2.44H28.643A2.443,2.443,0,0,0,26.2-4.582V2.253a2.443,2.443,0,0,0,2.439,2.44h2.109Z"
            transform="translate(-23.244 7.022)"
          />
        </g>
      </svg>
    );
  }
};
