<template>
  <ul class="slide-menu" :class="[...classes, $attrs.class]" :style="style">
    <slot></slot>
  </ul>
</template>

<script>
const ANCHOR_VALUES = ['left', 'right'];
const SLIDE_VALUES = ['down', 'down-2', 'up', 'up-2'];

export default {
  inheritAttrs: false,
  name: 'SlideMenu',
  props: {
    slide: {
      type: String,
      default: 'down',
      validator: (value) => SLIDE_VALUES.includes(value)
    },
    anchor: {
      type: String,
      default: 'left',
      validator: (value) => ANCHOR_VALUES.includes(value)
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: null
    },
    top: {
      type: Number,
      default: null
    },
    right: {
      type: Number,
      default: null
    },
    bottom: {
      type: Number,
      default: null
    },
    left: {
      type: Number,
      default: null
    },
    maxWidth: {
      type: Number,
      default: null
    },
    floating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parentComponent: null
    };
  },
  computed: {
    classes() {
      const classes = [
        `slide-menu--${this.slide}`,
        `slide-menu--${this.anchor}`,
        { 'slide-menu--auto-width': this.autoWidth },
        { 'slide-menu--full-width': this.fullWidth },
        { 'slide-menu--floating': this.floating }
      ];

      const floatingAnchor = {};
      floatingAnchor[`slide-menu--floating--${this.anchor}`] = this.floating;

      classes.push(floatingAnchor);

      return classes;
    },
    style() {
      const hasTop = this.top || this.top === 0;
      const hasRight = this.right || this.right === 0;
      const hasBottom = this.bottom || this.bottom === 0;
      const hasLeft = this.left || this.left === 0;

      return {
        zIndex: this.zIndex,
        top: hasTop ? `${this.top}px` : null,
        right: hasRight ? `${this.right}px` : null,
        bottom: hasBottom ? `${this.bottom}px` : hasTop ? 'inherit' : null,
        left: hasLeft ? `${this.left}px` : hasRight ? 'inherit' : null,
        maxWidth: this.maxWidth ? `${this.maxWidth}px` : null,
        maxHeight: this.maxHeight
      };
    },
    maxHeight() {
      if (!this.top || !this.parentComponent) return null;
      const rect = this.parentComponent.getBoundingClientRect();
      return `calc(100vh - ${rect.top + this.top + 50}px)`;
    }
  },
  mounted() {
    this.parentComponent = this.$parent.$parent.$el;
  }
};
</script>

<style lang="scss" scoped>
.slide-menu {
  @include slide-transition;
  background-color: $background-light;
  border-color: $border-base;
  border-style: solid;
  border-width: 0 0 $border-width 0;
  font-family: $roboto-condensed;
  position: absolute;
  width: 100%;
  z-index: $menu-index;
  left: 0;
  max-height: calc(100vh - #{$vertical-rhythm * 2});
  overflow-x: auto;

  &--down {
    top: $vertical-rhythm;
  }

  &--down-2 {
    top: $vertical-rhythm * 2;
  }

  &--up {
    bottom: $vertical-rhythm;
  }

  &--up-2 {
    bottom: $vertical-rhythm * 2;
  }

  &--floating {
    border-radius: $base-border-radius;
    left: $spacing-base;
    width: calc(100vw - #{$spacing-base * 2});
    max-width: calc(100vw - #{$spacing-base * 2});
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.36);
    border-style: none;

    > :first-child {
      border-width: 0;
    }
  }

  @include small-plus-mq {
    width: $small-size-break;

    &--auto-width {
      width: auto;
    }

    &--left {
      border-right-width: $border-width;
    }

    &--right {
      border-left-width: $border-width;
      right: 0;
      left: inherit;
    }

    &--full-width {
      width: 100%;
    }

    &--floating--left {
      left: $spacing-base;
      border-right-width: 0;
    }

    &--floating--right {
      border-left-width: 0;
      right: $spacing-base;
    }
  }
}
</style>
