<template>
  <div ref="root" class="content-photo" :class="$attrs.class">
    <Loading v-if="processing" class="content-photo__processing" />
    <div v-else class="content-photo__main">
      <div class="content-photo__top">
        <DblClickElement
          ref="imageContent"
          class="content-photo__top__image"
          @dblclick="togglePhoto"
          @dbltouch="togglePhoto"
        >
          <div
            class="content-photo__top__image--inner"
            @mousedown="mousedown"
            @touchstart="mousedown"
          >
            <img
              class="content-photo__top__image--img"
              :src="media.fileInfos.url"
              :alt="media.name"
              :style="styleDimensions"
              @dragstart.prevent
            />
          </div>
        </DblClickElement>
      </div>
      <div class="content-photo--name">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import DblClickElement from '../extensions/DblClickElement';
import Loading from '../icons/Loading';
import mediaProperties from '../mixins/mediaProperties';
import contentMediaMixin from './ContentMediaMixin';
import fullscreenMixin from '../mixins/fullscreen';

export default {
  inheritAttrs: false,
  name: 'ContentPhoto',
  mixins: [mediaProperties, contentMediaMixin, fullscreenMixin],
  components: {
    DblClickElement,
    Loading
  },
  data() {
    return {
      maximized: false
    };
  },
  computed: {
    title() {
      const { name, link } = this.item;
      return name || link;
    },
    styleDimensions() {
      const { fileInfos } = this.media;
      if (!fileInfos) return null;
      const { width, height } = fileInfos;
      if (!width || !height) return null;

      return `max-width: ${width}px; max-height: ${height}px;`;
    }
  },
  methods: {
    mousedown(e) {
      if (this.maximized) {
        e.stopPropagation();
      }
    },
    async togglePhoto() {
      if (this.processing) return;

      const callback = () => {
        this.maximized = !this.maximized;
        this.$emit('contentMaximized', this.maximized);
      };

      if (this.maximized) {
        this.exitFullscreen();
      } else {
        const { imageContent } = this.$refs;
        if (imageContent) {
          await this.requestFullscreen(imageContent.$el, callback);
          callback();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.content-photo {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;

  &__processing {
    width: $loading-size-base;
    height: $loading-size-base;
  }

  &__main {
    @include grid($rows: 1fr auto, $areas: 'top' 'name');
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &__top {
    @include grid-item($area: top);
    @include flexy($dir: column, $align: center, $just: center);
    overflow: hidden;

    &__image {
      @include flexy($dir: column, $align: center, $just: center);
      height: 100%;
      overflow: hidden;

      &--inner {
        @include flexy($dir: column, $just: center);
        height: 100%;
        overflow: hidden;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        > img {
          height: 100%;
          width: 100%;
          overflow: hidden;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }
  }

  &--name {
    @include grid-item($area: name);
    @include slider-tile-media-name();
  }
}
</style>
