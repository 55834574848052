<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.799 19.799"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-1317.201 -120)">
      <rect
        width="2"
        height="26"
        rx="1"
        transform="translate(1317.201 121.414) rotate(-45)"
      />
      <rect
        width="2"
        height="26"
        rx="1"
        transform="translate(1318.615 139.799) rotate(-135)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'CrossIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
