<template>
  <BaseDialogue
    class="account-settings"
    :class="$attrs.class"
    :title="$t('accountSettings.title')"
    :showCancel="false"
    @ok="cancel"
    @cancel="cancel"
  >
    <BorderField>
      <div class="account-settings__security">
        <div class="account-settings__security__title">
          {{ $t('accountSettings.security') }}
        </div>
        <div class="account-settings__security__mfa--title">
          {{ $t('accountSettings.mfa.title') }}
        </div>

        <div
          v-if="mfaActivated"
          class="account-settings__security__mfa--enabled"
        >
          {{ $t('accountSettings.mfa.enabled') }}
        </div>
        <div
          v-if="mfaActivated"
          class="account-settings__security__mfa--disable"
        >
          <CallToAction
            size="auto"
            @v-click="confirmDisableMfa"
            :disabled="mfaEnforced"
          >
            {{ $t('accountSettings.mfa.disable') }}
          </CallToAction>
          <div
            v-if="mfaEnforced"
            class="account-settings__security__mfa--enforced"
          >
            {{ enforceText }}
          </div>
        </div>

        <div
          v-if="!mfaActivated"
          class="account-settings__security__mfa--disabled"
        >
          {{ $t('accountSettings.mfa.disabled') }}
        </div>
        <div v-if="!mfaActivated" class="account-settings__security__mfa--info">
          {{ $t('accountSettings.mfa.info') }}
        </div>
        <div
          v-if="!mfaActivated"
          class="account-settings__security__mfa--setup"
        >
          <CallToAction size="auto" @v-click="setupMfa">
            {{ $t('accountSettings.mfa.setup') }}
          </CallToAction>
        </div>
        <div v-if="!mfaActivated" class="account-settings__security__mfa--note">
          <span class="account-settings__security__mfa--note--title">{{
            $t('accountSettings.mfa.note.title')
          }}</span>
          <span>{{ $t('accountSettings.mfa.note.text') }}</span>
        </div>
      </div>
    </BorderField>

    <ToastAlert
      v-if="activeToast === 'confirmDisableMfa'"
      prompt
      level="warning"
      @ok="disableMfa"
      @cancel="toggleToast()"
    >
      {{ $t('accountSettings.mfa.confirmDisable') }}
    </ToastAlert>
    <MfaSetupDialogue
      v-else-if="activeDialogue === 'mfa-setup'"
      :nextStep="firstStep"
      :fromLogin="fromLogin"
      @success="setupSucceeded"
      @cancel="toggleDialogue()"
    />
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import BaseDialogue from '../dialogues/BaseDialogue';
import CallToAction from '../forms/CallToAction';
import BorderField from '../forms/BorderField';
import MfaSetupDialogue from '../auth/MfaSetupDialogue';
import ToastAlert from '../toast/ToastAlert';
import authMixins from '../mixins/auth';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'AccountSettings',
  mixins: [toggleState('toast'), toggleState('dialogue'), authMixins],
  components: {
    BaseDialogue,
    BorderField,
    CallToAction,
    MfaSetupDialogue,
    ToastAlert
  },
  props: {
    fromLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mfaActivated() {
      if (!this.currentUser) return false;
      return this.currentUser.mfaActivated;
    },
    mfaEnforced() {
      if (!this.currentUser) return false;
      return this.currentUser.mfaEnforced;
    },
    firstStep() {
      return this.fromLogin ? 'verify/code/email' : 'verify/password';
    },
    enforceText() {
      const role = this.$t(`role.${this.currentUser.role.name}`);
      return this.$t('accountSettings.mfa.enforced', [role]);
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useAuthStore, [
      'mfaSetup',
      'mfaSetupAtLogin',
      'mfaDeactivate'
    ]),
    async setupMfa() {
      if (this.submitted) return;

      try {
        this.submitted = true;
        if (this.fromLogin) {
          await this.mfaSetupAtLogin();
        } else {
          await this.mfaSetup();
        }

        this.toggleDialogue('mfa-setup');
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    },
    confirmDisableMfa() {
      if (this.mfaEnforced) return;

      this.toggleToast('confirmDisableMfa');
    },
    async disableMfa() {
      if (this.mfaEnforced) return;

      this.toggleToast();
      if (this.submitted) return;

      try {
        this.submitted = true;
        await this.mfaDeactivate();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    },
    setupSucceeded() {
      this.toggleDialogue();

      if (this.fromLogin) {
        this.$emit('success');
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss">
.account-settings {
  .dialogue__header,
  .dialogue__footer {
    background-color: $account-management-base;
  }

  &__security {
    margin: 0 $spacing-base;

    &__title {
      text-transform: uppercase;
      font-size: $font-size-base !important;
    }

    &__mfa {
      &--title {
        font-weight: bold;
      }

      &--note--title {
        font-weight: bold;
      }

      &--setup,
      &--disable {
        > .call-to-action {
          background-color: $account-management-base;
        }
      }

      &--enforced {
        font-size: $font-size-small;
        font-style: italic;
        color: $error-color;
        margin-top: $spacing-half;
      }
    }

    > * {
      margin: $spacing-base 0;
      font-size: $font-size-small;
    }
  }
}
</style>
