<template>
  <BaseDialogue
    class="group-form-dialogue"
    :class="[
      { 'group-form-dialogue__members': step === STEP_MANAGE_MEMBERS },
      $attrs.class
    ]"
    size="small"
    :title="title"
    @cancel="back(true)"
    @ok="ok"
    :showOk="step !== STEP_MANAGE_MEMBERS"
    :showCancel="showCancel"
    :disableOk="!isValid"
    :zIndex="10"
  >
    <template #ok>{{ okButtonText }}</template>

    <transition :name="transitionName" @afterEnter="focus">
      <div
        key="base"
        v-if="step === 'base'"
        class="form-element group-form-dialogue__base"
        @keydown.enter="ok"
        tabindex="0"
      >
        <BorderField>
          <BaseInput
            ref="name"
            class="label-bold"
            :label="$t('groupForm.name')"
            name="name"
            v-model.trim="form.name"
          />

          <BaseCheckbox
            v-if="isAccountAdministrator"
            class="form-field form-field-public"
            name="public"
            :label="$t('groupForm.public')"
            v-model="form.isPublic"
          />
        </BorderField>
        <BorderField v-if="mode === 'edit'">
          <SliderFieldset
            class="label-bold"
            :label="$t('groupForm.members.label')"
            :active="item.memberCount > 0"
            @v-click="displayMembers"
          />
        </BorderField>
      </div>

      <GroupMembersSection
        ref="members"
        :key="STEP_MANAGE_MEMBERS"
        v-else-if="step === STEP_MANAGE_MEMBERS"
        @cancel="back(true)"
        @ok="ok"
        @countChanged="memberCountChanged"
        :group="item"
        :showPrevious="!manageMembers"
      />
    </transition>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useGroupStore } from '@/stores/group';
import '../../assets/stylesheets/components/_form.scss';
import BaseDialogue from '../dialogues/BaseDialogue';
import GroupMembersSection from './GroupMembersSection';
import SliderFieldset from '../forms/SliderFieldset';
import BorderField from '../forms/BorderField';
import authMixins from '../mixins/auth';

const STEP_BASE = 'base';
const STEP_MANAGE_MEMBERS = 'manage-members';

export default {
  inheritAttrs: false,
  name: 'groupFormDialogue',
  mixins: [authMixins],
  components: {
    BaseDialogue,
    GroupMembersSection,
    SliderFieldset,
    BorderField
  },
  props: {
    editItem: {
      type: Object,
      default: () => ({})
    },
    manageMembers: {
      type: Boolean,
      default: false
    },
    from: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      item: { ...this.editItem },
      form: {},
      submitted: false,
      step: this.manageMembers ? STEP_MANAGE_MEMBERS : STEP_BASE,
      transitionName: 'prev',
      isCreationProcess: !this.editItem.id
    };
  },
  computed: {
    STEP_MANAGE_MEMBERS() {
      return STEP_MANAGE_MEMBERS;
    },
    mode() {
      return this.item.id ? 'edit' : 'add';
    },
    isValid() {
      return !!this.form.name.trim();
    },
    title() {
      let title = '';
      switch (this.step) {
        case STEP_BASE:
          title = `groupForm.base.label.${this.mode}`;
          break;
        case STEP_MANAGE_MEMBERS:
          title = 'groupForm.members.label';
          break;
      }
      return this.$t(title);
    },
    okButtonText() {
      if (this.step === STEP_BASE && this.mode === 'add')
        return this.$t('dialogue.footer.next');
      return this.$t('dialogue.footer.ok');
    },
    showCancel() {
      return !this.manageMembers && this.step !== STEP_MANAGE_MEMBERS;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayInfo', 'displayError']),
    ...mapActions(useGroupStore, ['create', 'update', 'syncMembers']),
    init() {
      const { name = '', isPublic = false } = this.item;

      this.form = {
        name,
        isPublic
      };
    },
    async ok() {
      switch (this.step) {
        case STEP_BASE:
          if (this.submitted) return;

          if (!this.isValid) {
            this.focus();
            return;
          }

          this.submitted = true;

          try {
            let group;

            if (this.mode === 'edit') {
              const { name, isPublic } = this.form;
              const data = {
                id: this.item.id,
                name
              };

              if (this.isAccountAdministrator) {
                data.isPublic = !!isPublic;
              }

              group = await this.update(data);
            } else {
              const { name, isPublic } = this.form;
              const data = {
                name,
                isPublic: this.isAccountAdministrator ? !!isPublic : false
              };

              group = await this.create({
                data,
                from: this.from
              });
            }

            if (group.error) {
              // NAME_ALREADY_EXISTS
              this.displayError({
                message: group.code
                  ? this.$t(`groupForm.error.${group.code}`, [this.form.name])
                  : group.error
              });
              return;
            }

            if (this.mode === 'edit') {
              this.displayInfo({
                message: this.$t('groupForm.messages.updatedSuccessfully', [
                  group.name
                ])
              });

              if (this.from) {
                const entity = await this.syncMembers({
                  id: this.item.id,
                  from: this.from
                });
                this.$emit('success', entity);
              } else {
                this.$emit('success', group);
              }
            } else {
              this.item = { ...group };
              this.init();

              this.displayMembers();
            }
          } catch (e) {
            console.error(e);
            this.displayError({ message: this.$t('groupForm.error') });
          } finally {
            this.submitted = false;
          }

          break;
        case STEP_MANAGE_MEMBERS:
          if (this.submitted) return;

          this.submitted = true;

          try {
            if (this.isCreationProcess) {
              this.displayInfo({
                message: this.$t('groupForm.messages.createdSuccessfully', [
                  this.item.name
                ])
              });
            }

            if (this.from) {
              const entity = await this.syncMembers({
                id: this.item.id,
                from: this.from
              });
              this.$emit('success', entity);
            } else if (this.manageMembers || this.isCreationProcess) {
              this.$emit('success', this.item);
            } else {
              this.back();
            }
          } catch (e) {
            console.error(e);
            this.displayError({ message: this.$t('groupForm.error') });
          } finally {
            this.submitted = false;
          }

          break;
      }
    },
    async back(cancel) {
      switch (this.step) {
        case STEP_BASE:
          this.$emit('cancel');
          break;
        case STEP_MANAGE_MEMBERS:
          this.step = STEP_BASE;
          if (cancel) {
            if (this.manageMembers) this.$emit('cancel');
          }
          break;
      }
      this.transitionName = 'prev';
    },
    displayMembers() {
      this.step = STEP_MANAGE_MEMBERS;
    },
    memberCountChanged(count) {
      this.item = { ...this.item, memberCount: count };
    },
    focus() {
      if (this.step === STEP_BASE) {
        const { name } = this.$refs;
        if (name) name.focus();
      }
    }
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.$nextTick(function () {
      this.focus();
    });
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss">
.group-form-dialogue {
  @include next-prev-transition();

  &__members {
    > .dialogue__box {
      min-height: 410px;
      max-height: 100%;
    }
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $group-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }
}

.icon-button__invite {
  height: 100%;
}
</style>
