import { get as httpGet, post as httpPost } from '../requests';
import { getAll as genericGetAll } from '../generic';

export const ENDPOINT_URL = '/evaluation-results';

export const get = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}`);
  return response.json();
};

export const getAll = genericGetAll(ENDPOINT_URL);

export const getCriterias = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/getCriterias`,
    options
  );
  return response.json();
};

export const getStatements = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/getStatements`,
    options
  );
  return response.json();
};

export const getCards = async (id, options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/${id}/getCards`, options);
  return response.json();
};

export const getCardsByCriteria = async (id, criteriaId, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/criteria/${criteriaId}/getCards`,
    options
  );
  return response.json();
};

export const getCardsByStatement = async (id, statementId, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/statement/${statementId}/getCards`,
    options
  );
  return response.json();
};

export const getCardMedias = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/card/${id}/getMedias`,
    options
  );
  return response.json();
};

export const inviteAllEvaluationMembers = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/invite-all-evaluation-members`,
    options
  );
  return response.json();
};

export const getInviteInfo = async (token) => {
  const response = await httpGet(`${ENDPOINT_URL}/invite-info/${token}`);
  return response.json();
};
