import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { ServerError, NetworkError } from '@/errors';
import i18n from '@/i18n/index';

export function asQueryString(params = []) {
  return params
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
}

export class HttpError extends Error {
  constructor(status, message, response) {
    super(message);
    this.name = 'HttpError';
    this.status = status;
    this.response = response;
  }
}

export class BadRequestError extends Error {
  /**
   * @param {string} message
   * @param {string} code
   * @param {object} data
   */
  constructor(message, code, data) {
    super(message);
    this.name = 'BadRequestError';
    this.error = message;
    this.code = code;
    this.data = data;
  }
}

export async function request(url, options = {}) {
  const {
    method = 'GET',
    headers = {},
    body = null,
    redirectToLoginOnUnauthorize = true,
    ...otherOptions
  } = options || {};

  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'x-application-key': 'fassilio-front-key',
    'fassilio-front-key': process.env.APPLICATION_ID
  };

  let response = null;

  try {
    if (url.endsWith('/share')) {
      headers['x-share-token'] = useAuthStore().shareToken;
    }

    // console.log('request', {method, url});
    response = await fetch(`${process.env.API_URL}${url}`, {
      method,
      credentials: 'include',
      headers: {
        ...baseHeaders,
        ...headers
      },
      body: body ? JSON.stringify(body) : null,
      ...otherOptions
    });
  } catch (e) {
    if (e.name === 'TypeError') {
      await useAppStore().displayError({
        message: i18n.global.t('global.networkConnectionError')
      });
      throw new NetworkError(e.message);
    }

    throw e;
  }

  if (response.ok) {
    return response;
  }

  // let error = response.statusText
  let content;
  try {
    content = await response.json();
  } catch {
    //
  }

  // Throw an error on non-ok HTTP response
  if (url === '/share-assets/validate-hash') {
    throw new ServerError(
      response.status,
      content ? content.error : response.statusText,
      content?.code,
      content?.data,
      response
    );
  } else if (response.status === 401) {
    if (url === '/auth' || url === '/auth/logout') {
      throw new ServerError(
        response.status,
        content ? content.error : response.statusText,
        content?.code,
        content?.data,
        response
      );
    } else if (url.endsWith('/share')) {
      useAppStore().setNavigateTo({ name: 'root' });
    } else {
      await useAuthStore().logoutWithoutCallingServer({
        redirectToLogin: redirectToLoginOnUnauthorize
      });
    }
  } else if (response.status === 400 && content) {
    throw new BadRequestError(content.error, content.code, content.data);
  } else {
    throw new HttpError(
      response.status,
      content ? content.error : response.statusText,
      response
    );
  }
}

export async function get(url, params = [], options = {}) {
  const queryString = asQueryString(params || []);
  return request(queryString ? `${url}?${queryString}` : url, options);
}

export async function post(url, data = {}) {
  return request(url, { method: 'POST', body: data });
}

export async function put(url, data = {}) {
  return request(url, { method: 'PUT', body: data });
}

export async function del(url) {
  return request(url, { method: 'DELETE' });
}
