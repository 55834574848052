<template>
  <div class="slider-content" :class="$attrs.class">
    <SliderFieldset
      v-if="displayTitle"
      class="slider-content__fieldset"
      :label="label"
      :class="{ 'label-bold': labelBold }"
      direction="down"
      :expanded="expanded"
      :active="active"
      :displayIndicator="displayIndicator"
      :displayExpand="displayExpand"
      :arrowAlignLeft="arrowAlignLeft"
      @v-click="toggle"
    >
      <slot name="label"></slot>
    </SliderFieldset>
    <transition name="slide">
      <div v-if="true" ref="container" class="slider-content__content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import SliderFieldset from '../forms/SliderFieldset';

export default {
  inheritAttrs: false,
  name: 'SliderContent',
  components: { SliderFieldset },
  props: {
    label: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    labelBold: {
      type: Boolean,
      default: false
    },
    displayIndicator: {
      type: Boolean,
      default: true
    },
    displayExpand: {
      type: Boolean,
      default: true
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    arrowAlignLeft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpanded: this.modelValue,
      timeoutHandle: null
    };
  },
  computed: {
    expanded() {
      return this.isExpanded;
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    close() {
      if (!this.isExpanded) return;
      this.toggle();
    },
    toggleStyle() {
      const { container } = this.$refs;

      if (this.timeoutHandle) {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = null;
      }

      container.style.maxHeight = `${container.scrollHeight}px`;

      if (this.isExpanded) {
        this.timeoutHandle = setTimeout(() => {
          const { container } = this.$refs;
          container.style.maxHeight = 'fit-content';
          if (container.style.maxHeight !== 'fit-content') {
            container.style.maxHeight = '-moz-fit-content';
          }
          container.style.overflow = 'visible';

          this.timeoutHandle = null;
        }, 300);
      } else {
        this.timeoutHandle = setTimeout(() => {
          const { container } = this.$refs;
          container.style.overflow = 'hidden';
          container.style.maxHeight = '';
          this.timeoutHandle = null;
        }, 1);
      }
    }
  },
  mounted() {
    if (this.expanded) {
      const { container } = this.$refs;
      container.style.maxHeight = 'fit-content';
      if (container.style.maxHeight !== 'fit-content') {
        container.style.maxHeight = '-moz-fit-content';
      }
    }
  },
  watch: {
    modelValue() {
      if (this.isExpanded !== this.modelValue)
        this.isExpanded = this.modelValue;
    },
    isExpanded(nv, ov) {
      if (nv === ov) return;

      this.toggleStyle();
      this.$emit('update:modelValue', this.isExpanded);
    }
  }
};
</script>

<style lang="scss" scoped>
.slider-content {
  &__fieldset {
    width: 100%;
    height: 100%;
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in;
  }
}
</style>
