import { defineStore } from 'pinia';
import { mapStores } from '@/stores';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

import { isItemProtected, isItemAuthorBoard } from '@/utils/asset';
import i18n from '@/i18n';
import { BOARD_TYPE, FOLDER_TYPE, CARD_TYPE } from '@/utils/types';

const initialState = {
  activated: false,
  dragItem: null
};

export const key = 'drag-and-drop';
export const useDragAndDropStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async setActivated(activated) {
      this.activated = !!activated;
    },
    async setDragItem(dragItem) {
      this.dragItem = dragItem;
    },
    async move(destinationItem) {
      try {
        const { dragItem } = this;
        if (!dragItem || !destinationItem) return;

        const [from, to] = await Promise.all([
          mapStores.get(dragItem.type).use().fetch(dragItem),
          mapStores.get(destinationItem.type).use().fetch(destinationItem)
        ]);

        const authStore = useAuthStore();
        const { currentUser } = authStore;
        if (from.type === CARD_TYPE) {
          if (to.type !== FOLDER_TYPE && to.type !== BOARD_TYPE) return;
        } else if (from.type === FOLDER_TYPE) {
          if (to.type !== FOLDER_TYPE && to.type !== BOARD_TYPE) return;
        } else if (from.type === BOARD_TYPE) {
          if (from.sansBox || to.sansBox) return;
          if (to.type !== BOARD_TYPE) return;
          if (to.author.id !== currentUser.id) return;
        } else return;

        if (isItemProtected(from) && !isItemAuthorBoard(from, currentUser.id))
          return;
        else if (isItemProtected(to) && !isItemAuthorBoard(to, currentUser.id))
          return;

        const item = { id: from.id, type: from.type };

        const store = mapStores.get(to.type).use();
        const messages = await store.validateCopy({
          id: to.id,
          data: { action: 'move', items: [item] }
        });
        const message = messages.filter((x) => x.error)[0];
        if (message) {
          await useAppStore().displayError({
            message: i18n.global.t(`clipboard.errors.${message.code}`)
          });
          return false;
        }

        await store.moveTo({ id: to.id, item });
      } catch (e) {
        console.error('ERROR Drag & Drop', e);
        await useAppStore().displayError({
          message: i18n.global.t('global.error')
        });
      }
    }
  }
});

export default {
  key,
  use: useDragAndDropStore
};
