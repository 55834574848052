import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'NotificationIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18.947 22.282"
      >
        <title>{{ text }}</title>
        <path
          d="M260.729,214.413h-.736v-7.1a7.253,7.253,0,0,0-5.667-7.064v-.7a1.583,1.583,0,1,0-3.166,0v.7a7.253,7.253,0,0,0-5.667,7.064v7.1h-.736a1.487,1.487,0,0,0,0,2.974h15.973a1.487,1.487,0,1,0,0-2.974Zm-2.967,0H247.723v-7.1a5.025,5.025,0,0,1,5.017-5.019h0a5.025,5.025,0,0,1,5.019,5.019Z"
          transform="translate(-243.269 -197.97)"
        />
        <path
          d="M250.351,213.666a2.174,2.174,0,0,0,2.176-2.174h-4.35A2.174,2.174,0,0,0,250.351,213.666Z"
          transform="translate(-240.878 -191.384)"
        />
      </svg>
    );
  }
};
