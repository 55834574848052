<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95.2 110.4"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M47.6,0C20.5,0,0,9.4,0,21.8v66.9c0,12.4,20.5,21.8,47.6,21.8s47.6-9.4,47.6-21.8V21.8C95.2,9.4,74.7,0,47.6,0zM47.6,6c24.5,0,41.6,8.3,41.6,15.8S72.1,37.6,47.6,37.6C23.1,37.6,6,29.2,6,21.8S23.1,6,47.6,6z M6,53c7.4,6.8,23,11.4,41.6,11.4c18.6,0,34.2-4.6,41.6-11.4v17.8c-5,7.3-22,12.9-41.6,12.9C28,83.7,11,78.1,6,70.8V53z M47.6,61.4C28,61.4,11,55.8,6,48.5V32.7c8,6.6,23.3,10.9,41.6,10.9c18.3,0,33.6-4.3,41.6-10.9v15.8C84.2,55.8,67.1,61.4,47.6,61.4z M47.6,104.4C23.1,104.4,6,96.1,6,88.7V75.3c7.4,6.8,23,11.4,41.6,11.4c18.6,0,34.2-4.6,41.6-11.4v13.4C89.2,96.1,72.1,104.4,47.6,104.4z"
    />
    <circle cx="81.8" cy="45.7" r="3.3" />
    <circle cx="81.8" cy="67.1" r="3.3" />
    <circle cx="81.8" cy="88.5" r="3.3" />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'DatabaseIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
