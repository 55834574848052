<template>
  <li
    v-if="hasSubMenu && !disabled"
    class="menu-item menu-item--sub-menu"
    :class="[
      { 'menu-item--first': first },
      { 'menu-item--full-width': fullWidth },
      $attrs.class
    ]"
    @click="$emit('selected', $event)"
  >
    <div
      v-if="displayIndicator"
      class="menu-item__indicator"
      :class="{ 'menu-item__indicator--active': active }"
      @click.stop="toggleSubMenu"
    >
      &nbsp;
    </div>
    <div class="menu-item__label" @click.stop="toggleSubMenu">
      <div class="menu-item__label--inner">
        <slot name="label">
          <slot name="icon"></slot>
          <slot
            ><span>{{ label }}</span></slot
          >
        </slot>
      </div>
    </div>
    <div class="menu-item__expand" @click.stop="toggleSubMenu">
      <Chevron
        size="xsmall"
        :direction="open ? 'up' : 'down'"
        :title="open ? 'menu.icon.close' : 'menu.icon.open'"
      />
    </div>

    <transition name="slide">
      <ul class="menu-item__sub-menu" v-if="open">
        <slot name="submenu"></slot>
      </ul>
    </transition>
  </li>

  <li
    v-else
    class="menu-item"
    :class="[
      { 'menu-item--first': first },
      { 'menu-item--disabled': disabled },
      { 'menu-item--none-selectable': !selectable },
      { 'menu-item--full-width': fullWidth },
      $attrs.class
    ]"
    @click="clicked"
  >
    <div
      v-if="displayIndicator"
      class="menu-item__indicator"
      :class="{ 'menu-item__indicator--active': active }"
    >
      &nbsp;
    </div>

    <div
      class="menu-item__label"
      :class="{ 'menu-item__label--no-indicator': !displayIndicator }"
    >
      <slot name="label">
        <div class="menu-item__label--inner">
          <slot name="icon"></slot>
          <slot
            ><span>{{ label }}</span></slot
          >
        </div>
        <span v-if="displayNextButton" class="menu-item__label--next">
          <Chevron size="xsmall" direction="right" title="form.icon.open" />
        </span>
      </slot>
    </div>
    <div v-if="inline" class="menu-item__inline">
      <slot name="inline"></slot>
    </div>
  </li>
</template>

<script>
import Chevron from '../icons/Chevron';

export default {
  inheritAttrs: false,
  name: 'MenuItem',
  components: { Chevron },
  props: {
    label: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    displayIndicator: {
      type: Boolean,
      default: true
    },
    displayNextButton: {
      type: Boolean,
      default: false
    },
    first: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    open: false
  }),
  computed: {
    hasSubMenu() {
      return Boolean(this.$slots.submenu);
    }
  },
  methods: {
    clicked($event) {
      if (this.disabled || !this.selectable) {
        $event.stopPropagation();
        return;
      }

      this.$emit('selected', $event);

      if (!this.closeOnClick) {
        $event.stopPropagation();
      }
    },
    toggleSubMenu() {
      if (this.disabled) return;

      this.open = !this.open;
    }
  }
};
</script>

<style lang="scss" scoped>
$expand-icon-size: map_get($icon-sizes, 'xsmall') + $spacing-base;

.menu-item {
  @include grid(
    $cols: auto 1fr,
    $rows: minmax($vertical-rhythm, auto) auto,
    $areas: 'indicator label' 'indicator inline',
    $align: center
  );

  background-color: $background-light;
  color: $font-dark;
  font-size: $font-size-medium;
  font-weight: normal;
  font-style: normal;
  border-color: $border-menu;
  border-style: solid;
  border-width: $border-width 0 0 0;
  width: 100%;
  cursor: pointer;
  min-height: $vertical-rhythm;

  &--first {
    border-style: none;
    border-width: 0;
  }

  &--sub-menu {
    @include grid($cols: $spacing-half auto $expand-icon-size);
    grid-template-areas:
      'indicator label expand'
      'submenu submenu submenu';
  }

  &--none-selectable {
    cursor: default;
  }

  &--disabled {
    cursor: default;
    opacity: $disable-opacity;
  }

  &--full-width {
    display: block;
  }
}

.menu-item__indicator {
  @include grid-item(indicator);
  width: $spacing-half;
  place-self: stretch;
  margin-right: $spacing-half;

  &--active {
    background-color: map_get($icon-colors, 'active');
    border: 1px solid map_get($icon-colors, 'active');
  }
}

.menu-item__label {
  @include grid-item(label, $align: center);
  @include flexy($align: center, $just: space-between);
  height: 100%;

  &--no-indicator {
    margin-left: $spacing-base;
  }

  &--inner {
    @include flexy($align: center);
    height: 100%;

    :deep() > * {
      margin-right: $spacing-small;
    }
  }

  &--next {
    margin-right: $spacing-small;
  }
}

.menu-item__inline {
  @include grid-item(inline);
}

.menu-item__expand {
  @include grid-item(expand);
  @include flexy($align: center);
  height: 100%;
  margin-left: $spacing-half;
}

.menu-item__sub-menu {
  @include grid-item(submenu);
  @include slide-transition;
  width: 100%;

  .menu-item {
    background-color: $background-dark;
  }
}
</style>
