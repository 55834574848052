<template>
  <MediaFormDialogue
    class="form-dialogue-note"
    :class="$attrs.class"
    :title="$t(`mediaForm.note.label.${mode}`)"
    @cancel="$emit('cancel')"
    @ok="ok()"
    :disableOk="!isValid"
    v-model:error="error"
  >
    <div class="form-dialogue-note__content">
      <FormFieldMemo ref="content" name="content" v-model="form.content" />
    </div>
  </MediaFormDialogue>
</template>

<script>
import MediaFormDialogue from './MediaFormDialogue';
import FormFieldMemo from '../forms/FormFieldMemo';
import mediaForm from '../mixins/mediaForm';

export default {
  inheritAttrs: false,
  name: 'FormDialogueNote',
  mixins: [mediaForm],
  components: {
    MediaFormDialogue,
    FormFieldMemo
  },
  data() {
    const { content = '' } = this.editItem;

    return {
      form: {
        content,
        mediaType: 'note'
      },
      submitted: false,
      error: ''
    };
  },
  computed: {
    isValid() {
      return !!(this.form.content || '').trim();
    }
  },
  methods: {
    async ok() {
      if (!(this.form.content || '').trim()) {
        this.select('content');
        return;
      }

      if (!this.submitted) {
        this.submitted = true;

        try {
          const media = await this.modify();
          this.$emit('success', media);
        } catch (e) {
          console.error(e);
          this.error = this.$t('mediaForm.error');
        } finally {
          this.submitted = false;
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focus('content');
    });
  }
};
</script>

<style lang="scss">
.form-dialogue-note {
  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__main {
    overflow: hidden;
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $media-note;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    padding: $spacing-base;

    > * {
      flex: 1;
    }

    .form-field-memo__text-area {
      font-size: 30px;
    }
  }
}
</style>
