import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useFolderStore } from '@/stores/folder';
import assetMixin from '../mixins/asset';
import { toggleHiddenMixin } from '../mixins/assetOptionsToggleHidden';
import { toggleDeletedMixin } from '../mixins/assetOptionsToggleDeleted';
import commentMixin from '../mixins/comment';
import { FOLDER_TYPE } from '../../utils/types';

export default {
  mixins: [
    assetMixin,
    toggleHiddenMixin(FOLDER_TYPE),
    toggleDeletedMixin(FOLDER_TYPE),
    commentMixin
  ],
  computed: {
    canEdit() {
      if (this.isReadOnly || this.isDeleted) return false;
      if (!this.isProtected) return true;
      return this.isAuthorBoard;
    },
    canManageBackground() {
      return this.canEdit;
    },
    canAddComment() {
      return (
        !this.isReadOnly &&
        !this.isDeleted &&
        !this.isSandBox &&
        !this.fromSearch
      );
    },
    canShare() {
      if (!this.isParticipant) return false;

      if (this.isDeleted || this.isReadOnly) return false;
      if (this.sharingAllowed) return true;
      return this.isAuthorBoard;
    },
    canManageSubscription() {
      if (this.isReadOnly || this.isDeleted) return false;
      return !this.isSandBox && !this.fromSearch;
    },
    canDeletePermanently() {
      return this.isDeleted && this.isAuthorBoard;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useFolderStore, ['deletePermanently']),
    addItemToClipboard(action) {
      this.addToClipboard({ item: this.item, action });
      this.$emit('cancel');
    },
    _displayComments() {
      this.showComments();
      this.$emit('cancel');
    },
    async _deletePermanently() {
      try {
        this.toggleToast();
        await this.deletePermanently(this.item);
        this.$emit('cancel');
      } catch (e) {
        console.error('folderMixin => _deletePermanently', e);
        this.displayError({ message: this.$t('global.error') });
      }
    }
  }
};
