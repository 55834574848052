<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.315 18.98"
  >
    <title v-if="text">{{ text }}</title>
    <defs>
      <clipPath id="clip-path">
        <rect width="21.315" height="18.98" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0.001)" clip-path="url(#clip-path)">
        <path
          d="M19.962,0H1.353A1.461,1.461,0,0,0,0,1.549V17.431a1.461,1.461,0,0,0,1.353,1.55H19.962a1.461,1.461,0,0,0,1.353-1.55V1.549A1.461,1.461,0,0,0,19.962,0m0,1.549V12.818l-2.667-2.776a.925.925,0,0,0-1.4.038l-2.7,3.09L7.873,5.892A.935.935,0,0,0,6.325,5.88L1.353,12.495V1.549ZM14.21,5.617a2.009,2.009,0,0,1,1.861-2.13,2.009,2.009,0,0,1,1.86,2.13,2.009,2.009,0,0,1-1.86,2.13,2.009,2.009,0,0,1-1.861-2.13"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'ImageIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
