<template>
  <div class="evaluation-menu" :class="$attrs.class">
    <div class="evaluation-menu__button">
      <button
        class="icon-button menu-button"
        @click="toggle"
        :class="{ 'evaluation-menu__button--active': menuOpen }"
      >
        <EvaluationIcon :type="menuOpen ? 'active' : 'dark'" />
      </button>
      <div
        v-if="counter"
        class="evaluation-menu__button--count"
        @click="toggle"
      >
        {{ counter }}
      </div>
    </div>

    <transition name="slide">
      <SlideMenu v-if="menuOpen" slide="down" anchor="right">
        <MenuItem
          class="evaluation-menu__split-item evaluation-menu__to-evaluate"
          @selected="displayEvaluationsToEvaluate()"
          :displayNextButton="true"
        >
          <div class="evaluation-menu__to-evaluate__inner">
            <span>{{ $t('evaluationMenu.toEvaluate') }}</span>
            <div
              v-if="counter"
              class="evaluation-menu__to-evaluate__inner--count"
            >
              {{ toEvaluateCounter }}
            </div>
          </div>
        </MenuItem>
        <MenuItem
          v-if="isAdministrator"
          :label="$t('evaluationMenu.createEvaluationByCriteria')"
          @selected="createEvaluationByCriteria()"
          :displayNextButton="true"
        />
        <MenuItem
          v-if="isAdministrator"
          class="evaluation-menu__split-item"
          :label="$t('evaluationMenu.createEvaluationWithSurvey')"
          @selected="createEvaluationWithSurvey()"
          :displayNextButton="true"
        />
        <MenuItem
          v-if="isAdministrator"
          :label="$t('evaluationMenu.displayMyEvaluations')"
          @selected="displayMyEvaluations()"
          :displayNextButton="true"
        />
        <MenuItem
          :label="$t('evaluationMenu.displaySharedResults')"
          @selected="displaySharedResults()"
          :displayNextButton="true"
        />
      </SlideMenu>
    </transition>

    <EvaluationFormDialogue
      v-if="isAdministrator && activeDialogue === 'createEvaluation'"
      :type="evaluationType"
      @select="toggleDialogue()"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
    />
  </div>
</template>

<script>
import MenuItem from '../menus/MenuItem';
import authMixins from '../mixins/auth';
import SlideMenu from '../menus/SlideMenu';
import EvaluationIcon from '../icons/EvaluationIcon';
import toggleState from '../mixins/toggleState';
import EvaluationFormDialogue from './EvaluationFormDialogue';

export default {
  inheritAttrs: false,
  name: 'EvaluationMenu',
  mixins: [authMixins, toggleState('dialogue')],
  components: {
    MenuItem,
    SlideMenu,
    EvaluationIcon,
    EvaluationFormDialogue
  },
  data() {
    return {
      evaluationType: '',
      menuOpen: false,
      menuClicked: false
    };
  },
  computed: {
    counter() {
      const { evaluation = {} } = this.currentUser;
      const count = evaluation.toEvaluationCount || 0;
      if (!count || count < 0) return '';
      return count < 100 ? count : '99+';
    },
    toEvaluateCounter() {
      const { evaluation = {} } = this.currentUser;
      const count = evaluation.toEvaluationCount || 0;
      if (!count || count < 0) return '';
      return count < 100 ? count : '99+';
    }
  },
  methods: {
    toggle() {
      this.menuClicked = true;
      this.menuOpen = !this.menuOpen;
    },
    clicked() {
      if (!this.menuClicked) {
        this.menuOpen = false;
      }

      this.menuClicked = false;
    },
    displayEvaluationsToEvaluate() {
      this.goto('evaluation/to-evaluate');
    },
    displaySharedResults() {
      this.goto('evaluation/results');
    },
    createEvaluationByCriteria() {
      this.evaluationType = 'criteria';
      this.toggleDialogue('createEvaluation');
    },
    createEvaluationWithSurvey() {
      this.evaluationType = 'survey';
      this.toggleDialogue('createEvaluation');
    },
    displayMyEvaluations() {
      this.goto('evaluation');
    }
  },
  watch: {
    menuOpen(value) {
      if (value) {
        document.addEventListener('click', this.clicked);
      } else {
        document.removeEventListener('click', this.clicked);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.evaluation-menu {
  height: 100%;

  &__button {
    position: relative;
    height: 100%;

    &--active {
      @include svg-active-background($background-triangle-up-svg);
      background-position: bottom;
    }

    > .menu-button {
      height: 100%;
    }

    &--count {
      @include flexy($just: center, $align: center);
      position: absolute;
      top: 5px;
      right: -5px;
      border-radius: 15px;
      height: 15px;
      min-width: 15px;
      background-color: $counter-background;
      color: $font-light;
      font-size: 11px;
      font-weight: bold;
      padding: 0 calc($spacing-quarter / 2);
      cursor: pointer;
    }
  }

  &__split-item {
    border-width: 1px 0;
    border-bottom-color: $border-medium-color;
  }

  &__to-evaluate__inner {
    @include flexy($dir: row);

    &--count {
      @include flexy($just: center, $align: center);
      margin-left: $spacing-half;
      border-radius: 15px;
      height: 15px;
      min-width: 15px;
      background-color: $counter-background;
      color: $font-light;
      font-size: 11px;
      font-weight: bold;
      padding: 0 calc($spacing-quarter / 2);
    }
  }
}
</style>
