import { defineStore } from 'pinia';

import {
  get as apiGet,
  getAll as apiGetAll,
  deleteSelected as apiDeleteSelected,
  deleteAll as apiDeleteAll
} from '@/api/endpoints/subscription';
import { SUBSCRIPTION_TYPE } from '@/utils/types';

const defaultLimit = 30;

const initialState = {
  loading: 0,
  isOpen: false,
  subscriptions: [],
  filter: {},
  hasNextPage: false,
  hasPrevPage: false
};

const map = (item) => {
  return {
    ...item,
    name: item.asset.name.toUpperCase()
  };
};

export const key = SUBSCRIPTION_TYPE;
export const useSubscriptionStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, initialState);
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async assetExists({ id }) {
      if (!this.isOpen) return false;
      return this.subscriptions.some((x) => x.asset.id === id);
    },
    async refresh({ id }) {
      if (!this.isOpen) return;

      await this.startLoading();

      try {
        const item = await apiGet(id);

        if (this.subscriptions.some((x) => x.id === item.id)) {
          this.subscriptions = this.subscriptions.map((x) =>
            x.id !== item.id ? x : map(item)
          );
        } else {
          this.subscriptions = [map(item), ...this.subscriptions];
        }
      } finally {
        await this.stopLoading();
      }
    },
    async delete({ id }) {
      if (!this.isOpen) return;

      this.subscriptions = this.subscriptions.filter((x) => x.id !== id);
    },
    async start() {
      await this.reset();
      this.isOpen = true;
      return await this.get({ start: 0 });
    },
    async get(options = {}) {
      let { filter, subscriptions } = this;
      const { start = subscriptions.length } = options;
      const limit =
        start === 0 && subscriptions.length > defaultLimit
          ? subscriptions.length
          : defaultLimit;

      await this.startLoading();

      filter = {
        ...(filter.condition || {})
      };

      try {
        const { items, hasNextPage } = await apiGetAll({
          query: { start, limit, filter }
        });
        const newItems = items.map(map);

        this.subscriptions =
          start === 0 ? newItems : [...subscriptions, ...newItems];
        this.hasNextPage = hasNextPage;

        return { items: newItems, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async deleteSelected(ids) {
      await apiDeleteSelected(ids);

      this.subscriptions = this.subscriptions.filter(
        (x) => !ids.some((id) => x.id === id)
      );
    },
    async deleteAll() {
      await apiDeleteAll();

      this.subscriptions = [];
      this.hasNextPage = false;
    },
    async setFilter(filter) {
      this.filter = filter;
      await this.get({ start: 0 });
    },
    async assetUpdated(item) {
      this.subscriptions = this.subscriptions.map((x) => {
        if (x.asset.id === item.id) {
          x.asset.name = item.name;
          return { ...x, name: item.name.toUpperCase() };
        } else {
          return x;
        }
      });
    },
    async assetDeleted({ id }) {
      if (this.subscriptions.some((x) => x.asset.id === id)) {
        await this.get({ start: 0 });
      }
    }
  }
});

export default {
  key,
  use: useSubscriptionStore
};
