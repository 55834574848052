<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.937 36.223"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-331.292 -530)">
      <path
        d="M18.93,29.2l-7.358-7.356L7.648,17.925l-.956-.956,2.8-2.8,2.083-2.08L18.93,4.732A2.774,2.774,0,1,0,15.007.809L7.648,8.167,3.725,12.09.809,15.007a2.74,2.74,0,0,0-.772,1.76c0,.067-.037.134-.037.2A2.758,2.758,0,0,0,.809,18.93l4.757,4.76,2.083,2.08,7.358,7.358A2.774,2.774,0,1,0,18.93,29.2Z"
        transform="translate(331.292 566.223) rotate(-90)"
      />
      <rect
        width="6.031"
        height="30.157"
        rx="3.016"
        transform="translate(345.176 530)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'ArrowIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
