import { getCookie } from '@/utils/tools';
import { useAuthStore } from '@/stores/auth';

let isInitialised = false;

const checkRefreshIsNeeded = async (authStore) => {
  if (
    !isInitialised &&
    (getCookie('fso-authenticated') || localStorage.getItem('currentUserId'))
  ) {
    if (localStorage.getItem('currentUserId'))
      localStorage.removeItem('currentUserId');
    await authStore.refresh({ redirectToLogoutOnUnauthorize: false });
  }

  isInitialised = true;
};

export default {
  checkRefreshIsNeeded: async () => {
    await checkRefreshIsNeeded(useAuthStore());
  },

  ensureUserHasRole: (role) => {
    return async (to) => {
      const authStore = useAuthStore();

      await checkRefreshIsNeeded(authStore);

      const { isLoggedIn } = authStore;

      if (!isLoggedIn) {
        return { name: 'login', query: { redirectUrl: to.path } };
      } else if (!authStore.hasRole(role)) {
        return { name: 'root' };
      }
    };
  },

  ensureHasShareToken: () => {
    return async (to, from) => {
      const authenticated = !!useAuthStore().shareLogin;
      if (!authenticated)
        return {
          name: 'share',
          params: { hash: to.params?.hash },
          query: { redirectUrl: to.path }
        };

      if (to?.params?.hash || !from?.params?.hash) return true;

      return {
        ...to,
        params: { ...to.params, hash: from.params.hash }
      };
    };
  }
};
