<template>
  <svg :class="classes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-297 -114)">
      <circle cx="9" cy="9" r="9" transform="translate(297 114)" />
      <path
        style="fill: rgb(255, 255, 255)"
        d="M301.344,592.448h-3.3v-3.3a.8.8,0,1,0-1.6,0v3.3h-3.3a.8.8,0,0,0,0,1.6h3.3v3.3a.8.8,0,0,0,1.6,0v-3.3h3.3a.8.8,0,1,0,0-1.6Z"
        transform="translate(515.225 -506.753) rotate(45)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'CloseIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
