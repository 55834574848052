<template>
  <div class="message-field" :class="$attrs.class">
    <div
      ref="input"
      class="message-field__input"
      tabindex="0"
      contenteditable="true"
      :placeholder="placeholder"
      @input="input"
      @keydown="keydown"
      @paste.prevent="paste"
    ></div>
    <div class="message-field__chevron" :class="{ disabled: !canSend }">
      <div
        class="message-field__chevron--inner"
        :class="{ disabled: !canSend }"
        @click.prevent="send"
        @mousedown.prevent=""
      >
        <Chevron size="xsmall" direction="right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import Chevron from '../icons/Chevron';
import features from '../../utils/features';
import { getPasteHtmlFragment, convertTextToCleanHtml } from '../../utils/html';

export default {
  inheritAttrs: false,
  name: 'MessageField',
  components: {
    Chevron
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      editor: null,
      canSend: false,
      submitted: false
    };
  },
  computed: {
    isMobile() {
      return features.isMobile.any();
    }
  },
  methods: {
    ...mapActions(useAppStore, {
      cleanHtml: 'cleanHtml',
      displayError: 'displayError'
    }),
    send() {
      if (!this.canSend) return;
      this.$emit('done');
    },
    input() {
      if (this.editor.innerHTML === '<br>') this.editor.innerHTML = '';
      this.$emit('update:modelValue', this.editor.innerHTML);
      this.canSend = !!this.editor.innerText.trim();
    },
    keydown(e) {
      if (e.keyCode === 13) {
        if (this.isMobile) return true;
        if (e.altKey) return true;
        if (e.shiftKey) return true;

        e.stopPropagation();
        e.preventDefault();
        this.send();
        return false;
      }

      return true;
    },
    async paste(e) {
      if (this.submitted) return false;

      try {
        this.submitted = true;

        if (
          e &&
          e.clipboardData &&
          e.clipboardData.types &&
          e.clipboardData.getData
        ) {
          let html = e.clipboardData.getData('text/html');

          if (html) {
            html = await this.cleanHtml(getPasteHtmlFragment(html));
          } else {
            html = convertTextToCleanHtml(
              (e.clipboardData.getData('text/plain') || '').trim()
            );
          }

          document.execCommand('insertHTML', false, `<div>${html}</div>`);
        }
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }

      return false;
    },
    focus() {
      this.editor.focus();
    },
    select() {
      this.focus();
      document.execCommand('selectAll');
    },
    clear() {
      this.editor.innerHTML = '';
      this.canSend = false;
    }
  },
  mounted() {
    const { input } = this.$refs;
    this.editor = input;
    this.focus();
    this.editor.innerHTML = this.modelValue;
  }
};
</script>

<style lang="scss">
.message-field__input a {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.message-field {
  @include grid($cols: 1fr auto, $areas: 'input chevron');
  width: 100%;
  background-color: $background-light;
  padding-left: $spacing-half;
  border-radius: 20px;
  border: 1px solid $border-medium-color;

  &__input {
    @include grid-item(input);
    width: 100%;
    min-height: 20px;
    max-height: calc(50vh - 5.6rem);
    color: $font-dark;
    font-size: $font-size-medium;
    margin: $spacing-half;
    overflow: auto;
  }

  &__input:focus {
    outline: none;
  }

  &__input:empty:before {
    content: attr(placeholder);
  }

  &__chevron {
    @include grid-item(chevron);
    width: 29px;
    margin-left: $spacing-half;
    position: relative;

    &--inner {
      @include flexy($align: center);
      height: 40px;
      padding: 0 $spacing-half;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &--inner.disabled {
      cursor: default;
      opacity: $disable-opacity;
    }
  }
}
</style>
