<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.978 31.79"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-159.327 -174.669)">
      <path
        d="M160.8,208.391a1.476,1.476,0,0,1-1.476-1.476V191.158a1.476,1.476,0,0,1,2.951,0v15.758A1.476,1.476,0,0,1,160.8,208.391Z"
        transform="translate(0 -8.684)"
      />
      <path
        d="M175.042,207.872a1.476,1.476,0,0,1-1.476-1.476V185.153a1.476,1.476,0,0,1,2.951,0V206.4A1.475,1.475,0,0,1,175.042,207.872Z"
        transform="translate(-8.236 -5.21)"
      />
      <path
        d="M189.282,206.459a1.476,1.476,0,0,1-1.476-1.476V176.145a1.476,1.476,0,1,1,2.951,0v28.838A1.476,1.476,0,0,1,189.282,206.459Z"
        transform="translate(-16.472)"
      />
      <path
        d="M203.521,209.6a1.476,1.476,0,0,1-1.476-1.476V196.162a1.476,1.476,0,1,1,2.951,0v11.961A1.475,1.475,0,0,1,203.521,209.6Z"
        transform="translate(-24.708 -11.578)"
      />
      <path
        d="M217.761,206.459a1.476,1.476,0,0,1-1.476-1.476V176.145a1.476,1.476,0,1,1,2.951,0v28.838A1.476,1.476,0,0,1,217.761,206.459Z"
        transform="translate(-32.945)"
      />
      <path
        d="M232,208.391a1.476,1.476,0,0,1-1.476-1.476V191.158a1.476,1.476,0,1,1,2.951,0v15.758A1.476,1.476,0,0,1,232,208.391Z"
        transform="translate(-41.181 -8.684)"
      />
      <path
        d="M246.24,207.872a1.476,1.476,0,0,1-1.476-1.476V185.153a1.476,1.476,0,1,1,2.951,0V206.4A1.476,1.476,0,0,1,246.24,207.872Z"
        transform="translate(-49.417 -5.21)"
      />
      <path
        d="M260.479,206.459A1.476,1.476,0,0,1,259,204.983V176.145a1.476,1.476,0,0,1,2.951,0v28.838A1.476,1.476,0,0,1,260.479,206.459Z"
        transform="translate(-57.653)"
      />
      <path
        d="M274.719,208.391a1.476,1.476,0,0,1-1.476-1.476V191.158a1.476,1.476,0,0,1,2.951,0v15.758A1.476,1.476,0,0,1,274.719,208.391Z"
        transform="translate(-65.89 -8.684)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'AudioWave';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
