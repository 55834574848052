import { defineStore } from 'pinia';
import { mapStores } from '@/stores';

import {
  getOrganicUnassignedItems as apiGetUnassignedItems,
  getOrganicAssignedItems as apiGetAssignedItems,
  getOrganicPosition as apiGetOrganicPosition,
  assignOrganicItem as apiAssignOrganicItem,
  moveOrganicItems as apiMoveOrganicItems,
  unassignOrganicItems as apiUnassignOrganicItems,
  deleteOrganicItems as apiDeleteOrganicItems
} from '@/api/endpoints/asset';
import {
  get as apiGetOrganicTitle,
  create as apiCreateOrganicTitle,
  update as apiUpdateOrganicTitle
} from '@/api/endpoints/organicTitle';
import { defaultSort, sortOptions } from '@/utils/sort';
import {
  CARD_TYPE,
  ORGANIC_TITLE_TYPE,
  ASSET_ORGANIC_TYPE
} from '@/utils/types';

const getDefaultFilter = () => {
  return {
    keys: {},
    displayHidden: false,
    hasFilters: false
  };
};

const initialState = {
  share: false,
  current: null,
  childType: null,
  selectAction: 'select',

  unassignedItems: [],
  assignedItems: [],

  filter: getDefaultFilter(),
  sort: defaultSort,
  searchText: null
};

export const key = ASSET_ORGANIC_TYPE;
export const useAssetOrganicStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
      this.filter = getDefaultFilter();
    },
    async assetExists(options = {}) {
      const { id } = options;
      const result =
        this.unassignedItems.some((asset) => asset.id === id) ||
        this.assignedItems.some((asset) => asset.id === id);

      if (result) options.share = this.share;
      return result;
    },
    async load({ current, childType, share }) {
      this.current = { id: current.id, type: current.type };
      this.childType = childType;
      this.share = !!share;

      await Promise.all([this.getUnassignedItems(), this.getAssignedItems()]);
    },
    async getUnassignedItems() {
      const { sort, filter, current, childType, searchText } = this;
      if (!current) return;

      this.unassignedItems = await apiGetUnassignedItems(
        current.type,
        current.id,
        childType,
        {
          query: { sort: sortOptions[sort] },
          text: searchText || '',
          displayHidden: filter.displayHidden
        },
        this.share
      );

      return this.unassignedItems;
    },
    async getAssignedItems() {
      const { current, childType } = this;
      if (!current) return;

      this.assignedItems = await apiGetAssignedItems(
        current.type,
        current.id,
        childType,
        this.share
      );
      return this.assignedItems;
    },
    async setFilter(newFilter) {
      const { filter } = this;

      if (filter.keys[newFilter.key]) {
        filter[newFilter.key] = false;
        delete filter.keys[newFilter.key];
      } else {
        filter.keys[newFilter.key] = newFilter.key;
        filter[newFilter.key] = true;
      }

      this.filter = {
        ...filter,
        hasFilters: !!filter.displayHidden
      };

      await this.getUnassignedItems();
    },
    async setSort(sort) {
      this.sort = sort;
      await this.getUnassignedItems();
    },
    async search({ text }) {
      this.searchText = text;

      await this.getUnassignedItems();
    },
    async clearSearch() {
      this.searchText = null;
      await this.getUnassignedItems();
    },
    async clearFilters() {
      this.filter = getDefaultFilter();
      this.sort = defaultSort;
      this.searchText = null;

      await this.getUnassignedItems();
    },
    async assignOrganicItem({ id, position }) {
      const { current, childType, unassignedItems, assignedItems } = this;
      if (!current) return;

      const item = await apiAssignOrganicItem(childType, id, position);
      this.unassignedItems = unassignedItems.filter((x) => x.id !== item.id);
      this.assignedItems = [...assignedItems, item];

      return item;
    },
    async moveOrganicItems({ items }) {
      const { current, childType } = this;
      if (!current) return;

      const newItems = {};
      items.forEach((item) => {
        newItems[item.id] = item.position;
      });

      this.assignedItems = this.assignedItems.map((item) => {
        const position = newItems[item.id];

        if (position) {
          const newItem = { ...item };
          newItem.organicPosition = {
            ...newItem.organicPosition,
            x: position.x,
            y: position.y
          };

          return newItem;
        } else {
          return item;
        }
      });

      await apiMoveOrganicItems(childType, items);
    },
    async unassignOrganicItems({ assets }) {
      const { current, childType } = this;
      if (!current) return;

      const assetIds = assets
        .filter((x) => x.type === childType)
        .map((x) => x.id);
      const titleIds = assets
        .filter((x) => x.type === ORGANIC_TITLE_TYPE)
        .map((x) => x.id);

      const promises = [];

      if (assetIds.length > 0) {
        promises.push(apiUnassignOrganicItems(childType, assetIds));
      }
      if (titleIds.length > 0) {
        promises.push(apiDeleteOrganicItems(ORGANIC_TITLE_TYPE, titleIds));
      }

      await Promise.all(promises);

      const ids = [...assetIds, ...titleIds];
      const removedIds = {};

      ids.forEach((id) => {
        removedIds[id] = id;
      });

      this.assignedItems = this.assignedItems.filter(
        (item) => !removedIds[item.id]
      );
    },
    async createOrganicTitle(data) {
      const { current, assignedItems } = this;
      if (!current) return;

      const item = await apiCreateOrganicTitle({
        parentId: current.id,
        ...data
      });

      this.assignedItems = [
        ...assignedItems.filter((x) => x.id !== item.id),
        item
      ];

      return item;
    },
    async updateOrganicTitle({ id, content }) {
      const { current, assignedItems } = this;
      if (!current) return;

      const newItem = await apiUpdateOrganicTitle(id, { content });

      this.assignedItems = assignedItems.map((item) => {
        return item.id !== newItem.id
          ? item
          : { ...item, content: newItem.content };
      });

      return newItem;
    },
    async setSelectAction(action) {
      this.selectAction = action;
    },
    async fetchItem({ id, type }) {
      if (type === ORGANIC_TITLE_TYPE) {
        return await apiGetOrganicTitle(id, this.share);
      } else {
        const store = mapStores.get(type).use();
        return await store.fetch({ id, share: this.share });
      }
    },
    async updateItem(item) {
      const { organicPosition } = item;
      const existsAssignedItems = this.assignedItems.some(
        (x) => x.id === item.id
      );
      const existsUnassignedItems = this.unassignedItems.some(
        (x) => x.id === item.id
      );

      if (existsAssignedItems) {
        if (organicPosition) {
          this.assignedItems = this.assignedItems.map((asset) => {
            return asset.id !== item.id ? asset : { ...item };
          });
        } else {
          this.assignedItems = this.assignedItems.filter(
            (asset) => asset.id !== item.id
          );
        }
      } else if (organicPosition) {
        this.assignedItems = [...this.assignedItems, item];
      }

      if (existsUnassignedItems) {
        if (organicPosition) {
          this.unassignedItems = this.unassignedItems.filter(
            (asset) => asset.id !== item.id
          );
        } else {
          this.unassignedItems = this.unassignedItems.map((asset) => {
            return asset.id === item.id ? item : asset;
          });
        }
      }

      if (this.current && this.current.id === item.id) {
        this.unassignedItems = this.unassignedItems.map((asset) => {
          return { ...asset, board: { ...item.board } };
        });
        this.assignedItems = this.assignedItems.map((asset) => {
          return { ...asset, board: { ...item.board } };
        });
      }
    },
    async deleteItem(id) {
      if (this.unassignedItems.some((x) => x.id === id)) {
        this.unassignedItems = this.unassignedItems.filter(
          (asset) => asset.id !== id
        );
      }

      if (this.assignedItems.some((x) => x.id === id)) {
        this.assignedItems = this.assignedItems.filter(
          (asset) => asset.id !== id
        );
      }
    },
    async hideItem(item) {
      if (this.current?.id !== item.parent.id) return;
      const { assignedItems, filter } = this;

      if (assignedItems.some((x) => x.id === item.id)) {
        await this.deleteItem(item.id);
        if (filter.displayHidden) {
          await this.getUnassignedItems();
        }
        return;
      }

      if (filter.displayHidden) {
        await this.getUnassignedItems();
      } else {
        await this.deleteItem(item.id);
      }
    },
    async displayItem(item) {
      if (this.current?.id !== item.parent.id) return;
      await this.getUnassignedItems();
    },
    async restoreFromBin({ parentId }) {
      if (this.current?.id !== parentId) return;

      await this.getUnassignedItems();
    },
    async assetTagHasChanged({ id }) {
      if (this.current?.id !== id) return;

      await Promise.all([this.getUnassignedItems(), this.getAssignedItems()]);
    },
    async itemAdded({ id, type, parentId }) {
      if (type !== CARD_TYPE && type !== ORGANIC_TITLE_TYPE) return false;
      if (this.current?.id !== parentId) return;

      const { assignedItems } = this;
      if (assignedItems.some((x) => x.id === id)) return;

      const item = await this.fetchItem({ id, type });
      if (!item || !item.organicPosition) return;

      this.assignedItems = [...assignedItems, item];
    },
    async itemUpdated({ id, type, parentId }) {
      if (type !== CARD_TYPE && type !== ORGANIC_TITLE_TYPE) return false;
      if (this.current?.id !== parentId) return;

      if (!this.assignedItems.some((x) => x.id === id)) return;

      const item = await this.fetchItem({ id, type });
      if (!item || !item.organicPosition) return;
      await this.assetUpdated(item);
    },
    async itemDeleted({ id, type, parentId }) {
      if (type !== CARD_TYPE && type !== ORGANIC_TITLE_TYPE) return false;
      if (this.current?.id !== parentId) return;

      await this.deleteItem(id);
    },
    async itemHasBeenAssigned({ id, type, parentId }) {
      if (this.current?.id !== parentId) return;

      if (!this.assignedItems.some((x) => x.id === id)) {
        const item = await this.fetchItem({ id, type });
        await this.updateItem(item);
      }
    },
    async itemHasBeenMoved({ id, parentId }) {
      if (this.current?.id !== parentId) return;

      const item = this.assignedItems.filter((x) => x.id === id)[0];
      if (!item) return;

      const { organicPosition } = await apiGetOrganicPosition(
        item.type,
        item.id,
        this.share
      );
      if (!organicPosition) return;

      await this.updateItem({ ...item, organicPosition });
    },
    async itemsHaveBeenUnassigned({ parentId, ids }) {
      if (this.current?.id !== parentId) return;

      const itemIds = {};
      ids.forEach((id) => {
        itemIds[id] = true;
      });

      if (this.assignedItems.some((x) => itemIds[x.id])) {
        this.assignedItems = this.assignedItems.filter((x) => !itemIds[x.id]);
      }

      if (this.unassignedItems.some((x) => !itemIds[x.id])) {
        await this.getUnassignedItems();
      }
    },
    async assetUpdated(item) {
      if (!item) return;
      if (item.type !== CARD_TYPE && item.type !== ORGANIC_TITLE_TYPE) return;
      if (this.current?.id !== item.parent.id) return;
      if (
        !this.assignedItems.some((x) => x.id === item.id) &&
        !this.unassignedItems.some((x) => x.id === item.id)
      )
        return;

      await this.updateItem(item);
    }
  }
});

export default {
  key,
  use: useAssetOrganicStore
};
