import { getAll as genericGetAll } from '../generic';
import { get as httpGet, post as httpPost } from '../requests';

export const ENDPOINT_URL = '/dashboard';

export const getAll = genericGetAll(ENDPOINT_URL);

export const getHiddenCount = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/hidden-count`);
  return response.json();
};

export const getNavigationItems = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/navigation-items`);
  return response.json();
};

export const validateCopy = async (data) => {
  const response = await httpPost(`${ENDPOINT_URL}/validate-copy`, data);
  return response.json();
};

export const copy = async (data) => {
  const response = await httpPost(`${ENDPOINT_URL}/copy`, data);
  return response.json();
};

export const move = async (data) => {
  const response = await httpPost(`${ENDPOINT_URL}/move`, data);
  return response.json();
};
