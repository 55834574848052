import { capitalize } from '../../utils/text';

export default (name, initialValue = '') => {
  const capName = capitalize(name);
  return {
    data() {
      return {
        [`active${capName}`]: initialValue,
        menuClicked: false
      };
    },
    methods: {
      [`toggle${capName}`](value = '') {
        const state = `active${capName}`;

        if (state === 'activeMenu') {
          this.menuClicked = true;
        }

        this[state] = this[state] === value ? '' : value;
        return true;
      },
      clicked() {
        if (!this.menuClicked) {
          this.activeMenu = '';
        }
        this.menuClicked = false;
      }
    },
    watch: {
      activeMenu(value) {
        if (value) {
          document.addEventListener('click', this.clicked);
        } else {
          document.removeEventListener('click', this.clicked);
        }
      }
    }
  };
};
