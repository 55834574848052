<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.186 20.186"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M67.608,28.251A7.581,7.581,0,1,0,66.2,40.073L71.825,45.7a1.777,1.777,0,1,0,2.513-2.513l-5.629-5.627A7.586,7.586,0,0,0,67.608,28.251Zm-1.72,9a5.149,5.149,0,1,1,0-7.282A5.154,5.154,0,0,1,65.888,37.252Z"
      transform="translate(-54.669 -26.033)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'SearchIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
