<template>
  <BaseDialogue
    class="company-settings"
    :class="$attrs.class"
    :title="$t('company.settings')"
    @cancel="cancel"
    @ok="ok()"
    :disableOk="!isValid"
  >
    <BorderField>
      <div class="company-settings__section__title">
        {{ $t('companySettings.identification') }}
      </div>

      <BaseInput
        ref="description"
        :label="$t('companySettings.description')"
        name="description"
        v-model.trim="form.description"
        autocomplete="off"
      />
    </BorderField>

    <BorderField>
      <div class="company-settings__section__title">
        {{ $t('companySettings.security') }}
      </div>
      <div class="company-settings__section__title--mfa">
        {{ $t('companySettings.mfa.title') }}
      </div>
      <div class="company-settings__section__title--mfa--info">
        {{ $t('companySettings.mfa.info') }}
      </div>
      <div class="company-settings__section__roles">
        {{ $t('companySettings.roles') }}
      </div>

      <div class="company-settings__section__roles--item">
        <BaseCheckbox
          :label="$t('role.app_administrator')"
          v-model="authPool.options.mfa.roles.app_administrator"
        />
      </div>
      <div class="company-settings__section__roles--item">
        <BaseCheckbox
          :label="$t('role.app_participant')"
          v-model="authPool.options.mfa.roles.app_participant"
        />
      </div>
      <div class="company-settings__section__roles--item">
        <BaseCheckbox
          :label="$t('role.app_participant_restricted')"
          v-model="authPool.options.mfa.roles.app_participant_restricted"
        />
      </div>
    </BorderField>

    <BorderField>
      <div class="company-settings__section__title">
        {{ $t('companySettings.settings.title') }}
      </div>

      <BaseInput
        name="customRedirectUrlWhenShareIsInvalid"
        :label="
          $t('companySettings.settings.customRedirectUrlWhenShareIsInvalid1')
        "
        v-model.trim="settings.customRedirectUrlWhenShareIsInvalid"
        autocomplete="off"
      >
        <template #label>
          <div>
            {{
              $t(
                'companySettings.settings.customRedirectUrlWhenShareIsInvalid1'
              )
            }}
          </div>
          <div class="company-settings__section__info">
            {{
              $t(
                'companySettings.settings.customRedirectUrlWhenShareIsInvalid2'
              )
            }}
          </div>
        </template>
      </BaseInput>
    </BorderField>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useCompanyStore } from '@/stores/company';
import '../../assets/stylesheets/components/_form.scss';
import BaseDialogue from '../dialogues/BaseDialogue';
import BorderField from '../forms/BorderField';
import authMixins from '../mixins/auth';

export default {
  inheritAttrs: false,
  name: 'CompanySettings',
  mixins: [authMixins],
  components: {
    BaseDialogue,
    BorderField
  },
  data() {
    return {
      form: {
        description: ''
      },
      authPool: {
        options: {
          mfa: {
            roles: {
              app_participant_restricted: false,
              app_participant: false,
              app_administrator: false
            }
          }
        }
      },
      settings: { customRedirectUrlWhenShareIsInvalid: null },
      submitted: false
    };
  },
  computed: {
    isValid() {
      return this.textFieldIsValid('description');
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useCompanyStore, [
      'getAuthPool',
      'getSettings',
      'updateSettings'
    ]),
    async init() {
      this.form.description = this.currentCompany.description;

      let [{ options }, { settings }] = await Promise.all([
        this.getAuthPool(),
        this.getSettings()
      ]);

      if (!options) options = { ...this.authPool.options };
      if (!options.mfa) options.mfa = { ...this.authPool.options.mfa };
      if (!options.mfa.roles)
        options.mfa.roles = { ...this.authPool.options.mfa.roles };

      this.authPool.options.mfa.roles.app_administrator =
        !!options.mfa.roles.app_administrator;
      this.authPool.options.mfa.roles.app_participant =
        !!options.mfa.roles.app_participant;
      this.authPool.options.mfa.roles.app_participant_restricted =
        !!options.mfa.roles.app_participant_restricted;

      if (!settings) settings = { ...this.settings };
      this.settings = {
        customRedirectUrlWhenShareIsInvalid:
          (settings.customRedirectUrlWhenShareIsInvalid || '').trim() || null
      };
    },
    async ok() {
      if (this.submitted) return;

      this.submitted = true;
      try {
        const { description } = this.form;
        const data = {
          description,
          settings: this.settings,
          authPool: this.authPool
        };

        const result = await this.updateSettings(data);
        this.$emit('success', result);
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    textFieldIsValid(field) {
      const text = this.form[field] ? this.form[field].trim() : '';
      if (!text) return false;
      return true;
    }
  },
  async beforeMount() {
    await this.init();
  }
};
</script>

<style lang="scss">
.company-settings {
  .dialogue__header,
  .dialogue__footer {
    background-color: $company-base;
  }

  &__section {
    &__title {
      margin: $spacing-base;
      text-transform: uppercase;

      &--mfa {
        margin: $spacing-base $spacing-base $spacing-third $spacing-base;
        font-weight: bold;

        &--info {
          margin: 0 $spacing-base $spacing-base $spacing-base;
          font-size: $font-size-xsmall;
          font-style: italic;
        }
      }
    }

    &__roles {
      margin: $spacing-base;
      font-weight: bold;

      &--item {
        margin: $spacing-base ($spacing-base + $spacing-half);
        font-weight: bold;
      }
    }

    &__info {
      font-style: italic;
    }
  }
}
</style>
