<template>
  <div
    class="slider-fieldset"
    :class="[{ 'slider-fieldset--align-left': arrowAlignLeft }, $attrs.class]"
  >
    <div
      v-if="displayIndicator"
      class="slider-fieldset__indicator"
      :class="{ 'slider-fieldset__indicator--active': active }"
    >
      &nbsp;
    </div>
    <div class="slider-fieldset__content" @click="$emit('v-click', $event)">
      <slot
        ><span
          class="slider-fieldset__label"
          :class="{ 'slider-fieldset__label--no-indicator': !displayIndicator }"
          >{{ label }}</span
        ></slot
      >
    </div>
    <div
      v-if="displayExpand"
      class="slider-fieldset__expand"
      @click="$emit('v-click', $event)"
      :class="{ 'slider-fieldset__expand--expanded': expanded }"
    >
      <chevron size="xsmall" :direction="direction" title="form.icon.open" />
    </div>
  </div>
</template>

<script>
import Chevron from '../icons/Chevron';

export default {
  inheritAttrs: false,
  name: 'SliderFieldset',
  components: { Chevron },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: 'right'
    },
    expanded: {
      type: Boolean,
      default: false
    },
    displayIndicator: {
      type: Boolean,
      default: true
    },
    displayExpand: {
      type: Boolean,
      default: true
    },
    arrowAlignLeft: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
$icon-bold-dimension: 15px;

.slider-fieldset {
  @include grid($cols: auto 1fr auto, $areas: 'indicator content expand');

  min-height: $vertical-rhythm;

  &--align-left {
    @include grid(
      $cols: auto auto auto,
      $areas: 'indicator content expand',
      $just: left
    );
  }

  > .slider-fieldset__indicator {
    @include grid-item($area: indicator);
    width: $spacing-half;
    &--active {
      background-color: map_get($icon-colors, 'active');
    }
  }

  > .slider-fieldset__content {
    @include grid-item($align: center, $area: content);
    @include flexy($align: center);
    min-height: $vertical-rhythm;
    cursor: pointer;

    > .slider-fieldset__label {
      margin: 0 $spacing-half;
    }

    > .slider-fieldset__label--no-indicator {
      margin: 0 $spacing-base;
    }
  }

  > .slider-fieldset__expand {
    @include grid-item($align: center, $area: expand);
    @include flexy($align: center);
    height: $vertical-rhythm;
    margin-right: $spacing-base;
    padding-left: $spacing-half;
    cursor: pointer;

    > .icon-chevron-icon {
      @include ease();
    }

    &--expanded {
      > .icon-chevron-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }
}

.slider-fieldset.label-bold {
  > .slider-fieldset__label {
    font-weight: bold;
  }

  > .slider-fieldset__expand {
    > .icon-chevron-icon {
      height: $icon-bold-dimension;
      min-height: $icon-bold-dimension;
      width: $icon-bold-dimension;
      min-width: $icon-bold-dimension;
    }
  }
}
</style>
