export class ServerError extends Error {
  constructor(status, message, code, data, response) {
    super(message);
    this.name = 'ServerError';
    this.status = status;
    this.code = code;
    this.data = data;
    this.response = response;
  }
}

export class NetworkError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NetworkError';
  }
}
