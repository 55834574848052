import { defineStore } from 'pinia';
import { mapStores } from '@/stores';
import { useAuthStore } from '@/stores/auth';

import { BOARD_TYPE, FOLDER_TYPE, CARD_TYPE, MEDIA_TYPE } from '@/utils/types';
import { isItemProtected, isItemAuthorBoard } from '@/utils/asset';

const CLIPBOARD_ACTION_COPY = 'copy';
const CLIPBOARD_ACTION_MOVE = 'move';
const CLIPBOARD_ACTION_EVALUATION = 'evaluation';

const baseState = {
  currentLocation: undefined
};

const resetableState = {
  isSelectionMode: false,
  isSearchSelectionMode: false,
  assets: new Map(),
  validationMessages: new Map(),
  selectedAssetType: undefined,
  action: 'copy',
  current: null,
  executeAction: null
};

const DASHBOARD_LOCATION = 'dashboard';

export const key = 'clipboard';
export const useClipboardStore = defineStore(key, {
  state: () => ({ ...baseState, ...resetableState }),
  getters: {
    canPasteHere: (state) => {
      const { action, selectedAssetType, currentLocation } = state;

      if (action === 'evaluation') {
        return selectedAssetType === CARD_TYPE;
      }

      if (!currentLocation) return false;
      const { current, type, location } = currentLocation;

      if (location === DASHBOARD_LOCATION) {
        if (selectedAssetType !== BOARD_TYPE) return false;
      } else {
        if (!current) return false;

        if (
          isItemProtected(current) &&
          !isItemAuthorBoard(current, useAuthStore().currentUser.id)
        ) {
          return false;
        }
      }

      if (selectedAssetType === MEDIA_TYPE) {
        return type === CARD_TYPE;
      } else if (selectedAssetType === CARD_TYPE) {
        return type === FOLDER_TYPE || type === BOARD_TYPE;
      } else if (selectedAssetType === FOLDER_TYPE) {
        return type === BOARD_TYPE || type === FOLDER_TYPE;
      } else if (selectedAssetType === BOARD_TYPE) {
        if (
          current?.type === BOARD_TYPE &&
          (current?.sandBox ||
            current?.author.id !== useAuthStore().currentUser.id)
        )
          return false;
        else
          return (
            useAuthStore().isAdministrator &&
            (type === BOARD_TYPE || location === DASHBOARD_LOCATION)
          );
      } else {
        return false;
      }
    },
    selectedBriefCount: (state) => {
      return [...state.assets].filter(
        ([_k, v]) => v.type === CARD_TYPE && v.brief
      ).length;
    },
    currentHasBrief: (state) => {
      const { action, selectedAssetType, currentLocation } = state;
      if (action !== 'copy' && action !== 'move') return false;
      if (selectedAssetType !== CARD_TYPE) return false;
      if (!currentLocation) return false;
      const { location } = currentLocation;
      if (location !== BOARD_TYPE && location !== FOLDER_TYPE) return false;

      return !!mapStores.get(location).use().brief;
    }
  },
  actions: {
    async reset() {
      Object.assign(this, { ...resetableState });
    },
    async addItem({ ...data }) {
      if (data.item.type === BOARD_TYPE && !useAuthStore().isAdministrator)
        return;
      if (!this.action && !data.action) return;

      if (this.action && data.action && this.action !== data.action) {
        await this.reset();
      }

      if (this.assets.size === 0) this.selectedAssetType = data.item.type;
      if (this.selectedAssetType !== data.item.type) return;

      const assets = new Map(this.assets);
      assets.set(data.item.id, data.item);
      this.assets = assets;
      if (data.action) this.action = data.action;
    },
    async deleteItem({ id }) {
      if (!this.assets.has(id)) return;

      const assets = new Map(this.assets);
      assets.delete(id);
      if (assets.size === 0) this.selectedAssetType = undefined;
      this.assets = assets;

      const validationMessages = new Map(this.validationMessages);
      validationMessages.delete(id);
      this.validationMessages = validationMessages;
    },
    async clearAssets() {
      this.assets = new Map();
      this.selectedAssetType = undefined;
    },
    async setCurrentLocation(location) {
      this.currentLocation = location;
    },
    async enterSelectionCopy() {
      if (this.action !== CLIPBOARD_ACTION_COPY) {
        await this.reset();
      }

      this.action = CLIPBOARD_ACTION_COPY;
      this.current = null;
      this.isSelectionMode = true;
    },
    async enterSelectionMove() {
      if (this.action !== CLIPBOARD_ACTION_MOVE) {
        await this.reset();
      }

      this.action = CLIPBOARD_ACTION_MOVE;
      this.current = null;
      this.isSelectionMode = true;
    },
    async enterSelectionEvaluation({ current }) {
      if (this.action !== CLIPBOARD_ACTION_EVALUATION) {
        await this.reset();
      }

      this.action = CLIPBOARD_ACTION_EVALUATION;
      this.current = current;
      this.isSelectionMode = true;
    },
    async quitSelectionMode() {
      this.isSelectionMode = false;
    },
    async enterSearchSelectionCopy() {
      if (this.action === CLIPBOARD_ACTION_EVALUATION) {
        this.action = CLIPBOARD_ACTION_EVALUATION;
        this.isSearchSelectionMode = true;
      } else {
        if (this.action !== CLIPBOARD_ACTION_COPY) {
          await this.reset();
        }

        this.action = CLIPBOARD_ACTION_COPY;
        this.current = null;
        this.isSearchSelectionMode = true;
      }
    },
    async quitSearchSelectionMode() {
      this.isSearchSelectionMode = false;
    },
    async setValidationMessage(messages) {
      const validationMessages = new Map();

      if (messages) {
        messages.forEach((message) => {
          validationMessages.set(message.id, message);
        });
      }

      this.validationMessages = validationMessages;
    },
    async copy() {
      const quit = async () => {
        await this.quitSelectionMode();
        await this.quitSearchSelectionMode();
        await this.reset();
      };

      if (!this.canPasteHere) {
        await quit();
        return;
      }

      const { current, type, location } = this.currentLocation;
      let containerType = null;

      if (location === DASHBOARD_LOCATION) {
        containerType = DASHBOARD_LOCATION;
      } else if (type === BOARD_TYPE && current) {
        containerType = BOARD_TYPE;
      } else if (type === FOLDER_TYPE && current) {
        containerType = FOLDER_TYPE;
      } else if (type === CARD_TYPE && current) {
        containerType = CARD_TYPE;
      }

      if (containerType) {
        const items = [];
        this.assets.forEach((asset) => {
          items.push({ id: asset.id, type: this.selectedAssetType });
        });

        const store = mapStores.get(containerType).use();
        const messages = await store.validateCopy({
          id: current ? current.id : null,
          data: { action: 'copy', items }
        });
        if (messages.some((x) => x.error)) {
          await this.setValidationMessage(messages);
          return false;
        } else {
          await this.setValidationMessage([]);
        }

        await store.copy({ id: current ? current.id : null, items });
      }

      await quit();
      return true;
    },
    async move() {
      const quit = async () => {
        await this.quitSelectionMode();
        await this.reset();
      };

      if (!this.canPasteHere) {
        await quit();
        return;
      }

      const { current, type, location } = this.currentLocation;
      let containerType = null;

      if (location === DASHBOARD_LOCATION) {
        containerType = DASHBOARD_LOCATION;
      } else if (type === BOARD_TYPE && current) {
        containerType = BOARD_TYPE;
      } else if (type === FOLDER_TYPE && current) {
        containerType = FOLDER_TYPE;
      } else if (type === CARD_TYPE && current) {
        containerType = CARD_TYPE;
      }

      if (containerType) {
        const items = [];
        this.assets.forEach((asset) => {
          items.push({ id: asset.id, type: this.selectedAssetType });
        });

        const store = mapStores.get(containerType).use();
        const messages = await store.validateCopy({
          id: current ? current.id : null,
          data: { action: 'move', items }
        });
        if (messages.some((x) => x.error)) {
          await this.setValidationMessage(messages);
          return false;
        } else {
          await this.setValidationMessage([]);
        }

        await store.move({ id: current ? current.id : null, items });
      }

      await quit();
      return true;
    },
    async setExecuteAction(action) {
      this.executeAction = action;
    },
    async assetUpdated(item) {
      if (this.currentLocation) {
        const { current } = this.currentLocation;
        if (current && current.type === item.type && current.id === item.id) {
          this.currentLocation.current = item;
        }
      }

      if (this.selectedAssetType !== item.type) return;

      if (this.assets.has(item.id)) {
        const assets = new Map(this.assets);
        assets.set(item.id, { ...item });
        this.assets = assets;
      }
    },
    async assetDeleted(item) {
      if (this.selectedAssetType !== item.type) return;
      await this.deleteItem(item);
    }
  }
});

export default {
  key,
  use: useClipboardStore
};
