<template>
  <footer class="app-footer" :class="$attrs.class">
    <div class="app-footer__left">
      <slot name="left"></slot>
    </div>
    <div v-if="displayPoweredBy" class="app-footer__powered-by">
      <span>{{ $t('footer.poweredBy') }}</span>
      <img
        :src="defaultLogoPath"
        alt="Logo"
        :title="$t('footer.poweredByFassilio')"
        class="app-footer__logo"
      />
    </div>
    <div class="app-footer__right">
      <slot name="right"></slot>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'pinia';
import { useFooter } from '@/stores/footer';
import authMixins from '@/components/mixins/auth';

export default {
  inheritAttrs: false,
  name: 'AppFooter',
  mixins: [authMixins],
  computed: {
    ...mapState(useFooter, [
      'isDisplayedClearClipboardFooter',
      'isDisplayedClearFilters'
    ]),
    displayPoweredBy() {
      const smallWidth = document.body.clientWidth < 768;
      if (smallWidth && this.isDisplayedClearClipboardFooter) return false;
      else if (smallWidth && this.isDisplayedClearFilters) return false;
      return this.logoPath !== this.defaultLogoPath;
    }
  }
};
</script>

<style lang="scss">
.app-footer {
  @include flexy($align: center, $just: space-between);
  background: $background-light;
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
  font-family: $roboto-condensed;
  padding: 0 $spacing-base;
  width: 100%;

  &__left,
  &__powered-by,
  &__right {
    @include flexy($align: center);
    height: 100%;
  }

  &__left {
    @include flexy($just: flex-start);

    > * {
      height: 100%;
      margin-right: $spacing-base;
    }
  }

  &__powered-by {
    text-wrap: nowrap;

    @media screen and (max-width: #{$medium-size-break - 1}) {
      span {
        display: none;
      }
    }
  }

  &__right {
    @include flexy($just: flex-end);

    > * {
      height: 100%;
      margin-left: $spacing-base;
    }
  }

  &__logo {
    display: block;
    max-width: rem(115px);
    max-height: rem(37px);

    @media screen and (max-width: #{$medium-size-break - 1}) {
      max-width: rem(110px);
      max-height: rem(30px);
    }
  }
}
</style>
