<template>
  <div class="list-member" :class="$attrs.class">
    <div class="list-member__left">
      <div v-if="selectable" class="list-member__check">
        <BaseCheckbox
          :modelValue="member.selected"
          @update:modelValue="$emit('selectionChanged', $event)"
        />
      </div>
      <slot name="left">
        <div class="list-member__left--avatar">
          <GroupIcon v-if="member.group" type="light" size="small" />
          <AvatarPill v-else-if="member.account" :info="member.account" />
        </div>
        <slot name="info" v-bind="member"></slot>
      </slot>
    </div>
    <div class="list-member__right">
      <slot name="right">
        <Database v-if="restricted" />
        <button
          v-if="member.group && !selectable"
          class="icon-button list-member__action"
          @click="displayGroupOptions"
        >
          <Action />
        </button>
        <button
          v-if="showDelete && !selectable"
          class="icon-button list-member__delete"
          @click="$emit('delete', member)"
        >
          <Close />
        </button>
      </slot>
    </div>

    <GroupOptionsDialogue
      v-if="activeDialogue === 'groupOptions' && group"
      :item="group"
      @success="closeGroupOptions"
      @cancel="closeGroupOptions"
    />
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useGroupStore } from '@/stores/group';
import Action from '../icons/Action';
import Close from '../icons/Close';
import GroupIcon from '../icons/Group';
import Database from '../icons/Database';
import AvatarPill from '../AvatarPill';
import GroupOptionsDialogue from '../group/GroupOptionsDialogue';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'ListMember',
  mixins: [toggleState('dialogue')],
  components: {
    AvatarPill,
    Action,
    Close,
    Database,
    GroupIcon,
    GroupOptionsDialogue
  },
  props: {
    member: {
      type: Object,
      required: true
    },
    restricted: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      group: null
    };
  },
  methods: {
    ...mapActions(useGroupStore, {
      apiGetGroup: 'apiGet'
    }),
    async displayGroupOptions() {
      if (!this.member.group) return;
      this.group = await this.apiGetGroup(this.member.group.id);
      this.toggleDialogue('groupOptions');
    },
    closeGroupOptions() {
      this.toggleDialogue();
      this.group = null;
    },
    selectionChanged() {}
  }
};
</script>

<style lang="scss" scoped>
.list-member {
  @include flexy($align: center, $just: space-between);
  background-color: $background-light;
  color: $font-dark;
  font-weight: $font-weight-bold;
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
  padding: 0 $spacing-base;

  &__check {
    @include flexy($align: center);
    border-radius: $base-border-radius;
  }
}

.list-member__left,
.list-member__right {
  @include flexy($align: center);
  flex: 1;
  height: 100%;
}

.list-member__left {
  @include flexy($just: flex-start);

  &--avatar {
    @include pill();
    background: $font-dark;
  }

  > * {
    margin-right: $spacing-half;
  }

  > .avatar {
    margin-right: $spacing-third;
  }
}

.list-member__right {
  @include flexy($just: flex-end);

  > * {
    margin-left: $spacing-base;
  }
}

.list-member__action,
.list-member__delete {
  height: 100%;
}

.list-member__right {
  > .icon-database-icon {
    height: 100%;
    fill: #ef6341;
  }
}
</style>
