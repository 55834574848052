import { get as httpGet, post as httpPost } from '../requests';

export const ENDPOINT_URL = '/auth';

export async function login(info) {
  const response = await httpPost(ENDPOINT_URL, info);
  return response.json();
}

export const logout = async () => {
  const response = await httpPost(`${ENDPOINT_URL}/logout`);
  return response.json();
};

export const mfaSetup = async () => {
  const response = await httpPost(`${ENDPOINT_URL}/mfa/setup`);
  return response.json();
};

export const mfaSetupAtLogin = async () => {
  const response = await httpPost(`${ENDPOINT_URL}/mfa/setup-at-login`);
  return response.json();
};

export const mfaVerifyPassword = async (options) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/mfa/verify/password`,
    options
  );
  return response.json();
};

export const mfaVerifyCodeEmail = async (options) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/mfa/verify/code-email`,
    options
  );
  return response.json();
};

export const getMfaSecret = async () => {
  const response = await httpGet(`${ENDPOINT_URL}/mfa/secret`);
  return response.json();
};

export const mfaVerifyCode = async (options) => {
  const response = await httpPost(`${ENDPOINT_URL}/mfa/verify/code`, options);
  return response.json();
};

export const mfaDeactivate = async () => {
  const response = await httpPost(`${ENDPOINT_URL}/mfa/deactivate`);
  return response.json();
};

export const refreshToken = async () => {
  const response = await httpPost(`${ENDPOINT_URL}/refresh-token`);
  return response.json();
};

export async function getRoles() {
  const response = await httpGet(`${ENDPOINT_URL}/roles`);
  return response.json();
}

export async function requestReset(creds) {
  const response = await httpPost(`${ENDPOINT_URL}/forgot-password`, creds);
  return response.json();
}

export async function resetPasswordStart(data) {
  const response = await httpPost(`${ENDPOINT_URL}/reset-password/start`, data);
  return response.json();
}

export async function resetPasswordVerifyCode(data) {
  const response = await httpPost(
    `${ENDPOINT_URL}/reset-password/verify/code`,
    data
  );
  return response.json();
}

export async function resetPasswordVerifyPassword(data) {
  const response = await httpPost(
    `${ENDPOINT_URL}/reset-password/verify/password`,
    data
  );
  return response.json();
}

export async function resetPasswordNextLogin(data) {
  const response = await httpPost(
    `${ENDPOINT_URL}/reset-password-next-login`,
    data
  );
  return response.json();
}
