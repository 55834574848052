import { defineStore } from 'pinia';
import eventDispatcher from '@/stores/event-dispatcher';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

import {
  create as apiCreate,
  get as apiGet,
  getBrief as apiGetBrief,
  getSubBoards as apiGetSubBoards,
  getFolders as apiGetFolders,
  getCards as apiGetCards,
  update as apiUpdate,
  validateCopy as apiValidateCopy,
  copy as apiCopy,
  move as apiMove,
  getSubscription as apiGetSubscription,
  addSubscription as apiAddSubscription,
  editSubscription as apiEditSubscription,
  updatePositionSubElements as apiUpdatePositionSubElements,
  setAssetTagSubElements as apiSetAssetTagSubElements,
  getInviteInfo as apiGetInviteInfo,
  getBreadcrumbTrail as apiGetBreadcrumbTrail
} from '@/api/endpoints/board';
import { defaultSort, sortOptions } from '@/utils/sort';
import { CARD_DISPLAY_GRID, CARD_DISPLAY_ORGANIC } from '@/utils/asset';
import { BOARD_TYPE, FOLDER_TYPE, CARD_TYPE } from '@/utils/types';
import { track } from '@/utils/tracking';

const defaultLimit = 30;

const getDefaultFilter = () => {
  return {
    keys: {},
    types: [],
    displayHidden: false,
    displayPublicSubBoards: false,
    hasFilters: false
  };
};

const initialState = {
  share: false,
  current: null,
  breadcrumbs: [],
  brief: null,

  subBoards: [],
  hasSubBoardsNextPage: false,

  folders: [],
  hasFoldersNextPage: false,

  cards: [],
  hasCardsNextPage: false,

  cardDisplay: CARD_DISPLAY_GRID,

  filter: getDefaultFilter(),
  sort: defaultSort,
  searchText: null,
  moveSubElementsType: null,

  selectingForGrouping: false,
  selectedAssetTag: null,
  selectedGroupingAssetIds: new Map()
};

export const key = BOARD_TYPE;
export const useBoardStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
      this.filter = getDefaultFilter();
    },
    async assetExists(options = {}) {
      const { id, current = false } = options;
      if (!this.current) return false;

      const currentExists = this.current.id === id;

      if (current) return currentExists;
      const result =
        currentExists ||
        this.brief?.id === id ||
        this.brief?.board.id === id ||
        this.breadcrumbs.some((x) => x.id === id) ||
        this.subBoards.some((x) => x.id === id) ||
        this.folders.some((x) => x.id === id || x.board.id === id) ||
        this.cards.some((x) => x.id === id || x.board.id === id);

      if (result) options.share = this.share;

      return result;
    },
    async fetch({ id, share = false }) {
      return await apiGet(id, false, share);
    },
    async modify({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await (id ? apiUpdate(id, data) : apiCreate(data));

        if (id) {
          await eventDispatcher.assetUpdated(newItem);
        } else {
          this.track({ event: 'created', item: newItem });
        }

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async toggleHidden(item) {
      const { id, hidden } = item;

      const newItem = await this.modify({
        id,
        hidden: !hidden
      });

      if (newItem.hidden) {
        this.track({ event: 'hidden', item: newItem });
      }

      return newItem;
    },
    async get({ id, share }) {
      const gettingNewItem = this.current?.id !== id;
      if (gettingNewItem) {
        await this.reset();
      }

      const newItem = await apiGet(id, true, share);

      if (!share) {
        if (!newItem || (newItem.private && !newItem.partOfParticipants)) {
          const appStore = useAppStore();
          appStore.setNavigateTo({ name: 'root' });
          return;
        }
      }

      this.share = share;

      const [breadcrumbs, brief] = await Promise.all([
        apiGetBreadcrumbTrail(id, this.share),
        apiGetBrief(id, this.share)
      ]);

      this.current = newItem;
      this.breadcrumbs = breadcrumbs;
      this.brief = brief;

      if (gettingNewItem) {
        await this.refreshLists();
      }

      return newItem;
    },
    async getBrief() {
      if (!this.current) return;

      this.brief = await apiGetBrief(this.current.id, this.share);
      return this.brief;
    },
    async refreshLists() {
      await Promise.all([
        this.refreshSubBoards(),
        this.refreshFolders(),
        this.refreshCards()
      ]);
    },
    async getSubBoards(options = {}) {
      if (!this.current) return;

      if (this.share) {
        this.subBoards = [];
        this.hasSubBoardsNextPage = false;
        return { items: [], hasNextPage: false };
      }

      const { sort, filter, subBoards, searchText, moveSubElementsType } = this;

      if (
        filter.types.length > 0 &&
        !filter.types.some((type) => type === BOARD_TYPE)
      ) {
        this.subBoards = [];
        this.hasSubBoardsNextPage = false;

        return { items: [], hasNextPage: false };
      }

      const { start = subBoards.length } = options;
      let limit =
        start === 0 && subBoards.length > defaultLimit
          ? subBoards.length
          : defaultLimit;
      if (moveSubElementsType === BOARD_TYPE) limit = null;

      const { items, hasNextPage } = await apiGetSubBoards(this.current.id, {
        query: { start, sort: sortOptions[sort], limit },
        text: searchText || '',
        displayHidden: filter.displayHidden,
        displayPublicSubBoards: filter.displayPublicSubBoards
      });

      this.subBoards = start === 0 ? items : [...this.subBoards, ...items];
      this.hasSubBoardsNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshSubBoards() {
      await this.getSubBoards({ start: 0 });
    },
    async getFolders(options = {}) {
      if (!this.current) return;

      const { sort, filter, folders, searchText, moveSubElementsType } = this;

      if (
        filter.types.length > 0 &&
        !filter.types.some((type) => type === FOLDER_TYPE)
      ) {
        this.folders = [];
        this.hasFoldersNextPage = false;

        return { items: [], hasNextPage: false };
      }

      const { start = folders.length } = options;
      let limit =
        start === 0 && folders.length > defaultLimit
          ? folders.length
          : defaultLimit;
      if (moveSubElementsType === FOLDER_TYPE) limit = null;

      const { items, hasNextPage } = await apiGetFolders(
        this.current.id,
        {
          query: { start, sort: sortOptions[sort], limit },
          text: searchText || '',
          displayHidden: filter.displayHidden
        },
        this.share
      );

      this.folders = start === 0 ? items : [...this.folders, ...items];
      this.hasFoldersNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshFolders() {
      await this.getFolders({ start: 0 });
    },
    async getCards(options = {}) {
      if (!this.current) return;

      const { sort, filter, cards, searchText, moveSubElementsType } = this;

      if (
        filter.types.length > 0 &&
        !filter.types.some((type) => type === CARD_TYPE)
      ) {
        this.cards = [];
        this.hasCardsNextPage = false;

        return { items: [], hasNextPage: false };
      }

      const { start = cards.length } = options;
      let limit =
        start === 0 && cards.length > defaultLimit
          ? cards.length
          : defaultLimit;
      if (moveSubElementsType === CARD_TYPE) limit = null;

      const { items, hasNextPage } = await apiGetCards(
        this.current.id,
        {
          query: { start, sort: sortOptions[sort], limit },
          text: searchText || '',
          displayHidden: filter.displayHidden
        },
        this.share
      );

      this.cards = start === 0 ? items : [...this.cards, ...items];
      this.hasCardsNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async refreshCards() {
      await this.getCards({ start: 0 });
    },
    async setFilter(newFilter) {
      const { filter } = this;

      if (filter.keys[newFilter.key]) {
        if (newFilter.type) {
          filter.types = filter.types.filter((x) => x !== newFilter.key);
        } else {
          filter[newFilter.key] = false;
        }

        delete filter.keys[newFilter.key];
      } else {
        filter.keys[newFilter.key] = newFilter.key;

        if (newFilter.type) {
          filter.types.push(newFilter.key);
        } else {
          filter[newFilter.key] = true;
        }
      }

      this.filter = {
        ...filter,
        hasFilters:
          filter.displayHidden ||
          filter.displayPublicSubBoards ||
          filter.types.length > 0
      };

      await this.refreshLists();
    },
    async setSort(sort) {
      this.sort = sort;
      await this.refreshLists();
    },
    async search({ text }) {
      this.searchText = text;
      await this.refreshLists();
    },
    async clearSearch() {
      this.searchText = null;
      await this.refreshLists();
    },
    async clearFilters() {
      this.filter = getDefaultFilter();
      this.sort = defaultSort;
      this.searchText = null;

      await this.refreshLists();
    },
    async getSubscription({ parentId }) {
      return await apiGetSubscription(parentId);
    },
    async addSubscription({ parentId, data }) {
      return await apiAddSubscription(parentId, data);
    },
    async editSubscription({ parentId, id, data }) {
      return await apiEditSubscription(parentId, id, data);
    },
    async validateCopy({ id, data }) {
      return await apiValidateCopy(id, data);
    },
    async copy({ id, items }) {
      if (this.current?.id !== id) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiCopy(id, items);
        this.track({ event: 'copied', item: newItem });
        await eventDispatcher.assetUpdated(newItem);
        await this.refreshLists();

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async move({ id, items }) {
      if (this.current?.id !== id) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiMove(id, items);
        this.track({ event: 'moved', item: newItem });
        await eventDispatcher.assetUpdated(newItem);
        await this.refreshLists();
        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async moveTo({ id, item }) {
      const newItem = await apiMove(id, [item]);
      this.track({ event: 'moved', item: newItem });
      return newItem;
    },
    async updatePositionSubElements({ item: movedItem, newIndex }) {
      if (!this.current) return;

      const {
        subBoards,
        hasSubBoardsNextPage,
        folders,
        hasFoldersNextPage,
        cards,
        hasCardsNextPage
      } = this;

      const data = {
        type: movedItem.type,
        positions: []
      };

      let items = [];
      let hasNextPage = false;
      if (movedItem.type === BOARD_TYPE) {
        items = [...subBoards];
        hasNextPage = hasSubBoardsNextPage;
      } else if (movedItem.type === FOLDER_TYPE) {
        items = [...folders];
        hasNextPage = hasFoldersNextPage;
      } else if (movedItem.type === CARD_TYPE) {
        items = [...cards];
        hasNextPage = hasCardsNextPage;
      }

      const index = items.findIndex((x) => !x.hidden && x.id === movedItem.id);
      if (index === -1) return;
      const item = items[index];

      items.splice(index, 1);
      items.splice(newIndex, 0, item);

      let i = 1;
      items
        .filter((x) => !x.hidden)
        .forEach((item) => {
          if (i !== item.position) {
            data.positions.push({ id: item.id, position: i });
          }

          item.position = i;
          i++;
        });

      await apiUpdatePositionSubElements(this.current.id, data);

      if (movedItem.type === BOARD_TYPE) {
        this.subBoards = items;
        this.hasSubBoardsNextPage = hasNextPage;
      } else if (movedItem.type === FOLDER_TYPE) {
        this.folders = items;
        this.hasFoldersNextPage = hasNextPage;
      } else if (movedItem.type === CARD_TYPE) {
        this.cards = items;
        this.hasCardsNextPage = hasNextPage;
      }
    },
    async setAssetTagSubElements(data) {
      if (!this.current) return;
      await apiSetAssetTagSubElements(this.current.id, data);
    },
    async assetTagSelected(assetTag) {
      this.selectedAssetTag = assetTag;
      this.selectingForGrouping = true;
    },
    async setGroupingAsset(item) {
      const { asset, selected } = item;
      const ids = new Map(this.selectedGroupingAssetIds);
      if (selected) {
        ids.set(asset.id, true);
      } else {
        ids.delete(asset.id);
      }

      this.selectedGroupingAssetIds = ids;
    },
    async quitGroupingSelection() {
      this.selectingForGrouping = false;
      this.selectedAssetTag = null;
      this.selectedGroupingAssetIds = new Map();
    },
    async getInviteInfo(token) {
      return await apiGetInviteInfo(token);
    },
    async refreshBreadcrumbs(id) {
      if (!this.current) return;

      if (this.current.id === id || this.breadcrumbs.some((x) => x.id === id)) {
        this.breadcrumbs = await apiGetBreadcrumbTrail(this.current.id);
      }
    },
    async deleteItem(item) {
      if (this.subBoards.some((x) => x.id === item.id)) {
        this.subBoards = this.subBoards.filter((asset) => asset.id !== item.id);
      } else if (this.folders.some((x) => x.id === item.id)) {
        this.folders = this.folders.filter((asset) => asset.id !== item.id);
      } else if (this.cards.some((x) => x.id === item.id)) {
        this.cards = this.cards.filter((asset) => asset.id !== item.id);
      }

      if (this.brief && this.brief.id === item.id) {
        this.brief = null;
      }
    },
    async hideItem(item) {
      if (this.current?.id !== item.parent.id) return;

      const { filter } = this;
      if (filter.displayHidden) return;

      if (item.type === BOARD_TYPE) {
        if (!this.subBoards.some((x) => x.id === item.id)) return;
        if (this.hasSubBoardsNextPage) {
          await this.refreshSubBoards();
          return;
        }
      } else if (item.type === FOLDER_TYPE) {
        if (!this.folders.some((x) => x.id === item.id)) return;
        if (this.hasFoldersNextPage) {
          await this.refreshFolders();
          return;
        }
      } else if (item.type === CARD_TYPE) {
        if (!this.cards.some((x) => x.id === item.id)) return;
        if (this.hasCardsNextPage) {
          await this.refreshCards();
          return;
        }
      }

      await this.deleteItem(item);
    },
    async displayItem(item) {
      if (this.current?.id !== item.parent.id) return;

      const { filter } = this;
      if (filter.displayHidden) return;

      if (item.type === BOARD_TYPE) {
        if (this.subBoards.some((x) => x.id === item.id)) return;
        await this.refreshSubBoards();
      } else if (item.type === FOLDER_TYPE) {
        if (this.folders.some((x) => x.id === item.id)) return;
        await this.refreshFolders();
      } else if (item.type === CARD_TYPE) {
        if (this.cards.some((x) => x.id === item.id)) return;
        await this.refreshCards();
      }
    },
    async restoreFromBin(item) {
      if (this.current?.id !== item.parentId) return;

      if (item.brief) {
        await this.getBrief();
      } else if (item.type === FOLDER_TYPE) {
        await this.refreshFolders();
      } else if (item.type === CARD_TYPE) {
        await this.refreshCards();
      }
    },
    async setMoveSubElementsType(type) {
      this.moveSubElementsType = type;
    },
    async setCardDisplay(cardDisplay) {
      this.cardDisplay =
        cardDisplay === CARD_DISPLAY_GRID
          ? CARD_DISPLAY_GRID
          : CARD_DISPLAY_ORGANIC;
    },
    async assetTagHasChanged({ id }) {
      if (this.current?.id !== id) return;
      await this.refreshCards();
    },
    async assetUpdated(item) {
      if (!this.current) return;

      if (this.breadcrumbs.some((x) => x.id === item.id)) {
        this.breadcrumbs = this.breadcrumbs.map((asset) => {
          return asset.id === item.id ? { ...asset, name: item.name } : asset;
        });
      }

      if (this.subBoards.some((x) => x.id === item.id)) {
        this.subBoards = this.subBoards.map((x) => {
          return x.id !== item.id ? x : { ...item };
        });
      } else if (
        this.folders.some((x) => x.id === item.id || x.board?.id === item.id)
      ) {
        this.folders = this.folders.map((x) => {
          if (x.id === item.id) {
            return { ...item };
          } else if (x.board?.id === item.id && item.board) {
            const newItem = { ...x };
            newItem.board = { ...item.board };
            return newItem;
          } else return x;
        });
      } else if (
        this.cards.some((x) => x.id === item.id || x.board?.id === item.id)
      ) {
        this.cards = this.cards.map((x) => {
          if (x.id === item.id) {
            return { ...item };
          } else if (x.board?.id === item.id && item.board) {
            const newItem = { ...x };
            newItem.board = { ...item.board };
            return newItem;
          } else return x;
        });
      }

      if (this.current?.id === item.id) {
        this.current = { ...item };
      }

      if (this.brief) {
        if (this.brief.id === item.id) {
          this.brief = { ...item };
        } else if (this.brief.board.id === item.id) {
          const brief = { ...this.brief };
          brief.board = item.board;
          this.brief = brief;
        }
      }
    },
    async assetDeleted({ id }) {
      if (!this.current) return;

      if (this.current.id === id) {
        const appStore = useAppStore();
        await appStore.setNavigateTo({ name: 'root' });
        return;
      }

      if (this.brief?.id === id) {
        await this.getBrief();
      } else if (this.subBoards.some((x) => x.id === id)) {
        await this.refreshSubBoards();
      } else if (this.folders.some((x) => x.id === id)) {
        await this.refreshFolders();
      } else if (this.cards.some((x) => x.id === id)) {
        await this.refreshCards();
      }
    },
    async track({ event, item, isShared }) {
      if (isShared && useAuthStore().isInPreviewWebShare) return;

      await track(event, {
        object: item.parent ? 'sub_project_board' : 'project_board',
        id: item.id,
        name: item.name,
        time: new Date().toISOString(),
        sandBox: item.sandBox,
        protected: item.protected,
        sharingAllowed: item.sharingAllowed,
        copyAllowed: item.copyAllowed,
        private: item.private,
        isShared: !!isShared
      });
    }
  }
});

export default {
  key,
  use: useBoardStore
};
