<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.692 21.692"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M312.276,597.429h-7.309V590.12a1.768,1.768,0,0,0-3.537,0v7.309h-7.309a1.768,1.768,0,1,0,0,3.537h7.309v7.309a1.768,1.768,0,0,0,3.537,0v-7.309h7.309a1.768,1.768,0,1,0,0-3.537Z"
      transform="translate(-292.353 -588.352)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'CreationIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
