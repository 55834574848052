<template>
  <div class="evaluation-card-section" :class="$attrs.class">
    <div v-if="canEdit" class="evaluation-card-section__header">
      <div
        class="evaluation-card-section__header--add"
        @click="$emit('select')"
      >
        <Creation />
        <div>{{ $t('evaluationForm.card.add') }}</div>
      </div>
      <div
        class="evaluation-card-section__header--delete"
        :class="{ disabled: cards.length === 0 }"
        @click="confirmDeleteAll"
      >
        <div>{{ $t('evaluationForm.card.deleteAll') }}</div>
      </div>
    </div>
    <ListPageable
      displayType="list"
      :items="cards"
      :load="load"
      :hasNextPage="hasNextPage"
      :isEmpty="cards.length === 0"
    >
      <template #content-item="{ item }">
        <ListItem
          :item="item"
          :type="item.type"
          @delete="confirmDelete"
          :canDisplayEvaluation="false"
          :withActions="false"
          :showDelete="canEdit"
          :withLink="false"
        />
      </template>
      <template #content-empty>
        <span>{{ $t('evaluationForm.card.emptyText') }}</span>
      </template>
    </ListPageable>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="deleteItem"
      @cancel="clearDeleteInfo"
    >
      {{ $t('evaluationForm.card.confirmDelete') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmDeleteAll'"
      prompt
      level="warning"
      @ok="deleteAll"
      @cancel="clearDeleteInfo"
    >
      {{ $t('evaluationForm.card.confirmDeleteAll') }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useEvaluationStore } from '@/stores/evaluation';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import ListPageable from '../layout/ListPageable';
import ListItem from '../layout/ListItem';
import Creation from '../icons/Creation';

export default {
  inheritAttrs: false,
  name: 'EvaluationCardSection',
  mixins: [toggleState('toast')],
  components: {
    ToastAlert,
    Creation,
    ListPageable,
    ListItem
  },
  props: {
    evaluation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      current: null,
      cards: [],
      hasNextPage: false,
      hasPrevPage: false
    };
  },
  computed: {
    canEdit() {
      return !this.evaluation.sent || this.evaluation.editing;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useEvaluationStore, ['getCards', 'updateCards']),
    async load(options = {}) {
      const { start = this.cards.length } = options;

      try {
        const { items, hasNextPage, hasPrevPage } = await this.getCards({
          id: this.evaluation.id,
          options: {
            query: { start }
          }
        });

        this.cards = start === 0 ? items : [...this.cards, ...items];
        this.hasNextPage = hasNextPage;
        this.hasPrevPage = hasPrevPage;

        return { items, hasNextPage, hasPrevPage };
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    confirmDelete(item) {
      this.current = item;
      this.toggleToast('confirmDelete');
    },
    async deleteItem() {
      try {
        const { id } = this.current;
        const evaluation = await this.updateCards({
          id: this.evaluation.id,
          cardsToDelete: [id]
        });
        this.$emit('countChanged', evaluation.cardCount);
        this.cards = [...this.cards.filter((x) => x.id !== id)];
        this.clearDeleteInfo();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    confirmDeleteAll() {
      this.toggleToast('confirmDeleteAll');
    },
    async deleteAll() {
      try {
        const evaluation = await this.updateCards({
          id: this.evaluation.id,
          deleteAll: true
        });
        this.$emit('countChanged', evaluation.cardCount);
        this.clearDeleteInfo();
        await this.load({ start: 0 });
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    clearDeleteInfo() {
      this.current = null;
      this.toggleToast();
    }
  },
  async beforeMount() {
    await this.load({ start: 0 });
  }
};
</script>

<style lang="scss" scoped>
.evaluation-card-section {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;
  flex: 1;

  &__header {
    @include flexy($align: center, $just: space-between);
    background-color: $background-light;
    color: $font-dark;
    font-weight: $font-weight-bold;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;

    &--add {
      @include flexy($align: center);

      > * {
        margin-right: $spacing-half;
      }
    }

    &--add,
    &--delete {
      height: 100%;
      cursor: pointer;

      > * {
        @include flexy($align: center);
        height: 100%;
      }
    }

    &--delete.disabled {
      opacity: $disable-opacity;
      cursor: default;
    }
  }
}
</style>
