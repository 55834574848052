import vSelect from 'vue-select';
import BaseInput from '@/components/forms/BaseInput';
import BasePasswordInput from '@/components/forms/BasePasswordInput';
import BaseCheckbox from '@/components/forms/BaseCheckbox';
import BaseRadio from '@/components/forms/BaseRadio';
import BaseDatetimeInput from '@/components/forms/BaseDatetimeInput';

export const loadBaseComponents = (app) => {
  app.component('BaseInput', BaseInput);
  app.component('BasePasswordInput', BasePasswordInput);
  app.component('BaseCheckbox', BaseCheckbox);
  app.component('BaseRadio', BaseRadio);
  app.component('BaseDatetimeInput', BaseDatetimeInput);
  app.component('v-select', vSelect);
};
