import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';

import {
  get as apiGet,
  getAll as apiGetAll,
  create as apiCreate,
  update as apiUpdate,
  del as apiDel,
  getMembers as apiGetMembers,
  updateMembers as apiUpdateMembers,
  inviteMultipleMembers as apiInviteMultipleMembers,
  syncMembers as apiSyncMembers,
  searchMembers as apiSearchMembers,
  getInviteInfo as apiGetInviteInfo
} from '@/api/endpoints/group';
import { exists as apiEmailExists } from '@/api/endpoints/email';
import { GROUP_TYPE } from '@/utils/types';

const baseFilter = {};

const initialState = {
  loading: 0,
  current: null,
  groups: [],
  hasNextPage: false,
  filter: {},
  searchText: null,
  members: [],
  memberHasNextPage: false
};

export const key = GROUP_TYPE;
export const useGroupStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, { ...initialState });
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async exists({ id }) {
      return this.current?.id === id || this.groups.some((x) => x.id === id);
    },
    async updateItem(item) {
      this.groups = this.groups.map((group) =>
        group.id === item.id ? { ...item } : group
      );
      if (this.current && this.current.id === item.id) {
        this.current = { ...item };
      }
    },
    async refresh({ id }) {
      const item = await apiGet(id);
      if (!item) return item;

      await this.updateItem(item);

      return item;
    },
    async apiGet(id) {
      return await apiGet(id);
    },
    async create(data) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiCreate(data);

        await this.updateItem(newItem);

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async update({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiUpdate(id, data);

        await this.updateItem(newItem);

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async delete(item) {
      await apiDel(item.id);
      await this.itemDeleted(item);

      if (this.current?.id === item.id) {
        await this.reset();
      }
    },
    async get(options = {}) {
      const { filter = {}, groups, searchText } = this;
      let { start = groups.length, limit, text } = options;

      if (text === undefined) text = searchText;

      const apiSort = {
        name: 'asc'
      };

      const apiFilter = {
        ...baseFilter,
        ...filter
      };

      await this.startLoading();

      try {
        const { items, hasNextPage } = await apiGetAll({
          query: { start, sort: apiSort, limit, filter: apiFilter },
          text: text || ''
        });

        this.groups = start === 0 ? items : [...groups, ...items];
        this.hasNextPage = hasNextPage;
        this.searchText = text;

        return { items, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async setCurrent(id) {
      this.current = await apiGet(id);
    },
    async clearCurrent() {
      this.current = null;
      this.members = [];
      this.memberHasNextPage = false;
    },
    async setFilter(filter) {
      this.filter = filter;
      await this.get({ start: 0 });
    },
    async search({ text }) {
      await this.get({ start: 0, text });
    },
    async clearSearch() {
      await this.get({ start: 0, text: '' });
    },
    async clearFilters() {
      this.filter = {};
      await this.get({ start: 0, text: '' });
    },
    async getMembers(options = {}) {
      if (!this.current) return null;

      const { members, searchText } = this;
      let { start = members.length, limit, text } = options;

      if (text === undefined) text = searchText;

      await this.startLoading();

      try {
        const { items, hasNextPage } = await apiGetMembers(this.current.id, {
          query: { start, limit },
          text: text || ''
        });

        this.members = start === 0 ? items : [...members, ...items];
        this.memberHasNextPage = hasNextPage;
        this.searchText = text;

        return { items, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async memberSearch({ text }) {
      await this.getMembers({ start: 0, text });
    },
    async clearMemberSearch() {
      await this.getMembers({ start: 0, text: '' });
    },
    async clearMemberFilters() {
      await this.getMembers({ start: 0, text: '' });
    },
    async apiGetMembers({ id, options = {} }) {
      return await apiGetMembers(id, options);
    },
    async updateMembers({ id, ...data }) {
      return await apiUpdateMembers(id, data);
    },
    async inviteMultipleMembers({ id, ...data }) {
      return await apiInviteMultipleMembers(id, data);
    },
    async syncMembers({ id, ...data }) {
      return await apiSyncMembers(id, data);
    },
    async searchMembers({ id, text }) {
      if (!text || text.length < 2) return [];
      return await apiSearchMembers(id, { text });
    },
    async getInviteInfo(token) {
      return await apiGetInviteInfo(token);
    },
    async emailExists(email) {
      const s = (email || '').toString().trim();
      if (!s) return false;
      return await apiEmailExists(s);
    },
    async deleteMember({ id }) {
      if (!this.current) return null;

      if (this.members.some((x) => x.id === id)) {
        this.members = this.members.filter((x) => x.id !== id);
      }
    },
    async itemDeleted({ id }) {
      if (this.groups.some((x) => x.id === id)) {
        this.groups = this.groups.filter((x) => x.id !== id);
      }

      if (this.current?.id === id) {
        this.current = null;
        this.members = [];
        this.memberHasNextPage = false;
      }
    }
  }
});

export default {
  key,
  use: useGroupStore
};
