import { mapActions } from 'pinia';
import { mapStores } from '@/stores';

export default (types) => {
  const methods = {};

  types.forEach((type) => {
    const useStore = mapStores.get(type).use;

    const mapping = {};
    mapping[`${type}Modify`] = 'modify';

    const maps = {
      ...mapActions(useStore, mapping)
    };

    methods[`${type}Modify`] = maps[`${type}Modify`];
  });

  return {
    methods
  };
};
