import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useGroupStore } from '@/stores/group';
import auth from '../auth';
import { ROLE_ADMINISTRATOR } from '../../utils/role';
const GroupMember = () => import('../../components/group/GroupMember');
const GroupView = () => import('../../components/group/GroupView');
const RegisterFormDialogue = () =>
  import('../../components/auth/RegisterFormDialogue');

export default () => {
  return [
    {
      path: '/group/:id/invite/:token',
      name: 'inviteGroup',
      component: RegisterFormDialogue,
      props: (route) => ({ token: route.params.token }),
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isLoggedIn } = authStore;
        const { token } = to.params;

        try {
          const groupStore = useGroupStore();
          const { errorMessage, accountId, email, companyName } =
            await groupStore.getInviteInfo(token);

          if (errorMessage) {
            const appStore = useAppStore();
            appStore.displayError({ message: errorMessage });
            return { name: 'root' };
          }

          if (isLoggedIn) {
            return { name: 'root' };
          } else if (accountId) {
            const redirectUrl = '/';
            return {
              name: 'login',
              query: { redirectUrl, account: companyName, email }
            };
          }
        } catch (e) {
          console.error(e);
          return { name: 'root' };
        }
      }
    },
    {
      path: '/groups',
      name: 'groups',
      component: GroupView,
      beforeEnter: auth.ensureUserHasRole(ROLE_ADMINISTRATOR)
    },
    {
      path: '/group/:id',
      name: 'group',
      component: GroupMember,
      props: (route) => ({ ...route.params, ...route.query }),
      beforeEnter: auth.ensureUserHasRole(ROLE_ADMINISTRATOR)
    }
  ];
};
