<template>
  <component
    ref="container"
    :is="dialogue"
    class="view-media-dialogue"
    :class="[`view-media-dialogue--${item.mediaType}`, $attrs.class]"
    title=""
    :show-ok="false"
    :show-cancel="false"
    :content-maximized="contentMaximized"
    :maximized="isMaximized"
    :zIndex="zIndex"
    :defaultWidth="defaultWidth"
    :defaultHeight="defaultHeight"
    @selected="$emit('selected')"
    @resize="resize"
    @cancel="$emit('cancel')"
  >
    <template #head>
      <div class="view-media-dialogue__header">
        <DblClickElement
          class="view-media-dialogue__header--left"
          @dblclick="toggleMaximize"
          @dbltouch="toggleMaximize"
        >
          <AvatarPill :info="item.author" />
          <MediaPill :mediaType="item.mediaType" size="small" />
        </DblClickElement>

        <DblClickElement
          class="view-media-dialogue__header--name"
          @dblclick="toggleMaximize"
          @dbltouch="toggleMaximize"
        >
          <VClamp ref="clamp" :maxLines="2" :text="item.name" />
        </DblClickElement>

        <div class="view-media-dialogue__header--right">
          <div
            v-if="displayMaximize"
            class="view-media-dialogue__header--maximize"
            @click="toggleMaximize"
          >
            <UndoMaximizeIcon v-if="isMaximized" size="xsmall2" type="light" />
            <MaximizeIcon v-else size="xsmall2" type="light" />
          </div>
          <div
            v-if="displayActionButton"
            class="view-media-dialogue__header--action"
            @click="toggleDialogue('options')"
          >
            <ActionIcon size="small" type="light" />
          </div>
          <div
            class="view-media-dialogue__header--close"
            @click="$emit('cancel')"
          >
            <CrossIcon size="small" type="light" />
          </div>
        </div>
      </div>
    </template>

    <div class="view-media-dialogue__main" @click="contentClicked">
      <div class="view-media-dialogue__content">
        <!-- Add a key to prevent issue when toggling from one video to another -->
        <component
          ref="mediaContent"
          class="view-media-dialogue__content--inner"
          :key="item.id"
          :is="mediaContent"
          :media="item"
          :width="contentWidth"
          @contentMaximized="contentMaximized = $event"
        />
      </div>
    </div>

    <MediaOptionsDialogue
      v-if="activeDialogue === 'options'"
      :item="item"
      @mediaAddedToClipboard="$emit('cancel')"
      @navigate="$emit('navigate', $event)"
      @cancel="toggleDialogue()"
    />
  </component>
</template>

<script>
import { mapActions } from 'pinia';
import { useMediaStore } from '@/stores/media';
import BaseDialogue from '../dialogues/BaseDialogue';
import FloatableDialogue from '../dialogues/FloatableDialogue';
import DblClickElement from '../extensions/DblClickElement';
import AvatarPill from '../AvatarPill';
import VClamp from '../controls/VueClamp';
import MaximizeIcon from '../icons/Maximize';
import UndoMaximizeIcon from '../icons/UndoMaximize';
import ActionIcon from '../icons/Action';
import CrossIcon from '../icons/Cross';
import ContentAudio from './ContentAudio';
import ContentFile from './ContentFile';
import ContentLink from './ContentLink';
import ContentPhoto from './ContentPhoto';
import ContentText from './ContentText';
import ContentVideo from './ContentVideo';
import ContentNote from './ContentNote';
import MediaPill from './MediaPill';
import TileMedia from '../layout/TileMedia';
import MediaOptionsDialogue from './MediaOptionsDialogue';
import mediaProperties from '../mixins/mediaProperties';
import assetMixin from '../mixins/asset';
import toggleState from '../mixins/toggleState';

const contents = {
  audio: ContentAudio,
  file: ContentFile,
  link: ContentLink,
  photo: ContentPhoto,
  text: ContentText,
  video: ContentVideo,
  note: ContentNote
};

const smallWidth = 425;

export default {
  inheritAttrs: false,
  name: 'ViewMediaDialogue',
  mixins: [mediaProperties, assetMixin, toggleState('dialogue')],
  components: {
    DblClickElement,
    AvatarPill,
    VClamp,
    ActionIcon,
    MaximizeIcon,
    UndoMaximizeIcon,
    CrossIcon,
    MediaPill,
    TileMedia,
    MediaOptionsDialogue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    modal: {
      type: Boolean,
      default: false
    },
    maximize: {
      type: Function,
      default: null
    },
    displayActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      maximized: false,
      contentMaximized: false,
      contentWidth: 375
    };
  },
  computed: {
    dialogue() {
      return this.modal ? BaseDialogue : FloatableDialogue;
    },
    isMaximized() {
      return (
        this.maximized ||
        screen.width <= smallWidth ||
        screen.height <= smallWidth
      );
    },
    displayMaximize() {
      return (
        this.maximize && screen.width > smallWidth && screen.height > smallWidth
      );
    },
    displayActionButton() {
      return (
        this.displayActions &&
        !this.isShared &&
        !this.isSelectionMode &&
        !this.isReadOnly
      );
    },
    mediaContent() {
      return contents[this.item.mediaType];
    },
    defaultWidth() {
      const type = this.item.mediaType;

      if (
        type === 'text' ||
        type === 'photo' ||
        type === 'video' ||
        type === 'note'
      ) {
        return '50%';
      } else if (type === 'link' && this.linkBackgroundImage) {
        return '50%';
      } else if (type === 'file' && this.fileBackgroundImage) {
        return '50%';
      } else {
        return '348px';
      }
    },
    defaultHeight() {
      const type = this.item.mediaType;

      if (
        type === 'text' ||
        type === 'photo' ||
        type === 'video' ||
        type === 'note'
      ) {
        return '50%';
      } else if (type === 'link' && this.linkBackgroundImage) {
        return '50%';
      } else if (type === 'file' && this.fileBackgroundImage) {
        return '80%';
      } else {
        return '348px';
      }
    }
  },
  methods: {
    ...mapActions(useMediaStore, {
      track: 'track'
    }),
    contentClicked() {
      const component = this.$refs.mediaContent;
      if (!component || !component.click) return;
      component.click();
    },
    resize() {
      const { container, clamp, mediaContent } = this.$refs;

      this.contentWidth = container.$el.clientWidth;

      if (mediaContent && mediaContent.resize) {
        mediaContent.resize();
      }

      if (clamp) {
        clamp.cleanUp();
        clamp.update();
      }
    },
    toggleMaximize() {
      if (!this.displayMaximize) return;
      if (this.maximize) this.maximize(this.item);
      // this.maximized = !this.maximized;
    }
  },
  async beforeMount() {
    this.track({
      event: `${this.item.mediaType}_viewed`,
      item: this.item,
      isShared: this.isShared
    });

    window.addEventListener('resize', this.resize);
    window.addEventListener('orientationchange', this.resize);
  },
  mounted() {
    this.$nextTick(() => {
      if (this.item.mediaType === 'video') {
        this.contentClicked();
      }

      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('orientationchange', this.resize);
  }
};
</script>

<style lang="scss">
.view-media-dialogue {
  @include media-dialogue(background-color);
  cursor: inherit;

  &__header {
    @include grid(
      $cols: auto 1fr auto,
      $areas: 'left name right',
      $align: center
    );
    width: 100%;
    height: 100%;
    padding: 0 $spacing-third;
    overflow: hidden;

    > * {
      @include flexy($align: center);
      height: 100%;
    }

    &--left {
      @include grid-item($area: left);
      overflow: hidden;

      .avatar {
        margin-left: $spacing-third;
      }

      .avatar,
      .media-pill {
        margin-right: $spacing-third;
      }
    }

    &--right {
      @include grid-item($area: right);

      > * {
        padding: 0 $spacing-quarter;
      }
    }

    &--name {
      @include grid-item($area: name);
      font-weight: bold;
      cursor: default;
      padding: 0 $spacing-quarter;
      overflow: hidden;
      word-wrap: break-word;
    }

    &--maximize,
    &--action,
    &--close {
      @include flexy($align: center);
      height: 100%;
      padding: 0 $spacing-third;
      cursor: pointer;
    }
  }

  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__main {
    height: 100%;
    overflow: hidden;
  }

  &__main {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex: 1;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--inner {
      height: 100%;
      overflow: hidden;
    }
  }
}
</style>
