<template>
  <label
    class="file-input"
    :class="[
      { 'file-input--cta': cta },
      `file-input--cta--${size}`,
      $attrs.class
    ]"
    :for="name"
  >
    <slot name="content"><slot></slot></slot>
    <input
      type="file"
      ref="input"
      :id="name"
      :name="name"
      v-bind="extraProps"
      @change="$emit('change', $event.target.files)"
    />
  </label>
</template>

<script>
import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from '.';

export default {
  inheritAttrs: false,
  name: 'FileInput',
  props: {
    name: {
      type: String,
      default: 'file'
    },
    accept: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: DEFAULT_BUTTON_SIZE,
      validator: (value) => BUTTON_SIZES.includes(value)
    },
    capture: {
      type: String,
      default: ''
    },
    cta: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    extraProps() {
      const props = {};
      if (this.accept.length > 0) {
        props.accept = this.accept.join(',');
      }
      if (this.capture) {
        props.capture = this.capture;
      }
      return props;
    }
  },
  methods: {
    files() {
      const { input } = this.$refs;
      return input.files;
    }
  }
};
</script>

<style lang="scss">
.file-input {
  &--cta {
    @include cta();
  }

  > input[type='file'] {
    display: none;
  }
}
</style>
