<template>
  <nav class="breadcrumbs" :class="$attrs.class">
    <a
      v-if="canNavigate"
      class="breadcrumbs__back"
      href="#"
      @click.prevent="back"
      @dragstart.prevent
    >
      <Chevron direction="left" :size="arrowSize" title="navigation.back" />
    </a>
    <ItemIcon
      class="breadcrumbs__icon"
      :item="item"
      :type="type"
      :with-link="false"
      :displayIcon="displayIcon"
      :description="description"
    >
      <template #description>
        <slot name="description"></slot>
      </template>
    </ItemIcon>
  </nav>
</template>

<script>
import Chevron from '../icons/Chevron';
import ItemIcon from '../layout/ItemIcon';
import './_breadcrumbs.scss';

export default {
  inheritAttrs: false,
  name: 'BreadcrumbsBar',
  components: { Chevron, ItemIcon },
  props: {
    item: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    canNavigate: {
      type: Boolean,
      default: true
    },
    navigate: {
      type: Function,
      default: null
    },
    displayIcon: {
      type: Boolean,
      default: true
    },
    arrowSize: {
      type: String,
      default: 'medium'
    }
  },
  methods: {
    back() {
      if (this.navigate) this.navigate();
      else this.$router.back();
    }
  }
};
</script>
