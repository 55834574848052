import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'GearIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15.716 15.716"
      >
        <title>{{ text }}</title>
        <g>
          <g>
            <path d="M15.716,9.117V6.6l-1.84-.307a6.163,6.163,0,0,0-.656-1.58L14.3,3.192,12.524,1.411,11,2.5a6.151,6.151,0,0,0-1.58-.656L9.118,0H6.6L6.292,1.84a6.151,6.151,0,0,0-1.58.656L3.192,1.411,1.411,3.192,2.5,4.712a6.163,6.163,0,0,0-.656,1.58L0,6.6V9.117l1.84.307A6.163,6.163,0,0,0,2.5,11l-1.086,1.52L3.192,14.3l1.52-1.086a6.151,6.151,0,0,0,1.58.656l.307,1.84H9.118l.307-1.84A6.151,6.151,0,0,0,11,13.219l1.52,1.086L14.3,12.524,13.219,11a6.163,6.163,0,0,0,.656-1.58Zm-2.427-.051a5.518,5.518,0,0,1-.737,1.776l-.119.186,1.018,1.424-1,1-1.425-1.018-.186.119a5.516,5.516,0,0,1-1.776.738l-.216.048-.287,1.725H7.153l-.287-1.725-.216-.048a5.516,5.516,0,0,1-1.776-.738l-.186-.119L3.263,13.45l-1-1,1.018-1.424-.119-.186a5.518,5.518,0,0,1-.737-1.776L2.38,8.85.655,8.563V7.153L2.38,6.865l.048-.216a5.518,5.518,0,0,1,.737-1.776l.119-.186L2.266,3.263l1-1L4.688,3.284l.186-.119A5.516,5.516,0,0,1,6.65,2.427l.216-.048L7.153.655h1.41L8.85,2.379l.216.048a5.516,5.516,0,0,1,1.776.738l.186.119,1.425-1.018,1,1L12.432,4.687l.119.186a5.518,5.518,0,0,1,.737,1.776l.048.216,1.725.287v1.41l-1.725.287Z" />
          </g>
        </g>
        <g transform="translate(4.584 4.584)">
          <g>
            <path
              d="M146.634,143.36a3.274,3.274,0,1,0,3.274,3.274A3.278,3.278,0,0,0,146.634,143.36Zm0,5.893a2.619,2.619,0,1,1,2.619-2.619A2.622,2.622,0,0,1,146.634,149.253Z"
              transform="translate(-143.36 -143.36)"
            />
          </g>
        </g>
      </svg>
    );
  }
};
