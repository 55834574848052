import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'LinkMedia';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text, style] = getAttributes(name, this.$props);
    return (
      <svg
        class={[...classes, 'icon-media']}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.4 21.3"
      >
        <title>{{ text }}</title>
        <g transform="translate(0 0)">
          <g>
            <g transform="translate(0 0)">
              <path
                d="M22.9,0H1.5C0.7,0,0,0.7,0,1.5v6.7c0,0.8,0.7,1.5,1.5,1.5c0,0,0,0,0,0h7.9l-0.4-5
				c0-0.4,0.1-0.7,0.3-1c0.3-0.3,0.6-0.4,1-0.4c0.3,0,0.5,0.1,0.7,0.2l9.5,6.2h2.2c0.8,0,1.5-0.7,1.5-1.5c0,0,0,0,0,0V1.5
				C24.4,0.7,23.7,0,22.9,0"
              />
              <path
                d="M21,19.2l-3.6-6.1l3.2-1.2c0.2-0.1,0.4-0.4,0.3-0.6c0-0.1-0.1-0.2-0.2-0.2L11,4.8
				c-0.2-0.1-0.5-0.1-0.7,0.1c-0.1,0.1-0.1,0.2-0.1,0.3l0.9,11.5c0,0.3,0.2,0.5,0.5,0.4c0.1,0,0.2,0,0.3-0.1l2.6-2.2l3.6,6.1
				c0.2,0.3,0.6,0.4,0.9,0.2c0,0,0,0,0,0l1.8-1C21.1,20,21.2,19.6,21,19.2C21,19.3,21,19.3,21,19.2"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
};
