import {
  get as genericGet,
  create as genericCreate,
  update as genericUpdate,
  del as genericDelete,
  moveToBin as genericMoveToBin,
  restoreFromBin as genericRestoreFromBin,
  multiPartUploadFile as genericUploadFile,
  getSubscription as genericGetSubscription,
  addSubscription as genericAddSubscription,
  editSubscription as genericEditSubscription,
  MULTI_UPLOAD_FILE_CHUNK_SIZE
} from '../generic';
import { HttpError, get as httpGet, post as httpPost } from '../requests';

export const ENDPOINT_URL = '/medias';

export const create = genericCreate(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const del = genericDelete(ENDPOINT_URL);

export const moveToBin = genericMoveToBin(ENDPOINT_URL);

export const restoreFromBin = genericRestoreFromBin(ENDPOINT_URL);

export const get = genericGet(ENDPOINT_URL);

export async function uploadFile(options) {
  if (!options) throw new Error('The "options" parameter cannot be null!');

  const { id, file } = options;
  if (!id) throw new Error('The "id" parameter cannot be null!');
  if (!file) throw new Error('The "file" parameter cannot be null!');

  // Get the upload URL from the backend
  const fileSize = file.size;
  const chunks = Math.floor(fileSize / MULTI_UPLOAD_FILE_CHUNK_SIZE) + 1;

  const response = await httpPost(`${ENDPOINT_URL}/start-upload/${id}`, {
    fields: {
      filePath: file.name,
      contentType: file.type,
      chunks
    }
  });

  if (!response.ok) {
    throw new HttpError(response.status, await response.text(), response);
  }

  return genericUploadFile({
    ...options,
    endpoint: ENDPOINT_URL,
    multipartInfo: response.json()
  });
}

export const getSubscription = genericGetSubscription(ENDPOINT_URL);

export const addSubscription = genericAddSubscription(ENDPOINT_URL);

export const editSubscription = genericEditSubscription(ENDPOINT_URL);

export const getDownloadSignedUrl = async (id, share = false) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/${id}/download-signed-url${share ? '/share' : ''}`
  );
  return response.json();
};
