import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'StarIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.717 18.801"
      >
        <title>{{ text }}</title>
        <path
          d="M17.269,76.2a.5.5,0,0,1-.276-.082l-5.508-3.626L5.976,76.12a.5.5,0,0,1-.757-.551L6.965,69.21,1.814,65.092a.5.5,0,0,1,.29-.89L8.691,63.9l2.327-6.171a.5.5,0,0,1,.467-.324h0a.5.5,0,0,1,.468.324L14.278,63.9l6.589.306a.5.5,0,0,1,.289.89L16,69.21l1.746,6.359a.5.5,0,0,1-.482.633ZM3.476,65.14l4.371,3.5a.5.5,0,0,1,.17.523l-1.482,5.4,4.676-3.077a.5.5,0,0,1,.549,0l4.676,3.077-1.483-5.4a.5.5,0,0,1,.17-.523l4.372-3.5L13.9,64.88a.5.5,0,0,1-.444-.322l-1.974-5.239L9.511,64.558a.5.5,0,0,1-.445.322Z"
          transform="translate(-1.627 -57.401)"
        />
      </svg>
    );
  }
};
