<template>
  <router-link v-if="withLink" class="item-icon" :class="$attrs.class" :to="to">
    <div
      v-if="displayIcon"
      class="item-icon__image"
      :class="{ 'item-icon__image--small': smallIcon }"
    >
      <div
        class="item-icon__image--icon"
        :class="[
          `item-icon__image--icon--${type + suffix}`,
          { 'item-icon__image--icon--small': smallIcon }
        ]"
      ></div>
    </div>

    <slot name="description">
      <VClamp
        class="item-icon__name"
        :class="`item-icon__name--${type + suffix}`"
        :maxLines="maxLines"
        :text="_description"
      />
    </slot>
  </router-link>
  <div v-else class="item-icon" :class="$attrs.class">
    <div
      v-if="displayIcon"
      class="item-icon__image"
      :class="{ 'item-icon__image--small': smallIcon }"
    >
      <div
        class="item-icon__image--icon"
        :class="[
          `item-icon__image--icon--${type + suffix}`,
          { 'item-icon__image--icon--small': smallIcon }
        ]"
      ></div>
    </div>
    <slot name="description">
      <VClamp
        class="item-icon__name"
        :class="`item-icon__name--${type + suffix}`"
        :maxLines="maxLines"
        :text="_description"
      />
    </slot>
  </div>
</template>

<script>
import VClamp from '../controls/VueClamp';
import {
  BOARD_TYPE,
  CARD_TYPE,
  ACCOUNT_MANAGEMENT_TYPE,
  GROUP_TYPE,
  NOTIFICATION_TYPE,
  SUBSCRIPTION_TYPE,
  COMPANY_TYPE,
  AUDIT_LOGIN_TYPE,
  CHAT_TYPE,
  SHARE_ASSET_TYPE
} from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'ItemIcon',
  components: {
    VClamp
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    withLink: {
      type: Boolean,
      default: true
    },
    maxLines: {
      type: Number,
      default: 2
    },
    displayIcon: {
      type: Boolean,
      default: true
    },
    smallIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    suffix() {
      if (this.item) {
        if (this.type === BOARD_TYPE) {
          if (this.item.sandBox) return '-sandbox';
        } else if (this.type === CARD_TYPE) {
          if (this.item.brief) return '-brief';
        }
      }

      return '';
    },
    _description() {
      const getItemDescription = () => {
        if (this.type === BOARD_TYPE && this.item.sandBox)
          return this.sandBoxName;
        else return this.item.name;
      };

      if (this.description) {
        return this.description;
      } else if (this.type === NOTIFICATION_TYPE) {
        return this.$t('notificationPage.title');
      } else if (this.type === SUBSCRIPTION_TYPE) {
        return this.$t('subscriptionPage.title');
      } else if (this.type === ACCOUNT_MANAGEMENT_TYPE) {
        return this.$t('account.management');
      } else if (this.type === GROUP_TYPE) {
        return this.$t('group.management');
      } else if (this.type === COMPANY_TYPE) {
        return this.$t('company.management');
      } else if (this.type === AUDIT_LOGIN_TYPE) {
        return this.$t('auditLoginPage.title');
      } else if (this.type === CHAT_TYPE) {
        return this.$t('chatPage.title');
      } else if (this.type === SHARE_ASSET_TYPE) {
        return this.$t('shareAsset.title');
      } else if (this.item) return getItemDescription();
      else return '';
    },
    sandBoxName() {
      if (this.item.name === 'SANDBOX') {
        return this.$t('navigation.myWorkspace');
      } else {
        return this.item.name;
      }
    },
    to() {
      if (this.item) {
        return { name: this.type, params: { id: this.item.id } };
      } else {
        return { name: this.type };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-icon {
  @include flexy($align: center);

  &:link,
  &:visited {
    color: inherit;
  }

  &__name {
    max-width: 100%;
    box-sizing: border-box;
    word-wrap: break-word;

    &--company,
    &--notification,
    &--subscription,
    &--account-management,
    &--group,
    &--evaluation,
    &--evaluationAccount,
    &--evaluationResult,
    &--share-asset {
      text-transform: uppercase;
    }
  }

  &__image {
    margin-right: $spacing-half;

    &--icon {
      height: $spacing-base * 1.5;
      width: $spacing-base * 1.5;
      min-width: $spacing-base * 1.5;

      &--small {
        height: 11px;
        width: 11px;
        min-width: 11px;
      }

      &--chat {
        @include svg-background($background-chat-medium-svg, $chat-base);
      }

      &--board {
        @include svg-background($background-board-medium-svg, $board-base);
      }

      &--board-sandbox {
        @include svg-background(
          $background-board-medium-svg,
          $board-sandbox-base
        );
      }

      &--folder {
        @include svg-background($background-folder-large-svg, $folder-base);
      }

      &--card {
        @include svg-background($background-card-large-svg, $card-base);
      }

      &--card-brief {
        @include svg-background($background-card-brief-medium-svg, $brief-base);
      }

      &--notification {
        @include svg-background(
          $background-notification-medium-svg,
          $notification-base
        );
      }

      &--share-asset {
        @include svg-background(
          $background-share-asset-medium-svg,
          $share-asset-base
        );
      }

      &--subscription {
        @include svg-background(
          $background-notification-medium-svg,
          $notification-base
        );
      }

      &--account-management {
        @include svg-background(
          $background-account-management-medium-svg,
          $account-management-base
        );
      }

      &--group {
        @include svg-background($background-group-medium-svg, $group-base);
      }

      &--evaluation,
      &--evaluationAccount,
      &--evaluationResult {
        @include svg-background(
          $background-evaluation-medium-svg,
          $evaluation-base
        );
      }
    }

    &--small {
      margin-right: $spacing-quarter;
    }
  }
}
</style>
