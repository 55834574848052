import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useEvaluationStore } from '@/stores/evaluation';
import { useEvaluationResultStore } from '@/stores/evaluation-result';
import auth from '../auth';
import {
  ROLE_ADMINISTRATOR,
  ROLE_PARTICIPANT_RESTRICTED
} from '../../utils/role';
const EvaluationResult = () =>
  import('../../components/evaluationResult/EvaluationResult');
const EvaluationAccount = () =>
  import('../../components/evaluationAccount/EvaluationAccount');
const EvaluationView = () =>
  import('../../components/evaluation/EvaluationView');
const RegisterFormDialogue = () =>
  import('../../components/auth/RegisterFormDialogue');

export default () => {
  return [
    {
      path: '/evaluation/:id/invite/:token',
      name: 'inviteEvaluation',
      component: RegisterFormDialogue,
      props: (route) => ({
        token: route.params.token,
        redirectUrl: `/evaluation/to-evaluate/?evaluationId=${route.params.id}`
      }),
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isLoggedIn } = authStore;
        const { token } = to.params;

        try {
          const evaluationStore = useEvaluationStore();
          const { errorMessage, accountId, email, evaluationId, companyName } =
            await evaluationStore.getInviteInfo(token);

          if (errorMessage) {
            const appStore = useAppStore();
            appStore.displayError({ message: errorMessage });
            return { name: 'root' };
          }

          if (isLoggedIn) {
            return {
              name: 'evaluationAccount',
              query: { evaluationId }
            };
          } else if (accountId) {
            const redirectUrl = `/evaluation/to-evaluate/?evaluationId=${evaluationId}`;
            return {
              name: 'login',
              query: { redirectUrl, account: companyName, email }
            };
          } else return true;
        } catch (e) {
          console.error(e);
          return { name: 'root' };
        }
      }
    },
    {
      path: '/evaluation',
      name: 'evaluation',
      component: EvaluationView,
      beforeEnter: auth.ensureUserHasRole(ROLE_ADMINISTRATOR)
    },
    {
      path: '/evaluation/to-evaluate',
      name: 'evaluationAccount',
      component: EvaluationAccount,
      props: (route) => ({ ...route.params, ...route.query }),
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    },
    {
      path: '/evaluation-result/:id/invite/:token',
      name: 'inviteEvaluationResult',
      component: RegisterFormDialogue,
      props: (route) => ({
        token: route.params.token,
        redirectUrl: `/evaluation/results/?evaluationId=${route.params.id}`
      }),
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isLoggedIn } = authStore;
        const { token } = to.params;

        try {
          const evaluationResultStore = useEvaluationResultStore();
          const { errorMessage, accountId, email, evaluationId, companyName } =
            await evaluationResultStore.getInviteInfo(token);

          if (errorMessage) {
            const appStore = useAppStore();
            appStore.displayError({ message: errorMessage });
            return { name: 'root' };
          }

          if (isLoggedIn) {
            return {
              name: 'evaluationResult',
              query: { evaluationId }
            };
          } else if (accountId) {
            const redirectUrl = `/evaluation/results/?evaluationId=${evaluationId}`;
            return {
              name: 'login',
              query: { redirectUrl, account: companyName, email }
            };
          } else return true;
        } catch (e) {
          console.error(e);
          return { name: 'root' };
        }
      }
    },
    {
      path: '/evaluation/results',
      name: 'evaluationResult',
      component: EvaluationResult,
      props: (route) => ({ ...route.params, ...route.query }),
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    }
  ];
};
