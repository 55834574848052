<template>
  <OptionsDialogue
    v-if="activeDialogue === 'options'"
    class="card-options-dialogue"
    :class="$attrs.class"
    :title="$t('cardOptions.title')"
    :item="item"
    :type="item.type"
    @cancel="$emit('cancel')"
  >
    <ul>
      <AssetMenuItemIcon
        v-if="canEdit"
        :type="iconType"
        :label="
          item.brief ? $t('cardOptions.brief.edit') : $t('cardOptions.edit')
        "
        @selected.stop="toggleDialogue('edit')"
      />
      <AssetMenuItemIcon
        v-if="canManageBackground"
        :type="iconType"
        :label="$t('background.customize')"
        @selected.stop="toggleDialogue('background')"
      />
      <AssetMenuItemIcon
        v-if="canRemoveColorLabel"
        :type="iconType"
        :label="$t('assetTag.removeColorLabel')"
        @selected.stop="removeColorLabel"
      />

      <AssetMenuItemIcon
        v-if="canToggleBrief"
        :type="iconType"
        :label="
          item.brief
            ? $t('cardOptions.convertToCard')
            : $t('cardOptions.convertToBrief')
        "
        @selected.stop="_toggleBrief"
      />
      <AssetMenuItemIcon
        v-if="canAddComment"
        :label="$t('commentOptions.add')"
        @selected.stop="_displayComments"
      >
        <MessageIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        v-if="canManageSubscription"
        :label="
          item.brief
            ? $t('cardOptions.brief.subscription')
            : $t('cardOptions.subscription')
        "
        @selected.stop="toggleDialogue('subscription')"
      >
        <NotificationIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        v-if="canShare"
        :label="$t('cardOptions.share')"
        @selected.stop="displayShareAssetList(true)"
        :diplayOnlyOnSmallWidth="true"
      >
        <ShareIcon size="xsmall" />
      </AssetMenuItemIcon>
      <AssetMenuItemIcon
        :type="iconType"
        :label="
          item.brief
            ? $t('cardOptions.brief.information')
            : $t('cardOptions.information')
        "
        @selected.stop="toggleDialogue('information')"
      />

      <AssetCommonOptions>
        <AssetCommonOption
          v-if="isDeleted"
          :disabled="!canRestore"
          :label="$t('options.restore')"
          @selected.stop="toggleDeletedItem"
        >
          <RestoreIcon />
        </AssetCommonOption>

        <AssetCommonOption
          :disabled="!canCopyItem"
          :label="$t('options.copy')"
          @selected.stop="addItemToClipboard('copy')"
        >
          <CopyIcon />
        </AssetCommonOption>
        <AssetCommonOption
          v-if="canMoveItem"
          :label="$t('options.move')"
          @selected.stop="addItemToClipboard('move')"
        >
          <MoveIcon />
        </AssetCommonOption>
        <AssetCommonOption
          v-if="canShare"
          :label="$t('options.share')"
          @selected.stop="displayShareAssetList(true)"
          hideOnSmallWidth
        >
          <ShareIcon />
        </AssetCommonOption>

        <AssetCommonOption
          v-if="canToggleHidden"
          :label="$t(`options.${item.hidden ? 'unhide' : 'hide'}`)"
          @selected.stop="toggleHiddenItem"
        >
          <EyeOpenIcon v-if="isHidden" />
          <EyeCloseIcon v-else />
        </AssetCommonOption>

        <AssetCommonOption
          v-if="canDelete"
          :label="$t('options.delete')"
          @selected.stop="confirmDelete"
        >
          <TrashIcon />
        </AssetCommonOption>

        <AssetCommonOption
          v-if="canDeletePermanently"
          :label="$t('options.deletePermanently')"
          @selected.stop="toggleToast('confirmDeletePermanently')"
          warning
        >
          <TrashIcon />
        </AssetCommonOption>
      </AssetCommonOptions>
    </ul>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="toggleDeletedItem"
      @cancel="toggleToast()"
    >
      {{
        item.brief
          ? $t('cardOptions.brief.confirmDelete')
          : $t('cardOptions.confirmDelete')
      }}
    </ToastAlert>
    <ToastAlert
      v-else-if="activeToast === 'confirmDeletePermanently'"
      prompt
      level="error"
      @ok="_deletePermanently"
      @cancel="toggleToast()"
    >
      <div>{{ $t('options.warningDeletePermanently') }}</div>
      <br />
      <div>
        {{
          item.brief
            ? $t('cardOptions.brief.confirmDeletePermanently')
            : $t('cardOptions.confirmDeletePermanently')
        }}
      </div>
    </ToastAlert>
  </OptionsDialogue>

  <InformationDialogue
    v-else-if="activeDialogue === 'information'"
    :class="$attrs.class"
    :item="item"
    @cancel="$emit('cancel')"
  />

  <CardFormDialogue
    v-else-if="activeDialogue === 'edit' && canEdit"
    :class="$attrs.class"
    :editItem="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />

  <CardSubscriptionDialogue
    v-else-if="activeDialogue === 'subscription'"
    :class="$attrs.class"
    :asset="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />
  <BackgroundDialogue
    v-else-if="activeDialogue === 'background'"
    :class="$attrs.class"
    :item="item"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />
</template>

<script>
import OptionsDialogue from '../dialogues/OptionsDialogue';
import AssetMenuItemIcon from '../asset/AssetMenuItemIcon';
import MessageIcon from '../icons/Message';
import NotificationIcon from '../icons/NotificationIcon';
import CopyIcon from '../icons/Copy';
import MoveIcon from '../icons/Move';
import ShareIcon from '../icons/Share';
import EyeOpenIcon from '../icons/EyeOpen';
import EyeCloseIcon from '../icons/EyeClose';
import TrashIcon from '../icons/Trash';
import RestoreIcon from '../icons/Restore';
import AssetCommonOptions from '../asset/AssetCommonOptions';
import AssetCommonOption from '../asset/AssetCommonOption';
import InformationDialogue from '../dialogues/InformationDialogue';
import CardFormDialogue from './CardFormDialogue';
import CardSubscriptionDialogue from './CardSubscriptionDialogue';
import BackgroundDialogue from '../background/BackgroundDialogue';
import toggleState from '../mixins/toggleState';
import cardMixin from './cardMixin';

export default {
  inheritAttrs: false,
  name: 'CardOptionsDialogue',
  mixins: [toggleState('dialogue', 'options'), cardMixin],
  components: {
    OptionsDialogue,
    AssetMenuItemIcon,
    MessageIcon,
    NotificationIcon,
    CopyIcon,
    MoveIcon,
    ShareIcon,
    EyeOpenIcon,
    EyeCloseIcon,
    TrashIcon,
    RestoreIcon,
    AssetCommonOptions,
    AssetCommonOption,
    CardFormDialogue,
    CardSubscriptionDialogue,
    BackgroundDialogue,
    InformationDialogue
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    parentHasBrief: {
      type: Function,
      default: null
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconType() {
      return this.item.brief ? 'brief' : 'card';
    }
  }
};
</script>

<style lang="scss">
.card-options-dialogue__share {
  @include flexy($just: space-between);
  padding-right: $spacing-base;
}
</style>
