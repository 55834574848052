export const ROLE_ROOT_ADMINISTRATOR = 'app_root_administrator';
export const ROLE_ACCOUNT_ADMINISTRATOR = 'app_account_administrator';
export const ROLE_ADMINISTRATOR = 'app_administrator';
export const ROLE_PARTICIPANT = 'app_participant';
export const ROLE_PARTICIPANT_RESTRICTED = 'app_participant_restricted';

export const isUserAccountAdministrator = (user) => {
  if (!user || user.blocked || !user.role) return false;
  return user.role.name === ROLE_ACCOUNT_ADMINISTRATOR;
};

export const isUserAdministrator = (user) => {
  if (!user || user.blocked || !user.role) return false;
  return (
    user.role.name === ROLE_ADMINISTRATOR || isUserAccountAdministrator(user)
  );
};

export const isUserParticipant = (user) => {
  if (!user || user.blocked || !user.role) return false;
  return user.role.name === ROLE_PARTICIPANT || isUserAdministrator(user);
};

export const isUserParticipantRestricted = (user) => {
  if (!user || user.blocked || !user.role) return false;
  return (
    user.role.name === ROLE_PARTICIPANT_RESTRICTED || isUserParticipant(user)
  );
};
