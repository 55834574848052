export const NAVIGATION_TYPE = 'navigation';
export const SEARCH_TYPE = 'search';
export const BACKGROUND_TYPE = 'background';
export const AUDIT_LOGIN_TYPE = 'auditLogin';
export const CHAT_TYPE = 'chat';
export const CHAT_MESSAGE_TYPE = 'chatMessage';

export const SHARE_ASSET_TYPE = 'share-asset';
export const SHARE_ASSET_SEARCH_TYPE = 'share-asset-search';
export const BOARD_TYPE = 'board';
export const FOLDER_TYPE = 'folder';
export const CARD_TYPE = 'card';
export const MEDIA_TYPE = 'media';
export const ORGANIC_TITLE_TYPE = 'organicTitle';

export const ASSET_ORGANIC_TYPE = 'assetOrganic';
export const DASHBOARD_TYPE = 'dashboard';
export const COMMENT_TYPE = 'comment';
export const ACCOUNT_MANAGEMENT_TYPE = 'account-management';
export const GROUP_TYPE = 'group';
export const GROUP_MEMBER_TYPE = 'groupMember';
export const ACCOUNT_TYPE = 'account';
export const NOTIFICATION_TYPE = 'notification';
export const SUBSCRIPTION_TYPE = 'subscription';
export const COMPANY_TYPE = 'company';
export const EVALUATION_TYPE = 'evaluation';
export const EVALUATION_ACCOUNT_TYPE = 'evaluationAccount';
export const EVALUATION_RESULT_TYPE = 'evaluationResult';

export const MEDIA_TYPES = [
  'photo',
  'audio',
  'text',
  'link',
  'video',
  'file',
  'note'
];

export const ASSET_TYPES = [BOARD_TYPE, FOLDER_TYPE, CARD_TYPE, MEDIA_TYPE];
