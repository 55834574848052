import { get as httpGet } from '../requests';

export const ENDPOINT_URL = '/emails';

export async function exists(email) {
  const response = await httpGet(
    `${ENDPOINT_URL}/exists?email=${encodeURIComponent(email)}`
  );
  return response.json();
}
