<template>
  <div class="list-participant" :class="$attrs.class">
    <div class="list-participant__left">
      <div v-if="selectable" class="list-participant__check">
        <BaseCheckbox
          :modelValue="participant.selected"
          @update:modelValue="$emit('selectionChanged', $event)"
        />
      </div>

      <slot name="left">
        <AvatarPill v-if="participant.account" :info="participant.account" />
        <slot name="info" v-bind="participant"></slot>
      </slot>
    </div>
    <div class="list-participant__right">
      <slot name="right">
        <Database v-if="restricted" />
        <button
          v-if="showDelete && !selectable"
          class="icon-button list-participant__delete"
          @click="$emit('delete', participant)"
        >
          <DeleteAction />
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
import DeleteAction from '../icons/Close';
import Database from '../icons/Database';
import AvatarPill from '../AvatarPill';

export default {
  inheritAttrs: false,
  name: 'ListParticipant',
  components: {
    AvatarPill,
    DeleteAction,
    Database
  },
  props: {
    participant: {
      type: Object,
      required: true
    },
    restricted: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.list-participant {
  @include flexy($align: center, $just: space-between);
  background-color: $background-light;
  color: $font-dark;
  font-weight: $font-weight-bold;
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
  padding: 0 $spacing-base;

  &__check {
    @include flexy($align: center);
    border-radius: $base-border-radius;
  }
}

.list-participant__left,
.list-participant__right {
  @include flexy($align: center);
  flex: 1;
  height: 100%;
}

.list-participant__left {
  @include flexy($just: flex-start);

  > * {
    margin-right: $spacing-base;
  }

  > .avatar {
    margin-right: $spacing-third;
  }
}

.list-participant__right {
  @include flexy($just: flex-end);

  > * {
    margin-left: $spacing-base;
  }
}

.list-participant__delete {
  height: 100%;
}

.list-participant__right {
  > .icon-database-icon {
    height: 100%;
    fill: #ef6341;
  }
}
</style>
