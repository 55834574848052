<template>
  <div
    class="asset-common-option"
    :class="[
      { 'asset-common-option--disabled': disabled },
      { 'asset-common-option--hide': hideOnSmallWidth },
      $attrs.class
    ]"
    @click="clicked"
  >
    <div class="asset-common-option__inner">
      <div
        class="asset-common-option__inner--icon"
        :class="{ 'asset-common-option__inner--icon--warning': warning }"
      >
        <slot></slot>
      </div>
      <div class="asset-common-option__inner--label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'AssetCommonOption',
  props: {
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    hideOnSmallWidth: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked(e) {
      if (this.disabled) return;
      this.$emit('selected', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.asset-common-option {
  @include flexy();
  height: 100%;
  cursor: pointer;
  background-color: $background-light;

  &__inner {
    @include flexy($dir: column, $just: center);

    &--icon {
      @include flexy($just: center);
      width: 100%;

      &--warning {
        :deep(.icon) {
          fill: $error-color;
        }
      }
    }

    &--label {
      margin: $spacing-half $spacing-half 0 $spacing-half;
    }
  }

  &--disabled {
    cursor: default;
    opacity: $disable-opacity;
  }

  @media screen and (max-width: $small-size-break) {
    &--hide {
      display: none !important;
    }
  }
}
</style>
