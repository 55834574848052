import { defineStore } from 'pinia';
import { mapStores } from '@/stores';

import { getNavigationItems as apiGetNavigationItems } from '@/api/endpoints/dashboard';
import { getNavigationItems as apiGetBoardNavigationItems } from '@/api/endpoints/board';
import { getNavigationItems as apiGetFolderNavigationItems } from '@/api/endpoints/folder';
import { BOARD_TYPE, FOLDER_TYPE } from '@/utils/types';

let treeviewItems = [];
let allTreeviewItems = {};

const initialState = {
  displayedItems: [],
  selectedItem: null,
  treeviewIsLoaded: false
};

const map = (item, parent, from) => {
  return {
    level: parent ? parent.level + 1 : 0,
    loaded: from ? from.loaded : false,
    expanded: from ? from.expanded : false,
    parentId: parent ? parent.item.id : null,
    item: { ...item },
    items: from ? from.items : []
  };
};

const setAllTreeviewItems = (items) => {
  const setItems = (item) => {
    allTreeviewItems[item.item.id] = item;
    if (item.items) {
      item.items.forEach((item) => {
        setItems(item);
      });
    }
  };

  items.forEach((item) => setItems(item));
};

const setTreeviewItems = (store, items, loaded) => {
  treeviewItems = items;
  setAllTreeviewItems(treeviewItems);

  store.displayedItems = getDisplayedItems(treeviewItems);
  store.treeviewIsLoaded = loaded;
};

const getDisplayedItems = (items) => {
  const displayedItems = [];
  const addItems = (item) => {
    displayedItems.push(item);
    if (item.expanded && item.items) {
      item.items.forEach((item) => {
        addItems(item);
      });
    }
  };

  items.forEach((item) => addItems(item));

  return displayedItems;
};

export const key = 'navigation';
export const useNavigationStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async clearTreeview() {
      allTreeviewItems = {};
      setTreeviewItems(this, [], false);
    },
    async exists({ type, id }) {
      return !!allTreeviewItems[id] && allTreeviewItems[id].item.type === type;
    },
    async parentIsLoaded({ type, id }) {
      const tv = allTreeviewItems[id];
      return tv && tv.loaded && tv.item.type === type;
    },
    async setSelectedItem(item) {
      this.selectedItem = item;
    },
    async loadTreeviewItems() {
      const items = await apiGetNavigationItems();
      const newItems = items.map((x) => {
        return map(x);
      });

      allTreeviewItems = {};
      setTreeviewItems(this, newItems, true);
    },
    async reloadFirstLevel() {
      const items = await apiGetNavigationItems();
      const newItems = items.map((x) => {
        return map(x, null, allTreeviewItems[x.id]);
      });

      allTreeviewItems = {};
      setTreeviewItems(this, newItems, true);
    },
    async toggleItem(item) {
      const { expanded, loaded } = item;

      const update = (item) => {
        const current = allTreeviewItems[item.item.id];

        if (current && !current.loaded && item.loaded) {
          if (current.items) {
            current.items.forEach((x) => {
              delete allTreeviewItems[x.item.id];
            });
          }

          item.items.forEach((x) => {
            allTreeviewItems[x.item.id] = x;
          });
        }

        Object.assign(allTreeviewItems[item.item.id], item);

        const displayedItems = getDisplayedItems(treeviewItems);
        this.displayedItems = displayedItems.map((x) => {
          let newItem = x;
          // For refreshing reference pointer in memory for binding in components
          if (x.item.id === item.item.id) newItem = { ...x };
          return newItem;
        });
      };

      if (!expanded && !loaded) {
        let items;

        if (item.item.type === BOARD_TYPE) {
          items = await apiGetBoardNavigationItems(item.item.id);
        } else {
          items = await apiGetFolderNavigationItems(item.item.id);
        }

        const newItems = items.map((x) => {
          return map(x, item);
        });

        update({ ...item, expanded: true, loaded: true, items: newItems });
      } else {
        update({ ...item, expanded: !expanded });
      }
    },
    async displayItem(item) {
      await this.addItem(item);
    },
    async addItem(item) {
      if (allTreeviewItems[item.id]) return;
      if (item.type !== BOARD_TYPE && item.type !== FOLDER_TYPE) return;

      const { parent } = item;
      let parentItem = null;

      if (parent) {
        parentItem = allTreeviewItems[parent.id];
        if (!parentItem) return;
      }

      const newItem = map(
        {
          id: item.id,
          type: item.type,
          name: item.name,
          freeName: item.freeName
        },
        parentItem
      );

      allTreeviewItems[item.id] = newItem;

      if (parentItem) {
        parentItem.items.push(newItem);
        parentItem.items = parentItem.items.sort((a, b) => {
          const textA = a.item.freeName;
          const textB = b.item.freeName;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else {
        treeviewItems.push(newItem);
        treeviewItems = treeviewItems.sort((a, b) => {
          if (b.item.sandBox) return 1;

          const textA = a.item.freeName;
          const textB = b.item.freeName;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      }

      this.displayedItems = getDisplayedItems(treeviewItems);
    },
    async itemAdded({ id, type }) {
      const store = mapStores.get(type).use();
      const item = await store.fetch({ id });
      if (!item) return;

      await this.addItem(item);
    },
    async deleteItem({ id, type }) {
      const current = allTreeviewItems[id];
      if (!current || current.item.type !== type) return;

      // Delete recursively all sub items in allTreeviewItems list
      const deleteItems = (tv) => {
        tv.items.forEach((x) => deleteItems(x));
        delete allTreeviewItems[tv.item.id];
      };
      current.items.forEach((x) => deleteItems(x));
      delete allTreeviewItems[id];

      if (current.parentId) {
        const parent = allTreeviewItems[current.parentId];
        if (parent) {
          parent.items = parent.items.filter((x) => x.item.id !== id);
        }
      } else {
        treeviewItems = treeviewItems.filter((x) => x.item.id !== id);
      }

      this.displayedItems = getDisplayedItems(treeviewItems);
    },
    async hideItem(item) {
      await this.deleteItem(item);
    },
    async assetUpdated(item) {
      const current = allTreeviewItems[item.id];
      if (!current || current.item.type !== item.type) return;
      if (current.item.name === item.name) return;

      current.item.name = item.name;
      current.item.freeName = item.freeName;

      if (current.parentId) {
        const parent = allTreeviewItems[current.parentId];
        if (parent && parent.items) {
          parent.items = parent.items.sort((a, b) => {
            const textA = a.item.freeName;
            const textB = b.item.freeName;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
      } else {
        treeviewItems = treeviewItems.sort((a, b) => {
          if (b.item.sandBox) return 1;

          const textA = a.item.freeName;
          const textB = b.item.freeName;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      }

      const displayedItems = getDisplayedItems(treeviewItems);
      this.displayedItems = displayedItems.map((x) => {
        // For refreshing reference pointer in memory for binding in components
        if (x.item.id === item.id) x.item = { ...x.item };
        return x;
      });
    },
    async assetDeleted(item) {
      await this.deleteItem(item);
    },
    async restoreFromBin(item) {
      await this.addItem(item);
    }
  }
});

export default {
  key,
  use: useNavigationStore
};
