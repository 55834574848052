<template>
  <BaseDialogue
    class="view-clipboard-dialogue"
    :class="$attrs.class"
    :title="$t('clipboardForm.title')"
    :showClose="true"
    :showCancel="false"
    :showOk="false"
    :showFooterIfEmpty="true"
    @cancel="$emit('cancel')"
  >
    <ul class="clipboard__assets clipboard__assets--list">
      <li
        class="clipboard__asset"
        v-for="asset in assets.values()"
        :key="asset.id"
        data-testid="asset"
      >
        <ListMedia
          v-if="selectedAssetType === 'media'"
          :item="asset"
          :withActions="false"
          :showDelete="true"
          @delete="deleteItem"
        />
        <ListItem
          v-else
          :item="asset"
          :type="selectedAssetType"
          :withActions="false"
          :showDelete="true"
          @delete="deleteItem"
        />
        <div
          v-if="validationMessages.size > 0 && validationMessageCode(asset.id)"
          class="clipboard__assets--list--item--error"
        >
          {{ $t(`clipboard.errors.${validationMessageCode(asset.id)}`) }}
        </div>
      </li>
    </ul>
  </BaseDialogue>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useClipboardStore } from '@/stores/clipboard';
import BaseDialogue from '../dialogues/BaseDialogue';
import ListMedia from '../layout/ListMedia';
import ListItem from '../layout/ListItem';

export default {
  inheritAttrs: false,
  name: 'ViewClipboardDialogue',
  components: {
    BaseDialogue,
    ListMedia,
    ListItem
  },
  computed: {
    ...mapState(useClipboardStore, [
      'assets',
      'selectedAssetType',
      'validationMessages'
    ])
  },
  methods: {
    ...mapActions(useClipboardStore, ['deleteItem']),
    validationMessageCode(id) {
      const item = this.validationMessages.get(id);
      return item && item.code ? item.code : null;
    }
  }
};
</script>

<style lang="scss">
.view-clipboard-dialogue {
  .dialogue__header,
  .dialogue__footer {
    background-color: $clipboard-base;
  }
}

.clipboard__assets {
  @include item-list();

  &--list--item--error {
    color: $error-color;
    font-size: $font-size-small;
    font-weight: bold;
    margin: 0 $spacing-base $spacing-half $spacing-base;
  }
}
</style>
