import { defineStore } from 'pinia';
import eventDispatcher from '@/stores/event-dispatcher';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

import {
  create as apiCreate,
  update as apiUpdate,
  del as apiDelete,
  get as apiGet,
  getAssets as apiGetAssets,
  moveToBin as apiMoveToBin,
  restoreFromBin as apiRestoreFromBin,
  validateCopy as apiValidateCopy,
  copy as apiCopy,
  move as apiMove,
  getSubscription as apiGetSubscription,
  addSubscription as apiAddSubscription,
  editSubscription as apiEditSubscription,
  updatePositionSubElements as apiUpdatePositionSubElements,
  setAssetTagSubElements as apiSetAssetTagSubElements,
  getBreadcrumbTrail as apiGetBreadcrumbTrail,
  getFirstParentNoneDeleted as apiGetFirstParentNoneDeleted
} from '@/api/endpoints/card';
import { getAll as apiGetAssetTags } from '@/api/endpoints/assetTag';
import { track } from '@/utils/tracking';
import { CARD_TYPE, MEDIA_TYPE } from '@/utils/types';

const getDefaultFilter = () => {
  return {
    keys: {},
    displayHidden: false,
    hasFilters: false
  };
};

const initialState = {
  share: false,
  current: null,
  medias: [],
  filter: getDefaultFilter(),
  breadcrumbs: [],

  selectingForGrouping: false,
  selectedAssetTag: null,
  selectedGroupingAssetIds: new Map()
};

export const key = CARD_TYPE;
export const useCardStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
      this.filter = getDefaultFilter();
    },
    async fetch({ id, share }) {
      return await apiGet(id, false, share);
    },
    async modify({ id, ...data }) {
      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await (id ? apiUpdate(id, data) : apiCreate(data));

        if (id) {
          await eventDispatcher.assetUpdated(newItem);
        } else {
          this.track({ event: 'created', item: newItem });
        }

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async toggleHidden(item) {
      const { id, hidden } = item;

      const newItem = await this.modify({
        id,
        hidden: !hidden
      });

      if (newItem.hidden) {
        this.track({ event: 'hidden', item: newItem });
      }

      return newItem;
    },
    async toggleDeleted({ id, deleted }) {
      let newItem;

      if (deleted) {
        newItem = await apiRestoreFromBin(id);
      } else {
        newItem = await apiMoveToBin(id);
      }

      if (newItem.deleted) {
        this.track({ event: 'deleted', item: newItem });
      }

      return newItem;
    },
    async toggleBrief(item) {
      const { id, brief } = item;

      const newItem = await this.modify({
        id,
        brief: !brief
      });

      return newItem;
    },
    async get({ id, share }) {
      const gettingNewItem = this.current?.id !== id;
      if (gettingNewItem) {
        await this.reset();
      }

      const appStore = useAppStore();

      const [newItem, breadcrumbs = []] = await Promise.all([
        apiGet(id, true, share),
        apiGetBreadcrumbTrail(id, share)
      ]);

      if (!share) {
        if (newItem.board) {
          if (newItem.board.private && !newItem.partOfParticipants) {
            await appStore.setNavigateTo({ name: 'root' });
            return;
          }
        } else if (!newItem.partOfParticipants) {
          await appStore.setNavigateTo({ name: 'root' });
          return;
        }
      }

      this.share = share;
      this.current = newItem;
      this.breadcrumbs = breadcrumbs;

      if (gettingNewItem) {
        await this.getAssets();
      }

      return newItem;
    },
    async setMedias(items) {
      this.medias = items;
    },
    async getAssets() {
      if (!this.current) return;

      const { filter } = this;

      const items = await apiGetAssets(
        this.current.id,
        {
          query: {},
          displayHidden: !!filter.displayHidden
        },
        this.share
      );

      await this.setMedias(items);

      return { items };
    },
    async setFilter(newFilter) {
      const { filter } = this;

      if (filter.keys[newFilter.key]) {
        if (newFilter.key === 'displayHidden') {
          filter.displayHidden = false;
        }

        delete filter.keys[newFilter.key];
      } else {
        filter.keys[newFilter.key] = newFilter.key;

        if (newFilter.key === 'displayHidden') {
          filter.displayHidden = true;
        }
      }

      this.filter = {
        ...filter,
        hasFilters: !!filter.displayHidden
      };

      await this.getAssets();
    },
    async clearFilters() {
      this.filter = getDefaultFilter();
      await this.getAssets();
    },
    async getSubscription({ parentId }) {
      return await apiGetSubscription(parentId);
    },
    async addSubscription({ parentId, data }) {
      return await apiAddSubscription(parentId, data);
    },
    async editSubscription({ parentId, id, data }) {
      return await apiEditSubscription(parentId, id, data);
    },
    async validateCopy({ id, data }) {
      return await apiValidateCopy(id, data);
    },
    async copy({ id, items }) {
      if (this.current?.id !== id) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiCopy(id, items);

        await eventDispatcher.assetUpdated(newItem);
        await this.getAssets();
        this.track({ event: 'copied', item: newItem });

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async move({ id, items }) {
      if (this.current?.id !== id) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        const newItem = await apiMove(id, items);

        await eventDispatcher.assetUpdated(newItem);
        await this.getAssets();
        this.track({ event: 'moved', item: newItem });

        return newItem;
      } finally {
        await appStore.stopLoading();
      }
    },
    async updatePositionSubElements({ media, newIndex }) {
      if (!this.current) return;

      const medias = [...this.medias];
      const data = {
        positions: []
      };

      const index = medias.findIndex((x) => !x.hidden && x.id === media.id);
      if (index === -1) return;
      const item = medias[index];

      medias.splice(index, 1);
      medias.splice(newIndex, 0, item);

      let i = 1;
      medias
        .filter((x) => !x.hidden)
        .forEach((media) => {
          if (i !== media.position) {
            data.positions.push({ id: media.id, position: i });
          }

          media.position = i;
          i++;
        });

      await apiUpdatePositionSubElements(this.current.id, data);
      await this.setMedias(medias);
    },
    async assetTagSelected(assetTag) {
      this.selectedAssetTag = assetTag;
      this.selectingForGrouping = true;
    },
    async setGroupingAsset(item) {
      const { asset, selected } = item;
      const ids = new Map(this.selectedGroupingAssetIds);

      if (selected) {
        ids.set(asset.id, true);
      } else {
        ids.delete(asset.id);
      }

      this.selectedGroupingAssetIds = ids;
    },
    async quitGroupingSelection() {
      this.selectingForGrouping = false;
      this.selectedAssetTag = null;
      this.selectedGroupingAssetIds = new Map();
    },
    async setAssetTagSubElements(data) {
      if (!this.current) return;
      await apiSetAssetTagSubElements(this.current.id, data);
    },
    async refreshBreadcrumbs(id) {
      if (!this.current) return;

      if (this.current.id === id || this.breadcrumbs.some((x) => x.id === id)) {
        this.breadcrumbs = await apiGetBreadcrumbTrail(this.current.id);
      }
    },
    async hideItem(item) {
      if (this.current?.id !== item.parent.id) return;

      if (this.filter.displayHidden) return;
      if (!this.medias.some((x) => x.id === item.id)) return;

      if (this.medias.some((x) => x.id === item.id)) {
        this.medias = this.medias.filter((x) => x.id !== item.id);
      }
    },
    async displayItem(item) {
      if (this.current?.id !== item.parent.id) return;

      if (this.filter.displayHidden) return;
      if (this.medias.some((x) => x.id === item.id)) return;
      await this.getAssets();
    },
    async movedToBin(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        const parent = await apiGetFirstParentNoneDeleted(item.id);
        const appStore = useAppStore();

        if (!parent || !this.breadcrumbs.some((x) => x.id === parent.id)) {
          await appStore.setNavigateTo({ name: 'root' });
        } else {
          await appStore.setNavigateTo({
            name: parent.type,
            params: { id: parent.id }
          });
        }

        return;
      }

      if (this.current.id !== item.parentId) return;
      if (item.parentDeleted) return;
      if (item.type !== MEDIA_TYPE) return;

      if (this.medias.some((x) => x.id === item.id)) {
        await this.getAssets();
      }
    },
    async restoreFromBin(item) {
      if (!this.current) return;

      if (this.current.id === item.id) {
        await this.get({ id: item.id });
        await this.getAssets();
        return;
      }

      if (this.current.id !== item.parentId) return;

      await this.getAssets();
    },
    async deletePermanently(item) {
      await apiDelete(item.id);
    },
    async getAssetTags() {
      return await apiGetAssetTags();
    },
    async assetTagHasChanged({ id }) {
      if (this.current?.id !== id) return;
      await this.getAssets();
    },

    async assetExists(options = {}) {
      const { id, current = false } = options;
      if (!this.current) return false;

      const currentExists =
        this.current.id === id || this.current.board?.id === id;
      if (current) return currentExists;

      const result =
        currentExists ||
        this.breadcrumbs.some((x) => x.id === id) ||
        this.medias.some((x) => x.id === id || x.board?.id === id);

      if (result) options.share = this.share;
      return result;
    },
    async assetUpdated(item) {
      if (this.breadcrumbs.some((x) => x.id === item.id)) {
        this.breadcrumbs = this.breadcrumbs.map((x) => {
          return x.id === item.id ? { ...x, name: item.name } : x;
        });
      }

      if (
        this.medias.some((x) => x.id === item.id || x.board?.id === item.id)
      ) {
        this.medias = this.medias.map((x) => {
          if (x.id === item.id) {
            return { ...item };
          } else if (x.board?.id === item.id && item.board) {
            const newItem = { ...x };
            newItem.board = { ...item.board };
            return newItem;
          } else return x;
        });
      }

      if (!this.current) return;

      if (this.current.id === item.id) {
        this.current = { ...item };
      } else if (this.current.board?.id === item.id && item.board) {
        const current = { ...this.current };
        current.board = item.board;
        this.current = current;
      }
    },
    async assetDeleted(item) {
      if (!this.current) return;

      if (this.current.id === item.id || this.current.board?.id === item.id) {
        const appStore = useAppStore();
        await appStore.setNavigateTo({ name: 'root' });
        return;
      }

      if (this.current.id !== item.parentId) return;
      if (item.parentDeleted) return;
      if (item.type !== MEDIA_TYPE) return;

      if (this.medias.some((x) => x.id === item.id)) {
        await this.getAssets();
      }
    },
    async track({ event, item, isShared }) {
      if (isShared && useAuthStore().isInPreviewWebShare) return;

      await track(event, {
        object: item.brief ? 'instruction' : 'card',
        id: item.id,
        name: item.name,
        time: new Date().toISOString(),
        sandBox: !!item.board?.sandBox,
        protected: item.protected,
        isShared: !!isShared
      });
    }
  }
});

export default {
  key,
  use: useCardStore
};
