import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'HamburgerIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.824 16.959"
      >
        <title>{{ text }}</title>
        <g transform="translate(0 0)">
          <path
            d="M108.36,203.481h7.69a1.861,1.861,0,1,0,0-3.722h-19.1a1.861,1.861,0,1,0,0,3.722H108.36Z"
            transform="translate(-95.087 -199.759)"
          />
          <path
            d="M108.36,207.764h7.69a1.862,1.862,0,0,0,0-3.723h-19.1a1.862,1.862,0,0,0,0,3.723H108.36Z"
            transform="translate(-95.087 -197.423)"
          />
          <path
            d="M108.36,212.046h7.69a1.861,1.861,0,1,0,0-3.722h-19.1a1.861,1.861,0,1,0,0,3.722H108.36Z"
            transform="translate(-95.087 -195.087)"
          />
        </g>
      </svg>
    );
  }
};
