// Outgoing events

export const OUTGOING_JOIN = 'join';
export const OUTGOING_LOGOUT = 'userLogout';

// Incoming events
export const INCOMING_CONNECT = 'connect';
export const INCOMING_CONNECT_TIMEOUT = 'connect_timeout';
export const INCOMING_CONNECT_ERROR = 'connect_error';
export const INCOMING_RECONNECT = 'reconnect';
export const INCOMING_RECONNECT_ATTEMPT = 'reconnect_attempt';
export const INCOMING_RECONNECT_ERROR = 'reconnect_error';
export const INCOMING_DISCONNECT = 'disconnect';
export const INCOMING_USER_JOINED = 'userJoined';
export const INCOMING_USER_QUIT = 'userQuit';
export const INCOMING_LOGOUT = 'userLogout';
export const INCOMING_ERROR = 'error';

export const INCOMING_MEMBER_ADDED = 'memberAdded';
export const INCOMING_MEMBER_DELETED = 'memberDeleted';

export const INCOMING_USER_ADDED_TO_CHAT = 'userAddedToChat';
export const INCOMING_USER_REMOVED_FROM_CHAT = 'userRemovedFromChat';
export const INCOMING_USER_ADDED_TO_BOARD = 'userAddedToBoard';
export const INCOMING_USER_REMOVED_FROM_BOARD = 'userRemovedFromBoard';
export const INCOMING_ENTITY_ADDED = 'entityAdded';
export const INCOMING_ENTITY_MODIFIED = 'entityModified';
export const INCOMING_ENTITY_DELETED = 'entityDeleted';

export const INCOMING_ASSIGN_ORGANIC_ITEM = 'assignOrganicItem';
export const INCOMING_MOVE_ORGANIC_ITEM = 'moveOrganicItem';
export const INCOMING_UNASSIGN_ORGANIC_ITEMS = 'unassignOrganicItems';

export const INCOMING_MOVE_TO_BIN = 'moveToBin';
export const INCOMING_RESTORE_FROM_BIN = 'restoreFromBin';
export const INCOMING_ENTITY_MOVED = 'entityMoved';
export const INCOMING_UPDATE_POSITIONS_COMPLETED = 'updatePositionsCompleted';
export const INCOMING_SET_ASSETTAG_COMPLETED = 'setAssetTagCompleted';
export const INCOMING_REFRESH_USER_CHAT_MESSAGE_COUNT =
  'refreshUserChatMessageCount';
export const INCOMING_REFRESH_USER_NOTIFICATION_COUNT =
  'refreshUserNotificationCount';
export const INCOMING_REFRESH_USER_EVALUATION_COUNT =
  'refreshUserEvaluationCount';
export const INCOMING_MARKASREAD_NOTIFICATION = 'markAsReadNotification';
export const INCOMING_TEST_CONNECTION = 'testConnection';
