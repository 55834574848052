import { mapActions } from 'pinia';
import { mapStores } from '@/stores';
import { useAppStore } from '@/stores/app';
import authMixins from './auth';
import assetMixin from './asset';
import toggleStateMixin from './toggleState';
import ToastAlert from '../toast/ToastAlert';

import { BOARD_TYPE, FOLDER_TYPE, CARD_TYPE } from '../../utils/types';

export function toggleDeletedMixin(type) {
  const useStore = mapStores.get(type).use;

  return {
    mixins: [authMixins, assetMixin, toggleStateMixin('toast')],
    components: {
      ToastAlert
    },
    computed: {
      prefixMessage() {
        const { type, brief } = this.item;
        if (type === BOARD_TYPE || type === FOLDER_TYPE)
          return `${type}Options.`;
        else if (type === CARD_TYPE)
          return `${type}Options${brief ? '.brief' : ''}.`;
        else return `${type}Options.`;
      },
      canToggleDeleted() {
        const { type } = this.item;

        const base = () => {
          if (this.isReadOnly) return false;
          if (!this.isProtected) return true;
          return this.isAuthorBoard;
        };

        if (type === BOARD_TYPE) {
          return false;
        } else {
          return base();
        }
      },
      canDelete() {
        return (
          !this.isDeleted &&
          !this.fromSearch &&
          this.canToggleDeleted &&
          !this.isMediaNote
        );
      },
      canRestore() {
        return (
          this.isDeleted &&
          (!this.item.parent || !this.item.parent.deleted) &&
          this.canToggleDeleted
        );
      },
      toggleDeletedTitle() {
        return this.item.deleted
          ? this.$t(`${this.prefixMessage}restore`)
          : this.$t(`${this.prefixMessage}delete`);
      }
    },
    methods: {
      ...mapActions(useAppStore, ['displayInfo', 'displayError']),
      ...mapActions(useStore, ['toggleDeleted']),
      confirmDelete() {
        this.toggleToast('confirmDelete');
      },
      async toggleDeletedItem() {
        try {
          this.toggleToast();

          const newItem = await this.toggleDeleted(this.item);
          if (!newItem.deleted) {
            this.$emit('navigate', 'toParent');
          }

          this.$emit('cancel');
        } catch (e) {
          console.error(
            `assetOptionsToggleDeleted: ${this.item.type} => toggleDeletedItem`,
            e
          );
          this.displayError({ message: this.$t('global.error') });
        }
      }
    }
  };
}
