import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'FolderIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12.007 12"
      >
        <title>{{ text }}</title>
        <path
          d="M171.956,192.5h2.386c.726,0,.855,1.085.989,1.085h7.192a.732.732,0,0,1,.706.755l.021,9.406a.732.732,0,0,1-.706.755H171.956a.732.732,0,0,1-.706-.755v-10.16C171.25,193.168,171.126,192.5,171.956,192.5Z"
          transform="translate(-171.243 -192.5)"
        />
      </svg>
    );
  }
};
