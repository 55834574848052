<template>
  <OptionsDialogue
    v-if="activeDialogue === 'options'"
    class="group-options-dialogue"
    :class="$attrs.class"
    :title="$t('groupOptions.title')"
    :item="item"
    type="group"
    @cancel="$emit('cancel')"
  >
    <ul>
      <MenuItem
        v-if="isAuthor"
        :label="$t('groupOptions.manageMembers')"
        @selected="manageGroupMembers"
      />
      <MenuItem
        v-else
        :label="$t('groupOptions.displayMembers')"
        @selected="manageGroupMembers"
      />

      <MenuItem
        v-if="isAuthor"
        :label="$t('groupOptions.edit')"
        @selected="edit"
      />
      <MenuItem
        v-if="isAuthor && canDelete"
        :label="$t('groupOptions.delete')"
        @selected="toggleToast('confirmDelete')"
      />
    </ul>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="deleteItem"
      @cancel="toggleToast()"
    >
      {{ $t('groupOptions.confirmDelete') }}
    </ToastAlert>
  </OptionsDialogue>

  <GroupFormDialogue
    v-else-if="activeDialogue === 'edit'"
    :class="$attrs.class"
    :editItem="item"
    :manageMembers="manageMembers"
    @success="closeEdit"
    @cancel="closeEdit"
  />
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useGroupStore } from '@/stores/group';
import OptionsDialogue from '../dialogues/OptionsDialogue';
import MenuItem from '../menus/MenuItem';
import toggleState from '../mixins/toggleState';
import authMixins from '../mixins/auth';
import routerMixins from '../mixins/router';
import GroupFormDialogue from './GroupFormDialogue';
import ToastAlert from '../toast/ToastAlert';

export default {
  inheritAttrs: false,
  name: 'GroupOptionsDialogue',
  mixins: [
    authMixins,
    toggleState('dialogue', 'options'),
    toggleState('toast'),
    routerMixins
  ],
  components: {
    GroupFormDialogue,
    MenuItem,
    OptionsDialogue,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      manageMembers: false
    };
  },
  computed: {
    isAuthor() {
      return this.currentUser && this.currentUser.id === this.item.author.id;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useGroupStore, {
      delete: 'delete'
    }),
    edit() {
      this.manageMembers = false;
      this.toggleDialogue('edit');
    },
    async deleteItem() {
      try {
        await this.delete(this.item);
        this.$emit('cancel');
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    manageGroupMembers() {
      this.manageMembers = true;
      this.toggleDialogue('edit');
    },
    closeEdit() {
      this.manageMembers = false;
      this.$emit('cancel');
    }
  }
};
</script>
