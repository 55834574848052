<template>
  <label class="datetime-field form-field" :class="$attrs.class" :for="name">
    <span v-if="label" class="datetime-field__label">{{ label }}</span>
    <slot>
      <Datepicker
        ref="field"
        :uid="id"
        :name="name"
        :modelValue="modelValue"
        :minDate="minDate"
        :maxDate="maxDate"
        :enableTimePicker="enableTimePicker"
        :is24="is24"
        :locale="locale"
        :format="customFormat"
        :selectText="$t('datetimeField.ok')"
        :cancelText="$t('datetimeField.cancel')"
        :autoApply="autoApply"
        :clearable="clearable"
        :readonly="readonly"
        :inline="false"
        @open="$emit('open', $event)"
        @update:modelValue="$emit('update:modelValue', $event)"
      />
    </slot>
  </label>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';

import authMixins from '../mixins/auth';

export default {
  inheritAttrs: false,
  name: 'BaseDatetimeInput',
  mixins: [authMixins],
  components: {
    Datepicker
  },
  props: {
    label: {
      type: String
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    modelValue: {
      type: Date,
      default: null
    },
    format: {
      type: String,
      default: null
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    enableTimePicker: {
      type: Boolean,
      default: true
    },
    is24: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    autoApply: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formatDatetime() {
      if (this.format) return this.format;
      else if (this.enableTimePicker) return 'dd MMMM yyyy, HH:mm';
      else return 'dd MMMM yyyy';
    },
    locale() {
      return this.currentUser.lang;
    },
    formatLocale() {
      return this.currentUser.lang === 'en' ? enCA : frCA;
    },
    canDelete() {
      return !!this.modelValue;
    }
  },
  methods: {
    customFormat(date) {
      return date
        ? format(date, this.formatDatetime, { locale: this.formatLocale })
        : '';
    }
  }
};
</script>

<style lang="css">
.dp__theme_light {
  --dp-primary-color: #0dace4;
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #0dace4;
}
</style>

<style lang="scss">
.datetime-field {
  @include grid($cols: 1fr, $rows: auto auto, $areas: 'label' 'input');

  &__label {
    @include grid-item($area: label);
    margin-bottom: $spacing-third;
  }

  &__input {
    @include grid-item($area: input);
  }
}

.form-field.label-bold {
  > .form-field__label {
    font-weight: bold;
  }
}
</style>
