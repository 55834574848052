<template>
  <div
    class="border-field"
    :class="[
      { 'border-field__top': top },
      { 'border-field__right': right },
      { 'border-field__bottom': bottom },
      { 'border-field__left': left },
      $attrs.class
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'BorderField',
  props: {
    top: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.border-field {
  border-color: $border-base;
  border-width: 0;
  border-style: solid;

  &__top {
    border-top-width: 1px;
  }

  &__right {
    border-right-width: 1px;
  }

  &__bottom {
    border-bottom-width: 1px;
  }

  &__left {
    border-left-width: 1px;
  }
}
</style>
