import { post as httpPost } from '../requests';
import { CHAT_TYPE, EVALUATION_RESULT_TYPE } from '../../utils/types';

export const getEndPointUrl = (type) => {
  if (type === CHAT_TYPE) {
    return '/chat';
  } else if (type === EVALUATION_RESULT_TYPE) {
    return '/evaluation-results';
  } else {
    return `/${type}s`;
  }
};

export const get = async (type, id, options = {}) => {
  const response = await httpPost(
    `${getEndPointUrl(type)}/${id}/getMembers`,
    options
  );
  return response.json();
};

export const update = async (type, id, options = {}) => {
  const response = await httpPost(
    `${getEndPointUrl(type)}/${id}/update-members`,
    options
  );
  return response.json();
};

export const inviteMultiple = async (type, id, options = {}) => {
  const response = await httpPost(
    `${getEndPointUrl(type)}/${id}/invite-multiple-members`,
    options
  );
  return response.json();
};

export const search = async (type, id, options = {}) => {
  const response = await httpPost(
    `${getEndPointUrl(type)}/${id}/search-members`,
    options
  );
  return response.json();
};
