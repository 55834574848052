import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useBoardStore } from '@/stores/board';
import auth from '../auth';
import { ROLE_PARTICIPANT_RESTRICTED } from '../../utils/role';
const RegisterFormDialogue = () =>
  import('../../components/auth/RegisterFormDialogue');
const BoardView = () => import('../../components/board/BoardView');

export default () => {
  return [
    {
      path: '/board/:id/invite/:token',
      name: 'invite-board',
      component: RegisterFormDialogue,
      props: (route) => ({
        token: route.params.token,
        trackFirstParticipantEvent: true,
        redirectBoardId: route.params.id
      }),
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isLoggedIn } = authStore;
        const { token } = to.params;

        try {
          const boardStore = useBoardStore();
          const { errorMessage, accountId, email, boardId, companyName } =
            await boardStore.getInviteInfo(token);
          if (errorMessage) {
            const appStore = useAppStore();
            appStore.displayError({ message: errorMessage });
            return { name: 'root' };
          }

          if (isLoggedIn) {
            return {
              name: 'board',
              params: { id: boardId }
            };
          } else if (accountId) {
            const redirectUrl = `/board/${boardId}`;
            return {
              name: 'login',
              query: { redirectUrl, account: companyName, email }
            };
          } else return true;
        } catch (e) {
          console.error(e);
          return { name: 'root' };
        }
      }
    },
    {
      path: '/board/:id/share/:hash',
      name: 'board-share',
      component: BoardView,
      props: (route) => ({
        id: parseInt(route.params.id)
      }),
      beforeEnter: auth.ensureHasShareToken()
    },
    {
      path: '/board/:id',
      name: 'board',
      component: BoardView,
      props: (route) => ({
        id: parseInt(route.params.id),
        displayComments: route.query.displayComments === 'true'
      }),
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    }
  ];
};
