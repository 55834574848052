<template>
  <BaseDialogue
    class="chat-form-dialogue"
    :class="[
      { 'chat-form-dialogue__member': step === STEP_MEMBERS },
      $attrs.class
    ]"
    size="small"
    :title="title"
    @cancel="back(true)"
    @ok="ok()"
    :showOk="step !== STEP_MEMBERS"
    :showCancel="showCancel"
    :disableOk="!isValid"
    data-testid="chat-form-dialogue"
  >
    <template #ok>{{ okButtonText }}</template>

    <transition :name="transitionName" @afterEnter="focus">
      <form
        key="base"
        v-if="step === 'base'"
        class="form-element chat-form-dialogue__base"
        @keydown.enter="ok"
        @submit.prevent
        novalidate
      >
        <BorderField>
          <BaseInput
            ref="name"
            class="label-bold"
            :label="$t('chatForm.name')"
            name="name"
            v-model.trim="form.name"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <SliderFieldset
            class="label-bold"
            :label="$t('chatForm.members.label')"
            :active="item.memberCount > 0"
            @v-click="displayMembers"
          />
        </BorderField>
      </form>

      <MemberManagement
        :key="STEP_MEMBERS"
        v-else-if="step === STEP_MEMBERS"
        @countChanged="memberCountChanged"
        @ok="ok()"
        @cancel="back(true)"
        :parent="item"
        type="chat"
        :showPrevious="!addMembers"
        :canInviteMultiple="false"
      />
    </transition>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useChatStore } from '@/stores/chat';
import '../../assets/stylesheets/components/_form.scss';
import { diff } from '../../utils/object';
import BaseDialogue from '../dialogues/BaseDialogue';
import MemberManagement from '../member/MemberManagement';
import SliderFieldset from '../forms/SliderFieldset';
import BorderField from '../forms/BorderField';
import authMixins from '../mixins/auth';

const STEP_BASE = 'base';
const STEP_MEMBERS = 'memberManagement';

export default {
  inheritAttrs: false,
  name: 'ChatFormDialogue',
  mixins: [authMixins],
  components: {
    BaseDialogue,
    MemberManagement,
    SliderFieldset,
    BorderField
  },
  props: {
    editItem: {
      type: Object,
      default: () => ({})
    },
    addMembers: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {},
      item: { ...this.editItem },
      selectedParticipant: null,
      submitted: false,
      step: this.addMembers ? STEP_MEMBERS : STEP_BASE,
      transitionName: 'prev',
      isCreationProcess: !this.editItem.id
    };
  },
  computed: {
    STEP_MEMBERS() {
      return STEP_MEMBERS;
    },
    mode() {
      return this.isCreationProcess ? 'add' : 'edit';
    },
    isValid() {
      return !!this.form.name;
    },
    title() {
      let title = '';
      switch (this.step) {
        case STEP_BASE:
          title = `chatForm.base.label.${this.mode}`;
          break;
        case STEP_MEMBERS:
          title = 'chatForm.members.label';
          break;
      }
      return this.$t(title);
    },
    okButtonText() {
      if (this.step === STEP_BASE && this.mode === 'add')
        return this.$t('dialogue.footer.next');
      return this.$t('dialogue.footer.ok');
    },
    showCancel() {
      return !this.addMembers && this.step !== STEP_MEMBERS;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useChatStore, ['create', 'edit', 'searchParticipants']),
    init() {
      const { name = '' } = this.item;
      this.form = { name };
    },
    async ok() {
      switch (this.step) {
        case STEP_BASE:
          if (!this.submitted) {
            this.submitted = true;

            try {
              let result;
              if (this.item.id) {
                result = await this.edit({
                  id: this.item.id,
                  ...diff(this.item, this.form)
                });
              } else {
                result = await this.create({ ...this.form });
              }

              if (result.error) {
                this.displayError({ message: result.error });
                return;
              }

              if (this.mode === 'edit') {
                this.$emit('success', result);
              } else {
                this.item = { ...result };
                this.init();
                this.displayMembers();
              }

              return;
            } catch (e) {
              if (e.status === 400) {
                this.displayError({ message: e.message });
              } else {
                console.error(e);
                this.displayError({ message: this.$t('global.error') });
              }

              return;
            } finally {
              this.submitted = false;
            }
          }

          break;
        case STEP_MEMBERS:
          this.back(this.addMembers || this.isCreationProcess);
          break;
      }
    },
    back(cancel) {
      switch (this.step) {
        case STEP_BASE:
          this.$emit('cancel');
          break;
        case STEP_MEMBERS:
          this.step = STEP_BASE;
          if (cancel) {
            if (this.addMembers || this.isCreationProcess) this.$emit('cancel');
          }
          break;
      }

      this.transitionName = 'prev';
    },
    focus() {
      if (this.step === STEP_BASE) {
        const { name } = this.$refs;
        name.focus();
      }
    },
    displayMembers() {
      this.step = STEP_MEMBERS;
    },
    memberCountChanged(count) {
      this.item = { ...this.item, memberCount: count };
    }
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.addMembers) this.displayMembers();
      else this.focus();
    });
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';

.chat-form-dialogue .form-field__input .vs__actions {
  display: none;
}

.chat-form-dialogue .form-field__input .vs__selected-options {
  padding: 0;
}
</style>

<style lang="scss">
.chat-form-dialogue {
  @include next-prev-transition();

  &__member {
    > .dialogue__box {
      min-height: 410px;
      max-height: 100%;
    }
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $chat-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }
}
</style>
