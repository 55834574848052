<template>
  <div class="list-item" :class="$attrs.class">
    <div class="list-item__left">
      <ItemCheckBox v-if="selectable" :item="item" :type="type" />
      <ItemIcon
        class="list-item__icon"
        :item="item"
        :type="type"
        :withLink="withLink"
      />
    </div>

    <div class="list-item__right">
      <button
        v-if="displayActionButton"
        class="icon-button list-item__actions"
        @click.stop="toggleOptionsDialogue"
      >
        <Action size="small" />
      </button>
      <button
        v-if="showDelete"
        class="icon-button list-item__delete"
        @click.stop="$emit('delete', item)"
      >
        <DeleteAction />
      </button>
    </div>

    <!-- Options dialogue -->
    <component
      v-if="optionsDialogueOpen"
      :is="optionsComponent"
      :item="item"
      :fromSearch="fromSearch"
      @navigate="$emit('navigate', $event)"
      @cancel="toggleOptionsDialogue"
    />
  </div>
</template>

<script>
import Action from '../icons/Action';
import DeleteAction from '../icons/Close';
import itemOptions from '../mixins/itemOptions';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import assetMixin from '../mixins/asset';
import ItemIcon from './ItemIcon';
import ItemCheckBox from '../layout/ItemCheckBox';

export default {
  inheritAttrs: false,
  name: 'ListItem',
  mixins: [itemOptions, selectionClipboardMixin, assetMixin],
  components: {
    ItemCheckBox,
    ItemIcon,
    Action,
    DeleteAction
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    withActions: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    withLink: {
      type: Boolean,
      default: true
    },
    canDisplayEvaluation: {
      type: Boolean,
      default: true
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayActionButton() {
      if (!this.withActions || this.isShared) return false;
      if (this.fromSearch) return !this.isSearchSelectionMode;
      return !this.isSelectionMode;
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.list-item {
  @include flexy($align: center, $just: space-between);
  background-color: $background-light;
  color: $font-dark;
  font-weight: $font-weight-bold;
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
  overflow: hidden;
  padding: 0 $spacing-base;
}

.list-item__left,
.list-item__right {
  @include flexy($align: center);
}

.list-item__left {
  overflow: hidden;
}

.list-item__right {
  > .icon-button {
    margin-left: $spacing-half;
  }
}

.list-item__actions,
.list-item__delete {
  height: 100%;
}
</style>
