<template>
  <div class="notification-item-container" :class="$attrs.class">
    <div v-if="selectable" class="notification-item__check">
      <BaseCheckbox
        :modelValue="item.selected"
        @update:modelValue="$emit('selectionChanged', $event)"
      />
    </div>
    <div class="notification-item">
      <div
        class="notification-item__state"
        :class="{ 'notification-item__state--unread': !item.read }"
      ></div>
      <div
        class="notification-item__image"
        :class="`notification-item__image--${imageType}`"
      >
        <component v-if="item.subType" :is="mediaIcon" />
      </div>
      <div class="notification-item__since">{{ item.since }}</div>
      <div class="notification-item__message">
        {{ $t(item.messageTag, item.msgParameters) }}
        <a href="#" @click.prevent="goToUrl(item.url)">{{ item.name }}</a
        >.
      </div>
      <div class="notification-item__next" @click="goToUrl(item.url)">
        <Chevron size="xsmall" direction="right" />
      </div>
    </div>
  </div>
</template>

<script>
import AudioMedia from '../icons/AudioMedia';
import FileMedia from '../icons/FileMedia';
import LinkMedia from '../icons/LinkMedia';
import PhotoMedia from '../icons/PhotoMedia';
import TextMedia from '../icons/TextMedia';
import VideoMedia from '../icons/VideoMedia';
import Chevron from '../icons/Chevron';
import routerMixins from '../mixins/router';
import { CARD_TYPE } from '../../utils/types';

const icons = {
  audio: AudioMedia,
  file: FileMedia,
  link: LinkMedia,
  photo: PhotoMedia,
  text: TextMedia,
  video: VideoMedia
};

export default {
  inheritAttrs: false,
  name: 'NotificationItem',
  mixins: [routerMixins],
  components: {
    Chevron
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageType() {
      if (this.item.type === CARD_TYPE && this.item.brief)
        return `${CARD_TYPE}-brief`;
      else return this.item.type;
    },
    mediaIcon() {
      return icons[this.item.subType];
    }
  },
  methods: {
    goToUrl(url) {
      this.navigate(url);
      this.$emit('toggle');
    }
  }
};
</script>

<style lang="scss">
.notification-item-container {
  @include grid($gap: 0, $cols: auto 1fr, $areas: 'check item');
  color: $font-dark;
  background-color: #ffffff;
  font-family: $roboto-condensed;

  .notification-item__check {
    @include grid-item(check);
    @include flexy($align: center);
    margin: $spacing-base 0 $spacing-base $spacing-base;
    border-radius: $base-border-radius;
  }

  .notification-item {
    @include grid-item(item);
    @include grid(
      $gap: 0,
      $cols: 4px auto 1fr auto,
      $rows: auto auto,
      $areas: 'state image since next' 'state image message next'
    );

    &__state {
      @include grid-item(state);

      &--unread {
        background-color: $notification-unread-background;
      }
    }

    &__image {
      @include grid-item(image);
      @include flexy($just: center, $align: center);
      margin: $spacing-base;
      height: $spacing-base * 1.5;
      width: $spacing-base * 1.5;
      min-width: $spacing-base * 1.5;

      &--board {
        @include svg-background($background-board-medium-svg, $board-base);
      }

      &--folder {
        @include svg-background($background-folder-large-svg, $folder-base);
      }

      &--card {
        @include svg-background($background-card-large-svg, $card-base);
      }

      &--card-brief {
        @include svg-background($background-card-brief-medium-svg, $brief-base);
      }

      &--notification {
        @include svg-background(
          $background-notification-medium-svg,
          $notification-base
        );
      }

      &--account-management {
        @include svg-background(
          $background-account-management-medium-svg,
          $account-management-base
        );
      }
    }

    &__since {
      @include grid-item(since);
      margin: $spacing-base $spacing-base 0 0;
      font-size: $font-size-xsmall;
    }

    &__message {
      @include grid-item(message);
      margin: $spacing-quarter $spacing-base $spacing-base 0;

      > a {
        text-decoration: underline;
      }
    }

    &__next {
      @include grid-item(next);
      @include flexy($align: center);
      padding: $spacing-half;
      cursor: pointer;
    }
  }
}
</style>
