<template>
  <BaseDialogue
    class="card-form-dialogue"
    :class="[{ 'card-form-dialogue--brief': isBrief }, $attrs.class]"
    :title="title"
    size="small"
    @cancel="$emit('cancel')"
    @ok="ok()"
    :disableOk="!isValid"
    data-testid="card-form-dialogue"
  >
    <form
      class="form-element card-form-dialogue__base"
      @keydown.enter="ok"
      @submit.prevent
      novalidate
    >
      <BorderField>
        <BaseInput
          ref="name"
          class="label-bold"
          :label="$t('cardForm.name')"
          name="name"
          :maxlength="80"
          v-model.trim="form.name"
        />
      </BorderField>
      <BorderField>
        <SliderContent :label-bold="true" :label="$t('cardForm.options')">
          <div v-if="displayProtected" class="form-field">
            <BaseCheckbox
              class="form-field-protected"
              name="protected"
              :label="$t('cardForm.protected')"
              :disabled="!isAuthorBoard"
              v-model="form.protected"
            />
          </div>
        </SliderContent>
      </BorderField>
    </form>

    <template #ok>{{ $t(`dialogue.footer.${mode}`) }}</template>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useCardStore } from '@/stores/card';
import '../../assets/stylesheets/components/_form.scss';
import { diff } from '../../utils/object';
import BaseDialogue from '../dialogues/BaseDialogue';
import SliderContent from '../forms/SliderContent';
import BorderField from '../forms/BorderField';
import ToastAlert from '../toast/ToastAlert';
import authMixins from '../mixins/auth';
import assetMixins from '../mixins/asset';
import { CARD_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'CardFormDialogue',
  mixins: [authMixins, assetMixins],
  components: {
    BaseDialogue,
    SliderContent,
    BorderField,
    ToastAlert
  },
  props: {
    parent: {
      type: Object,
      default: null
    },
    editItem: {
      type: Object,
      default: () => ({})
    },
    brief: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const {
      brief = this.brief,
      name = '',
      protected: isProtected = false
    } = this.editItem;

    const item = { ...this.editItem };
    if (!item.id) {
      item.type = CARD_TYPE;
      item.parent = this.parent;
      item.board = this.parent.board;
    }

    return {
      form: {
        brief,
        name,
        protected: isProtected
      },
      item,
      error: ''
    };
  },
  computed: {
    mode() {
      return this.editItem.id ? 'edit' : 'add';
    },
    isBrief() {
      return this.brief || this.editItem.brief;
    },
    displayProtected() {
      return !this.item.board.sandBox;
    },
    isValid() {
      return !!this.form.name;
    },
    title() {
      return this.isBrief
        ? this.$t(`cardForm.brief.${this.mode}`)
        : this.$t(`cardForm.label.${this.mode}`);
    }
  },
  methods: {
    ...mapActions(useCardStore, ['modify']),
    async ok() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const data = this.editItem
            ? {
                ...diff(this.editItem, this.form),
                id: this.editItem.id
              }
            : this.form;

          if (!this.editItem.id) {
            data.parentId = this.item.parent.id;
          }

          const card = await this.modify(data);
          this.$emit('success', card);
        } catch (e) {
          this.submitted = false;
          this.error = this.$t('cardForm.error');
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { name } = this.$refs;
      name.focus();
    });
  }
};
</script>

<style lang="scss">
.card-form-dialogue {
  .dialogue__header,
  .dialogue__footer {
    background-color: $card-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }

  &--brief {
    .dialogue__header,
    .dialogue__footer {
      background-color: $brief-base;
    }
  }
}
</style>
