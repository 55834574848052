<template>
  <div ref="root" class="content-note" :class="$attrs.class">
    <div class="content-note--content">
      <div>
        {{ media.content }}
      </div>
    </div>
  </div>
</template>

<script>
import mediaProperties from '../mixins/mediaProperties';
import contentMediaMixin from './ContentMediaMixin';

export default {
  inheritAttrs: false,
  name: 'ContentNote',
  mixins: [mediaProperties, contentMediaMixin]
};
</script>

<style lang="scss">
.content-note {
  @include flexy($align: center, $just: center);
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: $spacing-base $tile-slide-move-icon-dim;

  @media screen and (max-width: $small-size-break),
    screen and (max-height: $small-size-break) {
    padding: $spacing-half $tile-slide-move-icon-dim;
  }

  &--content {
    @include flexy($align: center);
    min-width: $tile-media-slide-min;
    min-height: $tile-media-slide-min;
    height: $tile-media-slide-max;
    width: $tile-media-slide-max;
    max-width: 100%;
    max-height: 100%;
    padding: 40px;
    background-color: $media-note-background-color;
    border-radius: $tile-media-border-radius;
    border: 1px solid $media-note-background-color;
    overflow: hidden;

    @media screen and (max-width: $small-size-break),
      screen and (max-height: $small-size-break) {
      padding: 30px;
      min-width: unset;
      min-height: unset;
      width: 100%;
      height: 100%;
    }

    > * {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;

      text-align: left;
      font-size: 25px;
      line-height: 30px;
      color: $media-note-font-color;
      white-space: pre-wrap;

      @media screen and (max-width: $small-size-break),
        screen and (max-height: $small-size-break) {
        font-size: 23px;
        line-height: 28px;
      }
    }

    @media screen and (max-height: $small-size-break) {
      padding: 20px;

      > * {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
}
</style>
