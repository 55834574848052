import { defineStore } from 'pinia';

import { get as apiGet } from '@/api/endpoints/search';
import { SEARCH_TYPE } from '@/utils/types';

const defaultLimit = 30;

const getFilter = () => {
  const value = localStorage.getItem('search_filter');
  if (value) {
    return JSON.parse(value);
  }

  return null;
};

const getSearchText = () => {
  return localStorage.getItem('search_search-text') || '';
};

const getLastSelectedId = () => {
  return localStorage.getItem('search_last-selected-id') || '';
};

const initialState = {
  mode: null,
  loading: 0,
  isOpen: false,
  searchText: getSearchText(),
  filter: getFilter(),
  lastSelectedId: getLastSelectedId(),
  items: [],
  hasNextPage: false
};

export const key = SEARCH_TYPE;
export const useSearchStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, initialState);
      this.searchText = getSearchText();
      this.filter = getFilter();
      this.lastSelectedId = getLastSelectedId();
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async assetExists({ id }) {
      if (!this.isOpen) return false;
      return this.items.some((x) => x.id === id || x.board?.id === id);
    },
    async start() {
      this.isOpen = true;
      return await this.get({ start: 0 });
    },
    async get(options = {}) {
      const { mode, sort, filter, items, searchText } = this;
      let { start = items.length, text } = options;
      const limit =
        start === 0 && items.length > defaultLimit
          ? items.length
          : defaultLimit;

      if (text === undefined) text = searchText;

      await this.startLoading();

      try {
        if (text || filter || mode === 'bin') {
          const { items: newItems, hasNextPage } = await apiGet({
            query: { start, sort, limit },
            text: text || '',
            ...(filter || {}),
            deleted: mode === 'bin'
          });

          this.items = start === 0 ? newItems : [...items, ...newItems];
          this.hasNextPage = hasNextPage;

          return { items: newItems, hasNextPage };
        } else {
          this.items = [];
          this.hasNextPage = false;

          return { items: [], hasNextPage: false };
        }
      } finally {
        await this.stopLoading();
      }
    },
    async setSearchText(text) {
      this.searchText = text;
      if (text) {
        localStorage.setItem('search_search-text', text);
      } else {
        localStorage.removeItem('search_search-text');
      }
    },
    async setFilter(filter) {
      this.filter = filter;
      if (filter) {
        localStorage.setItem('search_filter', JSON.stringify(filter));
      } else {
        localStorage.removeItem('search_filter');
      }

      await this.get({ start: 0 });
    },
    async setLastSelectedId(id) {
      this.lastSelectedId = id;
      if (id) {
        localStorage.setItem('search_last-selected-id', id);
      } else {
        localStorage.removeItem('search_last-selected-id');
      }
    },
    async close() {
      this.isOpen = false;
      this.items = [];
      this.hasNextPage = false;
    },
    async setMode(mode) {
      this.mode = mode;

      if (this.mode === 'bin') {
        this.filter = null;
        localStorage.removeItem('search_filter');
        await this.setSearchText(null);
      }
    },
    async restoreFromBin({ id }) {
      if (this.mode !== 'bin') return;

      if (this.items.some((x) => x.id === id)) {
        await this.get({ start: 0 });
      }
    },
    async assetUpdated(item) {
      if (this.items.some((x) => x.id === item.id || x.board?.id === item.id)) {
        this.items = this.items.map((x) => {
          if (x.id === item.id) {
            return { ...item };
          } else if (x.board?.id === item.id && item.board) {
            const newItem = { ...x };
            newItem.board = { ...item.board };
            return newItem;
          } else return x;
        });
      }
    },
    async assetDeleted({ id }) {
      if (this.items.some((x) => x.id === id || x.board?.id === id)) {
        this.items = [];
        this.hasNextPage = false;
        await this.get({ start: 0 });
      }
    }
  }
});

export default {
  key,
  use: useSearchStore
};
