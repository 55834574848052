<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11.222 11.222"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M 2.67 1.565 L 5.103 4 L 6.403 5.294 L 6.719 5.61 L 5.794 6.536 L 5.103 7.224 L 2.67 9.657 C 2.17 10.157 2.399 11.012 3.083 11.195 C 3.4 11.28 3.738 11.189 3.97 10.957 L 6.403 8.521 L 7.703 7.221 L 8.663 6.26 C 8.816 6.103 8.907 5.896 8.918 5.678 C 8.918 5.656 8.93 5.634 8.93 5.612 C 8.932 5.369 8.835 5.135 8.663 4.963 L 7.089 3.388 L 6.403 2.7 L 3.968 0.267 C 3.468 -0.233 2.614 -0.004 2.431 0.679 C 2.346 0.996 2.436 1.335 2.668 1.567 Z"
      :transform="transform"
    />
  </svg>
</template>

<script>
import { computed } from 'vue';
import { useLogin, mixin } from '.';
import './_icon.scss';

const TRANSFORMS = {
  up: 'matrix(0, -1, 1, 0, 0.002905, 11.226671)',
  down: 'matrix(0, 1, -1, 0, 11.226671, -0.002905)',
  left: 'matrix(-1, 0, 0, -1, 11.229577, 11.223766)',
  right: ''
};

const DIRECTIONS = Object.keys(TRANSFORMS);

const name = 'ChevronIcon';

export default {
  mixins: [mixin],
  name,
  props: {
    direction: {
      type: String,
      default: 'up',
      validator: (value) => DIRECTIONS.includes(value)
    }
  },
  setup(props) {
    const { classes, text } = useLogin(name, props);

    return {
      classes,
      text,
      transform: computed(() => {
        return TRANSFORMS[props.direction];
      })
    };
  }
};
</script>
