<template>
  <div class="item-checkbox" :class="$attrs.class">
    <div @click.stop="">
      <BaseCheckbox
        v-if="mode === 'clipboard' && canDisplay"
        :modelValue="isSelected"
        :color="color"
        @update:modelValue="change"
      />
      <BaseCheckbox
        v-else-if="mode === 'normal' && displayCheckbox"
        :modelValue="modelValue"
        :color="color"
        @update:modelValue="$emit('update:modelValue', $event)"
      />
    </div>
  </div>
</template>

<script>
import authMixins from '../mixins/auth';
import selectionClipboardMixin from '../mixins/selectionClipboard';

export default {
  inheritAttrs: false,
  name: 'ItemCheckBox',
  mixins: [authMixins, selectionClipboardMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'clipboard'
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    displayCheckbox: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'dark'
    }
  },
  computed: {
    canDisplay() {
      if (this.clipboardAction === 'copy') {
        return this.canCopy(this.item);
      } else if (this.clipboardAction === 'move') {
        return this.canMove(this.item);
      } else if (this.clipboardAction === 'evaluation') {
        return this.canCopy(this.item);
      }

      return false;
    }
  },
  methods: {
    change(checked) {
      if (checked) {
        this.addToClipboard({ item: this.item });
      } else {
        this.deleteFromClipboard(this.item);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-checkbox {
  @include flexy($align: center);
  padding: $spacing-third;
  border-radius: $base-border-radius;
  min-width: 30px;
}
</style>
