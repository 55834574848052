import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'TextMedia';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={[...classes, 'icon-media']}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.793 10.986"
      >
        <title>{{ text }}</title>
        <path
          d="M195.448,282.37h-9.273a.76.76,0,0,0-.76.76v2.645a.76.76,0,0,0,1.52,0V283.89h3.105v7.945h-.911a.76.76,0,1,0,0,1.52h1.57c.017,0,.033,0,.05-.005s.033.005.051.005.033,0,.05-.005.033.005.051.005h1.57a.76.76,0,1,0,0-1.52h-.911V283.89h3.128v1.885a.76.76,0,0,0,1.52,0V283.13A.76.76,0,0,0,195.448,282.37Z"
          transform="translate(-185.415 -282.37)"
        />
      </svg>
    );
  }
};
