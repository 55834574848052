<template>
  <div class="search-menu" :class="$attrs.class">
    <div class="search-menu__button">
      <button
        class="icon-button menu-button"
        @click="toggle"
        :class="{ 'search-menu__button--active': isActive }"
      >
        <SearchIcon :type="isActive ? 'active' : 'dark'" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import authMixins from '../mixins/auth';
import SearchIcon from '../icons/SearchIcon';
import { SEARCH_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'SearchMenu',
  mixins: [authMixins],
  components: {
    SearchIcon
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar']),
    isActive() {
      const { type, mode } = this.activeLeftSideBar;
      return type === SEARCH_TYPE && !mode;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveLeftSideBar']),
    toggle() {
      const { type, mode } = this.activeLeftSideBar;

      if (type === SEARCH_TYPE && !mode) {
        this.setActiveLeftSideBar();
      } else {
        this.setActiveLeftSideBar({ type: SEARCH_TYPE });
      }
    }
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss" scoped>
.search-menu {
  height: 100%;

  &__button {
    position: relative;
    height: 100%;

    &--active {
      @include svg-active-background($background-triangle-up-svg);
      background-position: bottom;
    }

    > .menu-button {
      height: 100%;
    }
  }
}
</style>
