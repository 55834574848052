<template>
  <ToastRelative
    v-if="relative"
    :class="$attrs.class"
    :level="level"
    :prompt="prompt"
    :timeout="timeout"
    :messageHtml="messageHtml"
    :relative="relative"
    @cancel="$emit('cancel')"
    @ok="$emit('ok')"
  >
    <slot></slot>
  </ToastRelative>
  <Teleport to="body" v-else>
    <ToastRelative
      :class="$attrs.class"
      :level="level"
      :prompt="prompt"
      :timeout="timeout"
      :messageHtml="messageHtml"
      :relative="relative"
      @cancel="$emit('cancel')"
      @ok="$emit('ok')"
    >
      <slot></slot>
    </ToastRelative>
  </Teleport>
</template>

<script>
import ToastRelative from './ToastRelative';

const LEVELS = ['info', 'warning', 'error'];
const DEFAULT_TIMEOUT = 4000;

export default {
  inheritAttrs: false,
  name: 'ToastAlert',
  components: {
    ToastRelative
  },
  props: {
    level: {
      type: String,
      default: LEVELS[0],
      validator: (value) => LEVELS.includes(value)
    },
    prompt: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: DEFAULT_TIMEOUT
    },
    messageHtml: {
      type: String,
      default: ''
    },
    relative: {
      type: Boolean,
      default: false
    }
  }
};
</script>
