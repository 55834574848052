// eslint-disable-next-line no-extend-native
Array.prototype.toObject = function (key = 'id') {
  const o = {};

  this.forEach((item) => {
    if (typeof key === 'function') o[key(item)] = item;
    else o[item[key]] = item;
  });

  return o;
};
