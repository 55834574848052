import { get as httpGet } from '../requests';
import { create as genericCreate, update as genericUpdate } from '../generic';

export const ENDPOINT_URL = '/organic-titles';

export const create = genericCreate(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const get = async (id, share = false) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/${id}${share ? '/share' : ''}`
  );
  return response.json();
};
