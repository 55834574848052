<template>
  <div
    ref="list"
    class="list-pageable__items-container"
    :class="[{ 'list-pageable__items--empty': isEmpty }, $attrs.class]"
  >
    <draggable
      v-if="!loading && !isEmpty"
      class="list-pageable__items"
      :handle="handle"
      :class="`list-pageable__items--${displayStyle}`"
      :list="items"
      :item-key="itemKey"
      draggable=".draggable"
      @change="orderHasChanged"
    >
      <template #item="{ element: item }">
        <div
          class="list-pageable__item"
          :class="{ draggable: _canDragItem(item) }"
        >
          <slot name="content-item" v-bind:item="item"></slot>
        </div>
      </template>
    </draggable>

    <template v-if="loading">
      <div class="list-pageable__items-empty-loading">
        <ListLoading />
      </div>
    </template>
    <template v-else-if="!loading && isEmpty">
      <div class="list-pageable__items-container-empty-text">
        <slot name="content-empty">&nbsp;</slot>
      </div>

      <div
        v-if="displayEmptyArrow"
        class="list-pageable__items-container-empty-arrow"
      >
        <Arrow size="large" type="active" />
      </div>
    </template>
  </div>
</template>

<script>
import authMixin from '../mixins/auth';
import paginationMixin from '../mixins/pagination';
import ListLoading from '../layout/ListLoading';
import Arrow from '../icons/Arrow';
import draggable from 'vuedraggable';

export default {
  inheritAttrs: false,
  name: 'ListPageable',
  mixins: [authMixin, paginationMixin],
  components: {
    draggable,
    ListLoading,
    Arrow
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    load: {
      type: Function,
      default: null
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    hasNextPage: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Number,
      default: null
    },
    isEmpty: {
      type: Boolean,
      default: true
    },
    displayEmptyArrow: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    canDragItem: {
      type: Function,
      default: null
    },
    displayType: {
      type: String,
      default: ''
    },
    handle: {
      type: String,
      default: null
    }
  },
  computed: {
    displayStyle() {
      if (this.displayType) return this.displayType;
      return this.display;
    }
  },
  methods: {
    scrollTop() {
      if (!this.$refs?.list) return;
      this.$refs.list.scrollTop = 0;
    },
    _canDragItem(item) {
      if (!this.draggable) return false;
      if (!this.canDragItem) return true;
      return this.canDragItem(item);
    },
    orderHasChanged(e) {
      if (e.moved) {
        const { element, newIndex } = e.moved;
        this.$emit('reorderElements', {
          element,
          newIndex
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-pageable__items-container {
  @include item-list-container();
}

.list-pageable__items {
  @include item-list();
}
</style>
