import { post as httpPost } from '../requests';

export const ENDPOINT_URL = '/audit-login';

export const get = async (options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/get`, options);
  return response.json();
};

export const getEmails = async (options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/get-emails`, options);
  return response.json();
};
