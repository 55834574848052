<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 424.8 386.8"
  >
    <title v-if="text">{{ text }}</title>
    <g>
      <path
        d="M330.1,45.5c-20.3-16.2-49.9-13-66.2,7.2l-8.5,10.6,73.3,59.1,8.5-10.7c16.2-20.3,13-49.8-7.1-66.2Z"
      />
      <path
        d="M137.9,321.4l-33.3,4.2-3-33.4,157.2-195.3-18.5-15-163.2,202.6h0l6,66.2v1.2c-.1,0,0,0,0,0v.2l1-.4,66.1-8.1h0l163.2-202.7-18.5-15-157,195.3Z"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'EditIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
