<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="55.928 0 33.993 24.639"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M272.075,440.148a2.707,2.707,0,0,0-3.816,0l-17.34,17.34-7.375-7.376a2.7,2.7,0,1,0-3.816,3.816l9.284,9.283a2.7,2.7,0,0,0,3.815,0l19.248-19.247A2.707,2.707,0,0,0,272.075,440.148Z"
      transform="matrix(1, 0, 0, 1, -182.941002, -439.361)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'CheckIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
