import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'FilterIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18.015 18.056"
      >
        <title>{{ text }}</title>
        <path
          d="M105.686,179.7h8.057a.861.861,0,0,1,.7,1.467c-.525.482-6.5,6.547-6.5,6.547V196.6c0,1.095-.832,1.358-1.292,1.007s-2.868-1.949-2.868-1.949c-.307-.241-.35-.613-.35-1.139v-6.809l-6.306-6.328c-.832-.744-.328-1.686.328-1.686Z"
          transform="translate(-96.69 -179.698)"
        />
      </svg>
    );
  }
};
