<template>
  <OptionsDialogue
    v-if="activeDialogue === 'options'"
    class="share-asset-options-dialogue"
    :class="$attrs.class"
    :title="$t('shareAssetOptions.title')"
    :item="item"
    type="share-asset"
    @cancel="$emit('cancel')"
  >
    <ul>
      <MenuItem
        v-if="item.type === 'private'"
        :label="$t('shareAssetOptions.invite')"
        @selected="invite"
      >
        <template #icon>
          <ShareIcon size="xsmall" />
        </template>
      </MenuItem>
      <MenuItem :label="$t('shareAssetOptions.edit')" @selected="edit">
        <template #icon>
          <EditIcon size="xsmall" />
        </template>
      </MenuItem>
      <MenuItem :label="$t('shareAssetOptions.copyLink')" @selected="copyLink">
        <template #icon>
          <CopyLinkIcon size="xsmall" />
        </template>
      </MenuItem>
      <MenuItem
        :label="$t('shareAssetOptions.preview')"
        @selected="displayPreview"
      >
        <template #icon>
          <PreviewIcon size="xsmall" />
        </template>
      </MenuItem>
      <MenuItem
        :label="$t('shareAssetOptions.delete')"
        @selected="toggleToast('confirmDelete')"
      >
        <template #icon>
          <TrashIcon size="xsmall" />
        </template>
      </MenuItem>
    </ul>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="deleteItem"
      @cancel="toggleToast()"
    >
      {{ $t('shareAssetOptions.confirmDelete') }}
    </ToastAlert>
  </OptionsDialogue>

  <ShareAssetFormDialogue
    v-else-if="activeDialogue === 'edit'"
    :class="$attrs.class"
    :editItem="item"
    :inviteParticipants="inviteParticipants"
    @success="closeEdit"
    @cancel="closeEdit"
    @customizeShare="emitCustomizeShare"
  />
  <PreviewShareDialogue
    v-else-if="activeDialogue === 'preview'"
    :url="previewUrl"
    :title="previewTitle"
    @cancel="closePreview"
  />
</template>

<script>
import OptionsDialogue from '@/components/dialogues/OptionsDialogue';
import MenuItem from '@/components/menus/MenuItem';
import ShareAssetFormDialogue from '@/components/shareAsset/ShareAssetFormDialogue';
import PreviewShareDialogue from '@/components/shareAsset/PreviewShareDialogue';
import ShareIcon from '@/components/icons/Share';
import EditIcon from '@/components/icons/Edit';
import CopyLinkIcon from '@/components/icons/CopyLink';
import PreviewIcon from '@/components/icons/Preview';
import TrashIcon from '@/components/icons/Trash';
import ToastAlert from '@/components/toast/ToastAlert';

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores/app';
import { useShareAssetStore } from '@/stores/share-asset';
import { useToggleState } from '@/composables/toggle-state';

export default {
  inheritAttrs: false,
  name: 'ShareAssetOptionsDialogue',
  components: {
    OptionsDialogue,
    MenuItem,
    ShareAssetFormDialogue,
    PreviewShareDialogue,
    ShareIcon,
    EditIcon,
    CopyLinkIcon,
    PreviewIcon,
    TrashIcon,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n();
    const { displayError } = useAppStore();
    const { delete: deleteShareAsset } = useShareAssetStore();
    const { activeToast, toggleToast } = useToggleState('toast');
    const { activeDialogue, toggleDialogue } = useToggleState(
      'dialogue',
      'options'
    );

    const inviteParticipants = ref(false);

    const link = computed(() => {
      const { origin } = document.location;
      const { href } = router.resolve({
        name: 'share',
        params: { hash: props.item.hash }
      });
      return `${origin}/${href}`;
    });

    const previewUrl = computed(() => `${link.value}/?preview=true`);

    const previewTitle = computed(() =>
      props.item.type === 'named'
        ? t('shareAssetOptions.previewNamed', [
            `${props.item.firstName} ${props.item.lastName}`
          ])
        : t('shareAssetOptions.previewWeb', [props.item.name])
    );

    return {
      // COMPOSABLES
      activeToast,
      toggleToast,
      activeDialogue,

      // DATA
      inviteParticipants,

      // COMPUTED
      link,
      previewUrl,
      previewTitle,

      // METHODS
      invite() {
        inviteParticipants.value = true;
        toggleDialogue('edit');
      },
      edit() {
        inviteParticipants.value = false;
        toggleDialogue('edit');
      },
      copyLink() {
        const { origin } = document.location;
        const { href } = router.resolve({
          name: 'share',
          params: { hash: props.item.hash }
        });

        navigator.clipboard.writeText(`${origin}/${href}`);
        emit('cancel');
      },
      async deleteItem() {
        try {
          await deleteShareAsset(props.item.id);
          emit('cancel');
        } catch (e) {
          console.error(e);
          displayError({ message: t('global.error') });
        }
      },
      closeEdit() {
        inviteParticipants.value = false;
        emit('cancel');
      },
      emitCustomizeShare() {
        emit('customizeShare');
      },
      displayPreview() {
        toggleDialogue('preview');
      },
      closePreview() {
        emit('cancel');
      }
    };
  }
};
</script>

<style lang="scss">
.share-asset-options-dialogue {
  .icon {
    fill: $share-asset-base;
  }
}
</style>
