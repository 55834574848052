import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'LockedIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 21.022"
      >
        <title>{{ text }}</title>
        <g transform="translate(0 0)">
          <rect width="15" height="14" rx="2" transform="translate(0 7.022)" />
          <path
            d="M9.748,4.693H5.4a2.443,2.443,0,0,1-2.44-2.44V-4.582A2.443,2.443,0,0,1,5.4-7.022H9.748a2.443,2.443,0,0,1,2.44,2.44V2.253A2.443,2.443,0,0,1,9.748,4.693ZM5.4-5.022a.447.447,0,0,0-.44.44V2.253a.446.446,0,0,0,.44.44H9.748a.446.446,0,0,0,.44-.44V-4.582a.447.447,0,0,0-.44-.44Z"
            transform="translate(0 7.022)"
          />
        </g>
      </svg>
    );
  }
};
