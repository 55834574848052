<template>
  <div class="chat-message-item" :class="$attrs.class">
    <div
      class="chat-message-item__message"
      :class="{
        'chat-message-item__message--mine':
          item.author && item.author.id === currentUser.id
      }"
    >
      <AvatarPill :info="item.author || {}" />
      <div class="chat-message-item__message--right">
        <div class="chat-message-item__message--info">
          <MessageIcon :size="'xsmall'" />
          <div class="chat-message-item__message--since">{{ item.since }}</div>
        </div>
        <div
          class="chat-message-item__message--content"
          v-html="item.content"
        ></div>
      </div>
      <div
        v-if="canDelete"
        class="chat-message-item__message--delete"
        @click="$emit('deleteMessage', item)"
      >
        <CloseIcon size="xxsmall" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useChatStore } from '@/stores/chat';
import AvatarPill from '../AvatarPill';
import MessageIcon from '../icons/Message';
import CloseIcon from '../icons/Cross';
import authMixins from '../mixins/auth';

export default {
  inheritAttrs: false,
  name: 'ChatMessageItem',
  mixins: [authMixins],
  components: {
    AvatarPill,
    MessageIcon,
    CloseIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useChatStore, {
      current: 'current'
    }),
    canDelete() {
      return (
        this.item.author.id === this.currentUser.id ||
        (this.current && this.current.author.id === this.currentUser.id)
      );
    }
  }
};
</script>

<style lang="scss">
.chat-message-item__message--content a {
  text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.chat-message-item {
  width: 100%;

  &__message {
    @include flexy();

    background-color: $background-dark;
    padding: $spacing-base;
    border-radius: $tile-border-radius;
    margin-left: 0;
    margin-right: $spacing-triple;
    position: relative;
    overflow: hidden;

    &--mine {
      margin-right: 0;
      margin-left: $spacing-triple;
      overflow: hidden;
    }

    &--right {
      @include flexy($dir: column);
      overflow: hidden;

      margin-left: $spacing-base;
    }

    &--info {
      @include flexy($align: flex-end);
    }

    &--since {
      margin-left: $spacing-third;
      font-size: $font-size-xsmall;
    }

    &--content {
      overflow: hidden;
      word-wrap: break-word;
      margin-top: $spacing-quarter;
    }

    &--delete {
      @include flexy($align: center, $just: center);
      width: 15px;
      height: 15px;
      top: $spacing-quarter;
      right: $spacing-quarter;
      background-color: $background-light;
      position: absolute;
      cursor: pointer;
    }
  }
}

.icon.icon-message-icon {
  fill: $font-light;
  stroke: $font-dark;
}
</style>
