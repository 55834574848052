<template>
  <header class="app-header" :class="$attrs.class">
    <div class="app-header__left">
      <BurgerMenu v-if="!isInPreviewWebShare" />

      <img
        v-if="isInPreviewWebShare"
        :src="logoPath"
        alt="Logo"
        class="app-header__logo"
      />
      <RouterLink v-else :to="{ name: 'root' }" @dragstart.prevent>
        <img :src="logoPath" alt="Logo" class="app-header__logo" />
      </RouterLink>
    </div>
    <div class="app-header__right">
      <SearchMenu v-if="displanySearch" />
      <NotificationMenu v-if="displanyNotification" />
      <EvaluationMenu v-if="displanyEvaluation" />
      <button
        v-if="activateChatApp"
        class="icon-button menu-button menu-button-chat"
        @click="openLiveChat"
      >
        <Message />
      </button>
      <ProfileMenu v-if="displanyProfile" />
    </div>
  </header>
</template>

<script>
import BurgerMenu from './BurgerMenu';
import authMixins from '../components/mixins/auth';
import Message from '../components/icons/Message';
import SearchMenu from '../components/search/SearchMenu';
import NotificationMenu from '../components/notification/NotificationMenu';
import EvaluationMenu from '../components/evaluation/EvaluationMenu';
import ProfileMenu from '../components/profile/ProfileMenu';

export default {
  inheritAttrs: false,
  name: 'AppHeader',
  mixins: [authMixins],
  components: {
    BurgerMenu,
    Message,
    SearchMenu,
    NotificationMenu,
    EvaluationMenu,
    ProfileMenu
  },
  data() {
    return {
      isOpen: false,
      chatStatus: 'offline'
    };
  },
  computed: {
    displanySearch() {
      return (
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.isWebShareLoggedIn
      );
    },
    displanyNotification() {
      return (
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.isWebShareLoggedIn
      );
    },
    displanyEvaluation() {
      /*
        return (
        this.currentUser &&
        !this.isRootAdministrator &&
        !this.isWebShareLoggedIn
      );
      */
      return false;
    },
    activateChatApp() {
      return (
        process.env.ACTIVATE_CHAT_APP &&
        this.currentUser &&
        !this.isWebShareLoggedIn
      );
    },
    chatIsOnline() {
      return this.chatStatus !== 'offline';
    },
    displanyProfile() {
      return (
        this.currentUser &&
        !this.isWebShareLoggedIn &&
        !this.isRootAdministrator
      );
    }
  },
  methods: {
    openLiveChat() {
      if (this.isOpen) {
        this.isOpen = false;
        window.zE('webWidget', 'close');
      } else {
        this.isOpen = true;
        window.zE('webWidget', 'show');
        window.zE('webWidget', 'open');
      }
    }
  },
  created() {
    if (process.env.ACTIVATE_CHAT_APP) {
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', () => {
        this.isOpen = false;
        window.zE('webWidget', 'hide');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.app-header {
  @include flexy($align: center, $just: space-between);
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
  background: $background-light;
  padding: 0 $spacing-base;

  &__left,
  &__right {
    @include flexy($align: center, $just: flex-start);
    flex: 1;
    height: 100%;
  }

  &__left {
    > * {
      margin-right: $spacing-half;
    }
  }

  &__right {
    @include flexy($align: center, $just: flex-end);

    > * {
      margin-left: $spacing-base - 2px;
    }
  }

  &__logo {
    display: block;
    max-width: rem(115px);
    max-height: rem(37px);
  }
}
</style>
