import {
  get as httpGet,
  post as httpPost,
  del as httpDelete
} from '../requests';

export const ENDPOINT_URL = '/comments';

const getEndPointUrl = (typeEndpoint, parentId, id) => {
  const idPart = id ? `/${id}` : '';
  return `${typeEndpoint}/${parentId}${ENDPOINT_URL}${idPart}`;
};

export const get = async (typeEndpoint, parentId, id) => {
  const response = await httpGet(getEndPointUrl(typeEndpoint, parentId, id));
  return response.json();
};

export const getAll = async (typeEndpoint, parentId, options = {}) => {
  const response = await httpPost(
    `${getEndPointUrl(typeEndpoint, parentId)}/getAll`,
    options
  );
  return response.json();
};

export const create = async (typeEndpoint, parentId, data) => {
  const response = await httpPost(getEndPointUrl(typeEndpoint, parentId), data);
  return response.json();
};

export const del = async (typeEndpoint, parentId, id) => {
  const response = await httpDelete(getEndPointUrl(typeEndpoint, parentId, id));
  return response.json();
};
