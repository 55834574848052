import { useAuthStore } from '@/stores/auth';

const AccountManagement = () =>
  import('../../components/account/AccountManagement');
const CompanyView = () => import('../../components/company/CompanyView');

const beforeEnter = () => {
  return () => {
    const authStore = useAuthStore();
    const { canManageCompanies } = authStore;
    if (!canManageCompanies) {
      return { name: 'root' };
    }
  };
};

export default (store) => {
  return [
    {
      path: '/companies',
      name: 'company',
      component: CompanyView,
      beforeEnter: beforeEnter(store)
    },
    {
      path: '/companies/:companyId/accounts',
      name: 'company-accounts',
      component: AccountManagement,
      props: (route) => ({ ...route.params }),
      beforeEnter: beforeEnter(store)
    }
  ];
};
