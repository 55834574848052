<template>
  <MediaFormDialogue
    class="form-dialogue-video"
    :class="$attrs.class"
    :title="$t(`mediaForm.video.label.${mode}`)"
    @cancel="$emit('cancel')"
    @ok="ok()"
    :disableOk="!isValid"
    v-model:error="error"
  >
    <div class="form-dialogue-video__content" @keydown.enter.prevent="ok">
      <BaseInput
        ref="name"
        :label="$t('mediaForm.name')"
        name="name"
        :maxlength="120"
        v-model.trim="form.name"
      />

      <div class="form-dialogue-video__video">
        <transition :name="transitionName">
          <div v-if="step === 'select'" class="form-dialogue-video__transition">
            <div class="form-dialogue-video__select">
              <VideoMedia size="xlarge" />

              <div
                v-if="!uploadInProgress"
                class="form-dialogue-video__select--buttons"
              >
                <FileInput
                  v-if="canCapture"
                  :accept="['video/*']"
                  @change="fileSelected"
                  capture="environment"
                  name="capture"
                >
                  {{ $t('mediaForm.camera') }}
                </FileInput>
                <CallToAction
                  v-else-if="canUseUserMedia"
                  @v-click="displayRecording"
                  >{{ $t('mediaForm.video.createVideo') }}</CallToAction
                >

                <FileInput
                  :accept="['video/*']"
                  @change="fileSelected($event, true)"
                  name="files"
                >
                  {{ $t('mediaForm.select') }}
                </FileInput>
              </div>

              <div
                v-if="uploadInProgress"
                class="form-dialogue-video__select--upload"
              >
                {{ $t('mediaForm.video.uploadInProgress') }}
              </div>

              <BaseInput
                ref="link"
                :label="$t('mediaForm.video.pasteLink')"
                name="link"
                centered
                v-model.trim="form.link"
              />
            </div>
          </div>
        </transition>

        <transition :name="transitionName">
          <div
            v-if="step === 'recording'"
            class="form-dialogue-video__transition"
          >
            <VideoRecording
              @stop="stopVideoRecording"
              @cancel="cancelVideoRecording"
            />
          </div>
        </transition>

        <transition :name="transitionName" @afterEnter="previewDisplayed">
          <div
            v-if="step === 'preview'"
            class="form-dialogue-video__transition"
          >
            <div class="form-dialogue-video__preview">
              <div
                ref="container"
                class="form-dialogue-video__preview--content"
              >
                <VideoPlayer
                  ref="player"
                  :poster="form.poster"
                  :sources="sources"
                  :link="form.link"
                  @playing="updatePlaying"
                  :style="styleDimensions"
                />
              </div>

              <button
                class="button icon-button form-dialogue-video__preview--delete"
                @click="trash"
              >
                <Trash />
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </MediaFormDialogue>
</template>

<script>
import features from '../../utils/features';
import { toMegabytes } from '../../utils/numbers';
import MediaFormDialogue from './MediaFormDialogue';
import FileInput from '../forms/FileInput';
import CallToAction from '../forms/CallToAction';
import VideoPlayer from '../mediaPlayer/VideoPlayer';
import mediaForm from '../mixins/mediaForm';
import VideoRecording from '../controls/VideoRecording';
import Trash from '../icons/Trash';
import VideoMedia from '../icons/VideoMedia';
import * as video from '../../utils/video';

const maxSize = process.env.MEDIA_FILE_MAX_SIZE;

const STEP_BASE = 'select';
const STEP_PREVIEW = 'preview';
const STEP_RECORDING = 'recording';

export default {
  inheritAttrs: false,
  name: 'FormDialogueVideo',
  mixins: [mediaForm],
  components: {
    VideoPlayer,
    MediaFormDialogue,
    FileInput,
    CallToAction,
    VideoRecording,
    Trash,
    VideoMedia
  },
  data() {
    const {
      id,
      name = '',
      link = '',
      linkPostedUrl = '',
      fileInfos
    } = this.editItem;

    return {
      form: {
        file: null,
        name,
        link,
        linkPostedUrl,
        mediaType: 'video'
      },
      submitted: false,
      step: id ? STEP_PREVIEW : STEP_BASE,
      transitionName: 'prev',
      sources: fileInfos?.url
        ? [
            {
              id: fileInfos.id,
              src: fileInfos.url,
              type: fileInfos.contentType
            }
          ]
        : [],
      playing: false,
      error: '',
      fileInfos,
      width: null,
      height: null,
      isVideoRecording: false,
      canUseUserMedia: video.canUseUserMedia()
    };
  },
  computed: {
    canCapture() {
      return features.captureAttribute();
    },
    isValid() {
      if (this.step === STEP_RECORDING || this.step === STEP_PREVIEW)
        return true;
      return this.form.file || this.form.link;
    },
    displayPlayButton() {
      if (this.form.link) return true;
      if (!this.form.file) {
        return !!(
          this.editItem &&
          this.editItem.fileInfos &&
          this.editItem.fileInfos.url
        );
      }
      const reg = /.+\.(.+)$/i;
      let ext = '';

      const match = this.form.file.name.trim().match(reg);
      if (match && match[1]) {
        ext = match[1].trim().toLowerCase();
      }

      return ext !== 'mov';
    },
    displayBack() {
      return this.step === STEP_PREVIEW;
    },
    styleDimensions() {
      if (this.width && this.height) {
        return `max-width: ${this.width}px; max-height: ${this.height}px;`;
      } else {
        return 'max-width: 100%; max-height: 100%;';
      }
    }
  },
  methods: {
    async ok() {
      if (!this.isValid) return;

      switch (this.step) {
        case STEP_BASE:
          // Validate link if set
          if (this.form.link) {
            const provider = VideoPlayer.getProviderByUrl(this.form.link);

            if (!provider) {
              this.error = this.$t('mediaForm.video.linkDomainError', {
                link: this.form.link
              });
              break;
            }

            this.step = STEP_PREVIEW;
          } else {
            this.transitionName = 'next';
            this.step = STEP_PREVIEW;
          }
          break;
        case STEP_RECORDING:
          this.transitionName = 'next';
          this.step = STEP_PREVIEW;
          break;
        case STEP_PREVIEW:
          if (!this.form.name) {
            this.error = this.$t('mediaForm.nameRequired');
            this.select('name');
            return;
          }

          if (!this.submitted) {
            this.submitted = true;

            try {
              const media = await this.modify();
              this.$emit('success', media);
            } catch (e) {
              console.error(e);
              this.submitted = false;
              this.error = this.$t('mediaForm.error');
            }
          }
          break;
      }
    },
    trash() {
      if (!this.isVideoRecording && this.form.file) {
        let name;
        const pos = this.form.file.name.lastIndexOf('.');
        if (pos > 0) {
          name = this.form.file.name.substring(0, pos);
        } else {
          name = this.form.file.name;
        }

        if (this.form.name === name) this.form.name = '';
      }

      this.form.file = null;
      this.form.link = '';
      this.form.linkPostedUrl = '';

      this.sources = [];

      this.transitionName = 'prev';
      this.step = this.isVideoRecording ? STEP_RECORDING : STEP_BASE;
      this.select('name');
    },

    displayRecording() {
      this.isVideoRecording = true;
      this.step = STEP_RECORDING;
    },
    cancelVideoRecording() {
      this.isVideoRecording = false;
      this.transitionName = 'prev';
      this.step = STEP_BASE;
    },
    stopVideoRecording(file) {
      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
      } else {
        this.form.file = file;
        this.form.link = '';

        this.ok();
      }
    },

    fileSelected(files, setName) {
      const [file] = files;
      this.isVideoRecording = false;

      if (file.size > maxSize) {
        this.error = this.$t('mediaForm.sizeError', {
          size: this.$n(toMegabytes(file.size), 'decimal'),
          max: this.$n(toMegabytes(maxSize), 'decimal')
        });
      } else {
        this.form.file = file;
        this.form.link = '';
        if (setName && !(this.form.name || '')) {
          const pos = file.name.lastIndexOf('.');
          if (pos > 0) {
            this.form.name = file.name.substring(0, pos);
          } else {
            this.form.name = file.name;
          }
        }

        this.ok();
      }

      setTimeout(() => {
        this.select('name');
      }, 100);
    },
    clearSources() {
      this.sources.forEach((source) => URL.revokeObjectURL(source.src));
      this.sources = [];
    },
    updatePlaying({ playing }) {
      this.playing = playing;
    },

    previewDisplayed() {
      this.setDimensions();

      this.$nextTick(() => {
        const { player } = this.$refs;
        if (this.form.link) {
          this.form.linkPostedUrl = player.player.poster;
        }

        this.select('name');
      });
    },
    setDimensions() {
      const { container } = this.$refs;
      if (!container) return;

      const { clientHeight, clientWidth } = container;
      this.width = clientWidth;
      this.height = clientHeight;
    }
  },
  created() {
    window.addEventListener('resize', this.setDimensions);
  },
  mounted() {
    if (this.file) {
      this.fileSelected([this.file], true);
    }

    this.$nextTick(() => {
      this.select('name');
      this.setDimensions();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setDimensions);
    this.clearSources();
  },
  watch: {
    'form.file': function (newFile) {
      this.clearSources();

      if (newFile) {
        this.sources = [
          {
            id: 0,
            src: URL.createObjectURL(newFile),
            type: newFile.type
          }
        ];
      }
    }
  }
};
</script>

<style lang="scss">
$color: $media-video;

.form-dialogue-video {
  padding: $spacing-base;

  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $color;
  }

  .form-field {
    border: none;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
  }

  &__video {
    @include next-prev-transition();
    flex: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__transition {
    @include flexy($dir: column, $just: center);
    width: 100%;
    height: 100%;
  }

  &__select {
    @include flexy($dir: column, $just: center, $shrink: 0);

    &--buttons {
      @include flexy($dir: column, $align: center);

      > * {
        background-color: $color;
        margin-left: auto;
        margin-right: auto;
      }
    }

    > .icon {
      fill: $color;
      margin: 0 auto;
    }

    > .form-field {
      margin-top: $vertical-rhythm;
    }

    &--upload {
      @include flexy($dir: column, $just: center, $align: center);
      flex: 1;
      font-style: $font-italic;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      padding: $spacing-double;
      text-align: center;
    }
  }

  &__preview {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 $spacing-base $spacing-base $spacing-base;

    &--content {
      @include flexy($dir: column, $just: center, $align: center);
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: #000;

      > .video-player {
        height: 100%;
        width: 100%;
      }
    }

    &--delete {
      align-self: flex-end;
      padding: $spacing-base 0;
    }

    > .form-field {
      width: 100%;
      margin-top: $spacing-base;
    }
  }
}
</style>
