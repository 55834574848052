<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.306 22.443"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-97.66 -141.407)">
      <rect
        width="21.278"
        height="2.258"
        rx="0.784"
        transform="translate(99.918 142.573) rotate(90)"
      />
      <rect
        width="17.306"
        height="2.258"
        rx="0.784"
        transform="translate(114.966 144.831) rotate(-180)"
      />
      <rect
        width="17.306"
        height="2.258"
        rx="0.784"
        transform="translate(114.966 163.85) rotate(-180)"
      />
      <rect
        width="21.278"
        height="2.258"
        rx="0.784"
        transform="translate(114.966 142.573) rotate(90)"
      />
      <rect
        width="10.01"
        height="4.589"
        rx="1.592"
        transform="translate(101.308 141.407)"
      />
      <rect
        width="9.978"
        height="1.712"
        rx="0.594"
        transform="translate(101.339 151.295)"
      />
      <rect
        width="9.978"
        height="1.712"
        rx="0.594"
        transform="translate(101.339 154.38)"
      />
      <rect
        width="9.978"
        height="1.712"
        rx="0.594"
        transform="translate(101.339 157.464)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'ClipboardIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
