const tools = {
  regExpEmail:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  removeAccents(str) {
    str = str.toString();
    str = str.replace(/^\s+|\s+$/g, ''); // trim

    const from =
      'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const to =
      'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return str;
  },
  encodeRequest(values) {
    const valuesEncoded = [];
    for (const v in values) {
      if (typeof values[v] !== 'undefined') {
        if (
          values[v] &&
          values[v].constructor === Array &&
          values[v].length > 0
        ) {
          for (const i in values[v]) {
            valuesEncoded.push(
              encodeURIComponent(v) + '[]=' + encodeURIComponent(values[v][i])
            );
          }
        } else if (
          values[v] &&
          values[v].constructor === Array &&
          values[v].length === 0
        ) {
          valuesEncoded.push(
            encodeURIComponent(v) + '=' + encodeURIComponent([])
          );
        } else {
          valuesEncoded.push(
            encodeURIComponent(v) + '=' + encodeURIComponent(values[v])
          );
        }
      }
    }
    return valuesEncoded.join('&');
  }
};

export default tools;

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
