<template>
  <div
    ref="player"
    class="new-audio-player"
    :class="$attrs.class"
    @mousedown.stop="mousedown"
    @touchstart.prevent.stop="touchstart"
  >
    <div class="new-audio-player__progress-bar--outer">
      <div class="new-audio-player__progress-bar">
        <div ref="bar" class="new-audio-player__progress-bar--inner">
          <slot name="bar"></slot>
        </div>
      </div>
    </div>
    <audio
      v-if="initialized"
      ref="audio"
      @playing="playing"
      @timeupdate="timeupdate"
      @pause="pause"
      @ended="ended"
      @error="audioError"
    >
      <source
        :src="source.src"
        :type="source.type"
        v-for="source in sources"
        :key="source.id"
        @error="sourceError"
      />
    </audio>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'NewAudioPlayer',
  props: {
    sources: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialized: false,
      moving: false,
      touched: false,
      mousemouve: null,
      mouseup: null,
      touchmove: null,
      touchend: null
    };
  },
  methods: {
    mousedown(e) {
      if (this.disabled) return;
      if (this.touched) return;

      this.moving = true;

      if (this.mousemove)
        document.removeEventListener('mousemove', this.mousemove);
      if (this.mouseup) document.removeEventListener('mousemove', this.mouseup);

      this.mousemove = (e) => this.seek(e.clientX);
      this.mouseup = (e) => {
        e.preventDefault();
        e.stopPropagation();

        document.removeEventListener('mousemove', this.mousemove);
        document.removeEventListener('mouseup', this.mouseup);
        this.mousemove = null;
        this.mouseup = null;
        this.moving = false;
        this.seek(e.clientX);
      };

      document.addEventListener('mousemove', this.mousemove);
      document.addEventListener('mouseup', this.mouseup);

      this.seek(e.clientX);
    },
    touchstart(e) {
      if (this.disabled) return;

      const touch = e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];

      this.touched = true;
      if (this.touchmove)
        window.removeEventListener('touchmove', this.touchmove);
      if (this.touchend) {
        document.removeEventListener('touchend', this.touchend);
        document.removeEventListener('touchcancel', this.touchend);
      }

      this.moving = true;

      this.touchmove = (e) => {
        const touch =
          e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];
        this.seek(touch.clientX);
      };

      this.touchend = (e) => {
        const touch =
          e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];

        this.moving = false;
        this.touched = false;

        document.removeEventListener('touchend', this.touchend);
        document.removeEventListener('touchcancel', this.touchend);
        this.touchend = null;
        document.removeEventListener('touchmove', this.touchmove);
        this.touchmove = null;

        this.seek(touch.clientX);
      };

      document.addEventListener('touchend', this.touchend);
      document.addEventListener('touchcancel', this.touchend);
      document.addEventListener('touchmove', this.touchmove);

      this.seek(touch.clientX);
    },
    seek(clientX) {
      if (this.disabled) return;

      this.initAudio(() => {
        const { audio, player } = this.$refs;
        if (audio.readyState === 0) return;

        const { left, width } = player.getBoundingClientRect();
        let position = clientX - left;
        if (position < 0) position = 0;
        if (position > width) position = width;

        audio.currentTime = (audio.duration * position) / width;
      });
    },
    togglePlay() {
      if (this.disabled) return;
      if (this.moving) return;

      this.initAudio(() => {
        const { audio } = this.$refs;
        audio.paused ? audio.play() : audio.pause();
      });
    },
    isPlaying() {
      if (this.disabled) return false;
      const { audio } = this.$refs;
      return !audio.paused;
    },
    sourceError(event) {
      console.error(event);
      this.$emit('error', 'sourceError');
    },
    playing() {
      const { audio } = this.$refs;

      this.$emit('playing', {
        playing: true,
        event: 'playing',
        currentTime: audio.currentTime,
        duration: audio.duration
      });
    },
    timeupdate() {
      const { audio, bar, player } = this.$refs;
      const position =
        (player.clientWidth * audio.currentTime) / audio.duration;
      this.$emit('timeupdate', { position });
      bar.style.left = `${position}px`;
    },
    pause() {
      const { audio } = this.$refs;

      if (audio.currentTime !== audio.duration)
        this.$emit('playing', {
          playing: false,
          event: 'paused',
          currentTime: audio.currentTime,
          duration: audio.duration
        });
    },
    ended() {
      const { audio, bar } = this.$refs;
      if (this.moving) return;
      this.$emit('playing', {
        playing: false,
        event: 'ended',
        currentTime: audio.currentTime,
        duration: audio.duration
      });

      bar.style.left = '0';
    },
    audioError(e) {
      console.error('Audio error', e);
      this.$emit('error', e);
    },
    initAudio(callback) {
      if (this.initialized) {
        if (callback) callback();
        return;
      }

      this.initialized = true;

      this.$nextTick(() => {
        if (callback) callback();
      });
    },
    clearAudio() {
      const { bar } = this.$refs;
      this.$emit('clearAudio');
      if (bar) {
        bar.style.left = '0';
      }

      this.initialized = false;
    }
  },
  beforeUnmount() {
    this.clearAudio();
  },
  watch: {
    disabled() {
      if (this.disabled) {
        this.clearAudio();
      }
    }
  }
};
</script>

<style lang="scss">
.new-audio-player {
  @include flexy($align: center);
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__progress-bar--outer {
    width: 100%;
    padding: 7px;
    border: 1px solid #ffffff;
    border-radius: 21px;
    background-color: #ffffff;
  }

  &__progress-bar {
    position: relative;
    width: 100%;
    height: 7px;
    border-radius: 7px;
    background-color: #595b5f;
    overflow: hidden;
  }

  &__progress-bar--inner {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }
}
</style>
