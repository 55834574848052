<template>
  <div v-if="display" class="clipboard-footer-action" :class="$attrs.class">
    <button
      v-if="clipboardAction === 'evaluation' && clipboardAssets.size > 0"
      class="icon-button icon-button--active clipboard-footer-action__evaluation"
      @click="setExecuteActionClipboard('addToEvaluation')"
    >
      <Close type="active" />
      <span class="icon-button-label__right">{{
        $t('clipboardMenu.addToEvaluation')
      }}</span>
    </button>
    <button
      v-else-if="clipboardAction !== 'evaluation'"
      class="icon-button icon-button--active clipboard-footer-action__quit"
      @click="quit"
    >
      <Close type="active" />
      <span class="icon-button-label__right">{{
        $t('selectionMode.exit')
      }}</span>
    </button>
  </div>
</template>

<script>
import Close from '../icons/Close';
import { mapActions } from 'pinia';
import { useFooter } from '@/stores/footer';
import selectionClipboardMixin from '@/components/mixins/selectionClipboard';

export default {
  inheritAttrs: false,
  name: 'ClipboardFooterAction',
  mixins: [selectionClipboardMixin],
  components: {
    Close
  },
  props: {
    search: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    display() {
      if (this.search) return this.isSearchSelectionMode;
      else return this.isSelectionMode;
    }
  },
  methods: {
    ...mapActions(useFooter, {
      setIsDisplayedClearClipboardFooter: 'setIsDisplayedClearClipboardFooter',
      resetFooter: 'reset'
    }),
    quit() {
      if (this.search) {
        this.quitSearchSelectionMode();
      } else {
        this.quitSelectionMode();
      }
    }
  },
  watch: {
    async display(value) {
      await this.setIsDisplayedClearClipboardFooter(value);
      this.$forceUpdate();
    }
  },
  beforeUnmount() {
    this.resetFooter();
  }
};
</script>

<style lang="scss">
.clipboard-footer-action {
  @include flexy($align: center);
  height: 100%;

  > * {
    height: 100%;
    margin-left: $spacing-base;
  }

  &__evaluation {
    > .icon-close-icon {
      transform: rotateY(0deg) rotate(45deg);
    }
  }
}
</style>
