<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.745 20.979"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M15.075,2.434a10.491,10.491,0,0,0-9.2,15.526A4.739,4.739,0,0,1,2.82,20.988c1.245.855,3.125,1.143,5.847.237A10.489,10.489,0,1,0,15.075,2.434Zm0,19.379a8.889,8.889,0,1,1,8.889-8.889A8.889,8.889,0,0,1,15.075,21.813Zm-2.531-3.969q.069-.306.207-.808L13.994,12.5c.013-.053.025-.105.034-.158a.794.794,0,0,0,.015-.148q0-.4-.256-.5a3.4,3.4,0,0,0-.935-.128v-.532q.568-.039,1.649-.163t1.532-.212l.861-.168-1.568,5.748q-.2.739-.266,1.025a5.36,5.36,0,0,0-.177.947q0,.217.1.281a.388.388,0,0,0,.217.064q.306,0,.761-.523a7.877,7.877,0,0,0,.8-1.094l.444.286a13.762,13.762,0,0,1-1.511,1.952A2.618,2.618,0,0,1,13.858,20a1.578,1.578,0,0,1-.992-.34,1.233,1.233,0,0,1-.439-1.04A4.048,4.048,0,0,1,12.545,17.844ZM17.293,6.377a1.3,1.3,0,0,1,.395.956,1.319,1.319,0,0,1-.395.961,1.294,1.294,0,0,1-.956.4,1.363,1.363,0,0,1-1.36-1.361,1.3,1.3,0,0,1,.4-.956,1.32,1.32,0,0,1,.961-.394A1.3,1.3,0,0,1,17.293,6.377Z"
      transform="translate(-2.82 -2.434)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'BriefIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
