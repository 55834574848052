<template>
  <div ref="root" class="content-link" :class="$attrs.class">
    <Loading v-if="processing" class="content-link__processing" />
    <div v-else class="content-link__main">
      <div class="content-link__top">
        <div class="content-link__top__boxe">
          <TileCallToActionMedia :options="tileCallToActionMediaOptions" />
        </div>
      </div>
      <div class="content-link--name">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMediaStore } from '@/stores/media';
import TileCallToActionMedia from '../media/TileCallToActionMedia';
import assetMixin from '../mixins/asset';
import mediaProperties from '../mixins/mediaProperties';
import contentMediaMixin from './ContentMediaMixin';

export default {
  inheritAttrs: false,
  name: 'ContentLink',
  mixins: [assetMixin, mediaProperties, contentMediaMixin],
  components: {
    TileCallToActionMedia
  },
  methods: {
    ...mapActions(useMediaStore, ['track']),
    click($event) {
      const open = () => {
        this.track({
          event: 'link_opened',
          item: this.item,
          isShared: this.isShared
        });

        const link = this.item.link.trim();
        const s = link.toLowerCase();
        if (s.startsWith('mailto:') || s.startsWith('tel:'))
          location.href = link;
        else window.open(link, '_blank');
      };

      if (!$event?.event) open();

      const clickableElement = $event?.event.target.closest(
        '[data-clickable="true"]'
      );
      if (clickableElement) open();
    }
  }
};
</script>

<style lang="scss" scoped>
.content-link {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;

  &__processing {
    width: $loading-size-base;
    height: $loading-size-base;
  }

  &__main {
    @include grid($rows: 1fr auto, $areas: 'top' 'name');
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &__top {
    @include grid-item($area: top);
    @include flexy($dir: column, $align: center, $just: center);
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__boxe {
      height: $tile-media-slide-min;
      width: $tile-media-slide-min;
      max-width: 100%;
      max-height: 100%;

      @media screen and (max-width: $small-size-break),
        screen and (max-height: $small-size-break) {
        width: $tile-media-slide-min-small;
        height: $tile-media-slide-min-small;
      }

      @media screen and (max-height: $xsmall-size-break) {
        width: $tile-media-slide-min-xsmall;
        height: $tile-media-slide-min-xsmall;
      }
    }
  }

  &--name {
    @include grid-item($area: name);
    @include slider-tile-media-name();
  }
}
</style>
