import { defineStore } from 'pinia';

const baseState = {
  isDisplayedClearFilters: false,
  isDisplayedClearClipboardFooter: false
};

export const key = 'footer';
export const useFooter = defineStore(key, {
  state: () => ({ ...baseState }),
  actions: {
    async reset() {
      Object.assign(this, { ...baseState });
    },
    async setIsDisplayedClearFilters(value) {
      this.isDisplayedClearFilters = value;
    },
    async setIsDisplayedClearClipboardFooter(value) {
      this.isDisplayedClearClipboardFooter = value;
    }
  }
});

export default {
  key,
  use: useFooter
};
