<template>
  <div class="asset-common-options" :class="$attrs.class">
    <div class="asset-common-options__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'AssetCommonOptions'
};
</script>

<style lang="scss" scoped>
$height: 86px;

.asset-common-options {
  @include flexy($just: center);
  background-color: $background-light;
  border-color: $border-menu;
  border-style: solid;
  border-width: $border-width 0 0 0;
  width: 100%;
  height: $height;
  min-height: $height;

  &__content {
    @include flexy();
    height: 100%;
  }
}
</style>
