import { computed, reactive, toRefs } from 'vue';
import { StoreDefinition, storeToRefs } from 'pinia';
import { mapStores } from '@/stores';
import { DASHBOARD_TYPE, SHARE_ASSET_TYPE } from '@/utils/types';

export const defaultSort = 'positionAsc';
export const defaultShareAssetSort = 'nameAsc';
export const defaultDashboardSort = 'nameAsc';

export const sortOptions: any = {
  nameAsc: { freeName: 'asc', insertedOn: 'desc' },
  nameDesc: { freeName: 'desc', insertedOn: 'desc' },
  dateAsc: { insertedOn: 'asc' },
  dateDesc: { insertedOn: 'desc' },
  positionAsc: { position: 'asc', insertedOn: 'desc' }
};

export const sortShareAssetOptions: any = {
  nameAsc: { fullTextSearch: 'asc', creationDate: 'desc' },
  nameDesc: { fullTextSearch: 'desc', creationDate: 'desc' },
  dateAsc: { creationDate: 'asc' },
  dateDesc: { creationDate: 'desc' }
};

export const sortDashboardOptions: any = {
  nameAsc: { freeName: 'asc', insertedOn: 'desc' },
  nameDesc: { freeName: 'desc', insertedOn: 'desc' },
  dateAsc: { insertedOn: 'asc' },
  dateDesc: { insertedOn: 'desc' }
};

const defaultValues: any = {
  [DASHBOARD_TYPE]: {
    sort: defaultDashboardSort,
    options: sortDashboardOptions
  },
  [SHARE_ASSET_TYPE]: {
    sort: defaultShareAssetSort,
    options: sortShareAssetOptions
  },
  default: { sort: defaultSort, options: sortOptions }
};

type TSortableStore = {
  sort: string;
  setSort: (_sort: string) => void;
};

export const useSort = (module: string) => {
  const useStore = mapStores.get(module).use as StoreDefinition<
    string,
    TSortableStore
  >;
  const { setSort } = useStore();
  const { sort } = storeToRefs(useStore());

  const values = defaultValues[module]
    ? defaultValues[module]
    : defaultValues.default;

  const rs = reactive({
    defaultSort: values.sort,
    sortOptions: { ...values.options }
  });

  return {
    // DATA
    ...toRefs(rs),

    // COMPUTED
    sort,

    sortLabel: computed(() => {
      return (
        Object.keys(rs.sortOptions).filter((key) => key === sort.value)[0] ||
        rs.defaultSort
      );
    }),

    // METHODS
    setSort
  };
};
