<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.958 22.958"
  >
    <title v-if="text">{{ text }}</title>
    <defs>
      <clipPath id="clip-path">
        <rect width="22.958" height="22.958" transform="translate(0 0)" />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g clip-path="url(#clip-path)">
        <path
          d="M14.591,5.409a2.507,2.507,0,0,0-3.542,0L9.155,7.3a2.69,2.69,0,0,1,1.584.3l1.254-1.251a1.17,1.17,0,0,1,1.655,1.655L11.335,10.32a1.172,1.172,0,0,1-1.657,0h0a.668.668,0,0,0-.944.944,2.508,2.508,0,0,0,3.542,0l2.316-2.313a2.507,2.507,0,0,0,0-3.542"
        />
        <path
          d="M9.261,12.394,8.008,13.647a1.17,1.17,0,1,1-1.655-1.655L8.665,9.677a1.17,1.17,0,0,1,1.655,0,.668.668,0,0,0,.944-.944,2.508,2.508,0,0,0-3.542,0L5.409,11.049a2.5,2.5,0,0,0,3.542,3.542L10.845,12.7a2.69,2.69,0,0,1-1.584-.3"
        />
        <path
          d="M16,20H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H16a4,4,0,0,1,4,4V16a4,4,0,0,1-4,4M4,1.5A2.5,2.5,0,0,0,1.5,4V16A2.5,2.5,0,0,0,4,18.5H16A2.5,2.5,0,0,0,18.5,16V4A2.5,2.5,0,0,0,16,1.5Z"
        />
        <line y1="0.001" transform="translate(20.434 3.247)" />
        <line x2="0.001" transform="translate(3.245 20.428)" />
        <path
          d="M4.121,20.527a4.066,4.066,0,0,1-.875-.1,4,4,0,0,0,3.711,2.53h12a4,4,0,0,0,4-4v-12a4,4,0,0,0-2.522-3.71,4.157,4.157,0,0,1,.092.856v.923a2.486,2.486,0,0,1,.93,1.931v12a2.5,2.5,0,0,1-2.5,2.5h-12a2.486,2.486,0,0,1-1.93-.931"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'CopyLinkIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
