<template>
  <div ref="container" :class="$attrs.class" :style="$attrs.style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'DblClickElement',
  props: {
    prevent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      clicked: null,
      touched: null,
      clickedAndTouched: false
    };
  },
  methods: {
    click(e) {
      this.clickedAndTouched = !!this.touched;

      if (!this.clicked) {
        // if tap is not set, set up single tap
        this.clicked = setTimeout(() => {
          this.clicked = null;
          this.$emit('click', { event: e, touched: this.clickedAndTouched });
          this.clickedAndTouched = false;
        }, 300); // wait 300ms then run single click code
      } else {
        // clicked within 300ms of last click. double click
        clearTimeout(this.clicked); // stop single click callback
        this.clicked = null;

        if (this.clickedAndTouched) {
          this.clickedAndTouched = false;
        } else {
          this.$emit('dblclick', { event: e, touched: this.clickedAndTouched });
        }
      }

      if (this.prevent) e.preventDefault();
    },
    touch(e) {
      if (!this.touched) {
        // if tap is not set, set up single tap
        this.touched = setTimeout(() => {
          this.touched = null;
          this.$emit('touch', e);
        }, 300); // wait 300ms then run single click code
      } else {
        // clicked within 300ms of last click. double click
        if (this.clicked) clearTimeout(this.clicked); // stop single click callback
        clearTimeout(this.touched); // stop single click callback
        this.touched = null;
        this.$emit('dbltouch', e);
      }

      if (this.prevent) e.preventDefault();
    }
  },
  mounted() {
    const { container } = this.$refs;
    container.addEventListener('click', this.click);
    container.addEventListener('touchstart', this.touch);
  },
  beforeUnmount() {
    const { container } = this.$refs;
    container.removeEventListener('click', this.click);
    container.removeEventListener('touchstart', this.touch);
  }
};
</script>
