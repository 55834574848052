import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'SquareIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
      >
        <title>{{ text }}</title>
        <rect width="12" height="12" rx="1" />
      </svg>
    );
  }
};
