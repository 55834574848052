export default {
  data() {
    return {
      scrollList: null,
      listLoading: false
    };
  },
  methods: {
    async listScrolled() {
      if (!this.load || this.autoLoadOnNextPage === false) return;
      if (!this.hasNextPage) return;
      if (this.listLoading) return;
      this.listLoading = true;

      const loadMore = () => {
        const { scrollTop, scrollHeight, clientHeight } = this.scrollList;
        return (
          Math.abs(Math.ceil(scrollTop)) + clientHeight >= scrollHeight - 100
        );
      };

      try {
        if (!loadMore()) return;

        let maxLoad = 100;
        let result = await this.load();
        await this.$nextTick();

        while (result.hasNextPage && maxLoad > 0 && loadMore()) {
          result = await this.load();
          await this.$nextTick();
          maxLoad--;
        }
      } finally {
        this.listLoading = false;
      }
    }
  },
  mounted() {
    if (!this.load || this.autoLoadOnNextPage === false) return;

    this.$nextTick(() => {
      this.scrollList = this.$refs?.list || this.$el.closest('.list-page');
      this.scrollList?.addEventListener('scroll', this.listScrolled);
    });
  },
  beforeUnmount() {
    if (!this.load || this.autoLoadOnNextPage === false) return;

    this.scrollList?.removeEventListener('scroll', this.listScrolled);
  }
};
