<template>
  <section
    ref="dialogue"
    tabindex="0"
    @mousedown.stop
    @keydown.stop="keydown"
    class="floatable-dialogue"
    :class="[
      `floatable-dialogue--${size}`,
      {
        'floatable-dialogue--no-footer': !(
          showCancel ||
          showOk ||
          showFooterIfEmpty
        )
      },
      { 'floatable-dialogue--content-maximized': contentMaximized },
      $attrs.class
    ]"
    :style="dialogueStyle"
  >
    <header
      ref="header"
      v-if="displayHeader"
      class="floatable-dialogue__header"
      :class="{ 'floatable-dialogue__header--movable': movable && !maximized }"
      :style="mainStyle"
    >
      <slot name="head">
        <h1>{{ title }}</h1>
        <span v-if="showClose" class="floatable-dialogue__spacer">&nbsp;</span>
        <button v-if="showClose" class="icon-button" @click="$emit('cancel')">
          <Cross size="small" type="light" />
        </button>
      </slot>
    </header>

    <main ref="main" class="floatable-dialogue__main" :style="mainStyle">
      <div class="floatable-dialogue__main--content" :style="mainContentStyle">
        <slot></slot>
      </div>
    </main>

    <footer
      ref="footer"
      v-if="displayFooter"
      class="floatable-dialogue__footer"
      :style="mainStyle"
    >
      <button
        v-if="showCancel"
        class="text-button floatable-dialogue__footer__cancel"
        @click="$emit('cancel')"
      >
        <slot name="cancel">{{ $t('dialogue.footer.cancel') }}</slot>
      </button>

      <div class="text-button floatable-dialogue__footer__custom">
        <slot name="customButtons"></slot>
      </div>
      <div class="text-button floatable-dialogue__footer__custom">
        <slot name="customButtons2"></slot>
      </div>

      <button
        v-if="showOk"
        :disabled="disableOk"
        class="text-button floatable-dialogue__footer__ok"
        @click="$emit('ok')"
      >
        <slot name="ok">{{ $t('dialogue.footer.ok') }}</slot>
      </button>
    </footer>
  </section>
</template>

<script>
import '../../assets/stylesheets/components/_button.scss';
import Cross from '../icons/Cross';

const SIZES = ['small', 'medium', 'maximized'];
const minWidth = 348;
const minHeight = 348;

export default {
  inheritAttrs: false,
  name: 'FloatableDialogue',
  components: {
    Cross
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => SIZES.includes(value)
    },
    showOk: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showFooterIfEmpty: {
      type: Boolean,
      default: false
    },
    disableOk: {
      type: Boolean,
      default: false
    },
    contentMaximized: {
      type: Boolean,
      default: false
    },
    closeOnEscape: {
      type: Function,
      default: null
    },
    movable: {
      type: Boolean,
      default: true
    },
    resizable: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    defaultWidth: {
      type: String,
      default: `${minWidth}px`
    },
    defaultHeight: {
      type: String,
      default: `${minHeight}px`
    },
    maximized: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogue: null,
      top: 0,
      left: 0,
      width: 0,
      height: 0,
      moving: false,
      resizing: false,
      activeResizableZone: false,
      lastDraggingPosition: null
    };
  },
  computed: {
    displayHeader() {
      return !this.contentMaximized;
    },
    displayFooter() {
      if (this.contentMaximized) return false;
      return this.showCancel || this.showOk || this.showFooterIfEmpty;
    },
    baseZIndex() {
      return this.zIndex;
    },
    dialogueStyle() {
      return {
        zIndex: this.baseZIndex
      };
    },
    mainStyle() {
      return {
        zIndex: this.baseZIndex + 1
      };
    },
    mainContentStyle() {
      return {
        zIndex: this.baseZIndex + 2
      };
    }
  },
  methods: {
    dialogueSelected() {
      this.$emit('selected');
    },
    dialogueMousedown(e) {
      if (!this.activeResizableZone) return;

      this.resizing = true;
      this.lastDraggingPosition = {
        x: e.clientX,
        y: e.clientY
      };

      document.addEventListener('mousemove', this.resize);
    },
    dialogueTouchstart(e) {
      const touch = e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];

      this.checkResizableZones(touch);
      if (!this.activeResizableZone) return;

      this.resizing = true;
      this.lastDraggingPosition = {
        x: touch.clientX,
        y: touch.clientY
      };

      document.addEventListener('touchend', this.dialogueTouchend);
      document.addEventListener('touchcancel', this.dialogueTouchend);
      document.addEventListener('touchmove', this.dialogueTouchmove);
    },
    dialogueTouchmove(e) {
      const touch = e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];
      this.resize(touch);
    },
    dialogueTouchend() {
      document.removeEventListener('touchend', this.dialogueTouchend);
      document.removeEventListener('touchcancel', this.dialogueTouchend);
      document.removeEventListener('touchmove', this.dialogueTouchmove);

      this.moving = false;
      this.resizing = false;
      this.lastDraggingPosition = null;
      this.dialogue.style.cursor = '';
      document.body.style.cursor = '';
      this.activeResizableZone = false;
    },
    headerMousedown(e) {
      if (this.activeResizableZone) return;

      document.addEventListener('mousemove', this.move);
      this.moving = true;
      this.lastDraggingPosition = {
        x: e.clientX,
        y: e.clientY
      };
    },
    headerTouchstart(e) {
      const touch = e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];

      this.checkResizableZones(touch);
      if (this.activeResizableZone) return;

      document.addEventListener('touchend', this.headerTouchend);
      document.addEventListener('touchcancel', this.headerTouchend);
      document.addEventListener('touchmove', this.headerTouchmove);

      this.moving = true;
      this.lastDraggingPosition = {
        x: touch.clientX,
        y: touch.clientY
      };
    },
    headerTouchmove(e) {
      const touch = e.touches.length === 0 ? e.changedTouches[0] : e.touches[0];
      this.move(touch);
    },
    headerTouchend() {
      document.removeEventListener('touchend', this.headerTouchend);
      document.removeEventListener('touchcancel', this.headerTouchend);
      document.removeEventListener('touchmove', this.headerTouchmove);

      this.moving = false;
      this.resizing = false;
      this.lastDraggingPosition = null;
      this.dialogue.style.cursor = '';
      document.body.style.cursor = '';
      this.activeResizableZone = false;
    },
    mouseup(e) {
      this.moving = false;
      this.resizing = false;
      document.removeEventListener('mousemove', this.move);
      this.lastDraggingPosition = null;
      this.checkResizableZones(e);
    },
    move(e) {
      if (this.maximized) return;

      const { clientWidth, clientHeight } = document.body;

      let left = this.left + (e.clientX - this.lastDraggingPosition.x);
      let top = this.top + (e.clientY - this.lastDraggingPosition.y);
      if (top < 0) top = 0;
      if (top > clientHeight - 200) top = clientHeight - 200;
      if (this.width + left < 200) left = -this.width + 200;
      if (left > clientWidth - 200) left = clientWidth - 200;

      this.setPosition(left, top);

      this.lastDraggingPosition = {
        x: e.clientX,
        y: e.clientY
      };
    },
    resize(e) {
      this.$emit('resize', e);
      if (this.maximized) return;

      if (!this.resizing) return;

      let diffX = e.clientX - this.lastDraggingPosition.x;
      let diffY = e.clientY - this.lastDraggingPosition.y;
      const { cursor } = this.dialogue.style;

      const setWidth = (w) => {
        let width = w;
        if (width < minWidth) {
          diffX = 0;
          width = minWidth;
        }

        return width;
      };
      const setHeight = (h) => {
        let height = h;
        if (height < minHeight) {
          diffY = 0;
          height = minHeight;
        }

        return height;
      };

      if (cursor === 'sw-resize') {
        this.setDimension(setWidth(this.width - diffX), this.height + diffY);
        this.setLeft(this.left + diffX);
      } else if (cursor === 'w-resize') {
        this.setWidth(setWidth(this.width - diffX));
        this.setLeft(this.left + diffX);
      } else if (cursor === 'nw-resize') {
        this.setDimension(
          setWidth(this.width - diffX),
          setHeight(this.height - diffY)
        );
        this.setPosition(this.left + diffX, this.top + diffY);
      } else if (cursor === 'n-resize') {
        this.setHeight(setHeight(this.height - diffY));
        this.setTop(this.top + diffY);
      } else if (cursor === 'ne-resize') {
        this.setDimension(this.width + diffX, setHeight(this.height - diffY));
        this.setTop(this.top + diffY);
      } else if (cursor === 'e-resize') {
        this.setWidth(this.width + diffX);
      } else if (cursor === 'se-resize') {
        this.setDimension(this.width + diffX, this.height + diffY);
      } else if (cursor === 's-resize') {
        this.setHeight(this.height + diffY);
      }

      this.lastDraggingPosition = {
        x: e.clientX,
        y: e.clientY
      };
    },
    mouseenter() {
      this.dialogue.addEventListener('mousemove', this.checkResizableZones);
    },
    mouseleave() {
      this.dialogue.removeEventListener('mousemove', this.checkResizableZones);
      if (!this.resizing) {
        this.dialogue.style.cursor = '';
        document.body.style.cursor = '';
        this.activeResizableZone = false;
      }
    },
    checkResizableZones(e) {
      if (this.moving || this.resizing) return;
      const offset = 10;
      const px = e.pageX;
      const py = e.pageY;

      const right = this.left + this.width;
      const bottom = this.top + this.height;

      let cursor = '';

      if (px > this.left && px < this.left + offset) {
        // left border
        if (py > this.top && py < this.top + offset) {
          cursor = 'nw-resize';
        } else if (py < bottom && py > bottom - offset) {
          cursor = 'sw-resize';
        } else {
          cursor = 'w-resize';
        }
      } else if (py > this.top && py < this.top + offset) {
        // top border
        if (px > this.left && px < this.left + offset * 2) {
          cursor = 'nw-resize';
        } else if (px < right && px > right - offset * 2) {
          cursor = 'ne-resize';
        } else {
          cursor = 'n-resize';
        }
      } else if (px < right && px > right - offset) {
        // right border
        if (py > this.top && py < this.top + offset) {
          cursor = 'ne-resize';
        } else if (py < bottom && py > bottom - offset) {
          cursor = 'se-resize';
        } else {
          cursor = 'e-resize';
        }
      } else if (py < bottom && py > bottom - offset) {
        // bottom border
        if (px > this.left && px < this.left + offset * 2) {
          cursor = 'sw-resize';
        } else if (px < right && px > right - offset * 2) {
          cursor = 'se-resize';
        } else {
          cursor = 's-resize';
        }
      }

      this.activeResizableZone = !!cursor;
      this.dialogue.style.cursor = cursor;
      document.body.style.cursor = cursor;
    },
    keydown(e) {
      if (e.keyCode === 27) {
        if (!this.closeOnEscape || this.closeOnEscape()) {
          this.$emit('cancel');
        }
      }
    },
    setPosition(left, top) {
      this.setLeft(left);
      this.setTop(top);
    },
    setLeft(left) {
      this.left = left;
      this.dialogue.style.left = `${left}px`;
    },
    setTop(top) {
      this.top = top;
      this.dialogue.style.top = `${top}px`;
    },
    setDimension(width, height) {
      this.setWidth(width);
      this.setHeight(height);
    },
    setWidth(width) {
      if (width < minWidth) width = minWidth;
      this.width = width;
      this.dialogue.style.width = `${width}px`;
    },
    setHeight(height) {
      if (height < minHeight) height = minHeight;
      this.height = height;
      this.dialogue.style.height = `${height}px`;
    },
    toggleMaximize() {
      if (this.maximized) {
        this.dialogue.style.left = '0';
        this.dialogue.style.top = '0';
        this.dialogue.style.width = '100%';
        this.dialogue.style.height = '100%';
      } else {
        this.setPosition(this.left, this.top);
        this.setDimension(this.width, this.height);
      }

      this.resize();
    },
    focus() {
      const { dialogue } = this.$refs;
      dialogue.focus();
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { dialogue, header, main, footer } = this.$refs;

      this.dialogue = dialogue;
      dialogue.style.zIndex = this.baseZIndex;

      const w = window.innerWidth;
      const h = window.innerHeight;

      if (this.maximized) {
        this.setPosition(0, 0);
        this.dialogue.style.width = '100%';
        this.dialogue.style.height = '100%';
      } else {
        this.dialogue.style.width = this.defaultWidth;
        this.dialogue.style.height = this.defaultHeight;

        const { width, height } = this.dialogue.getBoundingClientRect();
        this.width = width;
        this.height = height;
        if (this.width < minWidth) this.width = minWidth;
        if (this.height < minHeight) this.height = minHeight;

        this.setPosition((w - this.width) / 2, (h - this.height) / 2);

        this.dialogue.addEventListener('mousedown', this.dialogueSelected);
        header.addEventListener('mousedown', this.headerMousedown);
        document.addEventListener('mouseup', this.mouseup);
        header.addEventListener('touchstart', this.headerTouchstart);

        if (this.resizable) {
          dialogue.addEventListener('mousedown', this.dialogueMousedown);
          dialogue.addEventListener('mouseenter', this.mouseenter);
          dialogue.addEventListener('mouseleave', this.mouseleave);
          dialogue.addEventListener('touchstart', this.dialogueTouchstart);
        }
      }

      main.style.zIndex = this.baseZIndex + 1;

      const mainChild = main.querySelector(
        '.floatable-dialogue__main > :first-child'
      );
      if (mainChild) mainChild.style.zIndex = this.baseZIndex + 2;

      if (footer) footer.style.zIndex = this.baseZIndex + 1;

      this.$emit('resize');

      this.focus();
    });
  },
  beforeUnmount() {
    const { header } = this.$refs;

    this.dialogue.removeEventListener('mousedown', this.dialogueSelected);
    header.removeEventListener('mousedown', this.headerMousedown);
    document.removeEventListener('mouseup', this.mouseup);
    header.removeEventListener('touchstart', this.headerTouchstart);

    if (this.resizable) {
      this.dialogue.removeEventListener('mousedown', this.dialogueMousedown);
      this.dialogue.removeEventListener('mouseenter', this.mouseenter);
      this.dialogue.removeEventListener('mouseleave', this.mouseleave);
      this.dialogue.removeEventListener('touchstart', this.dialogueTouchstart);
    }
  },
  watch: {
    maximized() {
      this.toggleMaximize();
    },
    contentMaximized() {
      if (!this.contentMaximized) this.focus();
    }
  }
};
</script>

<style lang="scss">
.floatable-dialogue {
  @include flexy($dir: column, $just: space-between);
  position: fixed;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.36);

  &--content-maximized {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  &__header {
    @include flexy($align: center, $just: center);
    background-color: $background-dark;
    color: $font-light;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
  }

  &__main {
    @include flexy($dir: column, $just: flex-start);
    background-color: $background-light;
    color: $font-dark;
    flex: 1;
    overflow-x: hidden;
    position: relative;

    &--content {
      @include flexy($dir: column, $just: flex-start);
      background-color: $background-light;
      color: $font-dark;
      flex: 1;
      overflow-x: hidden;
      position: relative;

      & > * {
        position: relative;
      }
    }
  }

  &__footer {
    @extend .floatable-dialogue__header;
    @include flexy($align: center, $just: space-between);

    &__custom {
      @include flexy($align: center);
    }

    .text-button {
      font-size: $font-size-large;
    }
  }

  &__spacer {
    flex: 1;
  }
}

.floatable-dialogue:focus {
  outline: none;
}
</style>
