<template>
  <div ref="root" class="content-file" :class="$attrs.class">
    <Loading v-if="processing" class="content-file__processing" />
    <div v-else class="content-file__main">
      <div class="content-file__top">
        <div
          v-if="fileBackgroundImage"
          class="content-file__top__image"
          data-clickable="true"
        >
          <img
            ref="image"
            class="content-file__top__image--img"
            :src="fileBackgroundImage"
            :alt="item.name"
            :style="imgStyle"
            @load="imageLoaded"
            @dragstart.prevent
          />
          <div class="content-file__top__image--pill">
            <div v-if="fileExt">{{ fileExt }}</div>
            <FileMediaIcon v-else size="medium" />
          </div>
        </div>
        <div v-else class="content-file__top__boxe" data-clickable="true">
          <div class="content-file__top__boxe--pill">
            <div v-if="fileExt">{{ fileExt }}</div>
            <FileMediaIcon v-else size="medium" />
          </div>
        </div>
      </div>
      <div class="content-file--name">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMediaStore } from '@/stores/media';
import FileMediaIcon from '../icons/FileMedia';
import Loading from '../icons/Loading';
import assetMixin from '../mixins/asset';
import mediaProperties from '../mixins/mediaProperties';
import contentMediaMixin from './ContentMediaMixin';

export default {
  inheritAttrs: false,
  name: 'ContentFile',
  mixins: [assetMixin, mediaProperties, contentMediaMixin],
  components: {
    FileMediaIcon,
    Loading
  },
  data() {
    return {
      fileImgStyle: { visibility: 'hidden' }
    };
  },
  computed: {
    imgStyle() {
      return this.fileImgStyle;
    }
  },
  methods: {
    ...mapActions(useMediaStore, ['getDownloadSignedUrl', 'track']),
    imageLoaded() {
      const { image } = this.$refs;
      const { width, height } = image.getBoundingClientRect();
      this.fileImgStyle = {
        width: '100%',
        height: '100%',
        maxWidth: `${width}px`,
        maxHeight: `${height}px`
      };
    },
    async download2() {
      this.track({
        event: 'file_opened',
        item: this.item,
        isShared: this.isShared
      });

      const { url } = await this.getDownloadSignedUrl({
        id: this.item.id,
        share: this.isShared
      });
      window.open(url, '_blank');
    },
    open() {
      this.track({
        event: 'file_opened',
        item: this.item,
        isShared: this.isShared
      });

      window.open(this.fileUrl, '_blank');
    },
    click($event) {
      if (!$event?.event) this.open();

      const clickableElement = $event?.event.target.closest(
        '[data-clickable="true"]'
      );
      if (clickableElement) this.open();
    }
  }
};
</script>

<style lang="scss" scoped>
.content-file {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;

  &__processing {
    width: $loading-size-base;
    height: $loading-size-base;
  }

  &__main {
    @include grid($rows: 1fr auto, $areas: 'top' 'name');
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &__top {
    @include grid-item($area: top);
    @include flexy($dir: column, $align: center, $just: center);
    overflow: hidden;

    &__image {
      @include flexy($dir: column, $align: center, $just: center);
      overflow: hidden;
      position: relative;

      &--img {
        overflow: hidden;
        cursor: pointer;
        object-fit: contain;
      }

      &--pill {
        @include flexy($align: center, $just: center);
        width: 53px;
        height: 53px;
        border-radius: 50% 50%;
        background-color: $media-file;
        position: absolute;
        cursor: pointer;

        > div {
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
        }

        > svg {
          fill: $media-file;
        }
      }
    }

    &__boxe {
      @include flexy($align: center, $just: center);
      height: $tile-media-slide-min;
      width: $tile-media-slide-min;
      max-width: 100%;
      max-height: 100%;
      background-color: $media-file;
      border-radius: $tile-media-border-radius;
      border: 1px solid $media-file;
      cursor: pointer;

      @media screen and (max-width: $small-size-break),
        screen and (max-height: $small-size-break) {
        width: $tile-media-slide-min-small;
        height: $tile-media-slide-min-small;
      }

      @media screen and (max-height: $xsmall-size-break) {
        width: $tile-media-slide-min-xsmall;
        height: $tile-media-slide-min-xsmall;
      }

      &--pill {
        @include flexy($align: center, $just: center);
        width: 53px;
        height: 53px;
        border-radius: 50% 50%;
        background-color: #fff;

        > div {
          color: $media-file;
          font-weight: bold;
          text-transform: uppercase;
        }

        > svg {
          fill: $media-file;
        }
      }
    }
  }

  &--name {
    @include grid-item($area: name);
    @include slider-tile-media-name();
  }
}
</style>
