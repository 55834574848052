export function toMegabytes(bytes) {
  return bytes / 1024 / 1024;
}

export function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function round(value, step = 1) {
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}
