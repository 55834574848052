<template>
  <div class="comment-indicator" :class="$attrs.class">
    <div
      class="comment-indicator__pill"
      :class="{ 'comment-indicator__pill--unread': item.hasUnreadComments }"
      @click.prevent="() => showComments()"
    ></div>
  </div>
</template>

<script>
import commentMixin from '../mixins/comment';

export default {
  inheritAttrs: false,
  name: 'CommentIndicator',
  mixins: [commentMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.comment-indicator {
  cursor: pointer;

  &__pill {
    border-radius: $comment-indicatpr-pill-border-radius;
    height: 25px;
    min-width: 25px;
    background-color: $comment-indicator-background;
    border: 1px solid $comment-indicator-background;

    &--unread {
      background-color: $comment-indicator-unread-background;
      border: 1px solid $comment-indicator-unread-background;
    }
  }
}
</style>
