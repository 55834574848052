import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'SubBoard';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
      >
        <title>{{ text }}</title>
        <g transform="translate(-228 -174)">
          <rect width="12" height="8" rx="1" transform="translate(228 178)" />
          <rect width="12" height="3" rx="1" transform="translate(228 174)" />
        </g>
      </svg>
    );
  }
};
