<template>
  <div class="search-item" :class="$attrs.class" @click="goToUrl">
    <div
      class="search-item__state"
      :class="{ 'search-item__state--selected': item.id === lastSelectedId }"
    ></div>
    <ListItem
      v-if="item.type !== 'media'"
      class="search-item__item"
      :item="item"
      :type="item.type"
      :selectable="isSearchSelectionMode"
      :withLink="false"
      :fromSearch="true"
      @navigate="navidate"
    />
    <ListMedia
      v-else
      class="search-item__item"
      :item="item"
      :selectable="isSearchSelectionMode"
      :fromSearch="true"
      @navigate="navigate"
    />

    <div class="search-item__next">
      <Chevron size="xsmall" direction="right" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useSearchStore } from '@/stores/search';
import { CARD_TYPE, MEDIA_TYPE } from '../../utils/types';
import Chevron from '../icons/Chevron';
import ListItem from '../layout/ListItem';
import ListMedia from '../layout/ListMedia';
import authMixins from '../mixins/auth';
import selectionClipboardMixin from '../mixins/selectionClipboard';

export default {
  inheritAttrs: false,
  name: 'SearchItem',
  mixins: [authMixins, selectionClipboardMixin],
  components: {
    Chevron,
    ListItem,
    ListMedia
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(useSearchStore, ['lastSelectedId'])
  },
  methods: {
    ...mapActions(useSearchStore, ['setLastSelectedId']),
    navidate($event) {
      if ($event === 'toParent') {
        const { id, type, parent } = this.item;

        if (parent) {
          this.goto(`${parent.type}/${parent.id}`);
        } else {
          this.goto(`${type}/${id}`);
        }

        this.setLastSelectedId(id);
      }

      this.$emit('selected');
    },
    goToUrl() {
      const { id, type, parent } = this.item;

      if (type === MEDIA_TYPE) {
        this.goto(`${CARD_TYPE}/${parent.id}/?mediaId=${id}`);
      } else {
        this.goto(`${type}/${id}`);
      }

      this.setLastSelectedId(id);
      this.$emit('selected');
    }
  }
};
</script>

<style lang="scss">
.search-item {
  @include grid($gap: 0, $cols: 4px 1fr auto, $areas: 'state item next');
  color: $font-dark;
  background-color: #ffffff;
  font-family: $roboto-condensed;
  overflow: hidden;
  cursor: pointer;

  &__state {
    @include grid-item(state);

    &--selected {
      background-color: $search-selected-background;
    }
  }

  &__item {
    @include grid-item(item);
  }

  &__next {
    @include grid-item(next);
    @include flexy($align: center);
    padding-right: $spacing-half;
    cursor: pointer;
  }
}
</style>
