<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.628 14.912"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(0 -11.141)">
      <path
        d="M15.484,17.869,8.13,11.293a.206.206,0,0,0-.042-.032.435.435,0,0,0-.588-.01L.145,17.827a.435.435,0,0,0,.579.649l1.324-1.186V24.96a1.094,1.094,0,0,0,1.093,1.093H5.462a1.026,1.026,0,0,0,1.025-1.025v-3.9a.158.158,0,0,1,.158-.158H8.988a.158.158,0,0,1,.158.158v3.9a1.026,1.026,0,0,0,1.025,1.025h2.321a1.242,1.242,0,0,0,1.093-1.369V17.332l1.324,1.186a.437.437,0,0,0,.614-.035A.443.443,0,0,0,15.484,17.869Zm-2.771,2.144v4.664c0,.312-.154.5-.225.5H10.168a.158.158,0,0,1-.158-.158V21.128A1.026,1.026,0,0,0,8.985,20.1H6.641a1.026,1.026,0,0,0-1.025,1.025v3.9a.158.158,0,0,1-.158.158H3.138a.226.226,0,0,1-.225-.225V16.513l4.879-4.359,4.921,4.4v3.459Z"
        transform="translate(0)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'DashboardIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
