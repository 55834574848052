import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useCardStore } from '@/stores/card';
import assetMixin from '../mixins/asset';
import { toggleHiddenMixin } from '../mixins/assetOptionsToggleHidden';
import { toggleDeletedMixin } from '../mixins/assetOptionsToggleDeleted';
import commentMixin from '../mixins/comment';
import { CARD_TYPE } from '../../utils/types';

export default {
  mixins: [
    assetMixin,
    toggleHiddenMixin(CARD_TYPE),
    toggleDeletedMixin(CARD_TYPE),
    commentMixin
  ],
  computed: {
    canEdit() {
      if (this.isReadOnly || this.isDeleted) return false;
      if (!this.isProtected) return true;
      return this.isAuthorBoard;
    },
    canManageBackground() {
      return !this.item.brief && this.canEdit;
    },
    canToggleBrief() {
      if (!this.parentHasBrief) return false;
      if (!this.canEdit) return false;
      if (this.item.brief) return true;
      return !this.parentHasBrief();
    },
    canAddComment() {
      return (
        !this.item.brief &&
        !this.isDeleted &&
        !this.isReadOnly &&
        !this.isSandBox &&
        !this.fromSearch
      );
    },
    canShare() {
      if (!this.isParticipant) return false;

      if (this.isDeleted || this.isReadOnly) return false;
      if (this.sharingAllowed) return true;
      return this.isAuthorBoard;
    },
    canManageSubscription() {
      return (
        !this.isDeleted &&
        !this.isReadOnly &&
        !this.isSandBox &&
        !this.fromSearch
      );
    },
    canDeletePermanently() {
      return this.isDeleted && this.isAuthorBoard;
    },
    canRemoveColorLabel() {
      return this.canEdit && !!(this.item.tag || {}).id;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayInfo', 'displayError']),
    ...mapActions(useCardStore, ['modify', 'toggleBrief', 'deletePermanently']),
    addItemToClipboard(action) {
      this.addToClipboard({ item: this.item, action });
      this.$emit('cancel');
    },
    async _toggleBrief() {
      try {
        const item = await this.toggleBrief(this.item);
        if (item.brief) {
          this.displayInfo({
            message: this.$t('cardOptions.convertedToBrief', this.item)
          });
        } else {
          this.displayInfo({
            message: this.$t('cardOptions.convertedToCard', this.item)
          });
        }

        this.$emit('cancel');
      } catch (e) {
        console.error('cardMixin: _toggleBrief', e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    _displayComments() {
      this.showComments();
      this.$emit('cancel');
    },
    async _deletePermanently() {
      try {
        this.toggleToast();
        await this.deletePermanently(this.item);
        this.$emit('cancel');
      } catch (e) {
        console.error('cardMixin => _deletePermanently', e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    async removeColorLabel() {
      try {
        this.modify({
          id: this.item.id,
          assetTagId: null
        });

        this.$emit('cancel');
      } catch (e) {
        console.error('cardMixin => removeColorLabel', e);
        this.displayError({ message: this.$t('global.error') });
      }
    }
  }
};
