<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.46 18.935"
  >
    <title v-if="text">{{ text }}</title>
    <g transform="translate(-293 -15.065)">
      <rect
        width="6.312"
        height="13.886"
        rx="3.156"
        transform="translate(293 20.114)"
      />
      <rect
        width="6.312"
        height="18.935"
        rx="3.156"
        transform="translate(300.574 15.065)"
      />
      <rect
        width="6.312"
        height="11.361"
        rx="3.156"
        transform="translate(308.148 22.639)"
      />
    </g>
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'EvaluationIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
