<template>
  <BaseDialogue
    class="information-dialogue"
    :class="[`information-dialogue--${type + suffix}`, $attrs.class]"
    :title="$t('information.title')"
    size="small"
    showClose
    :showOk="false"
    :showCancel="false"
    @cancel="$emit('cancel')"
  >
    <dl class="information-dialogue__list">
      <dt class="information-dialogue__term">{{ $t('information.name') }}</dt>
      <dd class="information-dialogue__definition">{{ item.name }}</dd>

      <dt class="information-dialogue__term">
        {{ $t('information.creationDate') }}
      </dt>
      <dd class="information-dialogue__definition">
        {{ $d(creationDate, { dateStyle: 'long', timeStyle: 'short' }) }}
      </dd>
    </dl>
  </BaseDialogue>
</template>

<script>
import BaseDialogue from './BaseDialogue';
import { CARD_TYPE, MEDIA_TYPE } from '@/utils/types';

export default {
  inheritAttrs: false,
  name: 'InformationDialogue',
  components: { BaseDialogue },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    creationDate() {
      return new Date(this.item.insertedOn);
    },
    type() {
      if (this.item.type === MEDIA_TYPE) return this.item.mediaType;
      else return this.item.type;
    },
    suffix() {
      if (this.item.type === CARD_TYPE) {
        return this.item.brief ? '-brief' : '';
      }

      return '';
    }
  }
};
</script>

<style lang="scss">
.information-dialogue {
  @include media-dialogue(background-color);
  color: $font-dark;
  font-weight: $font-weight-regular;
  text-transform: none;

  &__list {
    padding: $spacing-base;
  }

  &__term {
    height: calc($vertical-rhythm / 2);
    font-weight: $font-weight-bold;
  }

  &__definition {
    height: calc($vertical-rhythm / 2);
    margin-bottom: $spacing-base;
  }

  &--board {
    .dialogue__header,
    .dialogue__footer {
      background-color: $board-base;
    }
  }

  &--folder {
    .dialogue__header,
    .dialogue__footer {
      background-color: $folder-base;
    }
  }

  &--card {
    .dialogue__header,
    .dialogue__footer {
      background-color: $card-base;
    }
  }

  &--card-brief {
    .dialogue__header,
    .dialogue__footer {
      background-color: $brief-base;
    }
  }
}
</style>
