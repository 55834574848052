import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';

import {
  get as apiGet,
  update as apiUpdate,
  search as apiSearch,
  inviteMultiple as apiInviteMultiple
} from '@/api/endpoints/member';
import { exists as apiEmailExists } from '@/api/endpoints/email';
import { isUserParticipant } from '@/utils/role';
import { EVALUATION_RESULT_TYPE } from '@/utils/types';

const initialState = {
  loading: 0,
  parent: null,
  parentType: null,

  members: [],
  hasNextPage: false,
  options: []
};

const isSameDomain = (email, currentUser) => {
  if (!email || !currentUser) return false;
  const domain = email.replace(/.*@/, '');
  return currentUser.company.domainName.toLowerCase() === domain.toLowerCase();
};

const mapMember = (member, currentUser) => {
  let restricted;
  if (member.group) restricted = null;
  else if (member.account) restricted = !isUserParticipant(member.account);
  else restricted = !isSameDomain(member.email, currentUser);
  return { ...member, restricted };
};

export const key = 'member';
export const useMemberStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, { ...initialState });
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async setParent({ parent, parentType }) {
      this.parent = { ...parent };
      this.parentType = parentType;
    },
    async get(options = {}) {
      if (!this.parent) return;

      const { members } = this;
      const { start = members.length, limit } = options;

      await this.startLoading();

      try {
        let { items, hasNextPage } = await apiGet(
          this.parentType,
          this.parent.id,
          {
            query: { start, limit }
          }
        );

        const authStore = useAuthStore();
        items = items.map((item) => mapMember(item, authStore.currentUser));

        this.members = start === 0 ? items : [...members, ...items];
        this.hasNextPage = hasNextPage;

        return { items, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async update(data) {
      if (!this.parent) return;

      if (this.parentType === EVALUATION_RESULT_TYPE) {
        await apiUpdate(this.parentType, this.parent.id, data);
        return null;
      } else {
        this.parent = await apiUpdate(this.parentType, this.parent.id, data);
        return this.parent;
      }
    },
    async inviteMultiple(data) {
      if (!this.parent) return;

      if (this.parentType === EVALUATION_RESULT_TYPE) {
        await apiInviteMultiple(this.parentType, this.parent.id, data);
        return null;
      } else {
        this.parent = await apiInviteMultiple(
          this.parentType,
          this.parent.id,
          data
        );
        return this.parent;
      }
    },
    async search({ text }) {
      if (!this.parent) return;

      if (!text || text.length < 2) {
        this.options = [];
        return;
      }

      this.options = await apiSearch(this.parentType, this.parent.id, { text });

      return this.options;
    },
    async clearOptions() {
      this.options = [];
    },
    async emailExists(email) {
      const s = (email || '').toString().trim();
      if (!s) return false;
      return await apiEmailExists(s);
    }
  }
});

export default {
  key,
  use: useMemberStore
};
