<template>
  <BaseDialogue
    class="media-subscription-dialogue"
    :class="[`media-subscription-dialogue--${asset.mediaType}`, $attrs.class]"
    :title="title"
    size="small"
    @ok="ok"
    @cancel="$emit('cancel')"
  >
    <div class="form-element">
      <div class="form-field media-subscription-dialogue__title">
        {{ $t('mediaSubscriptionForm.adding.title') }}
      </div>
      <div class="form-field media-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="addingComment"
          :label="$t('mediaSubscriptionForm.adding.comment')"
          v-model="form.addingComment"
          @update:modelValue="valueChanged"
        />
      </div>

      <div class="form-field">
        <div class="media-subscription-dialogue__options">
          {{ $t('mediaSubscriptionForm.options') }}
        </div>

        <div class="media-subscription-dialogue__options--content">
          <BaseCheckbox
            class="form-field"
            name="receiveEmail"
            :label="$t('mediaSubscriptionForm.receiveEmail')"
            :disabled="disabledReceiveEmail"
            v-model="form.receiveEmail"
          />
        </div>
      </div>
    </div>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useMediaStore } from '@/stores/media';
import BaseDialogue from '../dialogues/BaseDialogue';

export default {
  inheritAttrs: false,
  name: 'MediaSubscriptionDialogue',
  components: {
    BaseDialogue
  },
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        addingComment: false,
        receiveEmail: false
      },
      option: null,
      submitted: false
    };
  },
  computed: {
    title() {
      return this.$t('mediaSubscriptionForm.title');
    },
    disabledReceiveEmail() {
      return !this.form.addingComment;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useMediaStore, [
      'getSubscription',
      'addSubscription',
      'editSubscription'
    ]),
    async ok() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const data = {
            options: {
              addingComment: this.form.addingComment,
              receiveEmail: this.form.receiveEmail
            }
          };

          if (this.entity.id) {
            await this.editSubscription({
              parentId: this.asset.id,
              id: this.entity.id,
              data
            });
          } else {
            await this.addSubscription({
              parentId: this.asset.id,
              data
            });
          }

          this.$emit('success');
        } catch (e) {
          console.error(e);
          this.submitted = false;
          this.displayError({ message: this.$t('global.error') });
        }
      }
    },
    valueChanged() {
      if (this.disabledReceiveEmail) {
        this.form.receiveEmail = false;
      }
    }
  },
  async beforeMount() {
    this.entity = await this.getSubscription({ parentId: this.asset.id });
    if (this.entity.id) {
      const { options } = this.entity;
      this.form = {
        addingComment: !!options.addingComment,
        receiveEmail: !!options.receiveEmail
      };
    }
  }
};
</script>

<style lang="scss">
.media-subscription-dialogue {
  @include media-dialogue(background-color);

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  &__checkbox {
    margin-left: $spacing-double;
  }

  &__options {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }
}
</style>
