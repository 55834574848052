import { defineStore } from 'pinia';

import {
  get as apiGet,
  getAll as apiGetAll,
  getCriterias as apiGetCriterias,
  getStatements as apiGetStatements,
  getCards as apiGetCards,
  getCardsByCriteria as apiGetCardsByCriteria,
  getCardsByStatement as apiGetCardsByStatement,
  getCardMedias as apiGetCardMedias,
  inviteAllEvaluationMembers as apiInviteAllEvaluationMembers,
  getInviteInfo as apiGetInviteInfo
} from '@/api/endpoints/evaluationResult';
import { CARD_TYPE, EVALUATION_RESULT_TYPE } from '@/utils/types';
import i18n from '@/i18n/index';
import { getRemainingTime } from '@/utils/date';
import { pad } from '@/utils/numbers';

const initialCurrentState = {
  currentItem: null,
  items: [],
  itemHasNextPage: false,

  currentCard: null,
  cards: [],
  cardHasNextPage: false,

  cardMedias: [],
  mediaHasNextPage: false
};

const initialState = {
  current: null,
  evaluations: [],
  hasNextPage: false,
  searchText: null,

  ...initialCurrentState
};

class EvaluationResult {
  constructor(item) {
    this.id = item.id;

    this.name = item.name;
    this.type = item.type;
    this.endDate = new Date(item.endDate);
    this.sent = !!item.sent;
    this.editing = !!item.editing;

    this.author = item.author;
    this.completed = item.completed;
    this.creationDate = new Date(item.creationDate);

    this.cardCount = item.cardCount;
    this.participantCount = item.participantCount;
    this.completedParticipantCount = item.completedParticipantCount;

    this.intervalTime = 60000;
    this.setRemainingTime();

    this.interval = setInterval(() => {
      this.setRemainingTime();
    }, this.intervalTime);
  }

  setRemainingTime() {
    if (new Date() > this.endDate) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      this.remainingTime = null;
    } else {
      const { days, hours, minutes, seconds } = getRemainingTime(this.endDate);
      if (
        days === 0 &&
        hours === 0 &&
        minutes <= 1 &&
        this.intervalTime > 1000
      ) {
        if (this.interval) clearInterval(this.interval);
        this.intervalTime = 1000;
        this.interval = setInterval(() => {
          this.setRemainingTime();
        }, this.intervalTime);
      }

      if (this.intervalTime > 1000) {
        this.remainingTime = i18n.global.t(
          'evaluationResult.remainingTimeValue',
          [days, pad(hours, 2), pad(minutes, 2)]
        );
      } else {
        this.remainingTime = i18n.global.t(
          'evaluationResult.remainingTimeValue2',
          [days, pad(hours, 2), pad(minutes, 2), pad(seconds, 2)]
        );
      }
    }
  }
}

export const key = EVALUATION_RESULT_TYPE;
export const useEvaluationResultStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      Object.assign(this, { ...initialState });
    },
    async getEvaluations(options = {}) {
      const { evaluations, searchText } = this;
      let { start = evaluations.length, limit, text } = options;

      if (text === undefined) text = searchText;

      const apiSort = {
        creationDate: 'desc'
      };

      let { items, hasNextPage } = await apiGetAll({
        query: { start, sort: apiSort, limit },
        text: text || ''
      });

      items = items.map((x) => new EvaluationResult(x));

      this.evaluations = start === 0 ? items : [...evaluations, ...items];
      this.hasNextPage = hasNextPage;
      this.searchText = text;

      return { items, hasNextPage };
    },
    async search({ text }) {
      await this.getEvaluations({ start: 0, text });
    },
    async clearSearch() {
      await this.getEvaluations({ start: 0, text: '' });
    },
    async clearFilters() {
      await this.getEvaluations({ start: 0, text: '' });
    },
    async setCurrent(evaluation) {
      if (evaluation) {
        this.current = await apiGet(evaluation.id);
      } else {
        this.current = null;
      }

      this.items = [];
      this.itemHasNextPage = false;
      this.currentItem = null;
      this.cards = [];
      this.cardHasNextPage = false;
      this.currentCard = null;
      this.cardMedias = [];
      this.mediaHasNextPage = false;
    },
    async getItems(options = {}) {
      if (!this.current) return;

      const { items: currentItems } = this;

      const { start = currentItems.length, limit } = options;

      try {
        const get =
          this.current.type === 'criteria' ? apiGetCriterias : apiGetStatements;
        const { items, hasNextPage } = await get(this.current.id, {
          query: { start, limit }
        });

        this.items = start === 0 ? items : [...currentItems, ...items];
        this.itemHasNextPage = hasNextPage;

        if (!this.currentItem && items.length > 0) {
          await this.setCurrentItem(items[0]);
        }

        return { items, hasNextPage };
      } catch (e) {
        console.error(e);
      }
    },
    async setCurrentItem(item) {
      this.currentItem = { ...item };

      this.cards = [];
      this.cardHasNextPage = false;
      this.currentCard = null;
      this.cardMedias = [];
      this.mediaHasNextPage = false;

      if (item) {
        await this.getCardsByItem({ start: 0 });
      }
    },
    async getCards(options = {}) {
      if (!this.current) return;

      const { cards } = this;

      const { start = cards.length, limit } = options;

      const apiSort = {
        creationDate: 'desc'
      };

      try {
        const { items, hasNextPage } = await apiGetCards(this.current.id, {
          query: { start, sort: apiSort, limit }
        });

        items.forEach((x) => {
          x.card.type = CARD_TYPE;
        });

        this.cards = start === 0 ? items : [...cards, ...items];
        this.cardHasNextPage = hasNextPage;

        if (!this.currentCard && items.length > 0) {
          await this.setCurrentCard(items[0]);
        }

        return { items, hasNextPage };
      } catch (e) {
        console.error(e);
      }
    },
    async getCardsByItem(options = {}) {
      if (!this.current || !this.currentItem) return;

      const { cards } = this;

      const { start = cards.length, limit } = options;

      const apiSort = {
        creationDate: 'desc'
      };

      try {
        const get =
          this.current.type === 'criteria'
            ? apiGetCardsByCriteria
            : apiGetCardsByStatement;
        const { items, hasNextPage } = await get(
          this.current.id,
          this.currentItem.id,
          {
            query: { start, sort: apiSort, limit }
          }
        );

        items.forEach((x) => {
          x.card.type = CARD_TYPE;
        });

        this.cards = start === 0 ? items : [...cards, ...items];
        this.cardHasNextPage = hasNextPage;

        if (!this.currentCard && items.length > 0) {
          await this.setCurrentCard(items[0]);
        }

        return { items, hasNextPage };
      } catch (e) {
        console.error(e);
      }
    },
    async apiGetCardsByCriteria({ currentId, currentItemId, options = {} }) {
      return await apiGetCardsByCriteria(currentId, currentItemId, options);
    },
    async apiGetCardsByStatement({ currentId, currentItemId, options = {} }) {
      return await apiGetCardsByStatement(currentId, currentItemId, options);
    },
    async setCurrentCard(card) {
      this.currentCard = { ...card };
      this.cardMedias = [];
      this.mediaHasNextPage = false;

      if (card) {
        await this.getCardMedias({ start: 0 });
      }
    },
    async getCardMedias(options = {}) {
      if (!this.currentCard) return;

      const { cardMedias } = this;

      const { start = cardMedias.length, limit } = options;

      const { items, hasNextPage } = await apiGetCardMedias(
        this.currentCard.id,
        {
          query: { start, limit }
        }
      );

      this.cardMedias = start === 0 ? items : [...cardMedias, ...items];
      this.mediaHasNextPage = hasNextPage;

      return { items, hasNextPage };
    },
    async updateItem(item) {
      if (this.evaluations.some((x) => x.id === item.id)) {
        this.evaluations = this.evaluations.map((x) =>
          x.id !== item.id ? x : item
        );
      }
    },
    async refresh(id) {
      const exists =
        this.evaluations.some((x) => x.id === id) || this.current?.id === id;
      if (!exists) return;

      const item = await apiGet(id);
      if (!item) {
        await this.itemDeleted({ id });
      } else {
        await this.updateItem(new EvaluationResult(item));
      }
    },
    async inviteAllEvaluationMembers(id) {
      return await apiInviteAllEvaluationMembers(id);
    },
    async getInviteInfo(token) {
      return await apiGetInviteInfo(token);
    },
    async itemDeleted({ id }) {
      if (this.current?.id === id) {
        this.current = null;
        Object.assign(this, { ...initialCurrentState });
      }

      if (this.evaluations.some((x) => x.id === id)) {
        this.evaluations = this.evaluations.filter((x) => x.id !== id);
      }
    }
  }
});

export default {
  key,
  use: useEvaluationResultStore
};
