<template>
  <div class="dashboard-treeview" :class="$attrs.class" @click.stop.prevent>
    <DashboardTreeviewItem
      v-for="tv in displayedItems"
      :key="tv.item.id"
      :item="tv.item"
      :level="tv.level"
      :expanded="tv.expanded"
      @toggle="toggleItem(tv)"
      @navigate="$emit('navigate', $event)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useNavigationStore } from '@/stores/navigation';
import DashboardTreeviewItem from './DashboardTreeviewItem';

export default {
  inheritAttrs: false,
  name: 'DashboardTreeview',
  components: {
    DashboardTreeviewItem
  },
  computed: {
    ...mapState(useNavigationStore, ['displayedItems'])
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useNavigationStore, ['loadTreeviewItems', 'toggleItem'])
  },
  beforeMount() {
    this.loadTreeviewItems();
  }
};
</script>

<style lang="scss" scoped>
.dashboard-treeview {
  @include flexy($dir: column);
  flex-wrap: wrap;
  overflow-y: auto;
}
</style>
