<template>
  <div class="new-search-field" :class="$attrs.class" @click.stop="">
    <div
      class="new-search-field__clear"
      :class="{ 'new-search-field__clear--disabled': !modelValue }"
      @click.stop="clear"
    >
      <Close size="small" />
    </div>

    <input
      ref="input"
      class="new-search-field__input"
      autocomplete="off"
      :value="modelValue"
      :placeholder="_placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown.enter.stop="search"
    />
    <div class="new-search-field__chevron" @click.stop="search">
      <Chevron size="xsmall" direction="right" />
    </div>
  </div>
</template>

<script>
import Close from '../icons/Close';
import Chevron from '../icons/Chevron';

export default {
  inheritAttrs: false,
  name: 'SearchField',
  components: {
    Close,
    Chevron
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    setFocus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    _placeholder() {
      return this.placeholder || this.$t('util.Search');
    }
  },
  methods: {
    search() {
      this.focus();
      this.$emit('search');
    },
    clear() {
      this.focus();
      this.$emit('clear');
    },
    focus() {
      const { input } = this.$refs;
      input.focus();
    },
    select() {
      const { input } = this.$refs;
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }
  },
  mounted() {
    if (this.setFocus) this.focus();
    this.$emit('mounted', this);
  }
};
</script>

<style lang="scss" scoped>
.new-search-field {
  @include grid($cols: auto 1fr auto, $areas: 'clear input chevron');
  width: 100%;
  background-color: $background-light;
  padding-left: $spacing-half;
  border-radius: 20px;

  &__clear {
    @include grid-item(clear);
    @include flexy($align: center);
    height: 100%;
    cursor: pointer;

    &--disabled {
      opacity: $disable-opacity;
      cursor: default;
    }
  }

  &__input {
    @include grid-item(input);
    width: 100%;
    color: $font-dark;
    font-size: $font-size-medium;
    border: none;
    margin: $spacing-half;
  }

  &__input:focus {
    outline: none;
  }

  &__chevron {
    @include grid-item(chevron);
    @include flexy($align: center);
    height: 100%;
    padding: 0 $spacing-half;
    margin-left: $spacing-half;
    cursor: pointer;
  }
}
</style>
