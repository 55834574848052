import { useAuthStore } from '@/stores/auth';
import auth from '../auth';
import { ROLE_PARTICIPANT_RESTRICTED } from '../../utils/role';

const DashboardView = () => import('../../components/DashboardView');

export default () => {
  return [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardView,
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isRootAdministrator } = authStore;
        if (isRootAdministrator) {
          return { name: 'account-management' };
        }

        return await auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)(to);
      }
    }
  ];
};
