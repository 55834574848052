import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'GroupIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 44.603 40.98"
      >
        <title>{{ text }}</title>
        <g transform="translate(-104.576 -117.959)">
          <g transform="translate(107.079 -128.496)">
            <g transform="translate(4.987 251.989)">
              <ellipse
                cx="8.252"
                cy="8.92"
                rx="8.252"
                ry="8.92"
                transform="translate(6.559 0)"
              />
              <g transform="translate(0 16.23)">
                <path
                  d="M142.25,643.232s4.971.045,9.113-.231,6.674-2.486,5.339-10.725-5.469-8.1-6.306-8.239a2.594,2.594,0,0,0-1.749.6c-.644.414-2.946,2.624-6.4,2.624s-5.754-2.209-6.4-2.624a2.594,2.594,0,0,0-1.749-.6c-.837.139-4.971,0-6.306,8.239s1.2,10.448,5.339,10.725S142.25,643.232,142.25,643.232Z"
                  transform="translate(-127.439 -624.02)"
                />
              </g>
            </g>
            <ellipse
              cx="5.385"
              cy="5.822"
              rx="5.385"
              ry="5.822"
              transform="translate(27.048 246.454)"
            />
            <path
              d="M533.405,435.589c-.871-5.377-3.569-5.286-4.115-5.377a1.693,1.693,0,0,0-1.141.39,6.881,6.881,0,0,1-4.175,1.712,6.029,6.029,0,0,1-3.458-1.184,10.88,10.88,0,0,1,.4,2.933,10.676,10.676,0,0,1-1.811,5.989,2.665,2.665,0,0,1,.6.031c.024,0,.1.012.151.018a6.431,6.431,0,0,1,3.749,1.605,7.586,7.586,0,0,1,.963,1.034c1.059,0,3.355-.019,5.355-.152C532.624,442.408,534.276,440.966,533.405,435.589Z"
              transform="translate(-491.539 -173.154)"
            />
            <ellipse
              cx="5.385"
              cy="5.822"
              rx="5.385"
              ry="5.822"
              transform="translate(1.777 246.454)"
            />
            <path
              d="M-2.27,435.589c.871-5.377,3.569-5.286,4.115-5.377a1.693,1.693,0,0,1,1.141.39,6.881,6.881,0,0,0,4.175,1.712,6.029,6.029,0,0,0,3.458-1.184,10.876,10.876,0,0,0-.4,2.933,10.676,10.676,0,0,0,1.811,5.989,2.667,2.667,0,0,0-.6.031c-.023,0-.1.012-.151.018a6.43,6.43,0,0,0-3.749,1.605,7.578,7.578,0,0,0-.963,1.034c-1.059,0-3.355-.019-5.355-.152C-1.489,442.408-3.141,440.966-2.27,435.589Z"
              transform="translate(0 -173.154)"
            />
          </g>
          <path
            class="check"
            d="M86.9,95.7a6,6,0,1,0,6,6A6,6,0,0,0,86.9,95.7Zm.321,8.226-1.184,1.184a.183.183,0,0,1-.241,0l-1.184-1.184-1.745-1.745a.183.183,0,0,1,0-.241l1.184-1.184a.183.183,0,0,1,.241,0l1.645,1.645,3.932-3.932a.183.183,0,0,1,.241,0l1.184,1.184a.183.183,0,0,1,0,.241Z"
            transform="translate(40.088 48.216)"
          />
        </g>
      </svg>
    );
  }
};
