import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'ShareIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20.584 18.164"
      >
        <title>{{ text }}</title>
        <path
          d="M95.185,10.793l-8-5.636a.7.7,0,0,0-1.108.574V7.513a10.9,10.9,0,0,0-8.121,4.24,14.011,14.011,0,0,0-2.833,10.9.7.7,0,0,0,.685.543l.03,0a.7.7,0,0,0,.666-.6,9.168,9.168,0,0,1,4.47-6.823,6.4,6.4,0,0,1,5.155-.6v2.039a.7.7,0,0,0,1.119.566l7.95-5.839a.7.7,0,0,0-.011-1.14Z"
          transform="translate(-74.899 -5.029)"
        />
      </svg>
    );
  }
};
