<template>
  <div class="audio-control" :class="$attrs.class">
    <div class="audio-control__icon">
      <AudioMediaIcon size="xxxlarge" />
      <PauseIcon v-if="playing && !disabled" size="small" />
      <VideoMediaIcon v-else size="small" />
    </div>

    <div class="audio-control__progress">
      <div class="audio-control__progress--bar">
        <NewAudioPlayer
          ref="player"
          v-if="hasSources"
          :sources="sources"
          :disabled="disabled"
          @clearAudio="clearAudio"
          @playing="audioPlaying"
          @error="audioError"
        />
      </div>
    </div>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </div>
</template>

<script>
import AudioMediaIcon from '../icons/AudioMedia';
import PauseIcon from '../icons/Pause';
import VideoMediaIcon from '../icons/VideoMedia';
import NewAudioPlayer from '../mediaPlayer/NewAudioPlayer';
import ToastAlert from '../toast/ToastAlert';
import mediaProperties from '../mixins/mediaProperties';

export default {
  inheritAttrs: false,
  name: 'AudioControl',
  mixins: [mediaProperties],
  components: {
    NewAudioPlayer,
    AudioMediaIcon,
    PauseIcon,
    VideoMediaIcon,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      playing: false,
      error: ''
    };
  },
  computed: {
    hasSources() {
      return !!this.sources[0];
    },
    sources() {
      const { fileInfos } = this.item;
      const { id, url, contentType } = fileInfos || {};
      if (!url) return [];

      return [
        {
          id,
          src: url,
          type: contentType
        }
      ];
    }
  },
  methods: {
    togglePlay() {
      if (this.disabled) return;

      const { player } = this.$refs;
      player.togglePlay();
    },
    clearAudio() {
      this.playing = false;
    },
    audioPlaying(data) {
      this.playing = !!data.playing;
      this.$emit('audio-action', data);
    },
    audioError() {
      this.error = this.$t('mediaForm.audio.cannotPlay');
    },
    stop() {
      if (this.playing) {
        this.togglePlay();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$tile-header-height: 40px;
$tile-footer-height: 40px;
$icon-dimension: 80px;

.audio-control {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &__icon {
    position: relative;
    width: $icon-dimension;
    height: $icon-dimension;

    > .icon-audio-media {
      position: absolute;
      fill: #ffffff;
    }

    > .icon-pause-icon,
    > .icon-video-media {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      width: 19px;
      min-width: 19px;
      height: 19px;
      min-height: 19px;
      fill: $media-audio;
    }
  }

  &__progress {
    @include flexy($just: center);
    max-width: 100%;
    height: 31px;
    margin-top: 14px;

    &--bar {
      width: 128px;
      height: 31px;

      &--mqw-170 {
        width: 70px;
      }

      &--mqw-375 {
        width: 128px;
      }

      &--mqw-768 {
        width: 150px;
      }

      &--mqw-1024 {
        width: 181px;
      }

      &--mqw-1200 {
        width: 210px;
      }

      &--mqw-1600 {
        width: 241px;
      }

      &--mqw-2000 {
        width: 270px;
      }
    }
  }
}
</style>
