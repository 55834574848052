<template>
  <div class="navigation-item" :class="$attrs.class" @click="$emit('selected')">
    <SliderContent
      v-if="hasContent"
      :displayExpand="false"
      :modelValue="active"
      :active="active"
    >
      <template #label>
        <div class="navigation-item__inner">
          <div class="navigation-item__inner__left">
            <div class="navigation-item__inner__left--icon">
              <slot name="icon"></slot>
            </div>
            <div class="navigation-item__inner__left--label">
              <slot name="label-text">{{ label }}</slot>
            </div>
          </div>
          <div class="navigation-item__inner__right">
            <slot name="right"></slot>
          </div>
        </div>
      </template>
      <slot></slot>
    </SliderContent>
    <SliderFieldset v-else :displayExpand="false" :active="active">
      <div class="navigation-item__inner">
        <div class="navigation-item__inner__left">
          <div class="navigation-item__inner__left--icon">
            <slot name="icon"></slot>
          </div>
          <div class="navigation-item__inner__left--label">
            <slot name="label-text">{{ label }}</slot>
          </div>
        </div>
        <div class="navigation-item__inner__right">
          <slot name="right"></slot>
        </div>
      </div>
    </SliderFieldset>
  </div>
</template>

<script>
import SliderFieldset from '../forms/SliderFieldset';
import SliderContent from '../forms/SliderContent';

export default {
  inheritAttrs: false,
  name: 'NavigationItem',
  components: {
    SliderFieldset,
    SliderContent
  },
  props: {
    label: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    hasContent: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
$height: 74px;

.navigation-item {
  width: 100%;

  &__inner {
    @include grid($cols: 1fr auto, $areas: 'left right');
    width: 100%;

    &__left {
      @include grid-item($area: left);
      @include flexy($align: center);
      width: 100%;
      height: $height;

      > * {
        margin-left: $spacing-half;
      }
    }

    &__right {
      @include grid-item($area: right, $align: center);
      margin-right: $spacing-half;
    }
  }
}
</style>
