import {
  create as genericCreate,
  del as genericDel,
  multiPartUploadFile as genericUploadFile,
  MULTI_UPLOAD_FILE_CHUNK_SIZE
} from '../generic';
import { HttpError, get as httpGet, post as httpPost } from '../requests';

export const ENDPOINT_URL = '/backgrounds';

export const create = genericCreate(ENDPOINT_URL);

export const del = genericDel(ENDPOINT_URL);

export const get = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}`);
  return response.json();
};

export const getAll = async (options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/getAll`, options);
  return response.json();
};

export async function uploadFile(options) {
  if (!options) throw new Error('The "options" parameter cannot be null!');

  const { id, file } = options;
  if (!id) throw new Error('The "id" parameter cannot be null!');
  if (!file) throw new Error('The "file" parameter cannot be null!');

  // Get the upload URL from the backend
  const fileSize = file.size;
  const chunks = Math.floor(fileSize / MULTI_UPLOAD_FILE_CHUNK_SIZE) + 1;

  const response = await httpPost(`${ENDPOINT_URL}/start-upload/${id}`, {
    fields: {
      filePath: file.name,
      contentType: file.type,
      chunks
    }
  });

  if (!response.ok) {
    throw new HttpError(response.status, await response.text(), response);
  }

  return genericUploadFile({
    ...options,
    endpoint: ENDPOINT_URL,
    multipartInfo: response.json()
  });
}
