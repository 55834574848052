<template>
  <section class="share-asset-list" :class="$attrs.class">
    <div class="share-asset-list__header">
      <BreadcrumbsBar
        class="share-asset-list__header--navigation"
        :type="SHARE_ASSET_TYPE"
        :canNavigate="false"
      >
        <template #description>
          <span class="share-asset-list__header--title">{{
            $t('shareAsset.title')
          }}</span>
          <span v-if="currentAsset">:</span>
          <ItemIcon
            v-if="currentAsset"
            class="share-asset-list__header--title--item"
            :type="currentAsset.type"
            :item="currentAsset"
            :withLink="false"
            smallIcon
          />
        </template>
      </BreadcrumbsBar>
      <button
        class="icon-button share-asset-list__header--close"
        @click="close"
      >
        <CrossIcon size="small" />
      </button>
    </div>

    <div class="share-asset-list__search-field">
      <SearchField
        ref="searchInput"
        v-model="text"
        :setFocus="setSearchFocus"
        @update:modelValue="setSearchText($event)"
        @clear="clearText"
        @search="startSearch"
      />
    </div>

    <ListPageable
      class="share-asset-list__list"
      :items="items"
      :load="getShares"
      :loading="loading"
      :hasNextPage="hasNextPage"
      :identifier="identifier"
      :isEmpty="empty"
      displayType="list"
    >
      <template #content-item="{ item }">
        <ShareAssetNamedItem
          v-if="item.type === 'named'"
          :item="item"
          :displayAsset="!currentAsset"
          @selected="toggle"
          @customizeShare="toggle"
        />
        <ShareAssetItem
          v-else
          :item="item"
          :displayAsset="!currentAsset"
          @selected="toggle"
          @customizeShare="toggle"
        />
      </template>
      <template #content-empty v-if="!searchInProgress">
        <div v-if="currentAsset">
          <div v-if="hasSearchCriterias">
            {{ $t('shareAsset.emptyText2') }}
          </div>
          <div v-else>
            {{ $t('share-asset-list.emptyText') }}
          </div>
        </div>
        <div v-else>
          <div v-if="hasSearchCriterias">
            {{ $t('shareAsset.emptyText2') }}
          </div>
          <div v-else>{{ $t('shareAsset.emptyText1') }}</div>
        </div>
      </template>
    </ListPageable>

    <AppFooter>
      <template #left>
        <button
          class="icon-button menu-button delete-filter-menu-button"
          :class="{ 'menu-button--active': displayFilter }"
          @click="displayFilter = !displayFilter"
        >
          <FilterIcon :type="displayFilter ? 'active' : 'dark'" />
        </button>

        <button
          v-if="filter && !displayFilter && !isSearchSelectionMode"
          class="icon-button icon-button--active filter-active-button"
          @click="clearFilters()"
        >
          <span class="icon-button-label">{{
            $t('shareAsset.clearFilters')
          }}</span>
          <CloseIcon type="active" />
        </button>
      </template>
      <template #right>
        <div v-if="displayFilter" class="apply-filter-menu-button">
          <CallToAction size="auto" @v-click="applyFilter">
            {{ $t('shareAsset.apply') }}
          </CallToAction>
        </div>
        <button
          v-else-if="currentAsset"
          class="icon-button menu-button add-menu-button"
          :class="{ 'menu-button--active': activeMenu === 'creation' }"
          @click="add"
        >
          <CreationIcon :type="activeMenu === 'creation' ? 'active' : 'dark'" />
        </button>
      </template>
    </AppFooter>

    <ShareAssetFormDialogue
      v-if="activeDialogue === 'create-named'"
      :asset="currentAsset"
      isPaidNamedWebLink
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
      @customizeShare="toggle"
    />
    <ShareAssetFormDialogue
      v-else-if="activeDialogue === 'create'"
      :asset="currentAsset"
      @success="toggleDialogue()"
      @cancel="toggleDialogue()"
      @customizeShare="toggle"
    />

    <!-- Filter menu -->
    <transition name="slide">
      <SlideMenu
        v-if="displayFilter"
        slide="up"
        anchor="left"
        :fullWidth="true"
      >
        <ShareAssetFilterMenu ref="filterMenu" @applied="fiterApplied" />
      </SlideMenu>
    </transition>

    <!-- Creation menu -->
    <transition name="slide">
      <SlideMenu
        v-if="activeMenu === 'creation'"
        slide="up"
        anchor="right"
        fullWidth
      >
        <MenuItem
          :label="$t('share-asset-list.create')"
          @selected="toggleDialogue('create')"
        />
        <MenuItem
          :label="$t('share-asset-list.create-named')"
          @selected="toggleDialogue('create-named')"
        />
      </SlideMenu>
    </transition>
  </section>
</template>

<script>
import BreadcrumbsBar from '@/components/navigation/BreadcrumbsBar';
import ItemIcon from '@/components/layout/ItemIcon';
import SearchField from '@/components/forms/SearchField';
import ListPageable from '@/components/layout/ListPageable';
import AppFooter from '@/components/AppFooter';
import SlideMenu from '@/components/menus/SlideMenu';
import MenuItem from '@/components/menus/MenuItem';
import ShareAssetItem from '@/components/shareAsset/ShareAssetItem';
import ShareAssetFilterMenu from '@/components/shareAsset/ShareAssetFilterMenu';
import ShareAssetNamedItem from '@/components/shareAsset/ShareAssetNamedItem';
import ShareAssetFormDialogue from '@/components/shareAsset/ShareAssetFormDialogue';
import CallToAction from '@/components/forms/CallToAction';
import CrossIcon from '@/components/icons/Cross';
import CreationIcon from '@/components/icons/Creation';
import FilterIcon from '@/components/icons/FilterIcon';
import CloseIcon from '@/components/icons/Close';
import appFeatures from '@/utils/features';

import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch
} from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useShareAssetSearchStore } from '@/stores/share-asset-search';
import { useToggleState } from '@/composables/toggle-state';
import { SHARE_ASSET_TYPE } from '@/utils/types';

export default {
  inheritAttrs: false,
  name: 'ShareAssetListView',
  components: {
    BreadcrumbsBar,
    ItemIcon,
    SearchField,
    ListPageable,
    AppFooter,
    SlideMenu,
    MenuItem,
    ShareAssetItem,
    ShareAssetFilterMenu,
    ShareAssetNamedItem,
    ShareAssetFormDialogue,
    CallToAction,
    CrossIcon,
    FilterIcon,
    CloseIcon,
    CreationIcon
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { setActiveLeftSideBar, displayError } = useAppStore();
    const { currentUser, isAdministrator, features } =
      storeToRefs(useAuthStore());
    const { reset, setCurrentAsset, start, setFilter, setSearchText } =
      useShareAssetSearchStore();
    const {
      currentAsset,
      filter,
      items,
      hasNextPage,
      searchText,
      searchInProgress,
      hasSearchCriterias
    } = storeToRefs(useShareAssetSearchStore());
    const { activeMenu, toggleMenu } = useToggleState('menu');
    const { activeDialogue, toggleDialogue } = useToggleState('dialogue');

    const $refs = {
      searchInput: ref(null),
      filterMenu: ref(null)
    };

    const state = reactive({
      currentUser,
      isAdministrator,
      paidNamedWebLink: !!features.value?.paidNamedWebLink,

      currentAsset,
      hasNextPage,
      items,

      loading: 0,

      text: '',
      displayFilter: false,
      setSearchFocus: !(props.item?.id && appFeatures.isMobile.any())
    });

    const init = async () => {
      if (!props.item?.id) {
        start();
        state.text = searchText.value;
        setFocus();
        return;
      }

      state.loading++;
      try {
        await setCurrentAsset(props.item);
        state.text = searchText.value;
        setFocus();
      } catch (error) {
        displayError(error);
      } finally {
        state.loading--;
      }
    };

    const close = () => {
      setActiveLeftSideBar();
    };

    const toggle = () => {
      emit('toggle');
    };

    const setFocus = () => {
      if (!state.setSearchFocus) return;

      const { searchInput } = $refs;
      searchInput.value?.focus();
    };

    // HOOKS
    onBeforeMount(init);
    onMounted(setFocus);
    onBeforeUnmount(() => {
      reset();
    });
    watch(
      () => props.item?.id,
      () => init()
    );
    watch(
      () => state.currentAsset?.id,
      () => {
        if (!state.currentAsset) close();
      }
    );

    return {
      SHARE_ASSET_TYPE,

      // REFS
      ...$refs,

      // COMPOSABLES
      activeMenu,
      toggleMenu,
      activeDialogue,
      toggleDialogue,

      // DATA
      ...toRefs(state),

      filter,
      searchText,
      searchInProgress,
      hasSearchCriterias,

      // COMPUTED
      identifier: computed(() => {
        // return `shareAssets${sortLabel.value}`;
        return 'shareAssets';
      }),
      empty: computed(() => {
        if (state.loading !== 0) return false;
        return state.items.length === 0;
      }),

      // METHODS
      toggle,
      add() {
        if (state.paidNamedWebLink) toggleMenu('creation');
        else toggleDialogue('create');
      },

      close,

      setSearchText,
      startSearch() {
        setFocus();
        start();
      },
      clearFilters() {
        setFocus();
        setFilter(null);
      },
      applyFilter() {
        const { filterMenu } = $refs;
        if (!filterMenu.value) return;
        filterMenu.value.apply();
      },
      fiterApplied() {
        setFocus();
        state.displayFilter = false;
      },
      clearText() {
        state.text = '';
        setSearchText('');
        setFocus();
        start();
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.share-asset-list {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;

  &__header {
    @include grid($gap: 0, $cols: 1fr auto, $areas: 'navigation close');

    &--navigation {
      @include grid-item(navigation);
    }

    &--title {
      text-transform: uppercase;

      &--item {
        padding-left: $spacing-half;
      }
    }

    &--close {
      @include grid-item(close);
      @include flexy($align: center);
      margin-right: $spacing-base;
    }
  }

  &__search-field {
    @include flexy($align: center);
    margin: $spacing-quarter $spacing-half;
  }

  &__list {
    margin: 0 $spacing-half;
  }

  .filter-active-button {
    color: $font-light;
  }
}

.menu-button--active {
  @include svg-active-background($background-triangle-down-svg);
}

.apply-filter-menu-button {
  @include flexy($align: center);

  > .call-to-action {
    background-color: $background-button;
    font-weight: bold;
  }
}
</style>
