import { get as httpGet, post as httpPost } from '../requests';
import {
  create as genericCreate,
  update as genericUpdate,
  del as genericDel
} from '../generic';

export const ENDPOINT_URL = '/evaluations';

export const create = genericCreate(ENDPOINT_URL);

export const update = genericUpdate(ENDPOINT_URL);

export const del = genericDel(ENDPOINT_URL);

export const endEvaluation = async (id) => {
  const response = await httpPost(`${ENDPOINT_URL}/end-evaluation/${id}`);
  return response.json();
};

export const get = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}`);
  return response.json();
};

export const getMyEvaluations = async (options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/getMyEvaluations`, options);
  return response.json();
};

export const getCards = async (id, options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/${id}/getCards`, options);
  return response.json();
};

export const getCriterias = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}/criterias`);
  return response.json();
};

export const getStatements = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}/statements`);
  return response.json();
};

export const updateCards = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/update-cards`,
    options
  );
  return response.json();
};

export const updateCriterias = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/update-criterias`,
    options
  );
  return response.json();
};

export const updateStatements = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/update-statements`,
    options
  );
  return response.json();
};

export const send = async (id) => {
  const response = await httpPost(`${ENDPOINT_URL}/send/${id}`);
  return response.json();
};

export const getInviteInfo = async (token) => {
  const response = await httpGet(`${ENDPOINT_URL}/invite-info/${token}`);
  return response.json();
};
