import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'NoteMedia';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={[...classes, 'icon-media']}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 46.1 53"
      >
        <title>{{ text }}</title>
        <path d="M25.1,19.2v-5.2c0.9-0.4,1.7-0.9,2.4-1.6c2.8-2.8,2.8-7.3,0-10.1c-2.8-2.8-7.3-2.8-10.1,0c-2.8,2.8-2.8,7.3,0,10.1c0.9,0.9,1.9,1.4,3,1.7v5c0,1.3,1.1,2.4,2.4,2.4C24.1,21.6,25.1,20.5,25.1,19.2z" />
        <path d="M41.4,5.2h-9.8c0.2,0.7,0.3,1.5,0.3,2.2c0,0.6-0.1,1.3-0.2,1.9h8.7c0.9,0,1.4,0.5,1.4,1.4v23.8l0,0h-8.4c-3.1,0-5.5,2.5-5.5,5.5v8.4l-0.1,0.1H5.8c-0.8,0-1.4-0.6-1.4-1.4V10.6c0-0.7,0.6-1.3,1.3-1.3h7.6c-0.1-0.6-0.2-1.2-0.2-1.9c0-0.8,0.1-1.5,0.3-2.2H4.7c-2.4,0-4.4,2-4.4,4.4v38.5c0,2.5,2,4.5,4.5,4.5h23.8c0.5,0,1.1-0.2,1.4-0.6l15.3-15.3c0.4-0.4,0.6-0.9,0.6-1.4V9.7C45.9,7.2,43.9,5.2,41.4,5.2z" />
      </svg>
    );
  }
};
