<template>
  <div
    class="app-left-sidebar"
    :class="[{ 'app-left-sidebar__hidden': isHidden }, $attrs.class]"
  >
    <NavigationView
      v-if="activeLeftSideBar.type === NAVIGATION_TYPE"
      @toggle="toggleHidden"
    />
    <SearchView
      v-else-if="activeLeftSideBar.type === SEARCH_TYPE"
      :mode="activeLeftSideBar.mode"
      @toggle="toggleHidden"
    />
    <NotificationView
      v-else-if="activeLeftSideBar.type === NOTIFICATION_TYPE"
      @toggle="toggleHidden"
    />
    <SubscriptionView
      v-else-if="activeLeftSideBar.type === SUBSCRIPTION_TYPE"
    />
    <AuditLogin v-else-if="activeLeftSideBar.type === AUDIT_LOGIN_TYPE" />
    <ChatView v-else-if="activeLeftSideBar.type === CHAT_TYPE" />
    <ShareAssetListView
      v-else-if="activeLeftSideBar.type === SHARE_ASSET_TYPE"
      :item="activeLeftSideBar.item"
      @toggle="toggleHidden"
    />

    <div
      v-if="activeLeftSideBar.type"
      class="app-left-sidebar__back"
      :class="{ 'app-left-sidebar__back--hidden': isHidden }"
    >
      <div class="app-left-sidebar__back--icon" @click="toggleHidden">
        <Chevron size="small" direction="right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import NavigationView from '../components/navigation/NavigationView';
import SearchView from '../components/search/SearchView';
import NotificationView from '../components/notification/NotificationView';
import SubscriptionView from '../components/subscription/SubscriptionView';
import AuditLogin from '../components/auditLogin/AuditLogin';
import ChatView from '../components/chat/ChatView';
import ShareAssetListView from '../components/shareAsset/ShareAssetListView';
import Chevron from '../components/icons/Chevron';
import {
  NAVIGATION_TYPE,
  SEARCH_TYPE,
  NOTIFICATION_TYPE,
  SUBSCRIPTION_TYPE,
  AUDIT_LOGIN_TYPE,
  CHAT_TYPE,
  SHARE_ASSET_TYPE
} from '../utils/types';

export default {
  inheritAttrs: false,
  name: 'AppLeftSideBar',
  components: {
    NavigationView,
    SearchView,
    NotificationView,
    SubscriptionView,
    AuditLogin,
    ChatView,
    ShareAssetListView,
    Chevron
  },
  data() {
    return {
      hidden: false
    };
  },
  computed: {
    ...mapState(useAppStore, ['activeLeftSideBar']),
    isHidden() {
      return (
        this.hidden &&
        (this.activeLeftSideBar.type === NAVIGATION_TYPE ||
          this.activeLeftSideBar.type === SEARCH_TYPE ||
          this.activeLeftSideBar.type === NOTIFICATION_TYPE ||
          this.activeLeftSideBar.type === SHARE_ASSET_TYPE)
      );
    },
    NAVIGATION_TYPE() {
      return NAVIGATION_TYPE;
    },
    SEARCH_TYPE() {
      return SEARCH_TYPE;
    },
    NOTIFICATION_TYPE() {
      return NOTIFICATION_TYPE;
    },
    SUBSCRIPTION_TYPE() {
      return SUBSCRIPTION_TYPE;
    },
    AUDIT_LOGIN_TYPE() {
      return AUDIT_LOGIN_TYPE;
    },
    CHAT_TYPE() {
      return CHAT_TYPE;
    },
    SHARE_ASSET_TYPE() {
      return SHARE_ASSET_TYPE;
    }
  },
  methods: {
    toggleHidden() {
      this.hidden = !this.hidden;
    }
  },
  watch: {
    activeLeftSideBar() {
      if (this.activeLeftSideBar.type && this.isHidden) this.toggleHidden();
      else if (!this.activeLeftSideBar.type) this.hidden = false;
    }
  }
};
</script>

<style lang="scss">
.app-left-sidebar {
  @include flexy();
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: $background-dark-2;

  .app-footer {
    background: $background-light-2;
  }

  @media screen and (max-width: #{$medium-size-break - 1}) {
    min-width: 100vw;
    margin-left: 0;
    transition: margin-left 0.5s ease-in;

    &__hidden {
      margin-left: -100vw;
      transition: margin-left 0.5s ease-out;
    }
  }

  @media screen and (min-width: #{$medium-size-break}) {
    width: 350px;
    min-width: 350px;
  }

  @media screen and (min-width: #{$medium-size-break + 50}) {
    width: 400px;
    min-width: 400px;
  }

  &__back {
    @include flexy($align: center);
    height: 100%;
    position: fixed;
    left: 100vw;
    z-index: 1;
    transition: left 0.5s ease-in;
    display: none;

    @media screen and (max-width: #{$medium-size-break - 1}) {
      display: inherit;
    }

    &--hidden {
      left: 0;
      transition: left 0.5s ease-out;
    }

    &--icon {
      @include flexy($align: center);
      height: 100px;
      cursor: pointer;
    }
  }
}
</style>
