export const CARD_DISPLAY_GRID = 'grid';
export const CARD_DISPLAY_ORGANIC = 'organic';

export const isItemProtected = (item) => {
  return !!(item?.inheritProtected || item?.protected);
};

export const isItemAuthorBoard = (item, accountId) => {
  return accountId && item?.board?.authorId === accountId;
};
