<template>
  <div class="loading-dialogue" :class="$attrs.class">
    <div class="loading-dialogue__content">
      <Loading class="loading-dialogue__content--image" />
      <div v-if="loadingMessage" class="loading-dialogue__content--message">
        {{ loadingMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../icons/Loading';

export default {
  inheritAttrs: false,
  name: 'LoadingDialogue',
  components: { Loading },
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  computed: {
    loadingMessage() {
      return this.message || this.$t('loading.defaultMessage');
    }
  }
};
</script>

<style lang="scss">
$base-index: 100;

.loading-dialogue {
  background-color: $overlay-base;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $base-index;
}

.loading-dialogue__content {
  @include flexy($dir: column, $just: center, $align: center);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;

  &--image {
    width: $loading-size-base;
    height: $loading-size-base;
  }

  &--message {
    color: $font-light;
  }
}
</style>
