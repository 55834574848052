<template>
  <label
    class="base-radio"
    :for="id"
    :class="[
      { 'base-radio__disabled': disabled },
      `base-radio--${color}`,
      $attrs.class
    ]"
  >
    <input
      class="base-radio__input"
      type="radio"
      :disabled="disabled"
      :name="name"
      :id="id"
      :value="value"
      :checked="modelValue === value"
      @change="$emit('update:modelValue', value)"
      @click="clicked"
    />
    <span
      class="base-radio__text"
      :class="{ 'base-radio__text--disabled': disabled }"
    >
      <span class="base-radio__text__check"
        ><span class="base-radio__text__check--checked"></span
      ></span>
      <span v-if="label" class="base-radio__text--label">{{ label }}</span>
    </span>
  </label>
</template>

<script>
const COLORS = ['light', 'dark'];

export default {
  name: 'BaseRadio',
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    color: {
      type: String,
      default: 'dark',
      validator: (value) => COLORS.includes(value)
    },
    canUnSelect: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked() {
      if (
        !this.disabled &&
        this.canUnSelect &&
        this.modelValue === this.value
      ) {
        this.$emit('update:modelValue', null);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-radio {
  display: inline-block;

  $inner-size: 10px;

  &__text {
    @include flexy($disp: inline-flex, $align: center);
    cursor: pointer;

    &__check {
      @include flexy($align: center, $just: center);
      border: $border-width solid $border-checkbox;
      background-color: $background-light;
      border-radius: 50% 50%;
      height: rem($spacing-base);
      width: rem($spacing-base);

      &--checked {
        height: rem($inner-size);
        width: rem($inner-size);
      }
    }

    &--label {
      margin-left: rem($spacing-half);
    }

    &--disabled {
      cursor: default;
    }
  }

  &__input {
    flex: none;
    height: 0;
    opacity: 0;
    width: 0;
    display: none;

    &:checked
      + .base-radio__text
      > .base-radio__text__check
      > .base-radio__text__check--checked {
      background-color: $background-selected-checkbox;
      border: 1px solid $background-selected-checkbox;
      border-radius: 50% 50%;
    }
  }

  &--light {
    .base-radio__text__check {
      border: $border-width solid $background-dark;
    }

    .base-radio__input:checked
      + .base-radio__text
      > .base-radio__text__check
      > .base-radio__text__check--checked {
      background-color: $background-dark;
      border: 1px solid $background-dark;
    }
  }

  &__disabled {
    opacity: $disable-opacity;
  }
}
</style>
