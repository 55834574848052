<template>
  <div ref="content-video" class="content-video" :class="$attrs.class">
    <Loading v-if="processing" class="content-video__processing" />
    <div v-else class="content-video__main">
      <div class="content-video__top" data-clickable="true">
        <div class="content-video__top__video" @touchstart.stop="">
          <VideoControl
            ref="video"
            :item="media"
            :autoPlay="autoPlay"
            @video-action="videoEvent"
          />
        </div>
      </div>
      <div class="content-video--name">
        <div>{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMediaStore } from '@/stores/media';
import VideoControl from '../controls/VideoControl';
import Loading from '../icons/Loading';
import assetMixin from '../mixins/asset';
import mediaProperties from '../mixins/mediaProperties';
import contentMediaMixin from './ContentMediaMixin';
import fullscreenMixin from '../mixins/fullscreen';

export default {
  inheritAttrs: false,
  name: 'ContentVideo',
  mixins: [assetMixin, mediaProperties, contentMediaMixin, fullscreenMixin],
  components: {
    VideoControl,
    Loading
  },
  props: {
    autoPlay: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(useMediaStore, ['track']),
    resizeVideo() {
      const { video } = this.$refs;
      video?.resize();
    },
    start() {
      const { video } = this.$refs;
      video?.start();
    },
    stop() {
      const { video } = this.$refs;
      video?.stop();
    },
    fullscreenChange() {
      this.$emit('contentMaximized', this.isFullscreen());
    },
    videoEvent(data) {
      this.track({
        event: `video_${data.event}`,
        item: this.item,
        isShared: this.isShared,
        currentTime: data.currentTime,
        duration: data.duration
      });
    },
    resized() {
      this.resizeVideo();
    }
  },
  mounted() {
    this.handleFullscreenChange(this.fullscreenChange);
    this.$nextTick(() => {
      this.resizeVideo();
    });
  },
  beforeUnmount() {
    this.clearFullscreenChange(this.fullscreenChange);
  }
};
</script>

<style lang="scss" scoped>
.content-video {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;

  &__processing {
    width: $loading-size-base;
    height: $loading-size-base;
  }

  &__main {
    @include flexy($dir: column, $align: center, $just: center);
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }

  &__top {
    @include flexy($dir: column, $align: center, $just: center);
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;

    &__video {
      @include flexy($dir: column, $align: center, $just: center);
      height: 100%;
      width: 100%;
      flex: 1;
      overflow: hidden;
    }
  }

  &--name {
    @include flexy($dir: column, $align: center, $just: center);
    @include slider-tile-media-name();
  }
}
</style>
