<template>
  <div class="color-picker">
    <div @click="toggleShowColorPicker">
      <slot></slot>
    </div>

    <div
      v-if="showColorPicker"
      ref="dialogueContainer"
      class="color-picker-dialogue--container"
    >
      <section class="color-picker-dialogue" @mousedown.stop @touchstart.stop>
        <header class="color-picker-dialogue__header">
          <div class="color-picker-dialogue__header--left">{{ title }}</div>
          <div class="color-picker-dialogue__header--right">
            <div
              class="color-picker-dialogue__header--icon"
              @click="toggleShowColorPicker"
            >
              <CrossIcon />
            </div>
          </div>
        </header>
        <main class="color-picker-dialogue__main">
          <ChromeColorPicker v-model="colors" />
        </main>

        <footer class="color-picker-dialogue__footer">
          <div class="color-picker-dialogue__footer--left">
            <div
              :class="['color-picker-dialogue__footer--icon', 'left']"
              @click="resetColor"
            >
              <RefreshIcon />
            </div>
            <div
              :class="['color-picker-dialogue__footer--icon', 'left']"
              @click="restoreColor"
            >
              <RestoreIcon />
            </div>
          </div>
          <div class="color-picker-dialogue__footer--right">
            <div
              class="color-picker-dialogue__footer--icon"
              @click="applyColor"
            >
              <CheckIcon />
            </div>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
import CrossIcon from '../icons/Cross';
import RefreshIcon from '../icons/Refresh';
import RestoreIcon from '../icons/Restore';
import CheckIcon from '../icons/Check';
import { Chrome } from '@ckpack/vue-color';
import tinycolor from 'tinycolor2';

export default {
  inheritAttrs: false,
  name: 'ColorPicker',
  components: {
    CrossIcon,
    RefreshIcon,
    RestoreIcon,
    CheckIcon,
    ChromeColorPicker: Chrome
  },
  props: {
    title: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    defaultColor: {
      type: String,
      default: '#000000'
    }
  },
  data() {
    return {
      colors: this.modelValue,
      showColorPicker: false
    };
  },
  methods: {
    toggleShowColorPicker() {
      if (this.showColorPicker) this.removeHandlerOnDialogue();
      else this.resetColor();

      this.showColorPicker = !this.showColorPicker;
      this.$nextTick(() => {
        if (this.showColorPicker) this.addHandlerOnDialogue();
      });
    },
    applyColor() {
      this.$emit('update:modelValue', this.colors.hex8 || this.colors);
      this.toggleShowColorPicker();
    },
    resetColor() {
      const color = tinycolor(this.modelValue).isValid()
        ? this.modelValue
        : tinycolor(this.defaultColor).isValid()
          ? this.defaultColor
          : '#000000';
      this.colors = tinycolor(color).toHex8String();
    },
    restoreColor() {
      const color = tinycolor(this.defaultColor).isValid()
        ? this.defaultColor
        : '#000000';
      this.colors = tinycolor(color).toHex8String();
    },
    mousedown() {
      this.toggleShowColorPicker();
    },
    addHandlerOnDialogue() {
      const { dialogueContainer } = this.$refs;
      dialogueContainer?.addEventListener('mousedown', this.mousedown);
    },
    removeHandlerOnDialogue() {
      const { dialogueContainer } = this.$refs;
      dialogueContainer?.removeEventListener('mousedown', this.mousedown);
    }
  },
  beforeUnmount() {
    this.removeHandlerOnDialogue();
  }
};
</script>

<style lang="scss">
.color-picker {
  cursor: pointer;
}

.color-picker-dialogue {
  background-color: $background-light;
  border-radius: $base-border-radius;

  &__main {
    padding: $spacing-half;
    border: solid 1px $background-light;
  }

  &--container {
    @include flexy($align: center, $just: center);
    background-color: $overlay-base;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    border: solid 3px black;
  }

  &__header,
  &__footer {
    background-color: $primay-color;
    border: solid 1px $primay-color;
    padding: $spacing-half;
    color: $font-light;
  }

  &__header {
    @include grid($cols: 1fr 1fr, $areas: 'left right');
    border-radius: $base-border-radius $base-border-radius 0 0;

    &--left {
      @include grid-item('left', $align: center, $just: flex-start);
    }

    &--right {
      @include flexy();
      @include grid-item('right', $align: center, $just: flex-end);
    }

    &--icon {
      display: flex;
      margin-left: $spacing-half;
      cursor: pointer;

      > * {
        height: 15px;
        min-height: 15px;
        width: unset;
        min-width: unset;
        fill: $font-light;
      }
    }
  }

  &__footer {
    @include grid($cols: 1fr 1fr, $areas: 'left right');
    border-radius: 0 0 $base-border-radius $base-border-radius;

    &--left {
      @include flexy();
      @include grid-item('left', $align: center, $just: flex-start);

      > * {
        margin-right: $spacing-half;
      }
    }

    &--right {
      @include flexy();
      @include grid-item('right', $align: center, $just: flex-end);

      > * {
        margin-left: $spacing-half;
      }
    }

    &--icon {
      display: flex;
      cursor: pointer;

      > * {
        height: 15px;
        min-height: 15px;
        width: unset;
        min-width: unset;
        fill: $font-light;
      }
    }

    &--icon.left {
      margin-right: $spacing-base;
    }
  }
}
</style>
