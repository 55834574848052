import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'TrashIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16.451 18.899"
      >
        <title>{{ text }}</title>
        <path
          d="M128.734,2.384h-1.576V2.078a1.942,1.942,0,0,0-1.94-1.94h-4.007a1.942,1.942,0,0,0-1.94,1.94v.306h-1.625a2.683,2.683,0,0,0-2.681,2.68v1.18a.5.5,0,0,0,.5.5h.6l.962,10.439a1.832,1.832,0,0,0,1.91,1.854h8.55a1.838,1.838,0,0,0,1.912-1.868l.961-10.425h.554a.5.5,0,0,0,.5-.5V5.064A2.684,2.684,0,0,0,128.734,2.384Zm-8.463-.306a.941.941,0,0,1,.94-.94h4.007a.941.941,0,0,1,.94.94v.306h-5.887Zm8.136,14.986c-.1.858-.522.973-.918.973h-8.55c-.4,0-.814-.115-.916-.96l-.952-10.333h12.286Zm2.009-11.32H115.965v-.68a1.683,1.683,0,0,1,1.681-1.68h11.088a1.683,1.683,0,0,1,1.682,1.68Zm-7.2,11.047a.5.5,0,0,1-.5-.5V8.438a.5.5,0,0,1,1,0v7.853A.5.5,0,0,1,123.214,16.791Zm-2.88,0a.5.5,0,0,1-.5-.479l-.341-7.845a.5.5,0,0,1,1-.043l.341,7.846a.5.5,0,0,1-.479.521Zm5.761,0h-.022a.5.5,0,0,1-.478-.521l.34-7.846a.494.494,0,0,1,.521-.478.5.5,0,0,1,.479.521l-.34,7.845A.5.5,0,0,1,126.1,16.792Z"
          transform="translate(-114.965 -0.138)"
        />
      </svg>
    );
  }
};
