<template>
  <BaseDialogue
    class="board-subscription-dialogue"
    :class="$attrs.class"
    :title="title"
    size="small"
    @ok="ok"
    @cancel="$emit('cancel')"
  >
    <div class="form-element">
      <div
        v-if="asset.sendAllAddNotifications"
        class="form-field board-subscription-dialogue__title"
        :class="{ warning: !form.disableAdminNotifications }"
      >
        {{ $t('boardSubscriptionForm.adminAllAddNotificationsActivated') }}
      </div>
      <div
        v-if="asset.sendAllAddNotifications"
        class="form-field board-subscription-dialogue__checkbox"
      >
        <BaseCheckbox
          name="disableAdminNotifications"
          :label="$t('boardSubscriptionForm.disableAdminAllAddNotifications')"
          v-model="form.disableAdminNotifications"
        />
      </div>

      <div class="form-field board-subscription-dialogue__title">
        {{ $t('boardSubscriptionForm.adding.title') }}
      </div>
      <div class="form-field board-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="addingAllItems"
          :label="$t('boardSubscriptionForm.adding.allItems')"
          @update:modelValue="addingAllItemsChanged"
          v-model="form.addingAllItems"
        />
      </div>
      <div class="form-field board-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="addingCard"
          :label="$t('boardSubscriptionForm.adding.card')"
          :disabled="form.addingAllItems"
          @update:modelValue="valueChanged"
          v-model="form.addingCard"
        />
      </div>
      <div class="form-field board-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="addingFolder"
          :label="$t('boardSubscriptionForm.adding.folder')"
          :disabled="form.addingAllItems"
          @update:modelValue="valueChanged"
          v-model="form.addingFolder"
        />
      </div>
      <div class="form-field board-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="addingComment"
          :label="$t('boardSubscriptionForm.adding.comment')"
          @update:modelValue="valueChanged"
          v-model="form.addingComment"
        />
      </div>

      <div class="form-field board-subscription-dialogue__title">
        {{ $t('boardSubscriptionForm.deleting.title') }}
      </div>
      <div class="form-field board-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="deletingCard"
          :label="$t('boardSubscriptionForm.deleting.card')"
          v-model="form.deletingCard"
          @update:modelValue="valueChanged"
        />
      </div>
      <div class="form-field board-subscription-dialogue__checkbox">
        <BaseCheckbox
          name="deletingFolder"
          :label="$t('boardSubscriptionForm.deleting.folder')"
          @update:modelValue="valueChanged"
          v-model="form.deletingFolder"
        />
      </div>

      <div class="form-field board-subscription-dialogue__options">
        {{ $t('boardSubscriptionForm.options') }}
      </div>
      <div class="board-subscription-dialogue__options--content">
        <BaseCheckbox
          class="form-field"
          name="receiveEmail"
          :label="$t('boardSubscriptionForm.receiveEmail')"
          :disabled="disabledReceiveEmail"
          v-model="form.receiveEmail"
        />
      </div>
    </div>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useBoardStore } from '@/stores/board';
import BaseDialogue from '../dialogues/BaseDialogue';

export default {
  inheritAttrs: false,
  name: 'BoardSubscriptionDialogue',
  components: {
    BaseDialogue
  },
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        disableAdminNotifications: false,
        addingAllItems: false,
        addingCard: false,
        deletingCard: false,
        addingFolder: false,
        deletingFolder: false,
        deletingSubBoard: false,
        addingComment: false,
        receiveEmail: false
      },
      option: null,
      submitted: false
    };
  },
  computed: {
    title() {
      return this.$t('boardSubscriptionForm.title');
    },
    disabledReceiveEmail() {
      return (
        !this.form.addingAllItems &&
        !this.form.addingCard &&
        !this.form.addingFolder &&
        !this.form.addingComment &&
        !this.form.deletingCard &&
        !this.form.deletingFolder
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useBoardStore, [
      'getSubscription',
      'addSubscription',
      'editSubscription'
    ]),
    async ok() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const data = {
            options: {
              disableAdminNotifications: this.form.disableAdminNotifications,
              addingAllItems: this.form.addingAllItems,
              addingCard: this.form.addingCard,
              deletingCard: this.form.deletingCard,
              addingFolder: this.form.addingFolder,
              deletingFolder: this.form.deletingFolder,
              deletingSubBoard: this.form.deletingSubBoard,
              addingComment: this.form.addingComment,
              receiveEmail: this.form.receiveEmail
            }
          };

          if (this.entity.id) {
            await this.editSubscription({
              parentId: this.asset.id,
              id: this.entity.id,
              data
            });
          } else {
            await this.addSubscription({
              parentId: this.asset.id,
              data
            });
          }

          this.$emit('success');
        } catch (e) {
          console.error(e);
          this.submitted = false;
          this.displayError({ message: this.$t('global.error') });
        }
      }
    },
    addingAllItemsChanged(value) {
      if (value) {
        this.form.addingCard = false;
        this.form.addingFolder = false;
      }

      this.valueChanged(value);
    },
    valueChanged() {
      if (this.disabledReceiveEmail) {
        this.form.receiveEmail = false;
      }
    }
  },
  async beforeMount() {
    this.entity = await this.getSubscription({ parentId: this.asset.id });
    if (this.entity.id) {
      const { options } = this.entity;
      this.form = {
        disableAdminNotifications: !!options.disableAdminNotifications,
        addingAllItems: !!options.addingAllItems,
        addingCard: !!options.addingCard,
        deletingCard: !!options.deletingCard,
        addingFolder: !!options.addingFolder,
        deletingFolder: !!options.deletingFolder,
        deletingSubBoard: !!options.deletingSubBoard,
        addingComment: !!options.addingComment,
        receiveEmail: !!options.receiveEmail
      };
    }
  }
};
</script>

<style lang="scss">
.board-subscription-dialogue {
  .dialogue__header,
  .dialogue__footer {
    background-color: $board-base;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  &__title.warning {
    color: $error-color;
  }

  &__checkbox {
    margin-left: $spacing-double;
  }

  &__options {
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }
}
</style>
