<template>
  <div
    ref="player"
    class="video-player"
    :class="$attrs.class"
    :style="$attrs.style"
    @mousedown.stop=""
  >
    <video v-if="sources.length > 0" :poster="poster" ref="video">
      <source
        :src="source.src"
        :type="source.type"
        v-for="source in sources"
        :key="source.id"
      />
    </video>

    <div
      v-if="link"
      ref="embed"
      :poster="posterUrl"
      :data-plyr-provider="provider"
      :data-plyr-embed-id="link"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import { trackError } from '@/log';
import { getProviderByUrl as plyrGetProviderByUrl } from 'plyr/src/js/config/types';
import 'plyr/dist/plyr.css';
import features from '@/utils/features';

const providers = {
  html5: 'html5',
  youtube: 'youtube',
  vimeo: 'vimeo'
};

const getProviderByUrl = (url) => {
  const provider = plyrGetProviderByUrl(url);
  if (provider) return provider;

  // YouTube
  if (
    /^(https?:\/\/)?((www\.)|(m\.))?(youtube\.com|youtube-nocookie\.com|youtu\.?be)\/.+$/.test(
      url
    )
  ) {
    return providers.youtube;
  }

  // Vimeo
  if (/^https?:\/\/player.vimeo.com\/video\/\d{0,9}(?=\b|\/)/.test(url)) {
    return providers.vimeo;
  }

  return null;
};

export default {
  inheritAttrs: false,
  getProviderByUrl,
  name: 'VideoPlayer',
  props: {
    link: {
      type: String,
      default: ''
    },
    sources: {
      type: Array,
      default: null
    },
    poster: {
      type: String,
      default: null
    }
  },
  data() {
    const isMobile = !!features.isMobile.any();
    const isIOS = !!features.isMobile.iOS();
    const isYoutubeIOS = isIOS && !!this.link;

    return {
      isMobile,
      isIOS,
      isYoutubeIOS,
      player: null,
      isReady: false,
      startVideoWhenIsReady: false
    };
  },
  computed: {
    provider() {
      return getProviderByUrl(this.link);
    },
    posterUrl() {
      if (this.link) return null;
      return this.poster;
    }
  },
  methods: {
    start() {
      if (!this.isReady) {
        this.startVideoWhenIsReady = true;
        return;
      }

      if (!this.isYoutubeIOS) this.player.play();
    },
    stop() {
      this.player.pause();
    },
    isPlaying() {
      return this.player.playing;
    }
  },
  mounted() {
    const { embed, video } = this.$refs;
    const controls = [
      'play',
      'play-large',
      'restart',
      'progress',
      'duration',
      'mute',
      'volume'
    ];

    if (!this.isIOS) controls.push('fullscreen');

    this.player = new Plyr(this.link ? embed : video, {
      controls,
      displayDuration: true,
      autopause: true,
      clickToPlay: true,
      fullscreen: { iosNative: this.isIOS }
    });

    this.player.on('ready', () => {
      this.$nextTick(() => {
        this.isReady = true;
        if (this.startVideoWhenIsReady && !this.isYoutubeIOS)
          this.player.play();

        this.isReady = true;
      });
    });

    this.player.on('play', () => {
      try {
        const start = () => {
          if (this.isIOS)
            this.player.once('canplay', () => this.player.fullscreen.enter());
          else this.player.fullscreen.enter();
        };

        if (this.isIOS) {
          if (!this.link) start();
        } else if (this.isMobile) start();
      } catch (error) {
        trackError({ eventName: 'play', error });
      }
    });

    this.player.on('playing', () => {
      this.$emit('playing', {
        playing: true,
        event: 'playing',
        currentTime: this.player.currentTime,
        duration: this.player.duration
      });
    });

    this.player.on('ended', () => {
      try {
        this.$emit('playing', {
          playing: false,
          event: 'ended',
          currentTime: this.player.currentTime,
          duration: this.player.duration
        });

        if (this.isMobile && this.player.fullscreen.active)
          this.player.fullscreen.exit();
      } catch (error) {
        trackError({ eventName: 'ended', error });
      }
    });

    this.player.on('pause', () => {
      if (this.player.currentTime !== this.player.duration)
        this.$emit('playing', {
          playing: false,
          event: 'paused',
          currentTime: this.player.currentTime,
          duration: this.player.duration
        });
    });
    this.player.on('error', (event) => {
      if (video)
        trackError({ eventName: 'videoError', event, error: video.error });
      else
        trackError({ eventName: 'videoError', event, error: 'Unknown error' });

      this.$emit('error');
    });
  }
};
</script>
