import i18n from '@/i18n';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';
import { useShareAssetStore } from '@/stores/share-asset';
import auth from '../auth';
import { ROLE_PARTICIPANT_RESTRICTED } from '@/utils/role';

const RegisterFormDialogue = () =>
  import('@/components/auth/RegisterFormDialogue');
const SharedPasswordPromptDialogue = () =>
  import('@/components/shareAsset/SharedPasswordPromptDialogue');
const ShareAssetView = () => import('@/components/shareAsset/ShareAssetView');

export default () => {
  return [
    {
      path: '/share-asset/invite/:token',
      name: 'invite-share-asset',
      component: RegisterFormDialogue,
      props: (to) => ({
        token: to.params.token,
        redirectUrl: `/share/${to.params.hash}`
      }),
      beforeEnter: async (to) => {
        const authStore = useAuthStore();
        const { isLoggedIn } = authStore;
        const { token } = to.params;

        try {
          const store = useShareAssetStore();
          const { errorMessage, accountId, email, hash, companyName } =
            await store.getInviteInfo(token);

          if (errorMessage) {
            const appStore = useAppStore();
            appStore.displayError({ message: errorMessage });
            return { name: 'root' };
          }

          if (isLoggedIn) {
            return {
              name: 'share',
              params: { hash }
            };
          } else if (accountId) {
            const redirectUrl = `/share/${hash}`;
            return {
              name: 'login',
              query: { redirectUrl, account: companyName, email }
            };
          } else {
            to.params.hash = hash;
            return true;
          }
        } catch (e) {
          console.error(e);
          return { name: 'root' };
        }
      }
    },
    {
      path: '/share-asset/:id',
      name: 'share-asset',
      component: ShareAssetView,
      props: (route) => ({
        id: parseInt(route.params.id)
      }),
      beforeEnter: auth.ensureUserHasRole(ROLE_PARTICIPANT_RESTRICTED)
    },
    {
      path: '/share/:hash',
      name: 'share',
      component: SharedPasswordPromptDialogue,
      props: (route) => ({
        hash: route.params.hash,
        redirectUrl: route.query.redirectUrl
      }),
      beforeEnter: async (to) => {
        const { hash } = to.params;

        try {
          const authStore = useAuthStore();
          const { login, alreadyAccess, preview } =
            await authStore.validateShareAssetHash({
              hash,
              preview: to.query.preview === 'true'
            });
          const { assetId, assetType } = login;

          if (alreadyAccess && !preview) {
            return {
              name: assetType,
              params: { id: assetId }
            };
          } else if (to.query?.redirectUrl) {
            return to.query.redirectUrl;
          } else {
            return {
              name: `${assetType}-share`,
              params: { id: assetId, hash }
            };
          }
        } catch (e) {
          // Prompt for password if authentication failed.
          // Otherwise (non-existent or expired share link), go to root for
          // the standard authentication flow
          if (e.status === 401 && e.code === 'INVALID_PASSWORD') return true;

          const appStore = useAppStore();

          if (e.code) {
            if (e.code === 'INVALID_LINK' && e.data?.redirectTo) {
              location.href = e.data.redirectTo;
              return false;
            }

            const code = `shareAsset.errors.${e.code}`;
            if (e.code !== 'CONNECTED_WRONG_COMPANY') {
              // document.location.href = 'https://google.ca';
              // return false;
              appStore.displayError({ message: i18n.global.t(code) });
            } else {
              appStore.displayError({
                message: i18n.global.t(code, [e.data?.companyName])
              });
            }
          } else {
            appStore.displayError({ message: i18n.global.t('global.error') });
          }

          return { name: 'root' };
        }
      }
    }
  ];
};
