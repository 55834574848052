<template>
  <div class="avatar" :class="$attrs.class" :title="fullName">
    <Loading
      v-if="displayPicture && pictureProcessing"
      :class="`avatar-processing-${size}`"
    />
    <img
      v-else-if="displayPicture && profilePicture"
      class="avatar-image-element"
      :src="profilePicture"
      :alt="initials"
      @dragstart.prevent
    />
    <div v-else class="avatar-initial" :class="`avatar-initial-${size}`">
      {{ initials }}
    </div>
  </div>
</template>

<script>
import { userFullname, userInitials } from '../utils/account';
import Loading from './icons/Loading';

export default {
  inheritAttrs: false,
  name: 'AvatarPill',
  components: { Loading },
  props: {
    info: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'small'
    },
    url: {
      type: String,
      default: ''
    },
    displayPicture: {
      type: Boolean,
      default: true
    },
    displayProcessing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    account() {
      return this.info;
    },
    initials() {
      return this.account ? userInitials(this.account) : '';
    },
    fullName() {
      return this.account ? userFullname(this.account) : '';
    },
    profilePicture() {
      if (this.url) {
        return this.url;
      } else if (this.info && this.info.picture && this.info.picture.url) {
        return this.info.picture.url;
      }

      return null;
    },
    pictureProcessing() {
      if (
        this.displayProcessing &&
        !this.url &&
        this.info &&
        this.info.picture
      ) {
        return this.info.picture.processing;
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  @include pill();
  background: $font-light;
  color: $font-dark;
  font-weight: $font-weight-light;
  text-transform: uppercase;

  &-image-element {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &-initial {
    &-medium {
      font-size: $font-size-base * 2.2;
    }

    &-large {
      font-size: $font-size-base * 6;
    }
  }

  &-processing {
    &-small {
      height: $loading-size-xxsmall;
      width: $loading-size-xxsmall;
    }

    &-medium {
      height: $loading-size-base;
      width: $loading-size-base;
    }

    &-large {
      height: $loading-size-base;
      width: $loading-size-base;
    }
  }
}
</style>
