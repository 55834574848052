<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.924 18.253"
  >
    <title v-if="text">{{ text }}</title>
    <path
      d="M-30.294,39.54l-1.187-.042h-8.731l-1.187.013a2.22,2.22,0,0,0-2.094,2.211v1.691h-.634l-1.187.013a2.219,2.219,0,0,0-2.094,2.211v9.89a2.227,2.227,0,0,0,2.225,2.224h10.56A2.228,2.228,0,0,0-32.4,55.527V53.836h1.691a2.228,2.228,0,0,0,2.224-2.224v-9.89A2.226,2.226,0,0,0-30.294,39.54Zm.623,12.072a1.039,1.039,0,0,1-1.037,1.038H-32.4V45.637a2.225,2.225,0,0,0-1.81-2.182l-1.186-.042h-6.912V41.722h0a1.037,1.037,0,0,1,1.039-1.036h10.56a1.037,1.037,0,0,1,1.037,1.037Z"
      transform="translate(47.408 -39.498)"
    />
  </svg>
</template>

<script>
import { useLogin, mixin } from '.';
import './_icon.scss';

const name = 'CopyIcon';

export default {
  mixins: [mixin],
  name,
  setup(props) {
    const { classes, text } = useLogin(name, props);
    return { classes, text };
  }
};
</script>
