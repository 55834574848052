<template>
  <div
    ref="root"
    class="tile-call-to-action-media"
    :class="$attrs.class"
    @click="clicked"
  >
    <div
      class="tile-call-to-action-media__boxe"
      :class="{ 'tile-call-to-action-media__boxe--has-button': !!buttonText }"
      :data-clickable="!buttonText"
      :style="style"
    >
      <div
        v-if="buttonText"
        class="tile-call-to-action-media__boxe--button"
        data-clickable="true"
        :style="buttonStyle"
        @click.stop="buttonClicked"
      >
        {{ buttonText }}
      </div>
      <LinkMediaIcon
        v-else-if="!options.backgroundImageUrl"
        size="large"
        type="light"
      />
    </div>
  </div>
</template>

<script>
import LinkMediaIcon from '../icons/LinkMedia';

export default {
  inheritAttrs: false,
  name: 'TileCallToActionMedia',
  components: {
    LinkMediaIcon
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    buttonText() {
      return this.options.button?.text;
    },
    style() {
      if (this.options.backgroundImageUrl)
        return { backgroundImage: `url(${this.options.backgroundImageUrl})` };
      else if (this.options.backgroundColor)
        return {
          backgroundColor: this.options.backgroundColor,
          border: `1px solid ${this.options.backgroundColor}`
        };

      return null;
    },
    buttonStyle() {
      if (!this.buttonText) return;

      return {
        backgroundColor: this.options.button.colors.background,
        border: `1px solid ${this.options.button.colors.background}`,
        color: this.options.button.colors.text
      };
    }
  },
  methods: {
    clicked($event) {
      if (!this.buttonText) this.$emit('click', $event);
    },
    buttonClicked($event) {
      this.$emit('click', $event);
    }
  }
};
</script>

<style lang="scss">
.tile-call-to-action-media {
  @include flexy($dir: column, $align: center, $just: center);
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__boxe {
    @include flexy($align: center, $just: center);
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $tile-media-border-radius;
    cursor: pointer;

    &--has-button {
      cursor: default;
    }

    &--button {
      @include flexy($align: center, $just: center);
      width: 250px;
      min-height: 60px;
      box-shadow: 0px 0px 10px #0000003d;
      border-radius: $tile-media-border-radius;
      font-size: $font-size-large;
      color: $font-dark;
      padding: $spacing-base;
      cursor: pointer;
      text-align: center;
      word-break: break-word;
    }

    &--pill {
      @include flexy($align: center, $just: center);
      width: 53px;
      height: 53px;
      border-radius: 50% 50%;
      background-color: #ffffff;

      > svg {
        fill: $media-link;
      }
    }
  }
}
</style>
