<template>
  <div class="evaluation-criteria-section" :class="$attrs.class">
    <div v-if="canEdit" class="evaluation-criteria-section__header">
      <div class="evaluation-criteria-section__header--add" @click="add">
        <Creation />
        <div>{{ $t('evaluationForm.criteria.add') }}</div>
      </div>
      <div
        class="evaluation-criteria-section__header--delete"
        :class="{ disabled: criterias.length === 0 }"
        @click="confirmDeleteAll"
      >
        <div>{{ $t('evaluationForm.criteria.deleteAll') }}</div>
      </div>
    </div>
    <ListPageable
      ref="criterias"
      key="position"
      displayType="list"
      :items="criterias"
      :isEmpty="criterias.length === 0"
      :draggable="canEdit"
      handle=".handle"
      @reorderElements="reorderElements"
    >
      <template #content-item="{ item }">
        <div class="evaluation-criteria-item">
          <span class="evaluation-criteria-item__label1">{{
            $t('evaluationForm.criteria.itemLabel', [item.position])
          }}</span>
          <span class="evaluation-criteria-item__label2">%</span>
          <div v-if="canEdit" class="evaluation-criteria-item__move handle">
            <UpDown />
          </div>
          <div class="evaluation-criteria-item__description">
            <input
              class="evaluation-criteria-item__description--input"
              :class="`description-${item.position}`"
              v-model.trim="item.description"
              :readonly="!canEdit"
            />
          </div>
          <div class="evaluation-criteria-item__weighting">
            <VueNumericInput
              class="evaluation-criteria-item__weighting--input"
              :class="`weighting-${item.position}`"
              v-model="item.weighting"
              @focus="weightingFocused(item)"
              @blur="weightingHasChnaged(item)"
              :readonly="!canEdit || !item.weightingIsLocked"
              width="80px"
              :min="1"
              :max="100"
              :precision="0"
              :controls="false"
              mousewheel
            />
            <div
              class="evaluation-criteria-item__weighting--lock"
              :class="{ readOnly: !canEdit }"
              @click="toggleLock(item)"
            >
              <Locked
                v-if="!item.weightingEditing"
                :type="item.weightingIsLocked ? 'active' : undefined"
              />
              <Unlocked
                v-else
                :type="item.weightingIsLocked ? 'active' : undefined"
              />
            </div>
          </div>
          <div
            v-if="canEdit"
            class="evaluation-criteria-item__delete"
            @click="remove(item)"
          >
            <DeleteAction />
          </div>
        </div>
      </template>
      <template #content-empty>
        <span>{{ $t('evaluationForm.criteria.emptyText') }}</span>
      </template>
    </ListPageable>

    <ToastAlert
      v-if="activeToast === 'confirmDeleteAll'"
      prompt
      level="warning"
      @ok="deleteAll"
      @cancel="toggleToast()"
    >
      {{ $t('evaluationForm.criteria.confirmDeleteAll') }}
    </ToastAlert>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useEvaluationStore } from '@/stores/evaluation';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';
import VueNumericInput from '../controls/VueNumericInput';
import ListPageable from '../layout/ListPageable';
import Creation from '../icons/Creation';
import Locked from '../icons/Locked';
import UpDown from '../icons/UpDown';
import Unlocked from '../icons/Unlocked';
import DeleteAction from '../icons/Close';

export default {
  inheritAttrs: false,
  name: 'EvaluationCriteriaSection',
  mixins: [toggleState('toast')],
  components: {
    ToastAlert,
    Creation,
    UpDown,
    Locked,
    Unlocked,
    DeleteAction,
    VueNumericInput,
    ListPageable
  },
  props: {
    evaluation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      criterias: []
    };
  },
  computed: {
    canEdit() {
      return !this.evaluation.sent || this.evaluation.editing;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError', 'displayWarning']),
    ...mapActions(useEvaluationStore, ['getCriterias', 'updateCriterias']),
    async load() {
      try {
        this.criterias = await this.getCriterias(this.evaluation.id);
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    validate() {
      const descriptions = new Map();
      for (let i = 0; i < this.criterias.length; i++) {
        const criteria = this.criterias[i];
        criteria.description = (criteria.description || '').trim();

        if (!criteria.description) {
          this.displayWarning({
            message: this.$t('evaluationForm.criteria.descriptionRequired', [
              criteria.position
            ])
          });
          this.setDescriptionFocus(criteria.position);
          return false;
        }

        const key = criteria.description.toLowerCase();
        if (descriptions.has(key)) {
          this.displayWarning({
            message: this.$t('evaluationForm.criteria.descriptionUnique', [
              criteria.position
            ])
          });
          this.setDescriptionFocus(criteria.position);
          return false;
        } else {
          descriptions.set(key, key);
        }
      }

      return true;
    },
    async update() {
      const evaluation = await this.updateCriterias({
        id: this.evaluation.id,
        criterias: this.criterias
      });
      this.$emit('countChanged', evaluation.criteriaCount);
    },
    refreshInfoCriterias(criterias = []) {
      let total = criterias.length;
      let remain = 100;
      let i = 1;

      // Clean position and weighting (int)
      criterias.forEach((criteria) => {
        criteria.position = i;
        criteria.weighting = parseInt(criteria.weighting || 1);
        criteria.weightingIsLocked = !!criteria.weightingIsLocked;
        criteria.description = (criteria.description || '').trim();

        i++;
      });

      i = 1;
      // Distribute percentage for locked weighting
      const lockedCriterias = criterias.filter(
        (criteria) => criteria.weightingIsLocked
      );
      lockedCriterias.forEach((criteria) => {
        if (criteria.weighting > remain - (total - i)) {
          criteria.weighting = remain - (total - i);
        }

        remain -= criteria.weighting;

        i++;
      });

      const notLockedCriterias = criterias.filter(
        (criteria) => !criteria.weightingIsLocked
      );
      total = notLockedCriterias.length;
      const percentage = parseInt(remain / total);
      i = 1;
      // Distribute percentage for not locked weighting
      notLockedCriterias.forEach((criteria) => {
        criteria.weighting = percentage;

        remain -= criteria.weighting;
        i++;
      });

      if (remain > 0) {
        const remainingCriterias =
          notLockedCriterias.length > 0 ? notLockedCriterias : lockedCriterias;
        const start = remainingCriterias.length - 1;
        // Distribute the remaining percentage
        for (let i = start; i > -1; i--) {
          if (remain === 0) break;

          remainingCriterias[i].weighting++;

          remain--;
        }
      }

      this.criterias = criterias;
    },
    add() {
      if (this.criterias.length >= 100) {
        this.displayWarning({
          message: this.$t('evaluationForm.criteria.maxCriteria')
        });
        return;
      }

      const items = [
        ...this.criterias,
        {
          position: this.criterias.length + 1,
          description: '',
          weighting: 1,
          weightingIsLocked: false
        }
      ];

      this.refreshInfoCriterias(items);
      this.setDescriptionFocus(this.criterias.length);
    },
    async remove(item) {
      const items = this.criterias.filter((x) => x !== item);

      this.refreshInfoCriterias(items);
    },
    confirmDeleteAll() {
      this.toggleToast('confirmDeleteAll');
    },
    async deleteAll() {
      try {
        const evaluation = await this.updateCriterias({
          id: this.evaluation.id,
          deleteAll: true
        });
        this.$emit('countChanged', evaluation.criteriaCount);
        this.criterias = [];
        this.toggleToast();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    toggleLock(item) {
      if (!this.canEdit) return;

      item.weightingIsLocked = !item.weightingIsLocked;

      if (item.weightingIsLocked) {
        this.$nextTick(() => {
          const { criterias } = this.$refs;

          const { $el: element } = criterias;
          if (!element) return;

          const txt = element.querySelector(
            `.weighting-${item.position} > .numeric-input`
          );
          if (txt) {
            txt.focus();
            if (txt.select) txt.select();
          }
        });

        return;
      }

      this.refreshInfoCriterias([...this.criterias]);
    },
    weightingFocused(item) {
      if (!item.weightingIsLocked) return;

      this.criterias = this.criterias.map((criteria) => {
        if (criteria.position !== item.position) return criteria;
        return {
          ...criteria,
          weightingEditing: true
        };
      });
    },
    weightingHasChnaged(item) {
      this.criterias = this.criterias.map((criteria) => {
        if (criteria.position !== item.position) return criteria;
        const newItem = { ...criteria };
        delete newItem.weightingEditing;
        return newItem;
      });

      if (!item.weightingIsLocked) return;
      this.refreshInfoCriterias([...this.criterias]);
    },
    reorderElements() {
      this.$nextTick(() => {
        this.refreshInfoCriterias([...this.criterias]);
      });
    },
    setDescriptionFocus(position) {
      this.$nextTick(() => {
        const { criterias } = this.$refs;
        const { $el: element } = criterias;
        if (!element) return;

        const txt = element.querySelector(`.description-${position}`);
        if (txt) {
          txt.focus();
          if (txt.select) txt.select();
        }
      });
    }
  },
  async beforeMount() {
    await this.load();
  }
};
</script>

<style lang="scss" scoped>
.evaluation-criteria-section {
  @include flexy($dir: column);
  width: 100%;
  height: 100%;
  flex: 1;

  &__header {
    @include flexy($align: center, $just: space-between);
    background-color: $background-light;
    color: $font-dark;
    font-weight: $font-weight-bold;
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;

    &--add {
      @include flexy($align: center);

      > * {
        margin-right: $spacing-half;
      }
    }

    &--add,
    &--delete {
      height: 100%;
      cursor: pointer;

      > * {
        @include flexy($align: center);
        height: 100%;
      }
    }

    &--delete.disabled {
      opacity: $disable-opacity;
      cursor: default;
    }
  }
}

.evaluation-criteria-item {
  @include grid(
    $cols: auto 1fr auto auto,
    $rows: auto auto,
    $areas: 'label1 label1 label2 .' 'move description weighting delete'
  );

  font-family: $roboto-condensed;
  width: 100%;
  padding: $spacing-base;

  &__label1 {
    @include grid-item($area: label1);
    margin-bottom: 3px;
    margin-right: $spacing-half;
  }

  &__label2 {
    @include grid-item($area: label2);
    margin-bottom: 3px;
    margin-left: $spacing-half;
  }

  &__move {
    @include grid-item($area: move);
    @include flexy($align: center);
    cursor: grab;
    margin-right: $spacing-half;
  }

  &__description {
    @include grid-item($area: description);

    margin-right: $spacing-half;
  }

  &__description--input {
    width: 100%;
    background-color: $background-light;
    border: $border-width solid $border-base;
    border-radius: $base-border-radius;
    color: $font-dark;
    font-size: $font-size-medium;
    padding: $spacing-half;
  }

  &__weighting {
    @include grid-item($area: weighting);
    position: relative;

    &--lock {
      cursor: pointer;
      position: absolute;
      right: $spacing-third;
      bottom: 3px;
    }

    &--lock.readOnly {
      cursor: default;
    }

    .vue-numeric-input {
      height: inherit;
    }
  }

  &__delete {
    @include grid-item($area: delete);
    @include flexy($align: center);
    cursor: pointer;
    margin-left: $spacing-half;
  }
}
</style>

<style lang="scss">
.evaluation-criteria-item {
  .evaluation-criteria-item__weighting--input.vue-numeric-input .numeric-input,
  .evaluation-criteria-item__weighting--input.vue-numeric-input
    .numeric-input.no-control {
    background-color: $background-light;
    border: $border-width solid $border-base;
    border-radius: $base-border-radius;
    color: $font-dark;
    font-size: $font-size-medium;
    padding: $spacing-half;
    padding-right: 2.8rem;
    line-height: inherit;
  }
}
</style>
