<template>
  <div class="chat-item" :class="$attrs.class" @click="$emit('selected', item)">
    <div class="chat-item__content">
      <div v-if="item.countUnreadMessages" class="chat-item__unread-count">
        {{ item.countUnreadMessages }}
      </div>

      <div class="chat-item__avatar">
        <AvatarPill :info="item.author" />
      </div>

      <div class="chat-item__top">
        <div class="chat-item__name">{{ item.name }}</div>
        <div class="chat-item__since">{{ item.since }}</div>
      </div>

      <div class="chat-item__bottom">
        <div class="chat-item__last-message">
          <div v-if="item.lastMessage">{{ item.lastMessage }}</div>
          <div v-else>{{ $t('chatForm.messages.empty') }}</div>
        </div>

        <button
          v-if="displayActionButton"
          class="icon-button chat-item__actions"
          @click.stop="toggleDialogue('options')"
        >
          <ActionIcon size="small" />
        </button>
      </div>
    </div>

    <!-- Options dialogue -->
    <ChatOptionsDialogue
      v-if="activeDialogue === 'options'"
      :item="item"
      @cancel="toggleDialogue()"
    />
  </div>
</template>

<script>
import AvatarPill from '../AvatarPill';
import ActionIcon from '../icons/Action';
import ChatOptionsDialogue from './ChatOptionsDialogue';
import authMixins from '../mixins/auth';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'ChatItem',
  mixins: [authMixins, toggleState('dialogue')],
  components: {
    AvatarPill,
    ActionIcon,
    ChatOptionsDialogue
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayActionButton() {
      return this.item.author.id === this.currentUser.id;
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.chat-item {
  width: 100%;
  height: $vertical-rhythm;
  color: $font-dark;
  background-color: #ffffff;
  padding: 0 $spacing-base;
  position: relative;
  cursor: pointer;

  &__unread-count {
    @include flexy($just: center, $align: center);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 15px;
    height: 15px;
    min-width: 15px;
    background-color: $counter-background;
    color: $font-light;
    font-size: 11px;
    font-weight: bold;
    padding: 0 calc($spacing-quarter / 2);
  }

  &__content {
    @include grid(
      $cols: auto 1fr,
      $rows: 1fr 1fr,
      $areas: 'avatar top' 'avatar bottom'
    );
    width: 100%;
    height: 100%;
  }

  &__avatar {
    @include grid-item($area: avatar, $align: center, $just: center);
  }

  &__top {
    @include grid($cols: 1fr auto, $areas: 'name since');
    @include grid-item($area: top);
  }

  &__bottom {
    @include grid($cols: 1fr auto, $areas: 'last actions');
    @include grid-item($area: bottom);
  }

  &__name {
    @include grid-item($area: name, $align: flex-end);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 1px $spacing-half;
  }

  &__last-message {
    @include grid-item($area: last, $align: flex-start);
    font-size: $font-size-small;
    margin: 1px $spacing-half;
    overflow: hidden;

    > * {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__since {
    @include grid-item($area: since, $align: center);
  }

  &__actions {
    @include grid-item($area: actions, $align: flex-start);
  }
}
</style>
