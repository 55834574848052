import { get as httpGet, post as httpPost, put as httpPut } from '../requests';

export const ENDPOINT_URL = '/assets';

export const getOrganicUnassignedItems = async (
  parentType,
  id,
  type,
  options = {},
  share = false
) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${parentType}/${id}/organic-unassigned/${type}/all${
      share ? '/share' : ''
    }`,
    options
  );
  return response.json();
};

export const getOrganicAssignedItems = async (
  parentType,
  id,
  type,
  share = false
) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/${parentType}/${id}/organic-assigned/${type}/all${
      share ? '/share' : ''
    }`
  );
  return response.json();
};

export const getOrganicPosition = async (type, id, share = false) => {
  const response = await httpGet(
    `${ENDPOINT_URL}/${type}/organic-position/${id}${share ? '/share' : ''}`
  );
  return response.json();
};

export const assignOrganicItem = async (type, id, options = {}) => {
  const response = await httpPut(
    `${ENDPOINT_URL}/${type}/${id}/assign-organic-item`,
    options
  );
  return response.json();
};

export const moveOrganicItems = async (type, items = []) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${type}/move-organic-items`,
    items
  );
  return response.json();
};

export const unassignOrganicItems = async (type, ids = []) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${type}/unassign-organic-items`,
    ids
  );
  return response.json();
};

export const deleteOrganicItems = async (type, ids = []) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${type}/delete-organic-items`,
    ids
  );
  return response.json();
};
