<template>
  <div class="list-media" :class="$attrs.class">
    <ItemCheckBox v-if="selectable" :item="item" type="media" />

    <AvatarPill v-if="item.author && !fromSearch" :info="item.author" />

    <MediaPill :mediaType="item.mediaType" />

    <Loading class="list-media__processing" v-if="processing" />
    <div
      v-else-if="backgroundImage"
      class="list-media__preview"
      :class="[
        `list-media__preview--${this.item.mediaType}`,
        { 'list-media__preview--unclickable': fromSearch }
      ]"
      :style="style"
      @click="displayMedia"
    />

    <div
      class="list-media__name"
      :class="{ 'list-media__name--unclickable': fromSearch }"
      @click="displayMedia"
    >
      <VClamp :maxLines="2" :text="item.name" />
    </div>

    <div class="list-media__spacer">&nbsp;</div>

    <button
      v-if="displayActionButton"
      class="icon-button list-media__actions"
      @click.stop="actionClicked"
    >
      <Action size="small" />
    </button>
    <button
      v-if="showDelete"
      class="icon-button list-item__delete"
      @click.stop="$emit('delete', item)"
    >
      <DeleteAction />
    </button>

    <MediaOptionsDialogue
      v-if="activeDialogue === 'options'"
      :item="item"
      :fromSearch="fromSearch"
      @navigate="$emit('navigate', $event)"
      @cancel="toggleDialogue()"
    />
    <ViewMediaDialogue
      v-else-if="activeDialogue === 'view'"
      :item="item"
      modal
      @navigate="$emit('navigate', $event)"
      @cancel="viewMediaClosed()"
    />
  </div>
</template>

<script>
import AvatarPill from '../AvatarPill';
import VClamp from '../controls/VueClamp';
import MediaOptionsDialogue from '../media/MediaOptionsDialogue';
import MediaPill from '../media/MediaPill';
import ViewMediaDialogue from '../media/ViewMediaDialogue';
import Action from '../icons/Action';
import DeleteAction from '../icons/Close';
import mediaProperties from '../mixins/mediaProperties';
import toggleState from '../mixins/toggleState';
import ItemCheckBox from '../layout/ItemCheckBox';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import Loading from '../icons/Loading';

export default {
  inheritAttrs: false,
  name: 'ListMedia',
  mixins: [mediaProperties, toggleState('dialogue'), selectionClipboardMixin],
  components: {
    Action,
    DeleteAction,
    ItemCheckBox,
    AvatarPill,
    VClamp,
    MediaOptionsDialogue,
    MediaPill,
    ViewMediaDialogue,
    Loading
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    withActions: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    fromSearch: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayActionButton() {
      if (!this.withActions || this.isShared) return false;
      if (this.fromSearch) return !this.isSearchSelectionMode;
      return !this.isSelectionMode;
    }
  },
  methods: {
    actionClicked() {
      this.toggleDialogue('options');
    },
    displayMedia() {
      if (this.fromSearch) return;
      this.toggleDialogue('view');
    },
    viewMediaClosed() {
      this.toggleDialogue();
      this.$emit('mediaClosed');
    }
  }
};
</script>

<style lang="scss" scoped>
.list-media {
  @include flexy($align: center, $just: space-between);
  background-color: $background-light;
  color: $font-dark;
  min-height: $vertical-rhythm;
  padding: 0 $spacing-base;
  overflow: hidden;

  &__processing {
    width: $loading-size-xsmall;
    height: $loading-size-xsmall;
  }

  > .avatar,
  > .media-pill {
    margin-right: $spacing-third;
  }
}

.list-media__name {
  display: flex;
  align-items: center;
  margin-right: $spacing-third;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &--unclickable {
    cursor: inherit;
  }
}

.list-media__preview {
  @include media-colors(border-color);
  background-size: cover;
  border-radius: $base-border-radius;
  cursor: pointer;
  min-height: $preview-size;
  margin-right: $spacing-half;
  min-width: $preview-size;

  &--unclickable {
    cursor: inherit;
  }
}

.list-media__spacer {
  flex: 1;
}

.list-media__actions,
.list-media__delete {
  height: 100%;
}
</style>
