import {
  get as httpGet,
  post as httpPost,
  put as httpPut,
  del as httpDelete
} from '../requests';

export const ENDPOINT_URL = '/chat';

export const get = async (id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${id}`);
  return response.json();
};

export const getAll = async (options = {}) => {
  const response = await httpPost(`${ENDPOINT_URL}/get-all`, options);
  return response.json();
};

export const create = async (data) => {
  const response = await httpPost(ENDPOINT_URL, data);
  return response.json();
};

export const update = async (id, data) => {
  const response = await httpPut(`${ENDPOINT_URL}/${id}`, data);
  return response.json();
};

export const del = async (id) => {
  const response = await httpDelete(`${ENDPOINT_URL}/${id}`);
  return response.json();
};

export const getMessages = async (id, options = {}) => {
  const response = await httpPost(
    `${ENDPOINT_URL}/${id}/get-messages`,
    options
  );
  return response.json();
};

export const addMessage = async (id, data) => {
  const response = await httpPost(`${ENDPOINT_URL}/${id}/messages`, data);
  return response.json();
};

export const getMessage = async (chatId, id) => {
  const response = await httpGet(`${ENDPOINT_URL}/${chatId}/messages/${id}`);
  return response.json();
};

export const deleteMessage = async (chatId, id) => {
  const response = await httpDelete(`${ENDPOINT_URL}/${chatId}/messages/${id}`);
  return response.json();
};
