export default {
  data() {
    return {
      defaultColors: {
        link: {
          tile: {
            background: '#107989'
          },
          button: {
            background: '#ffffff',
            text: '#595b5f'
          }
        }
      }
    };
  },
  computed: {
    backgroundImage() {
      if (this.processing) return '';

      const { mediaType, fileInfos } = this.item;
      let url = '';

      if (mediaType === 'photo' && fileInfos && fileInfos.url) {
        url = fileInfos.url;
      } else if (mediaType === 'video' && this.posterUrl) {
        url = this.posterUrl;
      }

      return url;
    },
    style() {
      const url = this.backgroundImage;
      return url ? { backgroundImage: `url(${url})` } : null;
    },
    linkBackgroundImage() {
      if (this.processing) return '';

      const { mediaType, fileInfos } = this.item;
      let url = '';

      if (mediaType === 'link' && fileInfos && fileInfos.url) {
        url = fileInfos.url;
      }

      return url;
    },
    linkStyle() {
      const url = this.linkBackgroundImage;
      return url ? { backgroundImage: `url(${url})` } : null;
    },
    fileBackgroundImage() {
      if (this.processing) return '';

      const { mediaType, fileInfos } = this.item;
      let url = '';

      if (mediaType === 'file' && fileInfos && fileInfos.posterUrl) {
        url = fileInfos.posterUrl;
      }

      return url;
    },
    fileStyle() {
      const url = this.fileBackgroundImage;
      return url ? { backgroundImage: `url(${url})` } : null;
    },
    title() {
      const { name, link, mediaType } = this.item;

      if (!this.processing) {
        if (mediaType === 'photo') {
          return '';
        } else if (mediaType === 'video' && this.backgroundImage) {
          return '';
        }
      }

      return name || link || '';
    },
    fileName() {
      const { fileInfos } = this.item;
      return (fileInfos ? fileInfos.name : '') || '';
    },
    fileExt() {
      const name = this.fileName;
      if (!name) return '';

      const reg = /.+\.(.+)$/i;
      let ext = '';

      const match = name.trim().match(reg);
      if (match && match[1]) {
        ext = match[1].trim().toLowerCase();
      }

      return ext;
    },
    posterUrl() {
      if (this.item.linkPostedUrl) return this.item.linkPostedUrl;
      const { fileInfos } = this.item;
      return fileInfos && fileInfos.posterUrl ? fileInfos.posterUrl : '';
    },
    processing() {
      const { fileInfos } = this.item;
      return fileInfos && !!fileInfos.processing;
    },
    fileUrl() {
      const { fileInfos } = this.item;
      return fileInfos ? fileInfos.url : null;
    },
    tagColor() {
      const { tag } = this.item;
      return tag ? tag.color : null;
    },
    tileCallToActionMediaOptions() {
      const { mediaType, additionalData } = this.item;
      if (mediaType !== 'link') return null;
      return {
        backgroundImageUrl: this.linkBackgroundImage,
        backgroundColor:
          additionalData?.tile?.background ||
          this.defaultColors.link.tile.background,
        button: {
          text: additionalData?.button.text,
          colors: {
            background: additionalData?.button?.colors?.background,
            text: additionalData?.button?.colors?.text
          }
        }
      };
    }
  }
};
