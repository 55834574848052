import { identifyUser } from '@/utils/tracking';
import { defineStore } from 'pinia';
import { useAppStore } from '@/stores/app';
import eventDispatcher from '@/stores/event-dispatcher';

import {
  login as apiLogin,
  logout as apiLogout,
  refreshToken as apiRefreshToken,
  requestReset as apiRequestReset,
  resetPasswordNextLogin as apiResetPasswordNextLogin,
  resetPasswordStart as apiResetPasswordStart,
  resetPasswordVerifyCode as apiResetPasswordVerifyCode,
  resetPasswordVerifyPassword as apiResetPasswordVerifyPassword,
  mfaSetup as apiMfaSetup,
  mfaSetupAtLogin as apiMfaSetupAtLogin,
  mfaVerifyPassword as apiMfaVerifyPassword,
  mfaVerifyCodeEmail as apiMfaVerifyCodeEmail,
  getMfaSecret as apiGetMfaSecret,
  mfaVerifyCode as apiMfaVerifyCode,
  mfaDeactivate as apiMfaDeactivate
} from '@/api/endpoints/auth';
import { validateHash as apiValidateHash } from '@/api/endpoints/shareAsset';
import {
  me as apiMe,
  updateProfile as apiUpdateProfile,
  uploadPicture as apiUploadPicture,
  getUnreadChatMessageCount as apiGetUnreadChatMessageCount,
  getUnreadNotificationCount as apiGetUnreadNotificationCount,
  getEvaluationCount as apiGetEvaluationCount
} from '@/api/endpoints/account';
import {
  ROLE_ROOT_ADMINISTRATOR,
  ROLE_ACCOUNT_ADMINISTRATOR,
  ROLE_ADMINISTRATOR,
  ROLE_PARTICIPANT,
  ROLE_PARTICIPANT_RESTRICTED
} from '@/utils/role';
import { storeEventHandlers, getSocket } from '@/io';
import { refreshExternalAppUserInfo } from '@/utils/account';
import { track } from '@/utils/tracking';
import { useRouter } from 'vue-router';

const roles = {
  [ROLE_ACCOUNT_ADMINISTRATOR]: 0,
  [ROLE_ADMINISTRATOR]: 1,
  [ROLE_PARTICIPANT]: 2,
  [ROLE_PARTICIPANT_RESTRICTED]: 3
};

const getEventHandlers = () => {
  const handlers = {};
  storeEventHandlers.forEach((eventName) => {
    handlers[eventName] = async (data) => {
      await eventDispatcher[eventName](data);
    };
  });
  return handlers;
};

let socket = null;

const disconnectFromSocket = async () => {
  try {
    if (socket) {
      await socket.disconnect();
      socket = null;
      console.log('you are disconnected');
    }
  } catch (e) {
    console.error('disconnectFromSocket', e);
  }
};

const connectToSocket = async (authStore, authenticatedCallback) => {
  try {
    if (socket) return;

    socket = await getSocket(
      getEventHandlers(),
      async () => {
        await authStore.refreshToken();
      },
      authenticatedCallback
    );
  } catch (e) {
    console.error(e);
  }

  return socket;
};

const initialState = {
  info: null,
  shareToken: null,
  shareLogin: null,
  shareLoginHandler: null,
  displayMode: 'tile',
  trackFirstParticipantEvent: false,
  roles: [],
  router: null
};

export const key = 'auth';
export const useAuthStore = defineStore(key, {
  state: () => ({ ...initialState }),
  getters: {
    isLoggedIn: (state) => {
      if (!state.info?.company) return false;
      return !state.info.resetPasswordNextLogin && !state.info.mustSetupMfa;
    },
    isWebShareLoggedIn: (state) => {
      const type = state.shareLogin?.type;
      return (
        (type === 'web' || type === 'named') &&
        useAppStore().currentRoute?.name?.endsWith('-share')
      );
    },
    isInPreviewWebShare: (state) => {
      return state.isWebShareLoggedIn && !!state.shareLogin?.preview;
    },
    currentUser: (state) => state.info,
    features: (state) => state.info?.company?.features,
    language: (state) => state.info?.lang,
    isRootAdministrator: (state) => {
      if (!state.isLoggedIn) return false;
      return state.info.role.name === ROLE_ROOT_ADMINISTRATOR;
    },
    isAccountAdministrator: (state) => {
      if (!state.isLoggedIn) return false;
      return state.info.role.name === ROLE_ACCOUNT_ADMINISTRATOR;
    },
    isAdministrator: (state) => {
      if (!state.isLoggedIn) return false;
      return (
        state.info.role.name === ROLE_ADMINISTRATOR ||
        state.isAccountAdministrator
      );
    },
    isParticipant: (state) => {
      if (!state.isLoggedIn) return false;
      return state.info.role.name === ROLE_PARTICIPANT || state.isAdministrator;
    },
    isParticipantRestricted: (state) => {
      if (!state.isLoggedIn) return false;
      return (
        state.info.role.name === ROLE_PARTICIPANT_RESTRICTED ||
        state.isParticipant
      );
    },
    hasRole: (state) => {
      return (role) => {
        if (!state.isLoggedIn) return false;

        if (role === ROLE_PARTICIPANT_RESTRICTED)
          return state.isParticipantRestricted;
        else if (role === ROLE_PARTICIPANT) return state.isParticipant;
        else if (role === ROLE_ADMINISTRATOR) return state.isAdministrator;
        else if (role === ROLE_ACCOUNT_ADMINISTRATOR)
          return state.isAccountAdministrator;
        return false;
      };
    },
    canManageCompanies: (state) => {
      if (!state.isLoggedIn) return false;
      if (state.info.company.name !== 'fassilio') return false;
      return !!state.info.canManageCompanies && state.isAdministrator;
    },
    defaultLogoPath: () => require('@/assets/img/logo.svg'),
    logoPath: (state) => {
      try {
        const compayName =
          state.shareLogin?.companyName || state.currentUser?.company.name;

        if (
          compayName === 'honda-ste-rose' ||
          compayName === 'venturi' ||
          compayName === 'dvox' ||
          compayName === 'empatya' ||
          compayName === 'bhhs-quebec' ||
          compayName === 'apex' ||
          compayName === 'gowling-wlg' ||
          compayName === 'ijw-co'
        ) {
          return require(`@/assets/img/${compayName}-logo.svg`);
        }

        return state.defaultLogoPath;
      } catch (e) {
        console.error('Error on logoPath', e);
        return state.defaultLogoPath;
      }
    }
  },
  actions: {
    async reset() {
      await this.clearShareLogin();

      Object.assign(this, { ...initialState });
      disconnectFromSocket();
      refreshExternalAppUserInfo(null);
    },
    async setInfo(account) {
      this.info = account;
      refreshExternalAppUserInfo(account);
    },
    async updateProfile({ file, ...data }) {
      if (!this.info) return;

      const appStore = useAppStore();
      await appStore.startLoading();

      try {
        if (file) {
          data.picture = {
            processing: true,
            size: file.size,
            name: file.name
          };
        }

        const account = await apiUpdateProfile(data);
        if (file) {
          await apiUploadPicture(file);
        }

        await this.setInfo(account);
        return account;
      } finally {
        await appStore.stopLoading();
      }
    },
    async refresh(options = {}) {
      const { redirectToLogoutOnUnauthorize = true } = options || {};
      const account = await apiMe();

      if (!account || account.blocked || account.company.blocked) {
        if (redirectToLogoutOnUnauthorize) {
          const appStore = useAppStore();
          await appStore.setNavigateTo({ name: 'logout' });
        }

        return;
      } else if (!this.info && account.mfaEnforced && !account.mfaActivated) {
        const appStore = useAppStore();
        await appStore.setNavigateTo({ name: 'logout' });
        return;
      } else if (this.info) {
        if (
          this.info.role.name !== account.role.name ||
          this.info.canManageCompanies !== account.canManageCompanies
        ) {
          await this.refreshToken();
        }

        if (roles[this.info.role.name] < roles[account.role.name]) {
          const appStore = useAppStore();
          await appStore.setNavigateTo({ name: 'root' });
        }
      }

      await this.setInfo(account);

      await this.refreshCurrentUserData();
      await connectToSocket(this);

      return account;
    },
    async refreshCurrentUserData() {
      await Promise.all([
        this.refreshUnreadChatMessageCount(),
        this.refreshUnreadNotificationCount(),
        this.refreshEvaluationCount()
      ]);
    },
    async refreshUnreadChatMessageCount() {
      if (!this.currentUser || this.isRootAdministrator) return;

      const { count } = await apiGetUnreadChatMessageCount();
      const account = { ...this.info, unreadChatMessageCount: count };
      await this.setInfo(account);
    },
    async refreshUnreadNotificationCount() {
      if (!this.currentUser || this.isRootAdministrator) return;

      const { unreadNotificationCount } = await apiGetUnreadNotificationCount();
      const account = { ...this.info, unreadNotificationCount };
      await this.setInfo(account);
    },
    async refreshEvaluationCount() {
      if (!this.currentUser || this.isRootAdministrator) return;

      const evaluation = await apiGetEvaluationCount();
      const account = { ...this.info, evaluation };
      await this.setInfo(account);
    },
    async login(creds) {
      let { user, needMfaCode, mustSetupMfa } = await apiLogin(creds);

      if (needMfaCode) {
        return { needMfaCode };
      } else if (mustSetupMfa) {
        user = { ...user, mustSetupMfa: true };
        this.info = user;
        return { user };
      }

      await this.setInfo(user);
      await connectToSocket(this);

      track('login', {
        time: new Date().toISOString(),
        user_id: user.id
      });

      return { user };
    },
    async logout() {
      if (this.info) {
        try {
          const appStore = useAppStore();
          const { applicationId } = appStore;
          await socket.logout({
            applicationId,
            id: this.info.id
          });
        } catch (e) {
          // console.error(e.message, e);
        }
      }

      try {
        await apiLogout();
      } catch (e) {
        // console.error(e.message, e);
      }

      await eventDispatcher.reset();
    },
    identifyUser(resetUser) {
      if (resetUser) {
        refreshExternalAppUserInfo(this.info);
        return;
      } else if (!this.shareLogin) {
        identifyUser(null);
        return;
      } else if (this.shareLogin.preview) return;

      const {
        id,
        type,
        assetId,
        assetType,
        assetName,
        companyId,
        companyName,
        companyDescription,
        email,
        phoneNumber,
        firstName,
        lastName,
        externalReference
      } = this.shareLogin;
      identifyUser(`share-${id}`, {
        type,
        asset_id: assetId,
        asset_type: assetType,
        asset_name: assetName,
        company_id: companyId,
        company_name: companyName,
        company_description: companyDescription,
        email,
        phone_number: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        reference: externalReference
      });
    },
    async validateShareAssetHash(data) {
      const { login, alreadyAccess, token, expiresInHours } =
        await apiValidateHash(data);

      await this.clearShareLogin();

      if (!alreadyAccess || (alreadyAccess && data.preview)) {
        this.shareLogin = {
          ...login,
          preview: alreadyAccess && data.preview === true
        };
        this.shareToken = token;

        if (
          this.shareLogin.type === 'web' ||
          this.shareLogin.type === 'named'
        ) {
          this.shareLoginHandler = setTimeout(
            async () => {
              await this.clearShareLogin(true);
            },
            1000 * 60 * 60 * expiresInHours
          ); // X hours
        }

        if (!data.preview) this.identifyUser();
      }

      return { login, alreadyAccess, preview: data.preview };
    },
    async requestReset(data) {
      await apiRequestReset(data);
    },
    async resetPasswordStart(data) {
      return await apiResetPasswordStart(data);
    },
    async resetPasswordVerifyCode(data) {
      return await apiResetPasswordVerifyCode(data);
    },
    async resetPasswordVerifyPassword(data) {
      return await apiResetPasswordVerifyPassword(data);
    },
    async resetPasswordNextLogin(data) {
      if (!this.info) return;
      const result = await apiResetPasswordNextLogin(data);
      await this.refresh();

      return result;
    },
    async setDisplayMode(mode) {
      this.displayMode = mode;
    },
    async setRouter(router) {
      this.router = router;
    },
    async refreshToken() {
      await apiRefreshToken();
    },
    async setTrackFirstParticipantEvent(firstParticipantEvent) {
      this.trackFirstParticipantEvent = firstParticipantEvent;
    },
    async logoutWithoutCallingServer(options = {}) {
      try {
        const { redirectToLogin = true } = options || {};
        await disconnectFromSocket();
        await eventDispatcher.reset();

        if (redirectToLogin) {
          const appStore = useAppStore();
          await appStore.setNavigateTo({ name: 'login' });
        }
      } catch (e) {
        // console.error('logoutWithoutCallingServer', e);
      }
    },
    async mfaSetup() {
      return await apiMfaSetup();
    },
    async mfaSetupAtLogin() {
      return await apiMfaSetupAtLogin();
    },
    async mfaVerifyPassword(options) {
      return await apiMfaVerifyPassword(options);
    },
    async mfaVerifyCodeEmail(options) {
      return await apiMfaVerifyCodeEmail(options);
    },
    async getMfaSecret() {
      return await apiGetMfaSecret();
    },
    async mfaVerifyCode(options) {
      const { valid, user } = await apiMfaVerifyCode(options);

      const { generateAuthToken } = options;
      if (generateAuthToken) {
        await this.setInfo(user);

        await connectToSocket(this);

        track('login', {
          time: new Date().toISOString(),
          user_id: user.id
        });
      }

      return { valid };
    },
    async mfaDeactivate() {
      await apiMfaDeactivate();
    },
    async clearShareLogin(redirectToRoot = false) {
      if (this.shareLoginHandler) clearTimeout(this.shareLoginHandler);
      this.shareLoginHandler = null;

      if (
        redirectToRoot &&
        useAppStore().currentRoute?.name?.endsWith('-share')
      ) {
        useRouter().push({ name: 'root' });
      }

      this.shareLogin = null;
      this.shareToken = null;
      this.identifyUser();
    }
  }
});

export default {
  key,
  use: useAuthStore
};
