<template>
  <BaseDialogue
    class="folder-form-dialogue"
    :class="$attrs.class"
    :title="$t(`folderForm.label.${mode}`)"
    size="small"
    @cancel="$emit('cancel')"
    @ok="ok()"
    :disableOk="!isValid"
    data-testid="folder-form-dialogue"
  >
    <div
      class="form-element folder-form-dialogue__base"
      @keydown.enter="ok"
      tabindex="0"
    >
      <BorderField>
        <BaseInput
          ref="name"
          class="label-bold"
          :label="$t('folderForm.name')"
          name="name"
          :maxlength="150"
          v-model.trim="form.name"
        />
      </BorderField>
      <BorderField>
        <SliderContent :label-bold="true" :label="$t('folderForm.options')">
          <div v-if="!item.board.sandBox" class="form-field">
            <BaseCheckbox
              class="form-field-protected"
              name="protected"
              :label="$t('folderForm.protected')"
              :disabled="!isAuthorBoard"
              v-model="form.protected"
            />
          </div>
        </SliderContent>
      </BorderField>
    </div>

    <template #ok>{{ $t(`dialogue.footer.${mode}`) }}</template>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useFolderStore } from '@/stores/folder';
import '../../assets/stylesheets/components/_form.scss';
import { diff } from '../../utils/object';
import BaseDialogue from '../dialogues/BaseDialogue';
import SliderContent from '../forms/SliderContent';
import BorderField from '../forms/BorderField';
import authMixins from '../mixins/auth';
import assetMixins from '../mixins/asset';
import ToastAlert from '../toast/ToastAlert';
import { FOLDER_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'FolderFormDialogue',
  mixins: [authMixins, assetMixins],
  components: {
    BaseDialogue,
    SliderContent,
    BorderField,
    ToastAlert
  },
  props: {
    parent: {
      type: Object,
      default: null
    },
    editItem: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const { name = '', protected: isProtected = false } = this.editItem;

    const item = { ...this.editItem };
    if (!item.id) {
      item.type = FOLDER_TYPE;
      item.parent = this.parent;
      item.board = this.parent.board;
    }

    return {
      form: {
        name,
        protected: isProtected
      },
      item,
      error: ''
    };
  },
  computed: {
    mode() {
      return this.editItem.id ? 'edit' : 'add';
    },
    isValid() {
      return !!this.form.name;
    }
  },
  methods: {
    ...mapActions(useFolderStore, ['modify']),
    async ok() {
      if (!this.submitted) {
        this.submitted = true;
        try {
          const data = this.editItem
            ? {
                ...diff(this.editItem, this.form),
                id: this.editItem.id
              }
            : this.form;

          if (!this.editItem.id) {
            data.parentId = this.item.parent.id;
          }

          const folder = await this.modify(data);
          this.$emit('success', folder);
        } catch (e) {
          this.submitted = false;
          this.error = this.$t('folderForm.error');
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { name } = this.$refs;
      name.focus();
    });
  }
};
</script>

<style lang="scss">
.folder-form-dialogue {
  .dialogue__header,
  .dialogue__footer {
    background-color: $folder-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }
}
</style>
