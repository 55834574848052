<template>
  <BaseDialogue
    class="mfa-setup-dialogue"
    :class="$attrs.class"
    :title="$t('mfaSetupDialogue.title')"
    :showOk="false"
    @cancel="$emit('cancel')"
  >
    <transition :name="transitionName" @afterEnter="focus">
      <div
        v-if="step === STEP_VERIFY_PASSWORD"
        class="form-element mfa-setup-dialogue__password"
      >
        <div class="mfa-setup-dialogue__title">
          {{ $t('mfaSetupDialogue.password.title') }}
        </div>

        <div class="mfa-setup-dialogue__field">
          <BasePasswordInput
            ref="password"
            :label="$t('mfaSetupDialogue.password.label')"
            id="password"
            name="password"
            centered
            v-model="password"
            autocomplete="off"
            :placeholder="$t('mfaSetupDialogue.password.placeholder')"
            @done="verifyPassword"
          />
        </div>

        <div class="mfa-setup-dialogue__action">
          <CallToAction
            size="auto"
            :disabled="!canVerifyPassword"
            @v-click="verifyPassword"
          >
            {{ $t('mfaSetupDialogue.password.action') }}
          </CallToAction>
        </div>
      </div>

      <div
        v-else-if="step === STEP_VERIFY_CODE_EMAIL"
        class="form-element mfa-setup-dialogue__code-email"
      >
        <div class="mfa-setup-dialogue__title">
          {{ $t('mfaSetupDialogue.code-email.title') }}
        </div>
        <div class="mfa-setup-dialogue__info">
          {{ $t('mfaSetupDialogue.code-email.info', [currentEmail]) }}
        </div>

        <div class="mfa-setup-dialogue__field">
          <BaseInput
            ref="code-email"
            :label="$t('mfaSetupDialogue.code-email.label')"
            id="code-email"
            name="code-email"
            centered
            v-model="codeEmail"
            autocomplete="off"
            :placeholder="$t('mfaSetupDialogue.code-email.placeholder')"
            :maxlength="6"
            @done="verifyCodeEmail"
          />
        </div>

        <div class="mfa-setup-dialogue__action">
          <CallToAction
            size="auto"
            :disabled="!canVerifyCodeEmail"
            @v-click="verifyCodeEmail"
          >
            {{ $t('mfaSetupDialogue.code-email.action') }}
          </CallToAction>
        </div>

        <div class="mfa-setup-dialogue__warning">
          {{ $t('mfaSetupDialogue.code-email.warning') }}
        </div>
      </div>

      <div
        v-else-if="step === STEP_CONFIGURE"
        class="form-element mfa-setup-dialogue__configure"
      >
        <div class="mfa-setup-dialogue__title">
          {{ $t('mfaSetupDialogue.configure.title') }}
        </div>
        <div class="mfa-setup-dialogue__info">
          {{ $t('mfaSetupDialogue.configure.info') }}
        </div>

        <div class="mfa-setup-dialogue__steps">
          <BorderField>
            <div class="mfa-setup-dialogue__step">
              <span class="mfa-setup-dialogue__step--image image1"></span>
              <div class="mfa-setup-dialogue__step--info">
                <div class="mfa-setup-dialogue__step--info--section-title">
                  {{ $t('mfaSetupDialogue.configure.step1.sectionTitle') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--title">
                  {{ $t('mfaSetupDialogue.configure.step1.title') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--details">
                  {{ $t('mfaSetupDialogue.configure.step1.info') }}
                </div>
              </div>
            </div>
          </BorderField>
          <BorderField>
            <div class="mfa-setup-dialogue__step">
              <img
                class="mfa-setup-dialogue__step--img image2"
                :src="imageUrl"
              />
              <div class="mfa-setup-dialogue__step--info">
                <div class="mfa-setup-dialogue__step--info--section-title">
                  {{ $t('mfaSetupDialogue.configure.step2.sectionTitle') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--title">
                  {{ $t('mfaSetupDialogue.configure.step2.title') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--details">
                  <div>
                    {{ $t('mfaSetupDialogue.configure.step2.info.title') }}
                  </div>
                  <ul>
                    <li>
                      {{ $t('mfaSetupDialogue.configure.step2.info.label1') }}
                    </li>
                    <li>
                      {{ $t('mfaSetupDialogue.configure.step2.info.label2') }}
                    </li>
                  </ul>
                </div>
                <div class="mfa-setup-dialogue__step--info--cannot-scan">
                  <div>
                    (<a href="#" @click="toggleDisplayCannotScan">{{
                      $t('mfaSetupDialogue.configure.step2.cannotScan.label')
                    }}</a
                    >)
                  </div>
                  <div
                    v-if="displayCannotScan"
                    class="mfa-setup-dialogue__step--info--cannot-scan--inner"
                  >
                    <div class="mfa-setup-dialogue__step--info">
                      {{
                        $t('mfaSetupDialogue.configure.step2.cannotScan.info1')
                      }}
                    </div>
                    <div
                      class="mfa-setup-dialogue__step--info secret user-select-all"
                    >
                      {{ secret }}
                    </div>
                    <div class="mfa-setup-dialogue__step--info last">
                      {{
                        $t('mfaSetupDialogue.configure.step2.cannotScan.info2')
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BorderField>
          <BorderField>
            <div class="mfa-setup-dialogue__step">
              <span class="mfa-setup-dialogue__step--image image3"></span>
              <div class="mfa-setup-dialogue__step--info">
                <div class="mfa-setup-dialogue__step--info--section-title">
                  {{ $t('mfaSetupDialogue.configure.step3.sectionTitle') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--title">
                  {{ $t('mfaSetupDialogue.configure.step3.title') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--details">
                  {{ $t('mfaSetupDialogue.configure.step3.info') }}
                </div>
                <div class="mfa-setup-dialogue__step--info--verify">
                  <div class="mfa-setup-dialogue__step--info--verify--input">
                    <BaseInput
                      ref="code"
                      id="code"
                      name="code"
                      v-model="code"
                      autocomplete="off"
                      :placeholder="
                        $t('mfaSetupDialogue.configure.step3.placeholder')
                      "
                      :maxlength="6"
                      @done="verifyCodeAndActivate"
                    />
                  </div>

                  <CallToAction
                    size="auto"
                    :disabled="!canVerifyCodeAndActivate"
                    @v-click="verifyCodeAndActivate"
                  >
                    {{ $t('mfaSetupDialogue.configure.step3.action') }}
                  </CallToAction>
                </div>
              </div>
            </div>
          </BorderField>
        </div>
      </div>
    </transition>

    <ToastAlert v-if="error" level="error" @cancel="error = ''">
      {{ error }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import BaseDialogue from '../dialogues/BaseDialogue';
import CallToAction from '../forms/CallToAction';
import BorderField from '../forms/BorderField';
import ToastAlert from '../toast/ToastAlert';
import authMixin from '../mixins/auth';

const STEP_VERIFY_PASSWORD = 'verify/password';
const STEP_VERIFY_CODE_EMAIL = 'verify/code/email';
const STEP_CONFIGURE = 'verify/code';

export default {
  inheritAttrs: false,
  name: 'MfaSetupDialogue',
  mixins: [authMixin],
  components: {
    BaseDialogue,
    CallToAction,
    BorderField,
    ToastAlert
  },
  props: {
    nextStep: {
      type: String,
      required: true
    },
    fromLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: '',
      codeEmail: '',
      imageUrl: '',
      secret: '',
      code: '',
      displayCannotScan: false,
      submitted: false,
      step: this.nextStep,
      transitionName: 'prev',
      error: ''
    };
  },
  computed: {
    STEP_VERIFY_PASSWORD() {
      return STEP_VERIFY_PASSWORD;
    },
    STEP_VERIFY_CODE_EMAIL() {
      return STEP_VERIFY_CODE_EMAIL;
    },
    STEP_CONFIGURE() {
      return STEP_CONFIGURE;
    },
    canVerifyPassword() {
      return !!(this.password || '').trim();
    },
    canVerifyCodeEmail() {
      return (this.codeEmail || '').trim().length === 6;
    },
    canVerifyCodeAndActivate() {
      return (this.code || '').trim().length === 6;
    },
    currentEmail() {
      if (!this.currentUser) return '';
      return this.currentUser.email;
    }
  },
  methods: {
    ...mapActions(useAuthStore, [
      'mfaVerifyPassword',
      'mfaVerifyCodeEmail',
      'getMfaSecret',
      'mfaVerifyCode'
    ]),
    async verifyPassword() {
      if (this.submitted) return;
      if (!this.canVerifyPassword) return;

      this.submitted = true;

      try {
        this.codeEmail = '';
        const result = await this.mfaVerifyPassword({
          password: this.password
        });

        const { valid } = result;

        if (valid) {
          this.next();
        } else {
          this.error = this.$t('mfaSetupDialogue.password.incorrect');
        }
      } catch (e) {
        console.error(e);
        this.submitted = false;
        this.error = this.$t('global.error');
      } finally {
        this.submitted = false;
      }
    },
    async verifyCodeEmail() {
      if (this.submitted) return;
      if (!this.canVerifyCodeEmail) return;

      this.submitted = true;

      try {
        this.imageUrl = '';
        const { valid, imageUrl } = await this.mfaVerifyCodeEmail({
          code: this.codeEmail
        });

        if (valid) {
          this.imageUrl = imageUrl;
          this.next();
        } else {
          this.error = this.$t('mfaSetupDialogue.code-email.incorrect');
        }
      } catch (e) {
        console.error(e);
        this.submitted = false;
        this.error = this.$t('global.error');
      } finally {
        this.submitted = false;
      }
    },
    async verifyCodeAndActivate() {
      if (this.submitted) return;
      if (!this.canVerifyCodeAndActivate) return;

      this.submitted = true;

      try {
        const { valid } = await this.mfaVerifyCode({
          code: this.code,
          generateAuthToken: this.fromLogin
        });

        if (valid) {
          this.$emit('success');
        } else {
          this.error = this.$t('mfaSetupDialogue.configure.step3.incorrect');
          this.form.mfaCode = '';
          this.focus();
        }
      } catch (e) {
        console.error(e);
        this.submitted = false;
        this.error = this.$t('global.error');
      } finally {
        this.submitted = false;
      }
    },
    async toggleDisplayCannotScan() {
      if (!this.displayCannotScan && !this.secret) {
        if (this.submitted) return;
        this.submitted = true;
        try {
          const { secret } = await this.getMfaSecret();
          this.secret = secret;
        } catch (e) {
          console.error(e);
          this.submitted = false;
          this.error = this.$t('global.error');
        } finally {
          this.submitted = false;
        }
      }

      this.displayCannotScan = !this.displayCannotScan;
    },
    next() {
      this.transitionName = 'next';
      if (this.step === STEP_VERIFY_PASSWORD)
        this.step = STEP_VERIFY_CODE_EMAIL;
      else if (this.step === STEP_VERIFY_CODE_EMAIL) this.step = STEP_CONFIGURE;
    },
    focus() {
      if (this.step === STEP_VERIFY_PASSWORD) {
        if (this.$refs.password) this.$refs.password.focus();
      } else if (this.step === STEP_VERIFY_CODE_EMAIL) {
        if (this.$refs['code-email']) this.$refs['code-email'].focus();
      } else if (this.step === STEP_CONFIGURE) {
        if (this.$refs.code) this.$refs.code.focus();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focus();
    });
  }
};
</script>

<style lang="scss">
.mfa-setup-dialogue {
  @include next-prev-transition();

  .dialogue__header,
  .dialogue__footer {
    background-color: $account-management-base;
  }

  > .dialogue__box {
    @media screen and (min-width: #{$medium-size-break}) {
      max-width: $dialogue-width-medium;
      max-height: $dialogue-height-base;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }

  .form-element {
    @include flexy($dir: column, $shrink: 0);
    padding: $spacing-base;
    width: 100%;
    overflow-x: hidden;

    > * {
      margin: $spacing-half 0;
    }
  }

  .call-to-action {
    background-color: $account-management-base;
  }

  &__title {
    font-weight: bold;
  }

  &__field {
    > * {
      width: 100%;

      @media screen and (min-width: #{$medium-size-break}) {
        width: 353px;
      }
    }
  }

  &__field,
  &__action {
    @include flexy($just: center);
  }

  &__warning {
    text-align: center;
    font-size: $font-size-small;
    font-style: italic;
    margin-top: $spacing-base;
  }

  &__steps {
    border: 1px solid $border-base;
    padding: 0 $spacing-base;

    > * {
      margin: $spacing-base 0;
    }
  }

  &__step {
    @include grid(
      $gap: $spacing-base,
      $cols: 1fr,
      $rows: auto auto,
      $areas: 'image' 'info'
    );

    @media screen and (min-width: #{$medium-size-break}) {
      @include grid($gap: $spacing-base, $cols: auto 1fr, $areas: 'image info');
    }

    &--image {
      @include grid-item($area: image, $just: center);
      display: inline-block;
      width: 200px;
      height: 130px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--image.image1 {
      background-image: url('../../assets/img/configure2fa_step1.png');
    }

    &--image.image3 {
      background-image: url('../../assets/img/configure2fa_step3.png');
    }

    &--img {
      @include grid-item($area: image, $just: center);
      max-width: 200px;
      height: auto;
      vertical-align: middle;
      border: 0;
    }

    &--info {
      @include grid-item($area: info);
      @include flexy($dir: column, $align: flex-start);

      > * {
        margin: $spacing-half 0;
      }

      &--section-title {
        font-size: $font-size-xsmall;
        margin-top: 0;
        margin-bottom: 0;
      }

      &--title {
        font-weight: bold;
      }

      &--details {
        ul {
          list-style: inherit;
          margin: 0 0 0 2rem;
          padding-left: 0;
        }
      }

      &--cannot-scan {
        margin-bottom: 0;

        a:hover {
          text-decoration: underline;
        }

        .secret {
          background: $background-dark;
          color: $error-color;
          border: 1px solid $border-base;
          width: fit-content;
          padding: 2px 5px;
        }

        &--inner {
          margin-bottom: 0;

          > * {
            margin: $spacing-half 0;
          }

          .last {
            margin-bottom: 0;
          }
        }
      }

      &--verify {
        @include flexy($just: center);
        margin-bottom: 0;

        &--input {
          @include flexy($just: center);
          width: 80px;
          min-width: 80px;
          margin-right: $spacing-half;

          > * {
            margin: 0;
          }
        }

        .call-to-action {
          margin: 0;
        }
      }
    }

    &--info.last {
      margin-bottom: 0;
    }
  }
}
</style>
