import { TrackJS } from 'trackjs';

export const configureTrackingError = () => {
  if (!process.env.TRACKJS_TOKEN) return;

  TrackJS.install({
    token: process.env.TRACKJS_TOKEN,
    application: 'fassilio',
    version: process.env.APP_ENV,
    console: {
      display: false,
      enabled: false
    }
  });
};

export const trackError = (error) => {
  console.error(error);

  if (!process.env.TRACKJS_TOKEN) return;
  TrackJS.track(error);
};
