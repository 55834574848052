<template>
  <Teleport to="#app" :disabled="!teleport">
    <div
      ref="dialogueContainer"
      class="dialogue"
      :class="$attrs.class"
      :style="dialogueStyle"
    >
      <section
        ref="dialogue"
        tabindex="0"
        @mousedown.stop
        @keydown.stop="keydown"
        class="dialogue__box"
        :class="[
          `dialogue__box--${size}`,
          {
            'dialogue__box--no-footer':
              !(showCancel || showOk || showFooterIfEmpty) && borderRadius,
            'dialogue__box--no-header': !showHeader && borderRadius,
            'dialogue__box--content-maximized': contentMaximized
          }
        ]"
        :style="dialogueBoxStyle"
      >
        <header
          ref="header"
          v-if="displayHeader"
          class="dialogue__header"
          :style="mainStyle"
        >
          <slot name="head">
            <h1>{{ title }}</h1>
            <span v-if="showClose" class="dialogue__spacer">&nbsp;</span>
            <button
              v-if="showClose"
              class="icon-button"
              @click="$emit('cancel')"
            >
              <Cross size="small" type="light" />
            </button>
          </slot>
        </header>

        <main ref="main" class="dialogue__main" :style="mainStyle">
          <div class="dialogue__main--content" :style="mainContentStyle">
            <slot></slot>
          </div>
        </main>

        <footer
          ref="footer"
          v-if="displayFooter"
          class="dialogue__footer"
          :style="mainStyle"
        >
          <slot name="footer">
            <button
              v-if="showCancel"
              class="text-button dialogue__footer__cancel"
              @click="$emit('cancel')"
              data-testid="dialogue-cancel"
            >
              <slot name="cancel">{{ $t('dialogue.footer.cancel') }}</slot>
            </button>

            <div class="text-button dialogue__footer__custom">
              <slot name="customButtons"></slot>
            </div>
            <div class="text-button dialogue__footer__custom">
              <slot name="customButtons2"></slot>
            </div>

            <button
              v-if="showOk"
              :disabled="disableOk"
              class="text-button dialogue__footer__ok"
              @click="$emit('ok')"
              data-testid="dialogue-ok"
            >
              <slot name="ok">{{ $t('dialogue.footer.ok') }}</slot>
            </button>
          </slot>
        </footer>
      </section>
    </div>
  </Teleport>
</template>

<script>
import '../../assets/stylesheets/components/_button.scss';
import Cross from '../icons/Cross';

const menuZIndex = 5;
const SIZES = ['small', 'medium', 'maximized'];

export default {
  inheritAttrs: false,
  name: 'BaseDialogue',
  components: { Cross },
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => SIZES.includes(value)
    },
    showOk: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooterIfEmpty: {
      type: Boolean,
      default: false
    },
    disableOk: {
      type: Boolean,
      default: false
    },
    contentMaximized: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    closeOnEscape: {
      type: Function,
      default: null
    },
    zIndex: {
      type: Number,
      default: 1
    },
    borderRadius: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    maximized: {
      type: Boolean,
      default: false
    },
    teleport: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    displayHeader() {
      if (this.contentMaximized) return false;
      return this.showHeader;
    },
    displayFooter() {
      if (this.contentMaximized) return false;
      return this.showCancel || this.showOk || this.showFooterIfEmpty;
    },
    baseZIndex() {
      return menuZIndex + this.zIndex;
    },
    dialogueStyle() {
      return {
        zIndex: this.baseZIndex
      };
    },
    dialogueBoxStyle() {
      if (this.maximized) {
        return {
          top: '0',
          left: '0',
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
          transform: 'translate(0, 0)'
        };
      } else if (this.width && this.height) {
        return {
          top: '50%',
          left: '50%',
          width: this.width,
          maxWidth: this.width,
          height: this.height,
          maxHeight: this.height,
          transform: 'translate(-50%, -50%)'
        };
      }

      return null;
    },
    mainStyle() {
      return {
        zIndex: this.baseZIndex + 1
      };
    },
    mainContentStyle() {
      return {
        zIndex: this.baseZIndex + 2
      };
    }
  },
  methods: {
    mousedown() {
      if (this.autoClose) {
        this.$emit('cancel');
      }
    },
    keydown(e) {
      if (e.keyCode === 27) {
        if (!this.closeOnEscape || this.closeOnEscape()) {
          this.$emit('cancel');
        }
      }
    },
    focus() {
      const { dialogue } = this.$refs;
      dialogue.focus();
    }
  },
  mounted() {
    const { dialogue, dialogueContainer, header, main, footer } = this.$refs;

    dialogue.style.zIndex = this.baseZIndex;
    if (header) header.style.zIndex = this.baseZIndex + 1;

    main.style.zIndex = this.baseZIndex + 1;
    const mainChild = main.querySelector('.dialogue__main > :first-child');
    if (mainChild) mainChild.style.zIndex = this.baseZIndex + 2;

    if (footer) footer.style.zIndex = this.baseZIndex + 1;

    dialogueContainer.addEventListener('mousedown', this.mousedown);
    this.focus();
  },
  beforeUnmount() {
    const { dialogueContainer } = this.$refs;
    dialogueContainer.removeEventListener('mousedown', this.mousedown);
  }
};
</script>

<style lang="scss">
.dialogue {
  background-color: $overlay-base;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: default;
}

.dialogue__box {
  @include flexy($dir: column, $just: space-between);
  height: $dialogue-height-base;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;

  &--small {
    left: 50%;
    height: auto;
    top: 50%;
    transform: translate(-50%, -50%);

    > .dialogue__header {
      border-radius: $spacing-half $spacing-half 0 0;
      font-style: italic;
    }

    > .dialogue__footer {
      border-radius: 0 0 $spacing-half $spacing-half;
    }
  }

  &--no-header {
    .dialogue__main {
      border-top-left-radius: $spacing-half;
      border-top-right-radius: $spacing-half;
    }
  }

  &--no-footer {
    .dialogue__main {
      border-bottom-left-radius: $spacing-half;
      border-bottom-right-radius: $spacing-half;
    }
  }

  @include small-plus-mq {
    &--small,
    &--medium {
      max-width: $dialogue-width-small;
      max-height: $dialogue-height-base;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      > .dialogue__header {
        border-radius: $spacing-half $spacing-half 0 0;
        font-style: italic;
      }

      > .dialogue__footer {
        border-radius: 0 0 $spacing-half $spacing-half;
      }
    }
  }

  &--content-maximized {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}

.dialogue__box:focus {
  outline: none;
}

.dialogue__header {
  @include flexy($align: center, $just: center);
  background-color: $background-dark;
  color: $font-light;
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
  padding: 0 $spacing-base;
}

.dialogue__main {
  @include flexy($dir: column, $just: flex-start);
  background-color: $background-light;
  color: $font-dark;
  flex: 1;
  overflow-x: hidden;
  position: relative;

  &--content {
    @include flexy($dir: column, $just: flex-start);
    background-color: $background-light;
    color: $font-dark;
    flex: 1;
    overflow-x: hidden;
    position: relative;
  }
}

.dialogue__footer {
  @extend .dialogue__header;
  @include flexy($align: center, $just: space-between);

  &__custom {
    @include flexy($align: center);
    height: 100%;
  }

  .text-button {
    font-size: $font-size-large;
  }
}

.dialogue__spacer {
  flex: 1;
}
</style>
