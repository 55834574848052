<template>
  <label
    class="form-field"
    :class="[{ 'form-field--centered': centered }, $attrs.class]"
    :for="name"
  >
    <span class="form-field__label">{{ label }}</span>
    <div class="form-field__content">
      <v-select
        ref="autoComplete"
        class="form-field__content form-field__input"
        :modelValue="null"
        :filterable="false"
        :options="options"
        :getOptionKey="getOptionKey"
        :clearSearchOnSelect="clearSearchOnSelect"
        @option:selected="$emit('option:selected', $event)"
        @search="search"
      >
        <template #no-options>
          <slot name="no-options"></slot>
        </template>
        <template #option="option">
          <slot name="option" v-bind="option"></slot>
        </template>
      </v-select>

      <div v-if="displayAction" class="form-field__content form-field__action">
        <slot name="action"></slot>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'AutoComplete',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    centered: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    getOptionKey: {
      type: Function
    },
    clearSearchOnSelect: {
      type: Boolean,
      default: false
    },
    displayAction: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    search(e) {
      if (typeof text === 'object') e.stopPropagation();
      else this.$emit('v-search', e);
    },
    focus() {
      this.$nextTick(() => {
        const { autoComplete } = this.$refs;
        if (!autoComplete) return;

        const input = autoComplete.$el.querySelector('input');
        input.focus();
      });
    },
    clear() {
      this.$refs.autoComplete.search = '';
    }
  }
};
</script>

<style>
.form-field__input .vs__open-indicator {
  display: none;
}

.form-field__input .vs__actions {
  border-left: 0;
}
</style>

<style lang="scss" scoped>
.form-field {
  > .form-field__label {
    flex: 1;
    margin-bottom: $spacing-third;
  }

  &--centered {
    > .form-field__label {
      align-self: stretch;
      text-align: center;
    }
  }

  > .form-field__content {
    display: flex;
    width: 100%;
    height: 42px;

    > .form-field__input {
      flex-grow: 1;
    }

    > .form-field__action {
      margin-left: $spacing-half;
      height: 100%;
    }
  }
}
</style>
