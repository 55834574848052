<template>
  <div v-if="display" class="app-right-sidebar" :class="$attrs.class">
    <CommentView v-if="activeRightSideBar === COMMENT_TYPE" />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import CommentView from '../components/comment/CommentView';
import { COMMENT_TYPE } from '../utils/types';

export default {
  inheritAttrs: false,
  name: 'AppRightSideBar',
  components: {
    CommentView
  },
  computed: {
    ...mapState(useAppStore, ['activeRightSideBar']),
    display() {
      return this.activeRightSideBar === COMMENT_TYPE;
    },
    COMMENT_TYPE() {
      return COMMENT_TYPE;
    }
  }
};
</script>

<style lang="scss">
.app-right-sidebar {
  @include flexy();
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: $background-dark-2;

  @media screen and (max-width: #{$medium-size-break - 1}) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  @media screen and (min-width: #{$medium-size-break}) {
    width: 379px;
    min-width: 379px;
  }
}
</style>
