<template>
  <div
    class="form-password"
    :class="[{ 'display-toggle': displayToggle }, $attrs.class]"
  >
    <BaseInput
      ref="field"
      class="form-password__field"
      :name="name"
      :label="label"
      :type="type"
      :modelValue="modelValue"
      :placeholder="placeholder"
      :centered="centered"
      :autocomplete="autocomplete"
      :isReadOnly="isReadOnly"
      @update:modelValue="$emit('update:modelValue', $event)"
      @focusin="$emit('focusin', $event)"
      @focusout="$emit('focusout', $event)"
      @keydown="$emit('keydown', $event)"
      @done="$emit('done', $event)"
    />
    <div
      v-if="displayToggle"
      class="form-password__toggle-display"
      @click="toggle"
    >
      <EyeOpen v-if="type === 'password'" />
      <EyeClose v-else />
    </div>
  </div>
</template>

<script>
import EyeClose from '../icons/EyeClose';
import EyeOpen from '../icons/EyeOpen';

export default {
  inheritAttrs: false,
  components: {
    EyeClose,
    EyeOpen
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    centered: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    displayToggle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      type: 'password'
    };
  },
  methods: {
    toggle() {
      this.type = this.type === 'password' ? 'text' : 'password';
    },
    focus() {
      const { field } = this.$refs;
      field.focus();
    },
    select() {
      const { field } = this.$refs;
      field.select();
    }
  }
};
</script>

<style lang="scss">
.form-password {
  @include flexy($dir: column, $align: center, $shrink: 0);
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  position: relative;

  > .form-password__field {
    width: 100%;
    margin: 0 !important;
  }

  &__toggle-display {
    position: absolute;
    right: 8px;
    bottom: 1px;
    cursor: pointer;
  }
}

.form-password.display-toggle input {
  padding-right: $spacing-double !important;
}
</style>
