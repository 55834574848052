<template>
  <MediaFormDialogue
    class="form-dialogue-text"
    :class="$attrs.class"
    :title="$t(`mediaForm.text.label.${mode}`)"
    @cancel="$emit('cancel')"
    @ok="ok()"
    :disableOk="!isValid"
    v-model:error="error"
  >
    <div class="form-dialogue-text__content">
      <BaseInput
        ref="name"
        :label="$t('mediaForm.name')"
        name="name"
        :maxlength="120"
        v-model.trim="form.name"
        @keydown.tab.prevent="focusEditor"
        @done.prevent="ok"
      />

      <div class="form-dialogue-text__editor-container">
        <div
          ref="editor"
          class="form-dialogue-text__editor"
          @paste.prevent="paste"
        />
      </div>
    </div>
  </MediaFormDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import pell from 'pell';
import MediaFormDialogue from './MediaFormDialogue';
import { getForAction } from '../icons/editor';
import mediaForm from '../mixins/mediaForm';
import { getPasteHtmlFragment, convertTextToCleanHtml } from '../../utils/html';

const actions = [
  'bold',
  'italic',
  'strikethrough',
  'underline',
  'ulist',
  'olist'
];

export default {
  inheritAttrs: false,
  name: 'FormDialogueText',
  mixins: [mediaForm],
  components: {
    MediaFormDialogue
  },
  data() {
    const { name = '', content = '' } = this.editItem;

    return {
      form: {
        name,
        mediaType: 'text'
      },
      editorContent: content,
      submitted: false,
      error: ''
    };
  },
  computed: {
    isValid() {
      return !!this.form.name && !!this.editorContent;
    }
  },
  methods: {
    ...mapActions(useAppStore, {
      cleanHtml: 'cleanHtml'
    }),
    async ok() {
      if (!(this.form.name || '').trim()) {
        this.error = this.$t('mediaForm.nameRequired');
        this.select('name');
        return;
      }

      if (!(this.editorContent || '').trim()) {
        this.focusEditor();
        return;
      }

      if (!this.submitted) {
        this.submitted = true;

        try {
          this.form.content = this.editorContent;
          const media = await this.modify();
          this.$emit('success', media);
        } catch (e) {
          console.error(e);
          this.error = this.$t('mediaForm.error');
        } finally {
          this.submitted = false;
        }
      }
    },
    async paste(e) {
      if (this.submitted) return false;

      try {
        this.submitted = true;

        if (
          e &&
          e.clipboardData &&
          e.clipboardData.types &&
          e.clipboardData.getData
        ) {
          let html = e.clipboardData.getData('text/html');

          if (html) {
            html = await this.cleanHtml(getPasteHtmlFragment(html));
          } else {
            html = convertTextToCleanHtml(
              (e.clipboardData.getData('text/plain') || '').trim()
            );
          }

          document.execCommand('insertHTML', false, `<div>${html}</div>`);
        }
      } catch (e) {
        console.error(e);
        this.error = this.$t('global.error');
      } finally {
        this.submitted = false;
      }

      return false;
    },
    setEditor() {
      const { editor } = this.$refs;

      pell.init({
        element: editor,
        onChange: (html) => {
          this.editorContent = html;
        },
        defaultParagraphSeparator: 'div',
        actions: [
          ...actions.map((action) => ({
            name: action,
            icon: getForAction(action, { size: 'small' }),
            title: this.$t(`icon.${action}`)
          }))
        ],
        classes: {
          actionbar: 'editor-actions',
          button: 'button editor-button',
          content: 'editor-content',
          selected: 'editor-button-selected'
        }
      });
      editor.content.innerHTML = this.editorContent;
    },
    focusEditor() {
      const { editor } = this.$refs;
      editor.querySelector('[contenteditable=true]').focus();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setEditor();
      this.select('name');
    });
  }
};
</script>

<style lang="scss">
.form-dialogue-text {
  > .dialogue__box {
    @include media-dialogue-box();
  }

  .dialogue__main {
    overflow: hidden;
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $media-text;
  }

  .form-field {
    border: none;
  }

  &__content {
    @include flexy($dir: column);
    width: 100%;
    height: 100%;
  }

  &__editor-container {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  &__editor {
    @include flexy($dir: column);
    padding: 0 $spacing-base $spacing-base;
    height: 100%;

    > .editor-actions {
      @include flexy();
      margin: $spacing-half 0;
      max-width: $small-size-break;

      > .editor-button {
        font-size: $font-size-xlarge;
        width: $media-text-button-width;
        margin-right: $spacing-half;
      }
    }

    > .editor-content {
      @include media-text();
      border: $border-base solid $border-width;
      border-radius: $base-border-radius;
      color: $font-darker;
      flex: 1;
      padding: $spacing-half;
      outline: none;
      font-size: $font-size-medium + 2;

      a {
        text-decoration: underline;
      }
    }
  }
}
</style>
