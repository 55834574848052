<template>
  <div
    class="list-page__container"
    :class="[$attrs.class, `list-page__container--${type}`]"
  >
    <div ref="list" class="list-page" :class="`list-page--${type}`">
      <slot name="list-page-header"></slot>
      <slot></slot>

      <div v-if="loading" class="list-page--loading">
        <ListLoading />
      </div>
      <div v-else-if="empty" class="list-page-empty-text">
        {{ emptyText }}
      </div>
    </div>

    <div
      v-if="displayMoveTopButton"
      class="list-page__move-top"
      @mousedown="startMoveUp()"
      @touchstart="startMoveUp()"
    >
      <div>
        <ChevronIcon direction="up" size="small" type="light" />
      </div>
    </div>
    <div
      v-if="displayMoveBottomButton"
      class="list-page__move-bottom"
      @mousedown="startMoveDown()"
      @touchstart="startMoveDown()"
    >
      <div>
        <ChevronIcon direction="down" size="small" type="light" />
      </div>
    </div>
  </div>
</template>

<script>
import ListLoading from '../layout/ListLoading';
import ChevronIcon from '../icons/Chevron';
import { round } from '../../utils/numbers';

export default {
  inheritAttrs: false,
  name: 'ListPage',
  components: {
    ListLoading,
    ChevronIcon
  },
  props: {
    type: {
      type: String,
      default: 'all'
    },
    loading: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: null
    },
    displayScrollingMouseOver: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      movingUp: false,
      movingDown: false,
      displayMoveTop: false,
      displayMoveBottom: false,
      moveTimerHandler: null,
      touched: false
    };
  },
  computed: {
    displayMoveTopButton() {
      return this.displayScrollingMouseOver && this.displayMoveTop;
    },
    displayMoveBottomButton() {
      return this.displayScrollingMouseOver && this.displayMoveBottom;
    }
  },
  methods: {
    resize() {
      if (!this.displayScrollingMouseOver) return;

      if (this.$refs.list) {
        setTimeout(() => {
          this.scroll();
        }, 10);
      }
    },

    dragover(e) {
      let element = null;

      if (this.displayMoveTop) {
        element = e.target.closest('.list-page__move-top');
        if (element) {
          if (!this.movingUp) {
            this.startDragoverMoveUp();
          }
        }
      }
      if (this.displayMoveBottom && !element) {
        element = e.target.closest('.list-page__move-bottom');
        if (element) {
          if (!this.movingDown) {
            this.startDragoverMoveDown();
          }
        }
      }

      if (!element) {
        this.stopDragoverMoving();
      }
    },
    startDragoverMoveUp() {
      if (this.moveTimerHandler) {
        clearInterval(this.moveTimerHandler);
      }

      this.moveTimerHandler = setInterval(() => {
        const scrollTop = this.$refs.list.scrollTop - 2;
        if (scrollTop < 0) {
          clearInterval(this.moveTimerHandler);
          this.moveTimerHandler = null;
        }

        this.$refs.list.scrollTop = scrollTop;
      }, 10);

      this.movingUp = true;
      this.movingDown = false;
    },
    startDragoverMoveDown() {
      if (this.moveTimerHandler) {
        clearInterval(this.moveTimerHandler);
      }

      this.moveTimerHandler = setInterval(() => {
        let { clientHeight, scrollTop, scrollHeight } = this.$refs.list;
        scrollTop += 2;
        if (round(scrollTop + clientHeight) >= scrollHeight) {
          clearInterval(this.moveTimerHandler);
          this.moveTimerHandler = null;
        }

        this.$refs.list.scrollTop = scrollTop;
      }, 10);

      this.movingDown = true;
      this.movingUp = false;
    },
    stopDragoverMoving() {
      if (this.moveTimerHandler) {
        clearInterval(this.moveTimerHandler);
        this.moveTimerHandler = null;
      }

      this.movingUp = false;
      this.movingDown = false;
    },

    startMoveUp(touched) {
      if (this.touched) return;

      if (this.moveTimerHandler) {
        clearInterval(this.moveTimerHandler);
      }

      if (touched) {
        this.touched = true;
        document.addEventListener('touchend', this.stopMoving);
        document.addEventListener('touchcancel', this.stopMoving);
      } else {
        document.addEventListener('mouseup', this.stopMoving);
      }

      this.moveTimerHandler = setInterval(() => {
        const scrollTop = this.$refs.list.scrollTop - 10;
        if (scrollTop < 0) {
          clearInterval(this.moveTimerHandler);
          this.moveTimerHandler = null;
        }

        this.$refs.list.scrollTop = scrollTop;
      }, 10);
    },
    startMoveDown(touched) {
      if (this.touched) return;

      if (this.moveTimerHandler) {
        clearInterval(this.moveTimerHandler);
      }

      if (touched) {
        this.touched = true;
        document.addEventListener('touchend', this.stopMoving);
        document.addEventListener('touchcancel', this.stopMoving);
      } else {
        document.addEventListener('mouseup', this.stopMoving);
      }

      this.moveTimerHandler = setInterval(() => {
        let { clientHeight, scrollTop, scrollHeight } = this.$refs.list;
        scrollTop += 10;
        if (round(scrollTop + clientHeight) >= scrollHeight) {
          clearInterval(this.moveTimerHandler);
          this.moveTimerHandler = null;
        }

        this.$refs.list.scrollTop = scrollTop;
      }, 10);
    },
    stopMoving() {
      if (this.moveTimerHandler) {
        clearInterval(this.moveTimerHandler);
      }

      if (this.touched) {
        document.removeEventListener('touchend', this.stopMoving);
        document.removeEventListener('touchcancel', this.stopMoving);
      } else {
        document.removeEventListener('mouseup', this.stopMoving);
      }

      this.touched = false;
    },

    scroll() {
      if (!this.displayScrollingMouseOver) return;

      const { clientHeight, scrollTop, scrollHeight } = this.$refs.list;
      this.displayMoveTop = scrollTop > 0;
      this.displayMoveBottom = round(scrollTop + clientHeight) < scrollHeight;
    },
    displayScrollingMouseOverHasChanged() {
      this.resize();

      window.removeEventListener('resize', this.resize);
      window.removeEventListener('orientationchange', this.resize);
      document.removeEventListener('dragover', this.dragover);
      this.$refs.list.removeEventListener('scroll', this.scroll);

      if (this.displayScrollingMouseOver) {
        this.resize();

        window.addEventListener('resize', this.resize);
        window.addEventListener('orientationchange', this.resize);

        document.addEventListener('dragover', this.dragover);

        this.$refs.list.addEventListener('scroll', this.scroll);
      }
    }
  },
  mounted() {
    this.displayScrollingMouseOverHasChanged();
  },
  beforeUnmount() {
    if (this.displayScrollingMouseOver) {
      window.removeEventListener('resize', this.resize);
      window.removeEventListener('orientationchange', this.resize);

      document.removeEventListener('dragover', this.dragover);

      this.$refs.list.removeEventListener('scroll', this.scroll);
    }
  },
  watch: {
    displayScrollingMouseOver() {
      this.displayScrollingMouseOverHasChanged();
    }
  }
};
</script>

<style lang="scss" scoped>
.list-page {
  @include flexy($dir: column);
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $background-list;

  &__container {
    @include flexy($dir: column);
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;

    &--media {
      background-color: $background-list-media;
    }

    &--evaluation {
      background-color: $background-list-evaluation;
    }

    &--background {
      background-color: $background-list-background;
    }
  }

  &--media {
    background-color: $background-list-media;
  }

  &--evaluation {
    background-color: $background-list-evaluation;
  }

  &--background {
    background-color: $background-list-background;
  }

  &--loading {
    @include flexy($dir: column, $just: center, $align: center);
    flex: 1;

    > img {
      width: $loading-size-base;
      height: $loading-size-base;
    }
  }

  @include item-list-empty();

  &__move-top,
  &__move-bottom {
    @include flexy($align: center, $just: center);
    height: 40px;
    position: absolute;
    left: 0;
    right: 50px;
    cursor: pointer;

    > * {
      @include flexy($align: center, $just: center);
      width: 40px;
      height: 40px;
      background-color: rgba(63, 65, 69, 0.5); // #3f4145
    }
  }

  &__move-top {
    top: 0;
  }

  &__move-bottom {
    bottom: 0;
  }
}
</style>
