<template>
  <section class="app-clipboard-container" :class="$attrs.class">
    <div
      v-if="
        isSelectionMode || isSearchSelectionMode || clipboardAssets.size > 0
      "
      class="app-clipboard"
    >
      <div class="app-clipboard__inner">
        <div class="app-clipboard__left">
          <button
            class="icon-button menu-button clipboard-menu-button"
            @click="viewClipboard"
          >
            <ClipboardIcon :type="'active'" :text="clipboardAssets.size" />
          </button>
          <span class="app-clipboard__info">
            <span v-if="clipboardAssets.size">{{ clipboardAssets.size }}</span>
            <span
              :class="{
                'app-clipboard__info--empty': clipboardAssets.size === 0
              }"
              >{{ assetTypeDescription }}</span
            >
          </span>
        </div>

        <div class="app-clipboard__right">
          <div
            class="app-clipboard__action"
            :class="{ 'app-clipboard__action--paste-here': canPasteOrMoveHere }"
            @click="_paste"
          >
            <span>{{ actionDescription }}</span>
          </div>

          <button
            class="icon-button menu-button action-menu-button"
            :class="{ 'menu-button--active': activeMenu === 'clipboardAction' }"
            @click="toggleMenu('clipboardAction')"
          >
            <Action
              :type="activeMenu === 'clipboardAction' ? 'dark' : 'active'"
            />
          </button>
        </div>
      </div>
    </div>

    <!-- Clipboard menu -->
    <transition name="slide">
      <SlideMenu
        v-if="activeMenu === 'clipboardAction'"
        slide="down-2"
        anchor="right"
      >
        <MenuItem
          v-if="clipboardAction === 'evaluation' && clipboardAssets.size > 0"
          :label="$t('clipboardMenu.addToEvaluation')"
          @selected="addToEvaluation"
        />
        <MenuItem
          v-else-if="isSelectionMode && clipboardAction !== 'evaluation'"
          :label="$t('selectionMode.exit')"
          @selected="exit"
        />

        <MenuItem
          :label="$t('clipboardMenu.displayClipboard')"
          @selected="viewClipboard()"
        />
        <MenuItem
          :label="$t('clipboardMenu.emptyClipboard')"
          @selected="clearClipboard()"
        />

        <MenuItem
          v-if="clipboardAction === 'evaluation'"
          :label="$t('clipboardMenu.cancel')"
          @selected="cancelEvaluation"
        />
        <MenuItem
          v-else-if="canPasteOrMoveHere"
          :label="pasteDescription"
          @selected="_paste"
        />
      </SlideMenu>
    </transition>

    <ToastAlert
      v-if="activeToast === 'confirmPaste'"
      prompt
      level="warning"
      @ok="acceptPaste"
      @cancel="toggleToast()"
    >
      <div>{{ $t(confirmMessagePaste) }}</div>
      <div>
        {{
          clipboardAction === 'copy'
            ? $t('clipboardMenu.continueCopying')
            : $t('clipboardMenu.continueMoving')
        }}
      </div>
    </ToastAlert>
    <ToastAlert
      v-else-if="activeToast === 'confirmCancelEvaluation'"
      prompt
      level="warning"
      @ok="acceptCancelEvaluation"
      @cancel="toggleToast()"
    >
      {{ $t('clipboardMenu.confirmCancelEvaluation') }}
    </ToastAlert>

    <ViewClipboardDialogue
      v-if="activeDialogue === 'viewClipboard'"
      @cancel="toggleDialogue('viewClipboard')"
    />
    <EvaluationFormDialogue
      v-if="activeDialogue === 'editEvaluation'"
      :evaluation="evaluation"
      @select="selectEvaluationItems"
      @success="closeEvaluation"
      @cancel="closeEvaluation"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useClipboardStore } from '@/stores/clipboard';
import Action from '../icons/Action';
import ClipboardIcon from '../icons/ClipboardIcon';
import ToastAlert from '../toast/ToastAlert';
import MenuItem from '../menus/MenuItem';
import SlideMenu from '../menus/SlideMenu';
import toggleState from '../mixins/toggleState';
import selectionClipboardMixin from '../mixins/selectionClipboard';
import ViewClipboardDialogue from './ViewClipboardDialogue';
import EvaluationFormDialogue from '../evaluation/EvaluationFormDialogue';
import { CARD_TYPE } from '../../utils/types';

export default {
  inheritAttrs: false,
  name: 'ClipboardBar',
  mixins: [
    selectionClipboardMixin,
    toggleState('toast'),
    toggleState('menu'),
    toggleState('dialogue')
  ],
  components: {
    ClipboardIcon,
    ToastAlert,
    Action,
    MenuItem,
    SlideMenu,
    ViewClipboardDialogue,
    EvaluationFormDialogue
  },
  data() {
    return {
      evaluation: null,
      submitted: false,
      confirmMessagePaste: ''
    };
  },
  computed: {
    ...mapState(useClipboardStore, {
      current: 'current',
      currentLocation: 'currentLocation',

      canPasteHere: 'canPasteHere',
      selectedBriefCount: 'selectedBriefCount',
      currentHasBrief: 'currentHasBrief'
    }),
    assetTypeDescription() {
      if (this.clipboardAssets.size > 0) {
        return this.$t(
          `clipboard.${this.selectedAssetType}`,
          this.clipboardAssets.size
        );
      } else if (this.clipboardAction === 'evaluation') {
        return this.$t('clipboard.selectCard');
      } else {
        return this.$t('clipboard.empty');
      }
    },
    canPasteOrMoveHere() {
      return (
        this.clipboardAction !== 'evaluation' &&
        this.clipboardAssets.size > 0 &&
        this.canPasteHere
      );
    },
    actionDescription() {
      if (!this.clipboardAction) return null;

      if (this.canPasteOrMoveHere) {
        return this.pasteDescription;
      } else {
        return this.$t(`clipboard.actions.${this.clipboardAction}`);
      }
    },
    pasteDescription() {
      if (this.clipboardAction === 'copy') {
        return this.$t('clipboardMenu.pasteHere');
      } else if (this.clipboardAction === 'move') {
        return this.$t('clipboardMenu.moveHere');
      }

      return '';
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useClipboardStore, {
      copy: 'copy',
      move: 'move',
      clearAll: 'reset'
    }),
    _paste() {
      if (!this.canPasteOrMoveHere) return;

      if (this.validatePaste()) {
        this.paste();
      }
    },
    validatePaste() {
      if (this.clipboardAction === 'copy' || this.clipboardAction === 'move') {
        if (this.selectedAssetType === CARD_TYPE) {
          const count = this.selectedBriefCount;
          if (count <= 0) return true;

          const hasBrief = this.currentHasBrief;
          if (count === 1 && !hasBrief) return true;
          else {
            if (hasBrief) {
              this.confirmMessagePaste = 'clipboardMenu.confirmCopyOrMoveBrief';

              this.toggleToast('confirmPaste');
            } else {
              this.displayError({
                message: this.$t(
                  'clipboard.errors.CANNOT_COPY_OR_MOVE_MULIPLE_INSTRUCTIONS'
                )
              });
            }

            return false;
          }
        }
      }

      return true;
    },
    acceptPaste() {
      this.toggleToast();
      this.paste();
    },
    async paste() {
      try {
        if (this.submitted) return;
        this.submitted = true;

        if (this.clipboardAction === 'copy') {
          const result = await this.copy();
          if (!result) this.viewClipboard();
        } else if (this.clipboardAction === 'move') {
          const result = await this.move();
          if (!result) this.viewClipboard();
        } else if (this.clipboardAction === 'evaluation') {
          this.addToEvaluation();
        }
      } catch (e) {
        if (e.status === 400) {
          this.displayError({ message: e.message });
        } else {
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
        }
      } finally {
        this.submitted = false;
      }
    },
    addToEvaluation() {
      this.evaluation = this.current;
      this.toggleDialogue('editEvaluation');
    },
    cancelEvaluation() {
      if (this.clipboardAssets.size > 0) {
        this.toggleToast('confirmCancelEvaluation');
      } else {
        this.exit();
        this.evaluation = this.current;
        this.toggleDialogue('editEvaluation');
      }
    },
    acceptCancelEvaluation() {
      this.toggleToast();
      this.exit();
      this.evaluation = this.current;
      this.toggleDialogue('editEvaluation');
    },
    viewClipboard() {
      this.toggleDialogue('viewClipboard');
    },
    exit() {
      this.quitSelectionMode();
    },
    selectEvaluationItems() {
      this.toggleDialogue();
      this.evaluation = null;
      this.clearClipboard();
    },
    closeEvaluation() {
      this.toggleDialogue();
      this.evaluation = null;
      this.clearAll();
    }
  },
  watch: {
    clipboardExecuteAction() {
      if (!this.clipboardExecuteAction) return;

      if (this.clipboardExecuteAction === 'addToEvaluation') {
        this.setExecuteActionClipboard(null);
        this.addToEvaluation();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-clipboard {
  height: $vertical-rhythm;
  min-height: $vertical-rhythm;
}

.app-clipboard__inner {
  @include flexy($align: center, $just: space-between);
  background-color: $clipboard-base;
  color: $font-light;
  height: $vertical-rhythm;
  padding: 0 $spacing-base;
}

.app-clipboard__left,
.app-clipboard__right,
.app-clipboard__info,
.app-clipboard__action {
  @include flexy($align: center);
  height: 100%;
}

.app-clipboard__info {
  font-size: $font-size-large;

  > span {
    margin-right: $spacing-half;
  }

  &--empty {
    font-style: italic;
  }
}

.app-clipboard__action {
  @include flexy($align: center, $just: flex-end);
  font-size: $font-size-large;

  &--paste-here {
    cursor: pointer;
  }
}

.app-clipboard__left {
  @include flexy($just: flex-start);

  > * {
    height: 100%;
    margin-right: $spacing-base;
  }

  > .clipboard-menu-button {
    margin-right: $spacing-half;
  }
}

.app-clipboard__right {
  @include flexy($just: flex-end);

  > * {
    height: 100%;
    margin-left: $spacing-base;
  }
}

.menu-button--active {
  @include svg-active-background($background-triangle-up-svg);
  background-position: bottom;
}
</style>
