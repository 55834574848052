import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'EyeOpen';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22.8 15"
      >
        <title>{{ text }}</title>
        <g>
          <path d="M11.4,1.5c4.1,0.1,7.9,2.4,9.8,6c-1.2,2.3-3.1,4.1-5.4,5.1c-1.4,0.6-2.8,0.9-4.3,0.9c-4.2,0-7.9-2.3-9.8-6C3.5,3.9,7.3,1.6,11.4,1.5 M11.4,0C6.5,0.1,2,3,0,7.5C2,12.2,6.6,15,11.4,15c1.6,0,3.3-0.3,4.9-1c2.9-1.3,5.3-3.6,6.5-6.5C20.8,3,16.4,0.1,11.4,0L11.4,0z" />
        </g>
        <path d="M11.4,4c-2,0-3.5,1.6-3.5,3.5c0,2,1.6,3.5,3.5,3.5c2,0,3.5-1.6,3.5-3.5C14.9,5.6,13.4,4,11.4,4z" />
      </svg>
    );
  }
};
