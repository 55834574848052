<template>
  <section class="comment" :class="$attrs.class">
    <header class="comment__header">
      <h1 class="comment__header--title">{{ title }}</h1>
      <button class="icon-button comment__header--close" @click="close">
        <CrossIcon size="small" />
      </button>
    </header>

    <div class="comment__inner">
      <ul
        ref="list"
        class="comment__messages"
        :class="{ 'comment__messages--empty': empty }"
      >
        <li
          class="comment__messages--item"
          v-for="comment in comments.values()"
          :key="comment.id"
          data-testid="comment"
        >
          <CommentItem :item="comment" @deleteComment="confirmDelete" />
        </li>

        <template v-if="!loading && empty">
          <li class="comment__messages-empty-text">
            <span>{{ $t('addCommentForm.noComments') }}</span>
          </li>
        </template>
      </ul>

      <div v-if="!_isReadOnly" class="comment__message">
        <MessageField
          ref="message"
          v-model="message"
          :placeholder="$t('addCommentForm.message')"
          @done="addMessage"
        />
      </div>
    </div>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      :relative="true"
      @ok="deleteComment"
      @cancel="closeConfirmDelete"
    >
      {{ $t('addCommentForm.confirmDelete') }}
    </ToastAlert>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useCommentStore } from '@/stores/comment';
import CrossIcon from '../icons/Cross';
import pagination from '../mixins/pagination';
import MessageField from '../forms/MessageField';
import CommentItem from './CommentItem';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'CommentView',
  mixins: [pagination, toggleState('toast')],
  components: {
    CrossIcon,
    MessageField,
    CommentItem,
    ToastAlert
  },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: '',
      deleteCommentId: null,
      submitted: false
    };
  },
  computed: {
    ...mapState(useCommentStore, [
      'parent',
      'comments',
      'loading',
      'hasNextPage'
    ]),
    empty() {
      return this.comments.length === 0;
    },
    title() {
      return this.parent ? this.parent.name : '';
    },
    _isReadOnly() {
      if (!this.parent) return true;
      return this.isReadOnly || this.parent.deleted;
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setActiveRightSideBar']),
    ...mapActions(useCommentStore, {
      reset: 'reset',
      add: 'add',
      remove: 'remove',
      setParent: 'setParent',
      load: 'getAll'
    }),
    focus() {
      this.$refs?.message?.focus();
    },
    async addMessage() {
      if (!this.message) return;

      await this.add({ content: this.message });

      const { list, message } = this.$refs;

      message.clear();
      this.focus();
      this.message = '';
      list.scrollTop = list.scrollHeight;
    },
    confirmDelete({ id }) {
      this.deleteCommentId = id;
      this.toggleToast('confirmDelete');
    },
    closeConfirmDelete() {
      this.deleteCommentId = null;
      this.toggleToast();
    },
    async deleteComment() {
      if (!this.deleteCommentId || this.submitted) return;

      this.submitted = true;

      try {
        await this.remove({ id: this.deleteCommentId });
        this.closeConfirmDelete();
        this.focus();
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    },
    close() {
      this.setActiveRightSideBar();
    }
  },
  mounted() {
    this.focus();
  },
  async beforeUnmount() {
    await this.reset();
  },
  watch: {
    parent() {
      this.focus();
    }
  }
};
</script>

<style lang="scss">
.comment {
  @include flexy($dir: column);
  width: 100%;
  background-color: $background-light;

  &__header {
    @include flexy($align: center, $just: space-between);
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    padding: 0 $spacing-base;
    border-top: 1px solid $border-navigation;

    &--title {
      font-weight: $font-weight-bold;
    }

    &--close {
      @include flexy($align: center);
    }
  }

  &__inner {
    @include flexy($dir: column, $just: flex-start);
    flex: 1;
    overflow-x: hidden;
    position: relative;
    padding-bottom: $spacing-base;

    .comment__messages {
      @include flexy($dir: column-reverse);
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      scroll-behavior: smooth;

      @include item-list-empty();

      > .comment__messages--item {
        margin: $spacing-third $spacing-base 0 $spacing-base;
      }

      &-empty-text {
        color: $font-light;
      }
    }

    > .comment__message {
      margin: $spacing-base $spacing-base 0 $spacing-base;
    }
  }
}
</style>
