import { getAttributes, mixin } from '.';
import './_icon.scss';

const name = 'MoveIcon';

export default {
  mixins: [mixin],
  name,
  render() {
    const [classes, text] = getAttributes(name, this.$props);
    return (
      <svg
        class={classes}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18.924 18.253"
      >
        <title>{{ text }}</title>
        <path
          d="M3.242,39.54,2.055,39.5H-6.676l-1.187.013a2.22,2.22,0,0,0-2.094,2.211v1.691h-.634l-1.187.013a2.219,2.219,0,0,0-2.094,2.211v9.85l4.278-4.278-.6-.6a.9.9,0,0,1,0-1.271.891.891,0,0,1,.415-.236l4.951-1.254A.9.9,0,0,1-3.74,48.5a.89.89,0,0,1,0,.441l-1.254,4.95a.9.9,0,0,1-1.092.651A.9.9,0,0,1-6.5,54.3l-.625-.625L-11.2,57.751H-1.087a2.228,2.228,0,0,0,2.224-2.224V53.836H2.828a2.228,2.228,0,0,0,2.224-2.224v-9.89A2.226,2.226,0,0,0,3.242,39.54Zm.623,12.072A1.039,1.039,0,0,1,2.828,52.65H1.137V45.637a2.225,2.225,0,0,0-1.81-2.182l-1.186-.042H-8.771V41.722h0a1.037,1.037,0,0,1,1.039-1.036H2.828a1.037,1.037,0,0,1,1.037,1.037Z"
          transform="translate(13.872 -39.498)"
        />
      </svg>
    );
  }
};
