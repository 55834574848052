<template>
  <BaseDialogue
    class="evaluation-form-dialogue"
    :class="[{ disabled: !canEdit }, $attrs.class]"
    :title="title"
    :size="size"
    @cancel="back(true)"
    @ok="ok()"
    :showOk="step !== STEP_MANAGE_MEMBERS"
    :showCancel="showCancel"
    :disableOk="!isValid"
  >
    <transition :name="transitionName" @afterEnter="focus">
      <form
        key="base"
        v-if="step === 'base'"
        class="form-element evaluation-form-dialogue__base"
        @submit.prevent
        novalidate
      >
        <BorderField>
          <BaseInput
            ref="name"
            :label="$t('evaluationForm.name')"
            name="name"
            v-model.trim="form.name"
            :isReadOnly="!canEdit"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <SliderFieldset
            class="label label-bold"
            :label="$t('evaluationForm.card.label')"
            :active="item.cardCount > 0"
            @v-click="manageCard(true)"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit' && form.type === 'criteria'">
          <SliderFieldset
            class="label label-bold"
            :label="$t('evaluationForm.criteria.label')"
            :active="item.criteriaCount > 0"
            @v-click="manageCriteria"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit' && form.type === 'survey'">
          <SliderFieldset
            class="label label-bold"
            :label="$t('evaluationForm.statement.label')"
            :active="item.statementCount > 0"
            @v-click="manageStatements"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <SliderFieldset
            class="label label-bold"
            :label="$t('evaluationForm.members.label')"
            :active="item.memberCount > 0"
            @v-click="manageMembers"
          />
        </BorderField>

        <BorderField v-if="mode === 'edit'">
          <div class="evaluation-form-dialogue__endDate">
            <div class="label">{{ $t('evaluationForm.endDate') }}</div>
            <BaseDatetimeInput
              name="endDate"
              v-model="form.endDate"
              :minDate="minEndDate"
              :clearable="false"
              :readonly="!canEdit"
              @open="refreshMinEndDate"
            />
          </div>
          <div
            v-if="canEndEvaluation"
            class="evaluation-form-dialogue__complete"
            @click="beforeEndEvaluation"
          >
            {{ $t('evaluationForm.completeEvaluation') }}
          </div>
        </BorderField>

        <div v-if="mode === 'edit'" class="evaluation-form-dialogue__start">
          <CallToAction
            v-if="canEdit"
            size="auto"
            :disabled="!canSend"
            @v-click="beforeSend"
          >
            {{
              item.editing
                ? $t('evaluationForm.restartEvaluation')
                : $t('evaluationForm.startEvaluation')
            }}
          </CallToAction>
          <CallToAction v-else size="auto" @v-click="beforeEditEvaluation">
            {{ $t('evaluationForm.editEvaluation') }}
          </CallToAction>
        </div>
      </form>

      <EvaluationCardSection
        key="manage-card"
        v-else-if="step === 'manage-card'"
        @select="selectCards"
        @countChanged="updateCardCount"
        :evaluation="item"
      />

      <EvaluationCriteriaSection
        ref="criteria"
        key="manage-criteria"
        v-else-if="step === 'manage-criteria'"
        @countChanged="updateCriteriaCount"
        :evaluation="item"
      />

      <EvaluationStatementSection
        ref="statement"
        key="manage-statement"
        v-else-if="step === 'manage-statement'"
        @countChanged="updateStatementCount"
        :evaluation="item"
      />

      <MemberManagement
        v-else-if="step === STEP_MANAGE_MEMBERS"
        :key="STEP_MANAGE_MEMBERS"
        @countChanged="memberCountChanged"
        @cancel="back(true)"
        @ok="ok()"
        :canEdit="canEdit"
        :parent="item"
        type="evaluation"
      />
    </transition>

    <ToastAlert
      v-if="activeToast === 'confirmSend'"
      prompt
      level="warning"
      @ok="sendEvaluation"
      @cancel="toggleToast()"
    >
      {{
        item.sent
          ? $t('evaluationForm.confirmRestart')
          : $t('evaluationForm.confirmStart')
      }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmSendNow'"
      prompt
      level="warning"
      @ok="sendEvaluationAndClose"
      @cancel="cancelSendEvaluationAndClose()"
    >
      {{ $t('evaluationForm.confirmRestartNow') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmEdit'"
      prompt
      level="warning"
      @ok="editEvaluation"
      @cancel="toggleToast()"
    >
      {{ $t('evaluationForm.confirmEdit') }}
    </ToastAlert>
    <ToastAlert
      v-if="activeToast === 'confirmEnd'"
      prompt
      level="warning"
      @ok="endEvaluation"
      @cancel="toggleToast()"
    >
      {{ $t('evaluationForm.confirmEnd') }}
    </ToastAlert>
  </BaseDialogue>
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useEvaluationStore } from '@/stores/evaluation';
import '../../assets/stylesheets/components/_form.scss';
import BaseDialogue from '../dialogues/BaseDialogue';
import ToastAlert from '../toast/ToastAlert';
import authMixins from '../mixins/auth';
import toggleState from '../mixins/toggleState';
import EvaluationCardSection from './EvaluationCardSection';
import EvaluationCriteriaSection from './EvaluationCriteriaSection';
import EvaluationStatementSection from './EvaluationStatementSection';
import MemberManagement from '../member/MemberManagement';
import SliderFieldset from '../forms/SliderFieldset';
import BorderField from '../forms/BorderField';
import CallToAction from '../forms/CallToAction';
import selectionClipboardMixins from '../mixins/selectionClipboard';
import { now, toDisplay } from '../../utils/date';
import { CARD_TYPE } from '../../utils/types';

const STEP_BASE = 'base';
const STEP_MANAGE_CARD = 'manage-card';
const STEP_MANAGE_CRITERIA = 'manage-criteria';
const STEP_MANAGE_STATEMENT = 'manage-statement';
const STEP_MANAGE_MEMBERS = 'manage-members';

export default {
  inheritAttrs: false,
  name: 'EvaluationFormDialogue',
  mixins: [authMixins, toggleState('toast'), selectionClipboardMixins],
  components: {
    BaseDialogue,
    ToastAlert,
    EvaluationCardSection,
    EvaluationCriteriaSection,
    EvaluationStatementSection,
    MemberManagement,
    SliderFieldset,
    BorderField,
    CallToAction
  },
  props: {
    type: {
      type: String,
      validator: function (value) {
        if (!value) return true;
        return ['criteria', 'survey'].indexOf(value) !== -1;
      }
    },
    evaluation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      item: { ...this.evaluation },
      form: {},
      submitted: false,
      step: STEP_BASE,
      transitionName: 'prev',
      minEndDate: this.getMinEndDate()
    };
  },
  computed: {
    STEP_MANAGE_MEMBERS() {
      return STEP_MANAGE_MEMBERS;
    },
    mode() {
      return this.item.id ? 'edit' : 'add';
    },
    isValid() {
      if (this.step === STEP_BASE) {
        return !!this.form.name;
      }

      return true;
    },
    canEdit() {
      if (!this.item) return false;
      return !this.item.sent || this.item.editing;
    },
    title() {
      let title = '';
      switch (this.step) {
        case STEP_BASE:
          title = `evaluationForm.title.${this.form.type}.${this.mode}`;
          break;
        case STEP_MANAGE_CARD:
          title = 'evaluationForm.card.label';
          break;
        case STEP_MANAGE_CRITERIA:
          title = 'evaluationForm.criteria.label';
          break;
        case STEP_MANAGE_STATEMENT:
          title = 'evaluationForm.statement.label';
          break;
        case STEP_MANAGE_MEMBERS:
          title = 'evaluationForm.members.label';
          break;
      }
      return this.$t(title);
    },
    size() {
      return this.mode === 'edit' ? 'medium' : 'small';
    },
    showCancel() {
      if (!this.item) return false;
      if (this.step === STEP_MANAGE_CARD || this.step === STEP_MANAGE_MEMBERS) {
        return false;
      }

      return !this.item.sent || this.item.editing;
    },
    showSend() {
      if (!this.item) return false;
      return this.step === STEP_BASE && this.mode === 'edit' && !this.sent;
    },
    canEndEvaluation() {
      return !this.canEdit && this.form.endDate > new Date();
    },
    canSend() {
      if (this.mode === 'add') return false;

      return (
        this.isValid &&
        !!this.form.endDate &&
        this.item.cardCount > 0 &&
        ((this.item.type === 'criteria' && this.item.criteriaCount > 0) ||
          (this.item.type === 'survey' && this.item.statementCount > 0)) &&
        this.item.memberCount > 0
      );
    }
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useEvaluationStore, [
      'save',
      'send',
      'end',
      'updateCards',
      'searchParticipants'
    ]),
    init() {
      const { name = '', type = this.type, endDate = null } = this.item;

      this.form = {
        name,
        type,
        endDate: endDate ? new Date(endDate) : null
      };
    },
    async ok() {
      if (!this.canEdit) {
        this.back();
        return;
      }

      switch (this.step) {
        case STEP_BASE:
          if (!this.submitted) {
            this.submitted = true;

            try {
              if (this.mode === 'add') {
                await this.create();
                return;
              } else {
                const result = await this.update();
                if (!result) return;

                if (
                  this.item &&
                  this.item.sent &&
                  this.item.editing &&
                  this.canSend
                ) {
                  this.toggleToast('confirmSendNow');
                  return;
                }
              }
            } catch (e) {
              console.error(e);
              this.displayError({ message: this.$t('global.error') });
              return;
            } finally {
              this.submitted = false;
            }
          }

          this.back();
          break;
        case STEP_MANAGE_CARD:
          this.back();
          break;
        case STEP_MANAGE_CRITERIA:
          if (!this.submitted) {
            this.submitted = true;

            try {
              const { criteria } = this.$refs;
              if (!criteria.validate()) return;

              await criteria.update();
            } catch (e) {
              console.error(e);
              this.displayError({ message: this.$t('global.error') });
              return;
            } finally {
              this.submitted = false;
            }
          }

          this.back();
          break;
        case STEP_MANAGE_STATEMENT:
          if (!this.submitted) {
            this.submitted = true;

            try {
              const { statement } = this.$refs;
              if (!statement.validate()) return;

              await statement.update();
            } catch (e) {
              console.error(e);
              this.displayError({ message: this.$t('global.error') });
              return;
            } finally {
              this.submitted = false;
            }
          }

          this.back();
          break;
        case STEP_MANAGE_MEMBERS:
          this.back();
          break;
      }
    },
    async create() {
      const { name, endDate, type } = this.form;
      const evaluation = await this.save({ name, endDate, type });

      if (evaluation.error) {
        this.displayError({ message: evaluation.error });
        return false;
      }

      this.item = evaluation;
      this.init();
      return true;
    },
    needUpdate() {
      const { name, endDate } = this.form;
      if (this.item.name !== name) return true;

      const date1 = this.item.endDate ?? '';
      const date2 = endDate ? endDate.toISOString() : '';
      return date1 !== date2;
    },
    async update() {
      const { name, endDate } = this.form;
      const evaluation = await this.save({ id: this.item.id, name, endDate });

      if (evaluation.error) {
        this.displayError({ message: evaluation.error });
        return false;
      }

      this.item = evaluation;
      this.init();

      return true;
    },
    back(cancel) {
      switch (this.step) {
        case STEP_BASE:
          if (cancel) {
            if (
              this.item &&
              this.item.sent &&
              this.item.editing &&
              this.canSend
            ) {
              this.toggleToast('confirmSendNow');
              return;
            }
          }

          this.$emit(cancel ? 'cancel' : 'success');
          break;
        case STEP_MANAGE_CARD:
        case STEP_MANAGE_CRITERIA:
        case STEP_MANAGE_STATEMENT:
        case STEP_MANAGE_MEMBERS:
          this.step = STEP_BASE;
          break;
      }

      this.transitionName = 'prev';
    },
    focus() {
      if (this.step === STEP_BASE) {
        const { name } = this.$refs;
        name.focus();
      }
    },
    async manageCard(displayClipboardIfEmpty = true) {
      try {
        if (
          !this.sent &&
          displayClipboardIfEmpty &&
          (this.item.cardCount || 0) === 0
        ) {
          if (this.needUpdate()) {
            const result = await this.update();
            if (!result) return;
          }

          await this.selectCards();
          return;
        }

        this.step = STEP_MANAGE_CARD;
      } catch (e) {
        console.error(e.message, e);
      }
    },
    async selectCards() {
      this.item.nextStep = STEP_MANAGE_CARD;

      await this.enterSelectionEvaluation({
        current: this.item
      });
      this.navigate({ name: 'root' });
      this.$emit('select');
    },
    async updateNewCards() {
      const cardsToAdd = [];
      this.clipboardAssets.forEach((asset) => {
        if (asset.type === CARD_TYPE) cardsToAdd.push(asset.id);
      });
      this.clearClipboard();
      this.quitSelection();
      this.quitSearchSelection();

      try {
        if (cardsToAdd.length > 0) {
          const evaluation = await this.updateCards({
            id: this.item.id,
            cardsToAdd
          });
          this.updateCardCount(evaluation.cardCount);
        }
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      }
    },
    updateCardCount(count) {
      this.item.cardCount = count;
    },
    updateCriteriaCount(count) {
      this.item.criteriaCount = count;
    },
    updateStatementCount(count) {
      this.item.statementCount = count;
    },
    memberCountChanged(count) {
      this.item.memberCount = count;
    },
    manageCriteria() {
      this.step = STEP_MANAGE_CRITERIA;
    },
    manageStatements() {
      this.step = STEP_MANAGE_STATEMENT;
    },
    manageMembers() {
      this.step = STEP_MANAGE_MEMBERS;
    },
    refreshMinEndDate() {
      this.minEndDate = this.getMinEndDate();
    },
    getMinEndDate() {
      return now();
    },
    beforeSend() {
      if (!this.canSend) return;

      const endDate = this.getMinEndDate();
      if (this.form.endDate <= endDate) {
        this.displayError({
          message: this.$t('evaluationForm.endDateValidation', [
            toDisplay(endDate)
          ])
        });
        return;
      }

      this.toggleToast('confirmSend');
    },
    async sendEvaluation() {
      try {
        if (this.needUpdate()) {
          const result = await this.update();
          if (!result) return false;
        }

        await this.send(this.item.id);
        this.$emit('success');
        this.toggleToast();
        return true;
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
        return false;
      }
    },
    async sendEvaluationAndClose() {
      this.toggleToast();

      const result = await this.sendEvaluation();
      if (!result) return;

      this.$emit('success');
    },
    cancelSendEvaluationAndClose() {
      this.toggleToast();
      this.$emit('cancel');
    },
    beforeEditEvaluation() {
      if (this.item.hasCompleted) {
        this.toggleToast('confirmEdit');
      } else {
        this.editEvaluation();
      }
    },
    async editEvaluation() {
      this.toggleToast();
      if (this.mode === 'add') return;

      if (!this.submitted) {
        this.submitted = true;

        try {
          const evaluation = await this.save({
            id: this.item.id,
            editing: true
          });

          if (evaluation.error) {
            this.displayError({ message: evaluation.error });
            return;
          }

          this.item = evaluation;
          this.init();
        } catch (e) {
          console.error(e);
          this.displayError({ message: this.$t('global.error') });
          return;
        } finally {
          this.submitted = false;
        }
      }
    },
    beforeEndEvaluation() {
      if (this.item.completed) {
        this.endEvaluation();
      } else {
        this.toggleToast('confirmEnd');
      }
    },
    async endEvaluation() {
      if (this.submitted) return;

      try {
        this.submitted = true;
        await this.end(this.item.id);
        this.toggleToast();
        this.$emit('success');
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    }
  },
  async beforeMount() {
    this.init();

    if (this.item.nextStep === STEP_MANAGE_CARD) {
      delete this.item.nextStep;

      if (
        this.clipboardAction === 'evaluation' &&
        this.clipboardCurrent &&
        this.clipboardCurrent.id === this.item.id &&
        this.clipboardAssets.size > 0
      ) {
        await this.updateNewCards();
      }

      await this.manageCard(false);
    }
  },
  mounted() {
    this.focus();
  }
};
</script>

<style lang="scss">
@import '../../assets/stylesheets/components/button';
</style>

<style lang="scss">
.evaluation-form-dialogue {
  @include next-prev-transition();

  &__endDate {
    @include flexy($align: center);
    margin-left: $spacing-base;
  }

  &__complete {
    @include flexy($align: center, $just: center);
    text-transform: uppercase;
    margin-bottom: $spacing-base;
    cursor: pointer;
  }

  &__start {
    @include flexy($align: center, $just: center);
    height: $vertical-rhythm;
    min-height: $vertical-rhythm;
    margin-top: $spacing-double;

    > button {
      background-color: $evaluation-base;
    }
  }

  .dialogue__header,
  .dialogue__footer {
    background-color: $evaluation-base;
  }

  .form-element {
    background-color: $background-light;
    height: 100%;
    width: 100%;
  }
}

.evaluation-form-dialogue.disabled {
  .label,
  .form-field__label {
    opacity: $disable-opacity;
  }
}
</style>
