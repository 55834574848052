<template>
  <BaseDialogue
    :class="[
      'preview-share-dialogue',
      'preview-share-dialogue--share-asset',
      $attrs.class
    ]"
    :title="title"
    :showOk="false"
    :showCancel="false"
    :showClose="true"
    :showFooterIfEmpty="false"
    maximized
  >
    <iframe :src="url"></iframe>
  </BaseDialogue>
</template>

<script>
import BaseDialogue from '@/components/dialogues/BaseDialogue';

export default {
  name: 'PreviewShareDialogue',
  components: {
    BaseDialogue
  },
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.preview-share-dialogue {
  @include color-form();

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
