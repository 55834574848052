<template>
  <OptionsDialogue
    v-if="activeDialogue === 'options'"
    class="chat-options-dialogue"
    :class="$attrs.class"
    :title="$t('chatOptions.title')"
    :item="item"
    type="chat"
    @cancel="$emit('cancel')"
  >
    <ul>
      <MenuItem
        :label="$t('chatOptions.manageMembers')"
        @selected="edit(true)"
      />
      <MenuItem :label="$t('chatOptions.edit')" @selected="edit(false)" />
      <MenuItem :label="$t('chatOptions.delete')" @selected="confirmDelete" />
    </ul>

    <ToastAlert
      v-if="activeToast === 'confirmDelete'"
      prompt
      level="warning"
      @ok="_delete"
      @cancel="closeConfirmDelete"
    >
      {{ $t('chatOptions.confirmDelete') }}
    </ToastAlert>
  </OptionsDialogue>

  <ChatFormDialogue
    v-else-if="activeDialogue === 'edit'"
    :class="$attrs.class"
    :editItem="item"
    :addMembers="addMembers"
    @success="$emit('cancel')"
    @cancel="$emit('cancel')"
  />
</template>

<script>
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useChatStore } from '@/stores/chat';
import OptionsDialogue from '../dialogues/OptionsDialogue';
import MenuItem from '../menus/MenuItem';
import ChatFormDialogue from './ChatFormDialogue';
import ToastAlert from '../toast/ToastAlert';
import toggleState from '../mixins/toggleState';

export default {
  inheritAttrs: false,
  name: 'ChatOptionsDialogue',
  mixins: [toggleState('dialogue', 'options'), toggleState('toast')],
  components: {
    OptionsDialogue,
    MenuItem,
    ChatFormDialogue,
    ToastAlert
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      addMembers: false
    };
  },
  methods: {
    ...mapActions(useAppStore, ['displayError']),
    ...mapActions(useChatStore, ['remove']),
    edit(addMembers) {
      this.addMembers = addMembers;
      this.toggleDialogue('edit');
    },
    confirmDelete() {
      this.toggleToast('confirmDelete');
    },
    closeConfirmDelete() {
      this.toggleToast();
    },
    async _delete() {
      if (this.submitted) return;

      this.submitted = true;

      try {
        await this.remove({ id: this.item.id });
        this.closeConfirmDelete();
        this.$emit('cancel');
      } catch (e) {
        console.error(e);
        this.displayError({ message: this.$t('global.error') });
      } finally {
        this.submitted = false;
      }
    }
  }
};
</script>
